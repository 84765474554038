import { useState } from "react";
import { RootState, useDispatch, useSelector } from "src/store";

import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import useFetchInterval from "@devices/hooks/useFetchInterval";
import { Device } from "@devices/types";
import { fetchFiles } from "@files/store";
import { selectFilesByGroupIdWithShared, selectFilesListStatus } from "@files/store/selectors";
import { hasUnsuccessfulAnalyzer, isApk, normalizeFiles } from "@files/utils";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getEnv } from "@services/configService";
import Table from "@shared/table/Table";

import TableItem from "./TableItem";

const useStyles = makeStyles({
    content: { maxHeight: "100%", overflowY: "scroll", marginBottom: 60 },
});

interface Props {
    device: Device;
    groupId: number;
}

const FileTab = (props: Props) => {
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
    const classes = useStyles();
    const dispatch = useDispatch();

    const status = useSelector(selectFilesListStatus);
    const files = useSelector((state: RootState) => selectFilesByGroupIdWithShared(state, props.groupId));

    const apks = files.filter((item) => isApk(item.filename));
    const normalizedApkFiles = normalizeFiles(apks);

    const filesUploadedByDevice = files.filter((item) => item.ownerId === String(props.device.id));
    const normalizedDeviceFiles = normalizeFiles(filesUploadedByDevice);

    const reFetchFiles = async () => {
        try {
            await dispatch(fetchFiles());
        } catch (err) {
            if (getEnv() === "Development") {
                console.error("[Files] reFetchFiles failed: ", err);
            }
        }
    };

    useFetchInterval(reFetchFiles, {
        condition: hasUnsuccessfulAnalyzer([...normalizedDeviceFiles, ...normalizedApkFiles]),
    });

    if (status === "pending" && !files.length) {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.content}>
                <PaneElement.Header>Files uploaded by device</PaneElement.Header>
                {normalizedDeviceFiles.length ? (
                    <Table
                        normalizedFiles={normalizedDeviceFiles}
                        allFiles={files}
                        selectedItem={props.device}
                        component={TableItem}
                        setSelectedFileId={setSelectedFileId}
                        cellProps={{ size: "small" }}
                    />
                ) : (
                    <PaneElement.NoData message="No files were uploaded yet" />
                )}
                <PaneElement.Header>Shared applications</PaneElement.Header>
                {normalizedApkFiles.length ? (
                    <Table<Device>
                        normalizedFiles={normalizedApkFiles}
                        allFiles={files}
                        selectedItem={props.device}
                        component={TableItem}
                        setSelectedFileId={setSelectedFileId}
                        cellProps={{ size: "small" }}
                    />
                ) : (
                    <PaneElement.NoData message="No applications in this group" />
                )}
            </Box>
            {selectedFileId ? (
                <FileSettingsDialog
                    open
                    onClose={() => setSelectedFileId(null)}
                    item={[...filesUploadedByDevice, ...apks].filter((item) => item.id === selectedFileId)[0]}
                />
            ) : null}
        </PaneElement.Container>
    );
};

export default FileTab;
