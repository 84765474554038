import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";

import { useCommands } from "@dashboard/devices/components/Commands/hooks";
import { JsonTextarea } from "@dashboard/shared/components/JsonTextarea";
import { MdmPolicyCommand } from "@devices/types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface Props {
    groupId: number;
    policyCommand: MdmPolicyCommand;
    policyCommands: MdmPolicyCommand[];
    isOpen: boolean;
    toggleDialog: VoidFunction;
}

type FormValues = {
    command: MdmPolicyCommand;
};

export const EditPolicyCommandDialog = (props: Props) => {
    const [error, setError] = React.useState<string | null>(null);
    const { updatePolicyCommands } = useCommands();

    const onSubmit = async (values: FormValues) => {
        const updatedPolicyCommands = props.policyCommands.map((command) => {
            return command === props.policyCommand ? values.command : command;
        });

        await updatePolicyCommands({
            groupId: props.groupId,
            policy: JSON.stringify(updatedPolicyCommands),
            onSuccess: props.toggleDialog,
        });
    };

    const onChange = (value: string, form: FormApi<FormValues, Partial<FormValues>>) => {
        try {
            const command: MdmPolicyCommand = JSON.parse(value);
            form.restart({ command });
            setError(null);
        } catch (err) {
            setError("Error: invalid JSON format");
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.toggleDialog} scroll="paper" maxWidth="md">
            <Form<FormValues>
                validateOnBlur
                onSubmit={onSubmit}
                initialValues={{ command: props.policyCommand }}
                render={({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit} id="policyCommand">
                        <DialogTitle>Policy command editor</DialogTitle>
                        <DialogContent dividers>
                            <JsonTextarea<FormValues>
                                value={values.command}
                                error={error}
                                onChange={onChange}
                                note="Note: JSON is valid, you can override command"
                            />
                        </DialogContent>
                        <DialogActions>
                            <SecondaryButton onClick={props.toggleDialog} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                form="policyCommand"
                                loading={submitting}
                                disabled={submitting || !!error}
                                tooltipProps={{ title: "Please remove validation errors.", hide: !error }}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};
