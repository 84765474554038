import clsx from "clsx";
import { useSelector } from "src/store";

import { alternatingBg } from "@dashboard/shared/styles";
import { GroupMember } from "@devices/types";
import { Box, MenuItem, Select, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { Tooltip } from "@shared/Tooltip";
import { selectWorkspace } from "@workspaces/store/selectors";

const useStyles = makeStyles(() => ({
    borderless: {
        borderBottom: "none !important",
    },
    inputItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexShrink: 0,
        "& .MuiInputBase-root": {
            height: 36,
            fontSize: 14,
        },
    },
    nameContainer: {
        display: "flex",
        gap: 10,
        alignItems: "center",
    },
    membershipIcon: {
        fontSize: 20,
        marginBottom: -2,
        marginRight: 12,
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        textAlign: "left",
    },
}));

interface Props {
    index: number;
    member: GroupMember;
}

export const WorkspaceMemberListItem = (props: Props) => {
    const classes = useStyles();
    const workspace = useSelector(selectWorkspace);
    const workspaceRole = workspace?.members.find(
        (workspaceMember) => workspaceMember.email === props.member.email,
    )?.role;

    return (
        <TableRow style={alternatingBg(props.index)}>
            <MinimalTableCell className={classes.borderless} align="justify">
                <div className={classes.nameContainer}>
                    <Tooltip title="Workspace member" placement="top">
                        <i className={clsx("fa-solid fa-house-user", classes.membershipIcon)} />
                    </Tooltip>
                    <Typography>{props.member.email}</Typography>
                </div>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="justify">
                <Box className={classes.inputItem}>
                    <Tooltip
                        title="You can't change his role because it is his workspace role. Use workspace page to change his workspace role."
                        placement="top"
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    maxWidth: 600,
                                },
                            },
                        }}
                    >
                        <Select defaultValue="User" disabled style={{ width: 200 }}>
                            <MenuItem value="User">{workspaceRole || "User"}</MenuItem>
                        </Select>
                    </Tooltip>
                </Box>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="right">
                <div className={classes.buttonBox}>
                    <DangerOutlinedButton
                        disabled
                        tooltipProps={{
                            title: "You can't delete workspace member from group. Use workspace page to delete this member from workspace.",
                        }}
                    >
                        <i className="fas fa-trash-alt" />
                    </DangerOutlinedButton>
                </div>
            </MinimalTableCell>
        </TableRow>
    );
};
