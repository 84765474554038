import { SectionDescription } from "@dashboard/shared/styles";
import { Alert, Box } from "@mui/material";

export const ProvisioningDescription = () => {
    return (
        <Box style={{ marginBottom: 24 }}>
            <SectionDescription>
                Emteria automates building the same Android core for many different devices and applications.
                Customization and provisioning options on this page provide a possibility for flexible configuration and
                tailoring of emteria.OS to the needs of a specific product. Device provisioning can take influence on
                various parts of the Android platform settings, including pre-installing additional applications,
                changing animations and adding custom repositories for a private application store. This list of
                settings can be easily extended with additional configuration options for new products. Please
                don&apos;t hesitate to contact us, if you need to change the default behavior of a component not listed
                here.
                <br />
                <br />
                The device provisioning is performed by the SetupWizard application during the very first boot or after
                a factory reset. In order for the SetupWizard to find the settings, they must be exported and stored in
                a &quot;<i>provisioning.json</i>&quot; file on the boot partition of the device. However, this process
                may differ for different types of devices.
            </SectionDescription>
            <Alert severity="info">
                Changing provisioning settings will not affect configuration of already installed devices. It can be
                applied during the very first device boot only.
            </Alert>
        </Box>
    );
};
