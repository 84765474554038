import { Tooltip, TooltipProps } from "src/ui/shared/Tooltip";

import { MenuItem, MenuItemProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    menuItem: {
        height: "1.85rem",
    },
});

export interface DropdownItemProps extends MenuItemProps {
    button?: true;
    tooltipProps?: Omit<TooltipProps, "children">;
}

export function DropdownItem({
    children,
    tooltipProps = { title: "", hide: true },
    ...props
}: DropdownItemProps): JSX.Element {
    const classes = useStyles();

    return (
        <Tooltip {...tooltipProps}>
            <MenuItem className={classes.menuItem} {...props}>
                {children}
            </MenuItem>
        </Tooltip>
    );
}
