import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    sectionContainer: {
        paddingBottom: theme.spacing(1.5),
    },
    sectionContent: {
        fontSize: 16,
        padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} 10px`,
        margin: 0,
        flexShrink: 0,
        paddingLeft: 16,
    },
    loadingContainer: {
        display: "flex",
        width: "100%",
        height: "80vh",
        alignItems: "center",
        justifyContent: "center",
    },
    saveBtnContainer: {
        marginTop: 6,
        display: "flex",
        justifyContent: "flex-end",
    },
    pageDescriptionBox: {
        marginBottom: 12,
    },
    workspaceNameBox: {
        marginTop: 12,
        paddingBottom: 8,
    },
    guidBox: {
        marginTop: 8,
        paddingBottom: 16,
    },
    organizationNameBox: {
        marginTop: 16,
        paddingBottom: 8,
    },
    organizationVatId: {
        marginTop: 8,
    },
    guidTooltipBox: {
        display: "block",
    },
}));
