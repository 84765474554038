import clsx from "clsx";
import React from "react";
import { useSelector } from "src/store";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PrimaryButton } from "./CustomButton";
import { CustomTileContainer } from "./CustomTile";

const useStyles = makeStyles(() => ({
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
    },
    content: { width: "100%", pointerEvents: "none" },
    blur: { filter: "blur(5px)" },
    expiredCard: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        padding: 32,
        gap: 16,
        maxWidth: 400,
        alignItems: "center",
        top: 50,
        zIndex: 100,
    },
}));

export const ExpiredWrapper = (props: React.PropsWithChildren<object>) => {
    const classes = useStyles();

    const isExpired = useSelector(selectSubscriptionExpired);

    const onRenew = () => {
        window.open("https://emteria.com/p/subscription", "_blank");
    };

    if (!isExpired) {
        return <Box>{props.children}</Box>;
    }

    return (
        <Box className={classes.container}>
            <Box className={clsx(classes.content, isExpired && classes.blur)}>{props.children}</Box>
            <CustomTileContainer className={classes.expiredCard}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Subscription expired
                </Typography>
                <Typography align="center">
                    Your subscription has expired. To renew your plan and continue using our services, please reach out
                    to our team.
                </Typography>
                <PrimaryButton onClick={onRenew}>Renew subscription</PrimaryButton>
            </CustomTileContainer>
        </Box>
    );
};

export const withExpiredWrapper = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const ComponentWithExpiredWrapper: React.FC<P & React.PropsWithChildren<object>> = (props) => {
        const isExpired = useSelector(selectSubscriptionExpired);

        if (!isExpired) {
            return <WrappedComponent {...props} />;
        }

        return (
            <ExpiredWrapper>
                <WrappedComponent {...props} />
            </ExpiredWrapper>
        );
    };

    return ComponentWithExpiredWrapper;
};
