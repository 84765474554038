import clsx from "clsx";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { complexCommands, PackageTypes } from "src/data/commands";
import { useSelector } from "src/store";
import { SelectField } from "src/ui/shared/form/SelectField";

import { selectDevicesByGroupId } from "@dashboard/devices/store/selectors/devices";
import { Device, Group } from "@dashboard/devices/types";
import { JSONEditor } from "@json-editor/json-editor/dist/jsoneditor.js";
import { Box, Typography } from "@mui/material";
import { showErrorToast } from "@shared/toasts/Toasts";

import { JsonEditorCommandType, updateEditor, validateEditor } from "../helpers/jsonEditor";
import { useCommands } from "../hooks";
import { FormValues } from "../NewCommandDialog";
import { InputItemContainer, useStyles } from "../styles";

let complexEditor: JSONEditor | null = null;

type Props = {
    device?: Device;
    group?: Group;
    submitRef: React.RefObject<HTMLButtonElement>;
    editorRef: React.RefObject<HTMLDivElement>;
    isPolicy?: boolean;
    toggleDialog: () => void;
};

export const ComplexCommands = ({ device, group, submitRef, editorRef, isPolicy, toggleDialog }: Props) => {
    const styles = useStyles();

    const policyCommands = group?.policy || [];
    const devices = useSelector((state) => selectDevicesByGroupId(state, group?.id));

    const { createMdmCommand, updatePolicyCommands } = useCommands();

    useEffect(() => {
        let availablePackages: string[] = [],
            userPackages: string[] = [],
            systemPackages: string[] = [],
            launchablePackages: string[] = [];

        if (device && device.lastState?.packages) {
            const { packages } = device.lastState;
            availablePackages = packages.map((p) => p.package);

            userPackages = packages.filter((p) => !p.system).map((p) => p.package);
            systemPackages = packages.filter((p) => p.system).map((p) => p.package);
            launchablePackages = packages?.filter((p) => p.launchable).map((p) => p.package);
        } else {
            // for group commands get all packages from all devices in a group
            const allPackages = [...new Set(devices.flatMap((d) => d?.lastState?.packages || []))]
                .filter((item) => item)
                .sort();

            if (allPackages.length) {
                availablePackages = allPackages.map((p) => p.package);
                userPackages = allPackages.filter((p) => !p.system).map((p) => p.package);
                systemPackages = allPackages.filter((p) => p.system).map((p) => p.package);
                launchablePackages = allPackages.filter((p) => p.launchable).map((p) => p.package);
            }
        }

        // inject packages into jsonObject
        complexCommands.forEach((command) => {
            if (command.schema.properties?.package?.packageType == undefined) {
                return;
            }

            switch (command.schema.properties.package?.packageType) {
                case PackageTypes.AVAILABLE:
                    command.schema.properties.package.enum = availablePackages.sort() as [];
                    command.schema.properties.package.default = availablePackages[0];
                    break;
                case PackageTypes.USER:
                    command.schema.properties.package.enum = userPackages.sort() as [];
                    command.schema.properties.package.default = userPackages[0];
                    break;
                case PackageTypes.SYSTEM:
                    command.schema.properties.package.enum = systemPackages.sort() as [];
                    command.schema.properties.package.default = systemPackages[0];
                    break;
                case PackageTypes.LAUNCHABLE:
                    command.schema.properties.package.enum = launchablePackages.sort() as [];
                    command.schema.properties.package.default = launchablePackages[0];
                    break;
            }
        });

        complexEditor = updateEditor(
            complexEditor,
            editorRef,
            JsonEditorCommandType.ComplexCommands,
            complexCommands,
            complexCommands[0].name,
        );

        // destroy the editor to prevent duplicates when component is unmounting
        return () => {
            if (complexEditor) {
                complexEditor.destroy();
            }
        };
    }, [device, device?.lastState, devices, editorRef]);

    const onSubmit = async (values: FormValues) => {
        const errors = validateEditor(complexEditor);
        if (errors) {
            showErrorToast(`Error: validation failed: ${errors[0]?.message as string} `);
            return;
        }

        const input = complexEditor && complexEditor.getValue();

        // Merge input with command type
        const command = complexCommands.find((obj) => obj.name == values.commandType);
        if (!command) {
            showErrorToast("Command not found");
            return;
        }

        const payload = { "command": values.commandType, "caption": command.schema.title, ...input };

        if (isPolicy && group) {
            const policyPayload = Array.isArray(policyCommands) && [...policyCommands, payload];
            await updatePolicyCommands({ groupId: group.id, policy: JSON.stringify(policyPayload) });
        } else {
            await createMdmCommand({ groupId: group?.id, deviceId: device?.id, json: payload });
        }
        toggleDialog();
    };

    return (
        <React.Fragment>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={{ commandType: complexCommands[0].name }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} id="editCommand">
                        <div className={styles.sectionContainer}>
                            <InputItemContainer>
                                <Box className={clsx([styles.input, styles.select])}>
                                    <div className="form-group">
                                        <Typography style={{ marginBottom: 5 }} variant="subtitle1">
                                            <b>Command type</b>
                                        </Typography>
                                        <SelectField
                                            name="commandType"
                                            className="form-control"
                                            options={complexCommands.map((obj) => ({
                                                value: obj.name,
                                                label: obj.schema.title,
                                            }))}
                                            onChange={() => {
                                                complexEditor = updateEditor(
                                                    complexEditor,
                                                    editorRef,
                                                    JsonEditorCommandType.ComplexCommands,
                                                    complexCommands,
                                                    form.getFieldState("commandType")?.value ?? complexCommands[0].name,
                                                );
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                </Box>
                            </InputItemContainer>
                            <InputItemContainer style={{ paddingBottom: 20 }}>
                                <Typography className={styles.commandParametersLabel} variant="subtitle1">
                                    <b>Command parameters</b>
                                </Typography>
                                <div id="json-editor" ref={editorRef}>
                                    {/* filled out by jsoneditor */}
                                </div>
                            </InputItemContainer>
                            <button ref={submitRef} type="submit" hidden />
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};
