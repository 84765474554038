import { diff } from "deep-diff";
import _ from "lodash";
import { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { LocalStorage } from "src/services/localStorageService";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch } from "src/store";
import { UncontrolledCheckboxField } from "src/ui/shared/form/CheckboxField";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { Tooltip } from "src/ui/shared/Tooltip";

import { selectSubscriptionExpired, selectSubscriptions } from "@dashboard/devices/store/selectors/subscriptions";
import { PageContainer, Row, SectionContainer, SectionDescription, SectionHeader } from "@dashboard/shared/styles";
import { checkWorkspaceGuid } from "@dashboard/workspaces/api";
import { fetchWorkspaces, setCurrentWorkspaceId, updateWorkspace } from "@dashboard/workspaces/store/index";
import { selectWorkspace, selectWorkspaces } from "@dashboard/workspaces/store/selectors";
import { Alert, Box, CheckboxProps, SelectChangeEvent } from "@mui/material";
import { PrimaryButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { resetFiltersToDefault } from "../devices/store";
import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { MembersTable } from "./components/MemberTable";
import { isSubscriptionExpiringSoon } from "./components/SubscriptionExpirationBanner";
import { SubscriptionSection } from "./components/SubscriptionSection/SubscriptionSection";
import { useStyles } from "./WorkspacesPage.style";

const maxCharactersLength = 64;

type FormValues = {
    id: number;
    name: string;
    guid: string;
    organizationName: string | null;
    organizationVatId: string | null;
};

const WorkspacesPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const workspaces = useSelector(selectWorkspaces);
    const currentWorkspace = useSelector(selectWorkspace);
    const subscriptions = useSelector(selectSubscriptions);
    const isExpired = useSelector(selectSubscriptionExpired);
    const subscription = subscriptions.find((item) => item.id === currentWorkspace?.subscription);
    const isPremiumSubscription = currentWorkspace && subscription?.variantName == "Premium";
    const initialValues = _.omit(currentWorkspace, ["members", "subscription"]);
    const [rerenderKey, setRerenderKey] = useState(Date.now());

    const { workspaceAbility } = permissionService();
    const allowedToEditWorkspace = workspaceAbility(currentWorkspace).can("update", "Workspace");

    const handleWorkspaceChange = (event: SelectChangeEvent<unknown>) => {
        if (typeof event.target.value === "number") {
            dispatch(setCurrentWorkspaceId(event.target.value));
            dispatch(resetFiltersToDefault());
        }
    };
    const validateWorkspaceDetails = async (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.name) {
            errors.name = "Workspace name is not allowed to be empty";
        } else if (values.name.length > maxCharactersLength) {
            errors.name = `Workspace name must be a maximum of ${maxCharactersLength} characters`;
        }

        if (!values.guid) {
            errors.guid = "GUID field is required and cannot be empty";
        } else if (values.guid.length < 3) {
            errors.guid = "GUID is too short. It must be at least 3 characters long";
        } else if (values.guid.length > 16) {
            errors.guid = "GUID is too long. It must be no more than 16 characters";
        } else if (/^\d/.test(values.guid.charAt(0))) {
            errors.guid = "GUID must start with a letter";
        } else if (!/^[a-z0-9]+$/.test(values.guid)) {
            errors.guid = "GUID must contain only alphanumeric characters and be in lowercase";
        } else {
            const isGuidAvailable = await checkWorkspaceGuid(values.guid);
            const isGuidDifferentAsInitial = values.guid !== initialValues.guid;

            if (!isGuidAvailable && isGuidDifferentAsInitial) {
                errors.guid = "GUID is taken, please choose a different one";
            }
        }

        return errors;
    };
    const validateOrganizationalDetails = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        const organizationName = values.organizationName || "";
        const organizationVatId = values.organizationVatId || "";

        if (organizationName.length > maxCharactersLength) {
            errors.organizationName = `Organization name must be a maximum of ${maxCharactersLength} characters`;
        }

        if (organizationVatId.length > maxCharactersLength) {
            errors.organizationVatId = `Organization VAT ID must be a maximum of ${maxCharactersLength} characters`;
        }

        return errors;
    };
    const onSubmitWorkspaceDetails = async (values: FormValues) => {
        if (currentWorkspace?.id) {
            await dispatch(
                updateWorkspace({
                    ...currentWorkspace,
                    organizationVatId: initialValues.organizationVatId,
                    organizationName: initialValues.organizationName,
                    name: values.name,
                    guid: values.guid,
                }),
            )
                .unwrap()
                .then(() => showSuccessToast("Workspace was updated"))
                .catch(({ message = "Something went wrong while updating workspace" }) => showErrorToast(message));
        }
    };
    const onSubmitOrganizationalDetails = async (values: FormValues) => {
        if (currentWorkspace?.id) {
            await dispatch(
                updateWorkspace({
                    ...currentWorkspace,
                    organizationVatId: values.organizationVatId,
                    organizationName: values.organizationName,
                    name: initialValues.name,
                    guid: initialValues.guid,
                }),
            )
                .unwrap()
                .then(() => showSuccessToast("Workspace was updated"))
                .catch(({ message = "Something went wrong while updating workspace" }) => showErrorToast(message));
        }
    };
    const onTerminatedChange: CheckboxProps["onChange"] = async (_e, checked) => {
        LocalStorage.setItem("includeTerminatedWorkspaces", checked);
        await dispatch(fetchWorkspaces({ includeTerminated: checked }));
    };
    const getTooltipProps = () => {
        const result = { title: "", hide: allowedToEditWorkspace && !isExpired };

        if (!allowedToEditWorkspace) {
            result.title = "Your workspace role doesn't allow you to make changes";
        }
        if (isExpired) {
            result.title = "Subscription expired";
        }

        return result;
    };

    return (
        <PageContainer>
            <PageTitleWithIcon title="Workspace" iconName="fa-solid fa-hammer" />
            <SectionDescription className={classes.pageDescriptionBox}>
                A workspace is an organizational unit to share groups, devices, and files between all members of an
                organization. For example, files added to the workspace can be accessed in all groups of the workspace,
                while files uploaded to a specific group are only visible to the members of this group. Similarly, all
                members of a workspace have access to all groups and devices of this workspace. Users can be invited to
                be members of multiple workspaces, but please note that changing your current workspace has effects on
                the visibility of data presented in device hub.
            </SectionDescription>
            {!currentWorkspace || !subscription ? (
                <FreshAccountMessage />
            ) : (
                <>
                    <Form<FormValues>
                        initialValues={initialValues}
                        onSubmit={onSubmitWorkspaceDetails}
                        validate={validateWorkspaceDetails}
                        render={({ handleSubmit, values, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <Box className={classes.sectionContent}>
                                    <SelectField
                                        name="id"
                                        label="Current workspace"
                                        onChange={handleWorkspaceChange}
                                        options={workspaces.map((item) => ({
                                            label: item.name,
                                            value: item.id,
                                        }))}
                                        withoutHelperText
                                        fullWidth
                                    />
                                    <UncontrolledCheckboxField
                                        defaultChecked={!!LocalStorage.getItem("includeTerminatedWorkspaces")}
                                        label="Include terminated workspaces"
                                        controlProps={{ style: { marginTop: 8 } }}
                                        onChange={onTerminatedChange}
                                    />
                                </Box>
                                <SectionHeader>Workspace details</SectionHeader>
                                <Row className={classes.workspaceNameBox}>
                                    <TextField
                                        name="name"
                                        label="Workspace name"
                                        fullWidth
                                        disabled={!allowedToEditWorkspace || isExpired}
                                    />
                                </Row>

                                <Tooltip
                                    style={{ display: "block" }}
                                    title={
                                        !isPremiumSubscription ? "Subscription upgrade required to change the GUID" : ""
                                    }
                                    placement="top-start"
                                >
                                    <Row className={classes.guidBox}>
                                        <TextField
                                            fullWidth
                                            name="guid"
                                            label="Workspace GUID"
                                            disabled={!isPremiumSubscription || !allowedToEditWorkspace || isExpired}
                                        />
                                    </Row>
                                </Tooltip>
                                <Row>
                                    <Alert severity="info">
                                        Workspace GUID must be a short, organization-related name which must be globally
                                        unique and will be automatically used to create an organizational context for
                                        managing global account information. Please be careful when changing this value,
                                        as it might have negative impact on devices with custom Android images, OTA
                                        distribution logic and provisioning configuration in future.
                                    </Alert>
                                </Row>
                                <div className={classes.saveBtnContainer}>
                                    <PrimaryButton
                                        onClick={handleSubmit}
                                        tooltipProps={getTooltipProps()}
                                        disabled={!diff(values, initialValues) || !allowedToEditWorkspace || isExpired}
                                        loading={submitting}
                                    >
                                        Save
                                    </PrimaryButton>
                                </div>
                            </form>
                        )}
                    />

                    {isSubscriptionExpiringSoon(subscription) ? (
                        <Alert title="Your subscription is nearing expiration" severity="warning">
                            Your current subscription will expire in 30 days or less. To ensure uninterrupted service
                            and access to all your features, we recommend taking action soon. If you wish to extend your
                            subscription or explore other options, please contact our team at your earliest convenience.
                            We&apos;re here to help with any questions or to guide you through the renewal process.
                            <a target="_blank" href="https://emteria.com/p/subscription" rel="noopener noreferrer">
                                {" "}
                                Contact Us{" "}
                            </a>
                            to extend your subscription or discuss your options.
                        </Alert>
                    ) : null}
                    <SubscriptionSection subscription={subscription} />

                    <Form<FormValues>
                        initialValues={initialValues}
                        validate={validateOrganizationalDetails}
                        onSubmit={onSubmitOrganizationalDetails}
                        render={({ handleSubmit, submitting, values }) => (
                            <SectionContainer>
                                <SectionHeader>Organization details</SectionHeader>
                                <Row className={classes.organizationNameBox}>
                                    <TextField
                                        name="organizationName"
                                        disabled={!allowedToEditWorkspace || isExpired}
                                        label="Organization name"
                                        fullWidth
                                    />
                                </Row>
                                <Row className="mt-2">
                                    <TextField
                                        name="organizationVatId"
                                        className={classes.organizationVatId}
                                        disabled={!allowedToEditWorkspace || isExpired}
                                        label="Organization VAT ID"
                                        fullWidth
                                    />
                                </Row>
                                <div className={classes.saveBtnContainer}>
                                    <PrimaryButton
                                        loading={submitting}
                                        onClick={handleSubmit}
                                        tooltipProps={getTooltipProps()}
                                        disabled={!allowedToEditWorkspace || !diff(values, initialValues) || isExpired}
                                    >
                                        Save
                                    </PrimaryButton>
                                </div>
                            </SectionContainer>
                        )}
                    />
                    <MembersTable
                        workspace={currentWorkspace}
                        key={rerenderKey}
                        onRerenderRequest={() => setRerenderKey(Date.now())}
                    />
                </>
            )}
        </PageContainer>
    );
};

export default WorkspacesPage;
