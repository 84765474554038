import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    section: {
        display: "flex",
        justifyContent: "space-between",
        gap: 16,
        marginBottom: 12,
    },
    centeredLabel: {
        alignItems: "center",
    },
    tagAreaBox: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        minHeight: 88,
        width: "100%",
        border: "1px solid" + theme.palette.grey[400],
        borderRadius: 4,
        boxSizing: "border-box",
    },
    tagsContainer: {
        display: "flex",
        gap: 4,
        padding: 10,
    },
    buttonBox: {
        marginTop: 24,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    placeholder: {
        fontSize: 16,
        color: theme.palette.text.disabled,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tagsInputContainer: {
        display: "flex",
        gap: 6,
    },
    submitButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 12,
    },
    inputItemLabelInvisible: {
        visibility: "hidden",
    },
    label: {
        width: 150,
        textAlign: "right",
        marginRight: 16,
    },
    hints: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(0.5),
        marginTop: 14,
    },
    hintText: {
        fontSize: 12,
    },
}));
