import { useState } from "react";
import { useForm } from "react-final-form";
import { JsonTextarea } from "src/ui/containers/dashboard/shared/components/JsonTextarea";

import { Product } from "@dashboard/products/types";

interface Props {
    values: Product;
}

export const ConfigImport = (props: Props) => {
    const form = useForm<Product>();
    const [parseError, setParseError] = useState({});
    const validationError = form.getState().errors || {};
    const mergedErrors = Object.values<string>({ ...parseError, ...validationError })[0];

    const onChange = (value: string) => {
        try {
            const product: Product = JSON.parse(value);
            form.restart(product);
            setParseError({});
        } catch (err) {
            setParseError({ parse: "Error: invalid JSON format" });
        }
    };

    return (
        <JsonTextarea<Product>
            value={props.values}
            error={mergedErrors}
            onChange={onChange}
            note="Note: imported configuration overrides values defined in the form"
        />
    );
};
