import { FormApi } from "final-form";
import queryString from "query-string";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import authService from "src/services/authService";
import { getHomepageUrl } from "src/services/configService";
import euporiaService from "src/services/euporiaService";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import useMetaTags from "src/ui/utils/useMetaTags";

import { unpackError } from "@dashboard/devices/api/helpers";
import { PageContainer } from "@dashboard/shared/styles";
import { Box, CardContent, InputAdornment } from "@mui/material";
import ApmLink from "@shared/ApmLink";
import { CustomCard, CustomCardHeader, CustomCardTitle } from "@shared/Card";
import { TextField } from "@shared/form/TextField";
import { showErrorToast } from "@shared/toasts/Toasts";

import { useStyles } from "./LoginPage.style";

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

type FormValues = {
    email: string;
    password: string;
    tft: string;
    totp: string;
    mode: "2fa" | "password";
};
const initialValues: FormValues = {
    email: "",
    password: "",
    totp: "",
    tft: "",
    mode: "password",
};

const LoginPage = () => {
    const classes = useStyles();
    useMetaTags({
        title: "Login | emteria Device Hub",
        description: "Log in to your emteria Device Hub account to manage all your Android devices.",
    });

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!emailRegex.test(values.email)) {
            errors.email = "Invalid email address";
        }

        if (!values.password) {
            errors.password = "Password is required";
        }

        if (values.mode === "2fa" && !values.totp) {
            errors.password = "Authentication code is required";
        }

        return errors;
    };
    const login = async (values: FormValues) => {
        try {
            await authService.loadCookie(values.email, values.password, values.tft, values.totp);

            const params = queryString.parse(window.location.search, {
                decode: true,
            });

            const url = typeof params?.returnUrl === "string" ? params.returnUrl : "/dashboard/overview";
            const isInternalUrl = url.startsWith("/");
            if (isInternalUrl) {
                window.location.assign(url);
            } else {
                window.location.href = url;
            }
        } catch (_error) {
            authService.setIsUserTotpValid(false);
            showErrorToast("Invalid 2FA code");
        }
    };
    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            if (values.mode === "password") {
                await authService.initSessionViaPass(values.email, values.password);
                euporiaService.updateUserContext();
                const user = authService.getCurrentUser();

                if (user && "tft" in user) {
                    form.restart({
                        ...values,
                        tft: user.tft,
                        mode: "2fa",
                    });
                } else {
                    await login(values);
                }
            }

            if (values.mode === "2fa") {
                authService.setIsUserTotpValid(true);
                await login(values);
            }
        } catch (error) {
            const err = unpackError(error);
            showErrorToast(err.message);
        }
    };

    return (
        <PageContainer className={classes.container}>
            <ApmLink href={getHomepageUrl()}>
                <img src="./img/logos/emteria-colored.webp" alt="emteria logo" className={classes.logo} />
            </ApmLink>
            <CustomCard className={classes.card}>
                <CustomCardHeader title={<CustomCardTitle>Login</CustomCardTitle>} />
                <CardContent>
                    <Form<FormValues>
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={initialValues}
                        render={({ handleSubmit, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                                {values.mode === "password" ? (
                                    <Box>
                                        <h1 className={classes.description}>Login to your emteria account</h1>
                                        <TextField
                                            autoComplete="on"
                                            placeholder="Email address"
                                            name="email"
                                            type="text"
                                            fullWidth
                                            style={{ marginBottom: 4 }}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-fw fa-envelope" />
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                        <TextField
                                            placeholder="Password"
                                            name="password"
                                            type="password"
                                            fullWidth
                                            style={{ marginBottom: 4 }}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-fw fa-key" />
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                    </Box>
                                ) : null}

                                {values.mode === "2fa" ? (
                                    <Box>
                                        <h1 className={classes.description}>Enter two-factor authentication code</h1>
                                        <TextField
                                            placeholder="Authentication code"
                                            autoComplete="off"
                                            name="totp"
                                            fullWidth
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <i className="fas fa-fw fa-user-shield" />
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                    </Box>
                                ) : null}
                                <PrimaryButton
                                    type="submit"
                                    disabled={submitting}
                                    loading={submitting}
                                    style={{ marginTop: "16px" }}
                                    fullWidth
                                >
                                    Login
                                </PrimaryButton>
                            </form>
                        )}
                    />

                    <ul className={classes.linksContainer}>
                        <li>
                            <Link to="/register">Create new account</Link>
                        </li>
                        <li>
                            <Link to="/account/recover">Recover your password</Link>
                        </li>
                    </ul>
                </CardContent>
            </CustomCard>
        </PageContainer>
    );
};

export default LoginPage;
