import clsx from "clsx";
import { FormApi } from "final-form";
import React from "react";
import { useForm } from "react-final-form";

import { Alert, Box, TextareaAutosize, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    advancedModeInput: {
        fontSize: 15,
        fontFamily: "monospace",
        marginTop: 16,
        padding: "6px 10px",
        flex: 1,
        overflowY: "scroll",
    },
    redText: { color: theme.palette.red[50] },
    reBorder: { borderColor: theme.palette.red[50] },
    alert: { minWidth: 500 },
}));

type Props<T> = {
    value: unknown;
    note: string;
    error: string | null;
    onChange: (value: string, form: FormApi<T, Partial<T>>) => void;
};
export const JsonTextarea = <T,>(props: Props<T>) => {
    const classes = useStyles();
    const form = useForm<T>();

    const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        props.onChange(e.currentTarget.value, form);
    };

    return (
        <Box className={classes.container}>
            {props.error ? (
                <Alert className={classes.alert} severity="error">
                    {String(props.error)}
                </Alert>
            ) : (
                <Alert className={classes.alert} severity="info">
                    {props.note}
                </Alert>
            )}

            <TextareaAutosize
                minRows={16}
                maxRows={16}
                spellCheck={false}
                onChange={onChange}
                defaultValue={JSON.stringify(props.value, null, 2)}
                className={
                    props.error
                        ? clsx(classes.advancedModeInput, classes.reBorder, classes.redText)
                        : classes.advancedModeInput
                }
            />
        </Box>
    );
};
