import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    textWrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "150px",
    },
    iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: 20,
    },
    collapsingRowContent: {
        padding: 10,
        width: "100%",
        maxWidth: "100%",
        textAlign: "left",
        wordWrap: "break-word",
    },
    collapseBtn: {
        maxWidth: 50,
        "&:focus": {
            outline: "none",
        },
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    borderBottomVisible: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        width: 140,
        height: 50,
    },
});
