import { Navigate } from "react-router";
import ApmLink from "src/ui/shared/ApmLink";

import { Box } from "@mui/material";

interface Props {
    title: string;
    text: string;
}

const BasePage = (props: Props) => (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>
        <div style={{ textAlign: "center" }}>
            <h1>{props.title}</h1>
            <ApmLink href="https://emteria.com">
                <h4>{props.text}</h4>
            </ApmLink>
        </div>
    </Box>
);

export const PageForbidden = () => <BasePage title="403 - Forbidden" text="Go to main page" />;
export const PageNotFound = () => <BasePage title="404 - Not Found" text="Go to main page" />;
export const RedirectToDashboard = () => <Navigate to="/dashboard/overview" />;
