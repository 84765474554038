import clsx from "clsx";
import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";

import { UploadProgressBar } from "@files/components/UploadProgressBar";
import { useUploadContext } from "@files/context/UploadContext";
import { maxFileSize } from "@files/UploadPage";
import { Box, Dialog, DialogContent } from "@mui/material";
import { UploadFileField } from "@shared/form/UploadFileField";

const useStyles = createUseStyles({
    dialogContent: {
        "&.MuiDialogContent-root": {
            paddingBottom: "20px",
        },
    },
    modalTitleBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: 20,
        paddingBottom: 0,
    },
    pointer: {
        cursor: "pointer",
    },
    contentBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "flex-end",
    },
});

interface FormValues {
    files: File[];
}
interface Props {
    groupId: number;
    open: boolean;
    handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FileUploadDialog = (props: Props) => {
    const classes = useStyles();
    const { uploadStarted } = useUploadContext();
    const validate = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};
        if (values.files) {
            for (const file of values.files) {
                if (file.size > maxFileSize) {
                    errors.files = `File ${file.name} exceeds the maximum size limit of 256 MB.`;
                    break;
                }
            }
        }
        return errors;
    };

    return (
        <Dialog open={props.open} maxWidth="md" fullWidth>
            <div className={classes.modalTitleBox}>
                <h2>Upload File</h2>
                <div className={clsx("fa-solid fa-times", classes.pointer)} onClick={() => props.handleClose(false)} />
            </div>
            <DialogContent className={classes.dialogContent}>
                <Form<FormValues>
                    validate={validate}
                    onSubmit={() => undefined}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <UploadFileField name="files" disabled={uploadStarted} groupId={props.groupId} />
                            {values.files && values.files.length ? (
                                <Box className={classes.contentBox}>
                                    <UploadProgressBar filesToUpload={values.files} />
                                </Box>
                            ) : null}
                        </form>
                    )}
                />
            </DialogContent>
        </Dialog>
    );
};
