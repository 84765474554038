import "src/ui/utils/datetime";

import _ from "lodash";
import React from "react";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";
import { fetchProfile } from "src/store/account";
import { selectIsUserCredentialValid } from "src/store/auth";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";

import {
    fetchDeviceMdmCommands,
    fetchFdroidRepos,
    fetchGroupMdmCommands,
    fetchGroups,
    fetchLicenses,
    selectDetailsIsGroup,
    selectDetailsIsLicense,
    selectDetailsSelectedData,
} from "@dashboard/devices/store";
import { selectSubscription } from "@dashboard/devices/store/selectors/subscriptions";
import { fetchFiles } from "@dashboard/files/store";
import { fetchProducts } from "@dashboard/products/store";
import { fetchProvisionings } from "@dashboard/provisioning/store";
import { fetchWorkspaces } from "@dashboard/workspaces/store";
import { selectWorkspace, selectWorkspaceGroupsIds } from "@dashboard/workspaces/store/selectors";

const useWorkspaces = () => {
    const [progress, setProgress] = React.useState(0);

    const dispatch = useDispatch();
    const userCredentialValid = useSelector(selectIsUserCredentialValid);

    React.useLayoutEffect(() => {
        if (userCredentialValid) {
            void dispatch(fetchWorkspaces())
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 50));
            void dispatch(fetchProfile())
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 50));
        }
        return () => setProgress(0);
    }, [dispatch, userCredentialValid]);

    return progress;
};

const useFetchFiles = () => {
    const [progress, setProgress] = React.useState(0);

    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace, shallowEqual);

    React.useLayoutEffect(() => {
        if (workspace?.id) {
            void dispatch(
                fetchFiles({
                    workspaceId: workspace.id,
                    pageIndex: 1,
                    pageSize: 50,
                    withPagination: true,
                }),
            )
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress(100));
        }
        return () => setProgress(0);
    }, [dispatch, workspace?.id]);

    React.useEffect(() => {
        if (workspace?.id) {
            void dispatch(fetchFiles({ workspaceId: workspace.id }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message));
        }
    }, [dispatch, workspace?.id]);

    return progress;
};

const useFetchGroups = () => {
    const [progress, setProgress] = React.useState(0);

    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace, shallowEqual);
    const groupIds = useSelector(selectWorkspaceGroupsIds, shallowEqual);

    React.useLayoutEffect(() => {
        if (workspace?.id) {
            void dispatch(fetchGroups(groupIds))
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 50));
            void dispatch(fetchLicenses({ groupId: groupIds, type: "some", pageSize: 100 }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 50));
        }
        return () => setProgress(0);
        // skipping the groupIds is intended to force the execution
        // of this hook ONLY when the workspace changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspace?.id]);

    React.useEffect(() => {
        if (workspace?.id) {
            void dispatch(fetchLicenses({ groupId: groupIds, type: "some" }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message));
        }
        // skipping the groupIds is intended to force the execution
        // of this hook ONLY when the workspace changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, workspace?.id]);

    return progress;
};

const useFetchProducts = () => {
    const [progress, setProgress] = React.useState(0);

    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace, shallowEqual);

    React.useLayoutEffect(() => {
        if (workspace?.id && workspace.subscription) {
            void dispatch(fetchFdroidRepos(workspace.subscription))
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 30));
            void dispatch(fetchProvisionings(workspace.id))
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 30));
            void dispatch(fetchProducts({ workspaceId: workspace.id }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message))
                .finally(() => setProgress((prev) => prev + 40));
        }
        return () => setProgress(0);
    }, [dispatch, workspace?.subscription, workspace?.id]);

    return progress;
};

const useFetchCommands = () => {
    const dispatch = useDispatch();
    const isSelectedGroup = useSelector(selectDetailsIsGroup);
    const isSelectedLicense = useSelector(selectDetailsIsLicense);
    const selected = useSelector(selectDetailsSelectedData, shallowEqual);

    const selectedItemId = React.useMemo(() => {
        if (selected.group) {
            return selected.group.id;
        }
        if (selected.license) {
            return selected.license.device?.id;
        }
        return undefined;
    }, [selected.group, selected.license]);

    React.useEffect(() => {
        if (isSelectedGroup && selectedItemId) {
            void dispatch(fetchGroupMdmCommands({ groupId: selectedItemId }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message));
        }
        if (isSelectedLicense && selectedItemId) {
            void dispatch(fetchDeviceMdmCommands({ deviceId: selectedItemId }))
                .unwrap()
                .catch(({ message }) => showErrorToast(message));
        }
    }, [dispatch, isSelectedGroup, isSelectedLicense, selectedItemId]);
};

export const usePrefetch = () => {
    useFetchCommands();

    const workspaceProgress = useWorkspaces();
    const fileProgress = useFetchFiles();
    const groupProgress = useFetchGroups();
    const productProgress = useFetchProducts();

    const stages = [workspaceProgress, fileProgress, groupProgress, productProgress];

    const subscription = useSelector(selectSubscription);
    if (!subscription && workspaceProgress === 100) {
        stages.splice(1);
    }

    return { progress: _.sum(stages) / stages.length };
};
