import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    dropDownContainer: {
        position: "relative",
        zIndex: 10,
    },
});

export function Dropdown({ children, ...props }: React.ComponentProps<"div">): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.dropDownContainer} {...props}>
            {children}
        </div>
    );
}
