import clsx from "clsx";
import React, { useState } from "react";
import { theme } from "src/providers/ThemeProvider";
import authService from "src/services/authService";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import DeleteGroupDialog from "src/ui/containers/dashboard/groups/components/DeleteGroupDialog";
import EditGroupDialog from "src/ui/containers/dashboard/groups/components/EditGroupDialog";
import { selectGroupById } from "src/ui/containers/dashboard/groups/store/selectors";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { ConfirmDialog } from "@dashboard/devices/components/shared/index";
import { selectDetailsIsGroupSelected, updateGroup } from "@dashboard/devices/store/index";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { flexRender, Row } from "@tanstack/react-table";

import { GroupOverviewData } from "../../GroupsPage";

interface TableRowProps {
    row: Row<GroupOverviewData>;
    index: number;
    onClick: (groupId: number, event: React.MouseEvent<HTMLDivElement>) => void;
}

const useStyles = makeStyles(() => ({
    actionContainer: {
        display: "flex",
        gap: 8,
        justifyContent: "flex-end",
    },
    row: {
        cursor: "pointer",
        "&.selected": {
            backgroundColor: `${theme.palette.blue[50]} !important`,
        },
        "&:hover": {
            backgroundColor: `${theme.palette.blue[50]} !important`,
        },
        "&.even": {
            backgroundColor: theme.palette.white[100],
        },
        "&.odd": {
            backgroundColor: theme.palette.grey[100],
        },
    },
}));

const GroupTableRow: React.FC<TableRowProps> = ({ row, onClick, index }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const isGroupSelected = useSelector((state) => selectDetailsIsGroupSelected(state, row.original.id));
    const workspace = useSelector(selectWorkspace);
    const group = useSelector((state) => selectGroupById(state, row.original.id));

    const currentUser = authService.getCurrentUser();
    const { groupAbility } = permissionService();
    const isDefaultGroup = workspace?.defaultGroupId === row.original.id;
    const isDeleteDisabled = isDefaultGroup || groupAbility(group || null, workspace).cannot("delete", "Group");
    const isGroupMember = group?.members.some((item) => item.email === currentUser?.email);

    const handleLeaveGroup = async () => {
        if (currentUser) {
            await dispatch(updateGroup({ id: row.original.id, removedMembers: [currentUser?.email] }))
                .unwrap()
                .then(() => showSuccessToast("Successfully left the group"))
                .catch(({ message }) => showErrorToast(message));
        }
    };

    let deleteTooltip = "";
    if (isDefaultGroup) {
        deleteTooltip = "You can't delete the default group";
    } else if (groupAbility(group || null, workspace).cannot("delete", "Group")) {
        deleteTooltip = "You do not have permission to delete this group";
    } else {
        deleteTooltip = "Delete group";
    }

    return (
        <>
            <TableRow
                onClick={(e) => onClick(row.original.id, e)}
                className={clsx(classes.row, isGroupSelected && "selected", index % 2 === 0 ? "even" : "odd")}
            >
                {row.getVisibleCells().map((cell) => (
                    <MinimalTableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </MinimalTableCell>
                ))}
                <MinimalTableCell align="right">
                    <div className={classes.actionContainer} onClick={(e) => e.stopPropagation()}>
                        <SecondaryButton tooltipProps={{ title: "Edit group" }} onClick={() => setEditDialogOpen(true)}>
                            <i className="fa-solid fa-pen-to-square" />
                        </SecondaryButton>
                        <DangerOutlinedButton
                            onClick={() => setLeaveDialogOpen(true)}
                            disabled={!isGroupMember}
                            tooltipProps={{
                                title: !isGroupMember ? "You are not a member of this group" : "Leave group",
                            }}
                        >
                            <i className="fa-solid fa-right-from-bracket" />
                        </DangerOutlinedButton>
                        <DangerOutlinedButton
                            onClick={() => setDeleteDialogOpen(true)}
                            disabled={isDeleteDisabled}
                            tooltipProps={{ title: deleteTooltip }}
                        >
                            <i className="fa-solid fa-trash-alt" />
                        </DangerOutlinedButton>
                    </div>
                </MinimalTableCell>
            </TableRow>
            <EditGroupDialog group={row.original} open={editDialogOpen} onClose={() => setEditDialogOpen(false)} />
            {group ? (
                <DeleteGroupDialog group={group} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
            ) : null}
            <ConfirmDialog
                dangerButton
                title="Leave group"
                open={leaveDialogOpen}
                onConfirm={handleLeaveGroup}
                onClose={() => setLeaveDialogOpen(false)}
            />
        </>
    );
};

export default GroupTableRow;
