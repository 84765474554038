import clsx from "clsx";
import React from "react";
import { EmteriaRoles } from "src/data/roles";
import { fetchAllBuilds } from "src/ui/containers/admin/api";

import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { ContentContainer, PageContainer } from "@dashboard/shared/styles";
import { Paper, TableBody, TableContainer, TablePagination, TableRow, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRequiredRole } from "@navigation/utils/withRequiredRole";
import { LoadingBox } from "@shared/Loading";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { showErrorToast } from "@shared/toasts/Toasts";
import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import TableItem from "./TableItem";

export const useStyles = makeStyles((theme: Theme) => ({
    statusIcon: {
        color: theme.palette.blue[300],
    },
    noResultsRow: {
        textAlign: "center",
        padding: "20px",
    },
}));

type Status = "pending" | "fulfilled" | "rejected" | "uninitialized";
export type State = {
    data: API.AdminBuild[];
    count: number;
    status: Status;
};
const defaultState: State = {
    data: [],
    count: 0,
    status: "uninitialized",
};

export const getStatus = (buildStatus: API.AdminBuild["buildStatus"] | null) => {
    switch (buildStatus) {
        case "running":
            return { icon: "fas fa-spinner fa-spin", tooltip: "Build is running" };
        case "pending":
            return { icon: "fas fa-hourglass-half", tooltip: "Build is pending" };
        default:
            return { icon: "fas fa-question-circle", tooltip: "Unknown status" };
    }
};

const columnHelper = createColumnHelper<API.AdminBuild>();

const tableColumns = [
    columnHelper.accessor("buildStatus", {
        id: "buildStatus",
        header: "Status",
        cell: (info) => (
            <MinimalTableCell>
                <i className={clsx(getStatus(info.getValue()).icon)} />
            </MinimalTableCell>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor("buildId", {
        id: "buildId",
        header: "Id",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("codename", {
        id: "codename",
        header: "Codename",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("version", {
        id: "version",
        header: "Version",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("created", {
        id: "created",
        header: "Created",
        cell: (info) => (
            <MinimalTableCell>{convertToLocalDate(info.getValue(), "ISO", "dateTime") ?? "-"}</MinimalTableCell>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor("metadata.user.tags", {
        id: "tags",
        header: "Tags",
        cell: (info) => {
            const tags = info.getValue() || [];
            const hasTags = tags.length > 0;

            return (
                <MinimalTableCell>
                    {hasTags ? (
                        <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                            {tags.map((tag: { key: string; value: string }) => (
                                <Tag key={tag.key} item={tag} />
                            ))}
                        </div>
                    ) : (
                        <Typography>-</Typography>
                    )}
                </MinimalTableCell>
            );
        },
        enableSorting: true,
    }),
];

const AdminFilesPage = () => {
    const [state, setState] = React.useState<State>(defaultState);
    const classes = useStyles();
    const table = useReactTable<API.AdminBuild>({
        data: state.data,
        columns: tableColumns,
        initialState: {
            sorting: [{ id: "filename", desc: true }],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
        // Turn off client-side pagination
        manualPagination: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const { pageIndex, pageSize } = table.getState().pagination;

    console.log({ test: state.data });

    React.useLayoutEffect(() => {
        setState((prev) => ({ ...prev, status: "pending" }));
        void fetchAllBuilds()
            .then((data) => setState({ data: data.list, count: data.count, status: "fulfilled" }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setState((prev) => ({ ...prev, status: "rejected" }));
            });
    }, [pageIndex, pageSize]);

    if (state.status === "uninitialized") {
        return <LoadingBox />;
    }

    return (
        <PageContainer>
            <PageTitleWithIcon title="Builds queue overview" iconName="fa-regular fa-hourglass-half" />
            <ContentContainer>
                <TableContainer component={Paper}>
                    <MinimalTable>
                        <MinimalTableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <Header key={header.id} {...header} />
                                    ))}
                                    <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                                </TableRow>
                            ))}
                        </MinimalTableHeader>
                        <TableBody>
                            {!table.getRowModel().rows.length ? (
                                <TableRow>
                                    <MinimalTableCell
                                        colSpan={tableColumns.length + 1}
                                        className={classes.noResultsRow}
                                    >
                                        No builds available yet
                                    </MinimalTableCell>
                                </TableRow>
                            ) : (
                                table
                                    .getRowModel()
                                    .rows.map((row) => <TableItem key={row.id} setState={setState} row={row} />)
                            )}
                        </TableBody>
                    </MinimalTable>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={state.data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onPageChange={(_event, page) => table.setPageIndex(page)}
                    onRowsPerPageChange={(e) => {
                        const size = e.target.value ? Number(e.target.value) : 10;
                        table.setPagination({ pageIndex: 0, pageSize: size });
                    }}
                />
            </ContentContainer>
        </PageContainer>
    );
};
export default withRequiredRole(AdminFilesPage, [EmteriaRoles.EmteriaAdminRole]);
