import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        height: 50,
    },
    tagsBox: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxWidth: 400,
        maxHeight: 150,
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});
