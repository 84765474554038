import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "src/services/accountService";
import authService from "src/services/authService";

import { PageContainer } from "@dashboard/shared/styles";
import { Card, CardActions, CardContent, CardHeader, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        backgroundColor: theme.palette.shadow[100],
        color: theme.palette.blue[200],
    },
    error: { color: theme.palette.red[50], fontWeight: 600 },
}));

type FormValues = {
    email: string | undefined;
    password: string;
};

const DeleteAccountPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();

    const classes = useStyles();
    const navigate = useNavigate();

    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.password) {
            errors.password = "Password cannot be empty";
        }

        return errors;
    };
    const onSubmit = async (values: FormValues) => {
        try {
            setLoading(true);

            values.email = email;

            const { message } = await deleteAccount(values);
            showSuccessToast(message);
            navigate("/logout");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const _user = authService.getCurrentUser();
        setEmail(_user?.email);
    }, []);

    return (
        <PageContainer>
            <Card>
                <CardHeader className={classes.header} title={<span style={{ fontSize: 18 }}>Delete Account</span>} />
                <Form<FormValues>
                    onSubmit={onSubmit}
                    validate={onValidate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <CardContent style={{ paddingBottom: 0 }}>
                                <Typography style={{ marginBottom: 15 }}>
                                    You are going to delete the account associated with{" "}
                                    <b className={classes.error}> {email} </b>. Proceeding will delete your personal
                                    information, cancel subscriptions and revoke all licenses. Please note that removed
                                    accounts <b className={classes.error}>cannot be restored</b> and access to all
                                    purchased licenses and activation codes will be irreversibly lost. Furthermore, you
                                    will not be able create a new account or to purchase new licenses using this email
                                    address in future.
                                </Typography>
                                <TextField
                                    name="password"
                                    type="password"
                                    placeholder="Enter your password to confirm"
                                    fullWidth
                                />
                            </CardContent>
                            <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
                                <DangerOutlinedButton type="submit" disabled={loading} loading={loading}>
                                    Delete
                                </DangerOutlinedButton>
                            </CardActions>
                        </form>
                    )}
                />
            </Card>
        </PageContainer>
    );
};

export default DeleteAccountPage;
