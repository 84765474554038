import { useField, UseFieldConfig } from "react-final-form";

import { FormControl, FormControlLabel, FormHelperText, styled, Switch, SwitchProps } from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    marginTop: 0,
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));
const StyledFormControlLabel = styled(FormControlLabel)({
    marginBottom: 0,
    marginLeft: 0,
});
const StyledFormControl = styled(FormControl)({
    display: "inline",
});
const StyledSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.blue[150],
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.blue[150],
    },
}));

interface Props extends SwitchProps {
    name: string;
    label?: string | React.ReactNode;
    helperText?: string;
    labelPlacement?: "bottom" | "end" | "start" | "top";
    config?: UseFieldConfig<boolean, boolean>;
}

export const SwitchField = ({
    name,
    config,
    label = "",
    helperText,
    labelPlacement = "start",
    onChange,
    ...rest
}: Props) => {
    const { input, meta } = useField<boolean>(name, { ...config, type: "checkbox" });
    const error = meta.touched && meta.error;

    const handleChange: SwitchProps["onChange"] = (e, child) => {
        input.onChange(e);
        if (onChange) {
            onChange(e, child);
        }
    };

    return (
        <StyledFormControl error={error}>
            <StyledFormControlLabel
                {...{ label, labelPlacement }}
                control={<StyledSwitch {...input} {...rest} onChange={handleChange} />}
            />
            <StyledFormHelperText>{error || helperText}</StyledFormHelperText>
        </StyledFormControl>
    );
};
