import React, { useCallback } from "react";
import { UncontrolledTextField } from "src/ui/shared/form/TextField";
import { showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { FileInfo } from "@dashboard/files/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        minWidth: 400,
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 6,
    },
    label: {
        marginRight: 12,
        minWidth: 140,
        maxWidth: 140,
    },
    category: {
        marginTop: 8,
    },
    copyButton: {
        marginLeft: 8,
    },
}));

interface Props {
    file: FileInfo | null;
    isOpen: boolean;
    closeDialog: () => void;
}

export const FileDetailsModal = ({ file, isOpen, closeDialog }: Props) => {
    const classes = useStyles();

    const copyToClipboard = useCallback((text: string | undefined) => {
        if (!text) {
            return;
        }
        navigator.clipboard
            .writeText(text)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    }, []);

    return (
        <Dialog open={isOpen} onClose={closeDialog} scroll="paper" maxWidth="lg">
            <DialogTitle>File information</DialogTitle>
            <DialogContent className={classes.container}>
                <Typography variant="h6" className={classes.category}>
                    General
                </Typography>
                <div className={classes.textContainer}>
                    <Typography className={classes.label}>Name:</Typography>
                    <UncontrolledTextField value={file?.filename} disabled fullWidth />
                </div>
                <div className={classes.textContainer}>
                    <Typography className={classes.label}>Uploader:</Typography>
                    <UncontrolledTextField value={file?.uploader} disabled fullWidth />
                </div>
                {file?.createdDate && (
                    <div className={classes.textContainer}>
                        <Typography className={classes.label}>Updated:</Typography>
                        <UncontrolledTextField
                            value={convertToLocalDate(file.createdDate, "ISO", "dateTime")}
                            disabled
                            fullWidth
                        />
                    </div>
                )}
                {file?.metadata?.file?.apk?.manifest?.versioncode && (
                    <div className={classes.textContainer}>
                        <Typography className={classes.label}>Version code:</Typography>
                        <UncontrolledTextField value={file.metadata.file.apk.manifest.versioncode} disabled fullWidth />
                        <SecondaryButton
                            className={classes.copyButton}
                            onClick={() => copyToClipboard(file?.metadata?.file?.apk?.manifest?.versioncode)}
                            tooltipProps={{ title: "Copy" }}
                        >
                            <i className="fa-solid fa-copy" />
                        </SecondaryButton>
                    </div>
                )}
                {file?.metadata?.file?.apk?.manifest?.versionname && (
                    <div className={classes.textContainer}>
                        <Typography className={classes.label}>Version name:</Typography>
                        <UncontrolledTextField value={file.metadata.file.apk.manifest.versionname} disabled fullWidth />
                        <SecondaryButton
                            className={classes.copyButton}
                            onClick={() => copyToClipboard(file?.metadata?.file?.apk?.manifest?.versionname)}
                            tooltipProps={{ title: "Copy" }}
                        >
                            <i className="fa-solid fa-copy" />
                        </SecondaryButton>
                    </div>
                )}
                {file?.metadata?.file?.apk?.signers?.map((signer, index) => (
                    <React.Fragment key={index}>
                        <Typography variant="h6" className={classes.category}>
                            Signer #{index + 1}
                        </Typography>
                        <div className={classes.textContainer}>
                            <Typography className={classes.label}>SHA256 digest:</Typography>
                            <UncontrolledTextField
                                value={signer.digests.find((d) => d.type === "sha-256")?.certificate}
                                disabled
                                fullWidth
                            />
                            <SecondaryButton
                                className={classes.copyButton}
                                onClick={() =>
                                    copyToClipboard(signer.digests.find((d) => d.type === "sha-256")?.certificate)
                                }
                                tooltipProps={{ title: "Copy" }}
                            >
                                <i className="fa-solid fa-copy" />
                            </SecondaryButton>
                        </div>
                        <div className={classes.textContainer}>
                            <Typography className={classes.label}>DN:</Typography>
                            <UncontrolledTextField
                                value={Object.entries(signer.distinguishedName)
                                    .filter(([_key, value]) => value !== "unknown")
                                    .map(([key, value]) => `${key}=${value}`)
                                    .join(", ")}
                                disabled
                                fullWidth
                            />
                            <SecondaryButton
                                className={classes.copyButton}
                                onClick={() =>
                                    copyToClipboard(
                                        Object.entries(signer.distinguishedName)
                                            .filter(([_key, value]) => value !== "unknown")
                                            .map(([key, value]) => `${key}=${value}`)
                                            .join(", "),
                                    )
                                }
                                tooltipProps={{ title: "Copy" }}
                            >
                                <i className="fa-solid fa-copy" />
                            </SecondaryButton>
                        </div>
                    </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={closeDialog}>Close</SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};
