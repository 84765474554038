import { Card, CardContent, CardHeader, styled, Typography } from "@mui/material";

export const CustomCard = styled(Card)({
    minWidth: 350,
});
export const CustomCardContent = styled(CardContent)({
    padding: 20,
});
export const CustomCardHeader = styled(CardHeader)({
    padding: "10px 20px",
    background: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
});
export const CustomCardTitle = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    color: theme.palette.blue[150],
}));
export const CustomCardText = styled(Typography)(({ theme }) => ({
    fontSize: 15,
    color: theme.palette.black[100],
}));
