import { createContext, CSSProperties, PropsWithChildren, useContext } from "react";
import { useField, UseFieldConfig } from "react-final-form";
import { Tooltip } from "src/ui/shared/Tooltip";

import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    RadioGroupProps,
    RadioProps,
    styled,
    Typography,
} from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));
const StyledRadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.blue[150],
    "&.Mui-checked": {
        color: theme.palette.blue[150],
    },
}));
const StyledRadioLabel = styled(FormControlLabel)({
    marginBottom: 0,
    height: 25,
});
const StyledRadioBox = styled("div")({
    display: "flex",
    flexDirection: "column",
});
const StyledRadioHelperText = styled(Typography)({
    paddingLeft: 30,
});

const RadioFieldGorupContext = createContext(true);

interface Props extends RadioGroupProps {
    name: string;
    helperText?: string;
    config?: UseFieldConfig<boolean, boolean>;
}
interface RadioFieldProps extends RadioProps {
    label: string;
    value: string;
    helperText?: string;
    style?: CSSProperties;
    tooltip?: string;
}
export const RadioField = ({ style, label, value, helperText, tooltip = "", ...rest }: RadioFieldProps) => {
    const inContextScope = useContext(RadioFieldGorupContext);

    if (!inContextScope) {
        throw new Error("RadioField can be use only in RadioFieldGorup scope.");
    }

    return (
        <Tooltip title={tooltip} placement="top-start">
            <StyledRadioBox style={style}>
                <StyledRadioLabel label={label} value={value} control={<StyledRadio {...rest} />} />
                <StyledRadioHelperText variant="caption" style={{ opacity: rest.disabled ? 0.4 : 1 }}>
                    {helperText}
                </StyledRadioHelperText>
            </StyledRadioBox>
        </Tooltip>
    );
};
export const RadioFieldGorup = ({ name, config, children, ...rest }: PropsWithChildren<Props>) => {
    const { input, meta } = useField(name, config);
    const error = meta.touched && meta.error;

    return (
        <RadioFieldGorupContext.Provider value={true}>
            <FormControl error={error}>
                <RadioGroup {...rest} name={name} value={input.value} onChange={input.onChange}>
                    {children}
                </RadioGroup>
                <StyledFormHelperText>{error}</StyledFormHelperText>
            </FormControl>
        </RadioFieldGorupContext.Provider>
    );
};
