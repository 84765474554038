import clsx from "clsx";
import { forwardRef } from "react";

import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Tooltip, TooltipProps } from "./Tooltip";

type PrivateButton = LoadingButtonProps & {
    tooltipProps?: Omit<TooltipProps, "arrow" | "children">;
};

const useStyles = makeStyles<Theme, PrivateButton>(() => ({
    fullWidth: (props) => ({
        width: props.fullWidth ? "100%" : "unset",
    }),
    baseStyle: {
        height: "40px",
        minWidth: 40,
        fontSize: "14px",
    },
}));
const BaseButton = forwardRef<HTMLButtonElement, PrivateButton>((props, ref) => {
    const { tooltipProps, loading, ...rest } = props;
    const classes = useStyles({ ...rest, loading });

    return (
        <Tooltip placement="top-start" {...tooltipProps} className={clsx(classes.fullWidth, tooltipProps?.className)}>
            <LoadingButton
                ref={ref}
                disableRipple={false}
                loading={loading}
                {...rest}
                className={clsx(classes.baseStyle, rest.className)}
            >
                {rest.children}
            </LoadingButton>
        </Tooltip>
    );
});

type PublicButton = Omit<PrivateButton, "color" | "variant">;

export const PrimaryButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="contained" color="primary" />;
});
export const PrimaryOutlinedButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="outlined" color="primary" />;
});

export const SecondaryButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="contained" color="secondary" />;
});

export const DangerButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="contained" color="error" />;
});
export const DangerOutlinedButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="outlined" color="error" />;
});

export const TextButton = forwardRef<HTMLButtonElement, PublicButton>((props: PublicButton, ref) => {
    return <BaseButton ref={ref} {...props} variant="text" color="inherit" />;
});

BaseButton.displayName = "BaseButton";
PrimaryButton.displayName = "PrimaryButton";
PrimaryOutlinedButton.displayName = "PrimaryOutlinedButton";
SecondaryButton.displayName = "SecondaryButton";
DangerButton.displayName = "DangerButton";
DangerOutlinedButton.displayName = "DangerOutlinedButton";
TextButton.displayName = "TextButton";
