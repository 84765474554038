import clsx from "clsx";
import { createUseStyles } from "react-jss";

import { DropdownItem, DropdownItemProps, Icon, IconSize } from "@devices/components/shared";
import { TooltipProps } from "@mui/material";
import { Tooltip } from "@shared/Tooltip";

const useStyles = createUseStyles({
    btnIcon: {
        marginRight: "0.15rem",
        fontSize: "0.85rem !important",
    },
    menuItem: {
        height: "1.85rem",
    },
});

export interface MenuItemProps extends DropdownItemProps {
    label: string;
    iconName: string;
    key?: string;
    onClick?: () => void;
    tooltip?: string;
}

export const MenuItemTooltip = ({ children, ...props }: TooltipProps): JSX.Element => {
    return (
        <Tooltip placement="bottom" {...props}>
            <div>{children}</div>
        </Tooltip>
    );
};

export const MenuItem = ({ iconName, label, className = "", ...props }: MenuItemProps): JSX.Element => {
    const classes = useStyles();

    const _DropdownItem = () => (
        <DropdownItem className={clsx(className, classes.menuItem)} {...props}>
            <Icon name={iconName} color="primary" size={IconSize.small} className={classes.btnIcon} />
            {label}
        </DropdownItem>
    );

    return props.tooltip ? (
        <MenuItemTooltip title={props.tooltip}>
            <_DropdownItem />
        </MenuItemTooltip>
    ) : (
        <_DropdownItem />
    );
};

export const EditItem = (props: Partial<MenuItemProps>): JSX.Element => {
    return <MenuItem {...props} label={props.label || "Edit"} iconName={props.iconName || "far fa-edit"} />;
};

export const DeleteItem = (props: Partial<MenuItemProps>): JSX.Element => {
    return (
        <MenuItem
            {...props}
            label={props.label || "Delete"}
            iconName="fas fa-trash-alt"
            data-testid="menu-delete-item"
        />
    );
};
