import * as Yup from "yup";

import { showErrorToast } from "@shared/toasts/Toasts";

import { ProvisioningSettings } from "../types";

const appPermissionSchema = Yup.object({
    package: Yup.string().required().label("App permission package"),
    permission: Yup.string().required().label("App permission"),
    action: Yup.string()
        .oneOf<API.AppPermission["action"]>(["Grant", "Revoke"])
        .required()
        .label("App permission action"),
    comment: Yup.string().defined().nullable().label("App permission comment"),
});
const appPreferenceSchema = Yup.object({
    package: Yup.string().required().label("App preference package"),
    category: Yup.string().required().label("App preference category"),
    type: Yup.string()
        .oneOf<API.AppPreference["type"]>(["Bool", "Float", "Int", "Long", "Set", "String"])
        .required()
        .label("App preference type"),
    key: Yup.string().required().label("App preference key"),
    value: Yup.string().required().label("App preference value"),
    comment: Yup.string().defined().nullable().label("App preference comment"),
});
const authorizedSshKeySchema = Yup.object({
    name: Yup.string().required().label("Ssh key name"),
    key: Yup.string().required().label("Ssh key"),
    comment: Yup.string().defined().nullable().label("Ssh key comment"),
});
const fDroidRepoSchema = Yup.object({
    name: Yup.string().required().label("FDroid repo name"),
    title: Yup.string().required().label("FDroid repo title"),
    url: Yup.string()
        .matches(
            /^https:\/\/apps\.emteria\.com\/fdroid\/[^/]+\/repo$/,
            "Invalid format. The URL must follow the pattern: https://apps.emteria.com/fdroid/<name>/repo",
        )
        .required()
        .label("FDroid repo url"),
    description: Yup.string().required().label("FDroid repo description"),
});
const hostedApkSchema = Yup.object({
    name: Yup.string().required().label("Hosted apk name"),
    url: Yup.string().required().label("Hosted apk url"),
    comment: Yup.string().defined().nullable().label("Hosted apk comment"),
});
const systemPropertySchema = Yup.object({
    property: Yup.string().required().label("System property name"),
    value: Yup.string().required().label("System property value"),
    comment: Yup.string().defined().nullable().label("System property comment"),
});
const systemSettingSchema = Yup.object({
    section: Yup.string()
        .oneOf<API.SystemSetting["section"]>(["Global", "Secure", "System"])
        .required()
        .label("System settings name"),
    type: Yup.string()
        .oneOf<API.SystemSetting["type"]>(["Bool", "Float", "Int", "Long", "Set", "String"])
        .required()
        .label("System settings type"),
    key: Yup.string().required().label("System settings key"),
    value: Yup.string().required().label("System settings value"),
    comment: Yup.string().defined().nullable().label("System settings comment"),
});

const exportProvisioningSettingsSchema = Yup.object({
    appPermissions: Yup.array().of(appPermissionSchema).label("App permissions"),
    appPreferences: Yup.array().of(appPreferenceSchema).label("App preferences"),
    authorizedSshKeys: Yup.array().of(authorizedSshKeySchema).label("App preferences"),
    automaticSetup: Yup.boolean().required().label("Automatic setup"),
    acceptedEula: Yup.boolean().required().label("Accepted Eula"),
    bootAnimationUrl: Yup.string().label("Animation boot url"),
    deviceManager: Yup.string().nullable().label("Device manager"),
    disabledComponents: Yup.array().of(Yup.string()).label("Disabled components"),
    enableBluetooth: Yup.boolean().required().label("Enable Bluetooth"),
    enableWifi: Yup.boolean().required().label("Enable wifi"),
    enableAdbServer: Yup.boolean().required().label("Enable adbServer"),
    enableSshServer: Yup.boolean().required().label("Enable sshServer"),
    enableSftpServer: Yup.boolean().required().label("Enable sftpServer"),
    enableVncServer: Yup.boolean().required().label("Enable vncServer"),
    enableMdmClient: Yup.boolean().required().label("Enable mdmClient"),
    fDroidRepos: Yup.array().of(fDroidRepoSchema).label("FDroidRepos"),
    fontScaling: Yup.number().required().label("Font scaling"),
    hostedApks: Yup.array().of(hostedApkSchema).label("Hosted apks"),
    orientationLock: Yup.boolean().required().label("Orientation lock"),
    screenHeight: Yup.number().required().label("Screen height"),
    screenWidth: Yup.number().required().label("Screen width"),
    screenFrequency: Yup.number().required().label("Screen frequency"),
    screenDensity: Yup.number().required().label("Screen density"),
    screenRotation: Yup.number()
        .required()
        .oneOf<API.ProvisioningSettings["screenRotation"]>([0, 1, 2, 3])
        .label("Screen rotation"),
    systemProperties: Yup.array().of(systemPropertySchema).label("System properties"),
    systemSettings: Yup.array().of(systemSettingSchema).label("System settings"),
    universalLicenses: Yup.array().of(Yup.string()).label("Universal licenses"),
    wallpaperUrl: Yup.string().label("Wallpaper url"),
});

type ExportProvisioningSettings = Yup.InferType<typeof exportProvisioningSettingsSchema>;

const normalizeFDroidRepos = (
    repos: ProvisioningSettings["fDroidRepos"],
): ExportProvisioningSettings["fDroidRepos"] => {
    return repos.map((item) => ({
        name: item.name,
        title: item.title,
        description: item.description,
        url: `https://apps.emteria.com/fdroid/${item.name}/repo`,
    }));
};

export const provisioningSettingsConverter = async (
    data: ProvisioningSettings,
): Promise<ExportProvisioningSettings | undefined> => {
    const provisioning = { ...data, fDroidRepos: normalizeFDroidRepos(data.fDroidRepos) };
    try {
        return await exportProvisioningSettingsSchema.validate(provisioning, { stripUnknown: true });
    } catch (error) {
        if (error instanceof Error) {
            showErrorToast(error.message);
        }
    }
};
