import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { RootState, useDispatch, useSelector } from "src/store";

import { deleteGroup } from "@dashboard/devices/store/index";
import { Group } from "@dashboard/devices/types/index";
import { selectFilesByGroupId } from "@dashboard/files/store/selectors";
import { selectProductsByGroupId } from "@dashboard/products/store/selectors/products";
import { Box, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles({
    dialogContent: {
        maxWidth: 500,
        width: 400,
        display: "flex",
        flexDirection: "column",
        paddingTop: 0,
    },
    dialogActions: {
        marginTop: 10,
    },
});

interface Props {
    group: Group;
    open: boolean;
    onClose: () => void;
}

interface FormValues {
    name: string;
    description: string;
}

const DeleteGroupDialog = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const groupProducts = useSelector((state: RootState) => selectProductsByGroupId(state, props.group.id));
    const groupFiles = useSelector((state: RootState) => selectFilesByGroupId(state, props.group.id));
    const licensesLength = props.group.licenseIds.length;
    const productsLength = groupProducts.length;
    const filesLength = groupFiles.length;

    const isGroupEmpty = !licensesLength && !productsLength && !filesLength;

    const formatCount = (count: number, singular: string, plural: string): string =>
        `${count} ${count === 1 ? singular : plural}`;

    const onSubmit = async () => {
        await dispatch(deleteGroup({ id: props.group.id }))
            .unwrap()
            .then(() => showSuccessToast("Group successfully deleted"))
            .catch(({ message = "Failed to delete group" }) => {
                showErrorToast(message);
            })
            .finally(() => {
                props.onClose();
            });
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && isGroupEmpty) {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => (
                <Dialog open={props.open} maxWidth="sm" onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogTitle>Delete group</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <Box ml={4} className={classes.dialogContent}>
                            {isGroupEmpty ? (
                                <Typography>Are You sure?</Typography>
                            ) : (
                                <>
                                    <Typography variant="body1" style={{ marginBottom: 16 }}>
                                        This group cannot be deleted because it still contains the following items:
                                    </Typography>
                                    <Box ml={4}>
                                        <ul>
                                            {licensesLength ? (
                                                <li>
                                                    <Typography variant="body2">
                                                        <b>{formatCount(licensesLength, "license", "licenses")}</b>
                                                    </Typography>
                                                </li>
                                            ) : null}
                                            {productsLength ? (
                                                <li>
                                                    <Typography variant="body2">
                                                        <b>{formatCount(productsLength, "product", "products")}</b>
                                                    </Typography>
                                                </li>
                                            ) : null}
                                            {filesLength ? (
                                                <li>
                                                    <Typography variant="body2">
                                                        <b>{formatCount(filesLength, "file", "files")}</b>
                                                    </Typography>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </Box>

                                    <Typography variant="body1" className={classes.dialogActions}>
                                        Please reassign or remove these items before attempting to delete the group.
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <DialogActions style={{ marginTop: 10 }}>
                            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                            <DangerOutlinedButton type="submit" disabled={!isGroupEmpty}>
                                Delete
                            </DangerOutlinedButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};

export default DeleteGroupDialog;
