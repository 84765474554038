import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        borderRadius: 5,
        boxSizing: "border-box",
        padding: 10,
    },
    iconDisabled: {
        color: theme.palette.shadow[300],
        border: "1px solid " + theme.palette.shadow[300],
    },
    icons: {
        marginTop: "2px",
        marginRight: "8px",
        fontSize: "0.9rem",
        color: theme.palette.shadow[300],
    },
    collapsingRowContent: {
        padding: 10,
        width: "100%",
        maxWidth: "100%",
        minHeight: 36,
        textAlign: "left",
        fontSize: "0.9rem",
        wordWrap: "break-word",
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        height: 50,
    },
    customCollapse: {
        "& .MuiCollapse-wrapper": {
            maxWidth: "650px",
        },
    },
}));
