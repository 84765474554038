import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useDispatch } from "src/store";
import { selectWorkspace } from "src/ui/containers/dashboard/workspaces/store/selectors";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { createGroup } from "@dashboard/devices/store";
import { Subscription } from "@dashboard/devices/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";

import { selectGroups } from "../store/selectors";

const ContentBox = styled(DialogContent)({
    width: 400,
    display: "flex",
    flexDirection: "column",
});

interface FormValues {
    name: string;
    description: string;
}

interface Props {
    open: boolean;
    onClose: () => void;
    subscription?: Subscription;
}

const CreateGroupDialog = ({ open = false, ...props }: Props) => {
    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace);
    const groups = useSelector(selectGroups);

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        const groupNameExists = groups.some(
            (group) => group.name?.toLowerCase().trim() === values.name?.toLowerCase().trim(),
        );

        if (!values.name) {
            errors.name = "Name is required";
        }
        if (groupNameExists) {
            errors.name = "A group with this name already exists in this workspace";
        }

        return errors;
    };

    const onSubmit = async (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
        if (workspace) {
            await dispatch(createGroup({ subscriptionId: workspace.subscription, ...values }))
                .unwrap()
                .then((e) => {
                    if (e) {
                        const savedGroups = sessionStorage.getItem("newGroups") || "[]";
                        const groupsArray = [...JSON.parse(savedGroups), e.id];
                        sessionStorage.setItem("newGroups", JSON.stringify(groupsArray));
                    }
                    showSuccessToast("Created group");
                    form.reset();
                    props.onClose();
                })
                .catch(({ message = "Couldn't create group" }) => {
                    showErrorToast(message);
                });
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            e.preventDefault();
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };
    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, form }) => (
                <Dialog open={open} maxWidth="sm" onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogTitle>Add group</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <ContentBox>
                            <TextField name="name" label="Name" style={{ marginBottom: 12 }} required />
                            <TextField name="description" label="Description" fullWidth />
                        </ContentBox>
                        <DialogActions style={{ marginTop: 10 }}>
                            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit">Create</PrimaryButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};

export default CreateGroupDialog;
