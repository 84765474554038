import { KeyboardEvent, RefObject } from "react";

import { ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";

interface Props {
    isOpen: boolean;
    onClickAway?: () => void;
    isBlockedOnClickAway?: boolean;
    anchorRef: RefObject<HTMLButtonElement>;
    children?: React.ReactNode;
    eventType?: "onKeyDown" | "onClick";
}

export function DropdownList({
    children,
    isOpen,
    onClickAway,
    isBlockedOnClickAway,
    anchorRef,
    eventType = "onClick",
}: Props): JSX.Element {
    const handleClose = () => {
        if (isBlockedOnClickAway) {
            return;
        }
        if (onClickAway) {
            onClickAway();
        }
    };

    const handleListKeyDown = (event: KeyboardEvent) => {
        if (isBlockedOnClickAway && event.key === "Tab") {
            event.preventDefault();
            if (onClickAway) {
                onClickAway();
            }
        }
    };

    const handleListClick = () => {
        if (onClickAway) {
            onClickAway();
        }
    };

    const eventHandlers = eventType === "onKeyDown" ? { onKeyDown: handleListKeyDown } : { onClick: handleListClick };

    return (
        <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-end"
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        marginTop: 2,
                        transformOrigin: placement === "bottom" ? "right bottom" : "right top",
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow" {...eventHandlers}>
                                {children}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
