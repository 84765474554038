import lodash from "lodash";
import React from "react";
import { useDispatch, useSelector } from "src/store";

import { closeBatchMode, closeDetails } from "@dashboard/devices/store";
import { selectGroupsByWorkspaceWithFilter } from "@dashboard/groups/store/selectors";
import { PageContainer } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box, Typography } from "@mui/material";

import { useStyles } from "./DevicesLegacyPage.style";
import Group from "./Listing/Group/Group";
import { ManagementPanel } from "./ManagementPanel";

const DevicesLegacyPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const currentWorkspace = useSelector(selectWorkspace);
    const groupsWithFilters = useSelector(selectGroupsByWorkspaceWithFilter);

    React.useEffect(() => {
        return () => {
            dispatch(closeDetails());
            dispatch(closeBatchMode());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer>
            {currentWorkspace ? (
                <>
                    <ManagementPanel />
                    <Box className={classes.subscriptionContainer}>
                        {lodash.sortBy(groupsWithFilters, "name").map((group) => (
                            <Group key={group.id} group={group} />
                        ))}
                    </Box>
                </>
            ) : (
                <Box className={classes.welcomeBox}>
                    <Typography className={classes.welcomeTitle} variant="h5" gutterBottom>
                        Welcome to Device Management
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        You currently have no devices or groups. Ask a collegue to invite you to their workspace or try
                        out our free starter plan at{" "}
                        <a
                            className={classes.starterSubscriptionLink}
                            href="https://emteria.com/kb/starter-subscription"
                        >
                            https://emteria.com/kb/starter-subscription
                        </a>
                    </Typography>
                </Box>
            )}
        </PageContainer>
    );
};

export default DevicesLegacyPage;
