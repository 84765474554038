import { Form } from "react-final-form";
import { useDispatch } from "src/store";

import { updateGroup } from "@dashboard/devices/store/index";
import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { GroupOverviewData } from "../GroupsPage";

const useStyles = makeStyles(() => ({
    dialogActions: {
        marginTop: 10,
    },
    nameInput: {
        marginBottom: 12,
    },
    contentBox: {
        width: 400,
        display: "flex",
        flexDirection: "column",
        padding: 16,
    },
}));

interface Props {
    group: GroupOverviewData;
    open: boolean;
    onClose: () => void;
}

interface FormValues {
    name: string;
    description: string;
}

const EditGroupDialog = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues = {
        name: props.group.name,
        description: props.group.description,
    };

    const onSubmit = (values: FormValues) => {
        dispatch(updateGroup({ id: props.group.id, name: values.name, description: values.description }))
            .unwrap()
            .then(() => {
                showSuccessToast("Successfully updated group");
            })
            .catch(({ message }) => showErrorToast(message))
            .finally(() => {
                props.onClose();
            });
    };

    return (
        <Dialog open={props.open} maxWidth="sm">
            <DialogTitle>Edit group details</DialogTitle>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box className={classes.contentBox}>
                            <TextField name="name" label="Name" className={classes.nameInput} required />
                            <TextField multiline minRows={6} maxRows={6} name="description" label="Description" />
                        </Box>
                        <DialogActions className={classes.dialogActions}>
                            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit">Save</PrimaryButton>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};

export default EditGroupDialog;
