import React from "react";
import { createUseStyles } from "react-jss";
import { RootState, useDispatch, useSelector } from "src/store";

import { ConfirmDialog } from "@devices/components/shared";
import { deleteGroup, selectPaneEditEnabled, setPaneEditEnabled, setPaneTab, updateGroup } from "@devices/store";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { Group } from "@devices/types";
import { selectFilesByGroupId } from "@files/store/selectors";
import { Box, Typography } from "@mui/material";
import { selectProductsByGroupId } from "@products/store/selectors/products";
import authService from "@services/authService";
import { permissionService } from "@services/permissionService/permissionService";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import MenuContainer from "@sidePanel/shared/MenuLists/MenuContainer";
import { DeleteItem, EditItem, MenuItemTooltip } from "@sidePanel/shared/MenuLists/MenuItems";
import { selectWorkspace } from "@workspaces/store/selectors";

const useStyles = createUseStyles({
    groupActionContainer: {
        display: "flex",
        flexDirection: "column",
    },
});

function MenuGroup(props: Group): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = authService.getCurrentUser();
    const [isDeleteGroupDialogOpen, setIsDeleteGroupDialogOpen] = React.useState(false);
    const [isLeaveGroupDialogOpen, setIsLeaveGroupDialogOpen] = React.useState(false);
    const editEnabled = useSelector(selectPaneEditEnabled);
    const groupProducts = useSelector((state: RootState) => selectProductsByGroupId(state, props.id));
    const groupFiles = useSelector((state: RootState) => selectFilesByGroupId(state, props.id));
    const isGroupMember = props.members.some((item) => item.email === currentUser?.email);
    const isExpired = useSelector(selectSubscriptionExpired);

    const licensesLength = props.licenseIds.length;
    const productsLength = groupProducts.length;
    const filesLength = groupFiles.length;

    const isGroupEmpty = !licensesLength && !productsLength && !filesLength;

    const workspace = useSelector(selectWorkspace);
    const isDefaultGroup = workspace?.defaultGroupId === props.id;
    const { groupAbility } = permissionService();

    const handleEditGroup = () => {
        dispatch(setPaneTab("SUMMARY"));
        dispatch(setPaneEditEnabled(true));
    };
    const handleDeleteGroup = () => {
        setIsDeleteGroupDialogOpen(true);
    };
    const handleLeaveGroup = () => {
        setIsLeaveGroupDialogOpen(true);
    };
    const cancelDeleteGroupDialog = () => {
        if (isDeleteGroupDialogOpen) {
            setIsDeleteGroupDialogOpen(false);
        }
    };
    const cancelLeaveGroupDialog = () => {
        if (isLeaveGroupDialogOpen) {
            setIsLeaveGroupDialogOpen(false);
        }
    };
    const confirmDeleteGroup = async () => {
        await dispatch(deleteGroup({ id: props.id }))
            .unwrap()
            .then(() => showSuccessToast("Group successfully deleted"))
            .catch(({ message = "Failed to delete group" }) => {
                showErrorToast(message);
                setIsDeleteGroupDialogOpen(false);
            });
    };
    const leaveGroup = async () => {
        if (currentUser) {
            await dispatch(updateGroup({ id: props.id, removedMembers: [currentUser?.email] }))
                .unwrap()
                .then(() => showSuccessToast("Member updated"))
                .catch(({ message }) => showErrorToast(message));
        }
    };
    const DeleteGroupItem = () => {
        let tooltipMessage = "";

        if (isDefaultGroup) {
            tooltipMessage = "You can't delete the default group";
        } else if (groupAbility(props, workspace).cannot("delete", "Group")) {
            tooltipMessage = "You do not have permission to delete this group";
        }

        const isDeleteDisabled = isDefaultGroup || groupAbility(props, workspace).cannot("delete", "Group");

        return (
            <MenuItemTooltip title={tooltipMessage}>
                <DeleteItem disabled={isDeleteDisabled} label="Delete group" onClick={handleDeleteGroup} />
            </MenuItemTooltip>
        );
    };
    const DeleteGroupDialogContent = () => {
        const formatCount = (count: number, singular: string, plural: string): string =>
            `${count} ${count === 1 ? singular : plural}`;

        if (isGroupEmpty) {
            return <Typography>Are You sure?</Typography>;
        }

        return (
            <>
                <Typography variant="body1" style={{ marginBottom: 16 }}>
                    This group cannot be deleted because it still contains the following items:
                </Typography>
                <Box ml={4}>
                    <ul>
                        {licensesLength ? (
                            <li>
                                <Typography variant="body2">
                                    <b>{formatCount(licensesLength, "license", "licenses")}</b>
                                </Typography>
                            </li>
                        ) : null}
                        {productsLength ? (
                            <li>
                                <Typography variant="body2">
                                    <b>{formatCount(productsLength, "product", "products")}</b>
                                </Typography>
                            </li>
                        ) : null}
                        {filesLength ? (
                            <li>
                                <Typography variant="body2">
                                    <b>{formatCount(filesLength, "file", "files")}</b>
                                </Typography>
                            </li>
                        ) : null}
                    </ul>
                </Box>

                <Typography variant="body1" style={{ marginTop: 16 }}>
                    Please reassign or remove these items before attempting to delete the group.
                </Typography>
            </>
        );
    };
    const LeaveGroupItem = () => {
        if (!isGroupMember) {
            return (
                <MenuItemTooltip title="You are not a direct member of this group" arrow placement="bottom">
                    <EditItem label="Leave group" iconName="fa-solid fa-right-from-bracket" disabled />
                </MenuItemTooltip>
            );
        } else {
            return (
                <EditItem label="Leave group" iconName="fa-solid fa-right-from-bracket" onClick={handleLeaveGroup} />
            );
        }
    };
    const EditGroupItem = () => {
        let tooltipMessage = null;

        if (isExpired) {
            tooltipMessage = "Subscription expired";
            return (
                <MenuItemTooltip title={tooltipMessage}>
                    <EditItem label="Edit group" disabled />
                </MenuItemTooltip>
            );
        }

        return <EditItem label="Edit group" onClick={handleEditGroup} disabled={editEnabled} />;
    };

    return (
        <>
            <MenuContainer>
                <div className={classes.groupActionContainer}>
                    <EditGroupItem key="0" />
                    <DeleteGroupItem key="1" />
                    <LeaveGroupItem key="2" />
                </div>
            </MenuContainer>

            <ConfirmDialog
                title="Delete group"
                content={<DeleteGroupDialogContent />}
                open={isDeleteGroupDialogOpen}
                onConfirm={confirmDeleteGroup}
                onConfirmDisabled={!isGroupEmpty}
                dangerButton
                primaryActionText="Delete"
                onClose={cancelDeleteGroupDialog}
            />
            <ConfirmDialog
                title="Leave group"
                open={isLeaveGroupDialogOpen}
                onConfirm={leaveGroup}
                onClose={cancelLeaveGroupDialog}
            />
        </>
    );
}

export default MenuGroup;
