import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.shadow[50],
        color: theme.palette.black[100],
    },
    box: {
        backgroundColor: theme.palette.white[50],
        padding: 32,
        borderRadius: 16,
        textAlign: "center",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        maxWidth: 600,
    },
    logo: {
        width: 100,
        height: "auto",
        marginBottom: 16,
    },
    countdown: {
        marginTop: 14,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: "pointer",
        marginTop: 12,
        display: "inline-block",
    },
    inviationTarget: {
        fontWeight: "bold",
    },
    loadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    topSpacing: {
        marginTop: 6,
    },
}));
