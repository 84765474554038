import { memo, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import authService from "src/services/authService";
import { RootState, useDispatch, useSelector } from "src/store";
import { selectProfile } from "src/store/account";
import { selectGroupMemberRoleByEmail } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectLicensesByGroupIdWithFilter } from "src/ui/containers/dashboard/licenses/store/selectors";
import { TextButton } from "src/ui/shared/CustomButton";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import {
    addBatchSelection,
    removeBatchSelection,
    selectBatchActionIsActiveGroup,
    selectBatchActionSelection,
    selectViewGroupCollapsedById,
    setViewGroupsCollapseById,
    setViewGroupsExpandById,
} from "@dashboard/devices/store";
import { Group as GroupType } from "@dashboard/devices/types";
import { selectWorkspaceId, selectWorkspaceMemberRoleByEmail } from "@dashboard/workspaces/store/selectors";
import { Box, Checkbox } from "@mui/material";

import Licenses from "../Licenses/Licenses";
import * as Style from "./Group.style";
import { GroupSummary } from "./GroupSummary";

interface Props {
    group: GroupType;
}

const Group = ({ group }: Props) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckable, setIsCheckable] = useState(true);
    const [isGroupOpen, setIsGroupOpen] = useState(true);

    const classes = Style.useStyles();
    const dispatch = useDispatch();
    const isBatchActionGroup = useSelector(selectBatchActionIsActiveGroup);
    const batchActionSelection = useSelector(selectBatchActionSelection, shallowEqual);
    const isCollapsed = useSelector((state) => selectViewGroupCollapsedById(state, group.id));
    const groupLicenses = useSelector((state) => selectLicensesByGroupIdWithFilter(state, group.id), shallowEqual);
    const shouldToggle = groupLicenses.length >= 0 && !isBatchActionGroup;

    const currentUser = authService.getCurrentUser();
    const profile = useSelector(selectProfile);
    const email = currentUser?.email || profile?.email || null;
    const workspaceId = useSelector(selectWorkspaceId);
    const groupRole = useSelector((state: RootState) => selectGroupMemberRoleByEmail(state, group.id, email));
    const workspaceRole = useSelector((state: RootState) =>
        workspaceId ? selectWorkspaceMemberRoleByEmail(state, workspaceId, email) : null,
    );

    const sortedLicenses = groupLicenses.sort((a, b) => {
        if (!a.revocationDate && b.revocationDate) {
            return -1;
        } else if (a.revocationDate && !b.revocationDate) {
            return 1;
        }

        if (!a.device?.name && b.device?.name) {
            return 1;
        } else if (a.device?.name && !b.device?.name) {
            return -1;
        }

        if (a.device?.name && b.device?.name) {
            return a.device.name.localeCompare(b.device.name);
        } else {
            return 0;
        }
    });

    const onToggleGroup = () => {
        setIsGroupOpen(!isGroupOpen);
        if (!isGroupOpen) {
            dispatch(setViewGroupsExpandById(group.id));
        } else if (isGroupOpen) {
            dispatch(setViewGroupsCollapseById(group.id));
        }
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!group.id) {
            return;
        }

        const isSelected = event.target.checked;
        if (isSelected) {
            dispatch(addBatchSelection(group.id));
        } else {
            dispatch(removeBatchSelection(group.id));
        }
    };

    useEffect(() => {
        setIsChecked(batchActionSelection.includes(group.id));
        setIsCheckable(true);
    }, [batchActionSelection, group.id]);

    useEffect(() => {
        setIsGroupOpen(!isCollapsed);
    }, [isCollapsed]);

    if (!workspaceRole && !groupRole) {
        return null;
    }

    return (
        <Box className={classes.container}>
            {isBatchActionGroup && (
                <Checkbox
                    className={classes.itemCheckbox}
                    size="small"
                    onChange={handleCheckboxChange}
                    checked={isChecked}
                    disabled={!isCheckable}
                />
            )}
            {!shouldToggle ? (
                <Box className={classes.summaryBox}>
                    <GroupSummary {...{ group }} />
                </Box>
            ) : (
                <Style.Accordion expanded={isGroupOpen} className={classes.accordion}>
                    <Style.AccordionSummary
                        aria-controls={`acc-groupId-${group.id}`}
                        id={`acc-groupId-${group.id}`}
                        onDoubleClick={onToggleGroup}
                    >
                        <Box className={classes.expandBox}>
                            <TextButton onClick={onToggleGroup}>
                                <Icon
                                    name="fas fa-angle-right"
                                    size={IconSize.small}
                                    className={`${classes.toggleIcon}  ${isGroupOpen ? "toggleOpen" : ""}`}
                                />
                            </TextButton>
                            <GroupSummary {...{ group }} />
                        </Box>
                    </Style.AccordionSummary>
                    <Style.AccordionDetails className={classes.detailsBox}>
                        <Licenses licenses={sortedLicenses} groupId={group.id} />
                    </Style.AccordionDetails>
                </Style.Accordion>
            )}
        </Box>
    );
};

export default memo(Group);
