import { DateTime } from "luxon";
import { useMemo } from "react";
import semver from "semver";
import { theme } from "src/providers/ThemeProvider";
import { RootState, useSelector } from "src/store";
import { createCustomColumnHelper } from "src/types/tanstack";

import { selectSubscription, selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectGroupById, selectGroups } from "@dashboard/groups/store/selectors";

import { FileSizeCell, MoveLicenseCell, StatusCell } from "./components/Table/Cells";
import { getIPs } from "./store/helpers";
import { DeviceState, Group, License } from "./types";
import { dateTimeFormat } from "./utils/dates";
import { getDeviceOnlineSince, getHumanReadableDeviceUptime } from "./utils/device";
import { getLicenseActivationStatus } from "./utils/license";

export const successColor = theme.palette.green[50];
export const warningColor = theme.palette.yellow[100];
export const dangerColor = theme.palette.red[50];
export const neutralColor = theme.palette.grey[300];

export const visibleColumnsConfig = {
    "moveLicenseCheckbox": false,
    "licenseStatus": false,
    "memoryTotal": false,
    "batteryLevel": false,
    "batteryVoltage": false,
    "batteryPlugged": false,
    "batteryStatus": false,
    "batteryHealth": false,
    "displaySize": false,
    "displayRotation": false,
    "displayDensity": false,
    "systemVersion": false,
    "systemUptime": false,
    "systemTime": false,
    "systemTimezone": false,
    "wifiNetwork": false,
    "onlineSince": false,
    "ipv6": false,
    "connection": false,
};

export type DeviceTableData = {
    id: number;
    onlineStatus: string;
    status: string;
    policyStatus: string;
    licenseStatus: string;
    name: string | null;
    comment: string | null;
    groupName: string | null;
    codename: string | null;
    serial: string | null;
    memoryTotal: number | null;
    memoryAvailable: number | null;
    memoryLow: string;
    batteryLevel: string | null;
    batteryVoltage: string | null;
    batteryPlugged: string | null;
    batteryStatus: string | null;
    batteryHealth: string | null;
    description: string | null;
    displaySize: string | null;
    displayRotation: number | null;
    displayDensity: string | null;
    systemVersion: string | null;
    systemUptime: string | null;
    systemTime: string | null;
    systemTimezone: string | null;
    wifiNetwork: string | null;
    onlineSince: string | null;
    imei: string | null;
    ipv4: string | null;
    ipv6: string | null;
    connection: string | null;
    code: string;
    groupId: number;
};

export const getSummaryIndicatorColor = (status: string): string => {
    switch (status) {
        case "Subscription expired":
            return neutralColor;
        case "Invalid subscription":
            return warningColor;
        case "Revoked license":
            return warningColor;
        case "Unused license":
            return neutralColor;
        case "Device offline":
            return dangerColor;
        case "Policy warning":
            return warningColor;
        case "Device online":
            return successColor;
        default:
            return neutralColor;
    }
};

export const useDeviceStatus = () => {
    const currentSubscription = useSelector(selectSubscription);
    const isExpired = useSelector(selectSubscriptionExpired);

    return useMemo(
        () => ({
            getSummaryIndicatorHint: (license: License): string => {
                const device = license.device;
                const deviceState: Partial<DeviceState> = device?.lastState ?? {};

                if (isExpired) {
                    return "Subscription expired";
                } else if (!currentSubscription?.isValid) {
                    return "Invalid subscription";
                } else if (!device) {
                    return "Unused license";
                } else if (!device?.isOnline) {
                    return "Device offline";
                } else if (deviceState?.policy && !deviceState?.policy?.successful) {
                    return "Policy warning";
                } else {
                    return "Device online";
                }
            },
            getOnlineStatusHint: (license: License): string => {
                if (isExpired) {
                    return "Subscription expired";
                } else if (!license.device?.id) {
                    return "No device connected";
                }
                if (license.device?.isOnline) {
                    return "Device is online";
                } else {
                    return "Device is offline";
                }
            },

            getOnlineStatusColor: (license: License): string => {
                if (isExpired) {
                    return neutralColor;
                } else if (!license.device?.id) {
                    return neutralColor;
                }
                if (license.device?.isOnline) {
                    return successColor;
                } else {
                    return dangerColor;
                }
            },
            getLicenseStatusColor: (license: License): string => {
                if (isExpired) {
                    return neutralColor;
                } else if (license.device) {
                    return successColor;
                } else {
                    return neutralColor;
                }
            },
            getLicenseStatusHint: (license: License): string => {
                if (isExpired) {
                    return "Subscription expired";
                } else if (license.device) {
                    return "Active license";
                } else {
                    return "Unused license";
                }
            },
        }),
        [currentSubscription, isExpired],
    );
};
export const usePolicyStatus = () => {
    const isExpired = useSelector(selectSubscriptionExpired);

    return {
        getPolicyStatusHint: (license: License): string => {
            const licenseGroup = useSelector((state: RootState) => selectGroupById(state, license.groupId));
            const deviceState: Partial<DeviceState> = license.device?.lastState ?? {};

            if (isExpired) {
                return "Subscription expired";
            } else if (!licenseGroup?.policy?.length) {
                return "No group policy defined";
            } else if (!license.device?.id) {
                return "No device connected";
            } else if (deviceState?.policy?.successful) {
                return "Group policy compliant";
            } else {
                return "Group policy non-compliant";
            }
        },
        getPolicyStatusColor: (license: License): string => {
            const licenseGroup = useSelector((state: RootState) => selectGroupById(state, license.groupId));
            const deviceState: Partial<DeviceState> = license.device?.lastState ?? {};

            if (isExpired) {
                return neutralColor;
            } else if (!licenseGroup?.policy?.length || !license.device?.id) {
                return neutralColor;
            } else if (deviceState?.policy?.successful) {
                return successColor;
            } else {
                return dangerColor;
            }
        },
    };
};
export const getDeviceBatteryPluggedDesc = (status: number) => {
    switch (status) {
        case 1:
            return "AC";
        case 2:
            return "USB";
        case 4:
            return "Wireless";
        default:
            return "Unknown";
    }
};
export const getDeviceBatteryStatusDesc = (status: number) => {
    switch (status) {
        case 2:
            return "Charging";
        case 3:
            return "Discharging";
        case 4:
            return "Not charging";
        case 5:
            return "Full";
        default:
            return "Unknown";
    }
};
export const getDeviceBatteryHealthDesc = (status: number) => {
    switch (status) {
        case 2:
            return "Good";
        case 3:
            return "Overheat";
        case 4:
            return "Dead";
        case 5:
            return "Over voltage";
        case 6:
            return "Failure";
        case 7:
            return "Cold";
        default:
            return "Unknown";
    }
};

const getSystemTime = (row: License) => {
    const { properties } = row.device?.lastState || {};
    const timezone = properties?.["persist.sys.timezone"];
    const deviceState = row.device?.lastState;

    return deviceState?.datetime?.currentMs
        ? DateTime.fromMillis(deviceState.datetime.currentMs, { zone: timezone || "UTC" }).toFormat(dateTimeFormat)
        : null;
};

const getPolicyStatus = (row: License, groups: Group[]) => {
    const group = groups.find((g) => g.id === row.groupId);
    if (!group?.policy?.length) {
        return "Compliant";
    }
    const deviceState: Partial<DeviceState> = row.device?.lastState ?? {};
    return deviceState?.policy?.successful ? "Compliant" : "Non-compliant";
};

interface Network {
    interface: string;
}
export const getInternetConnection = (networks: Network[] | undefined): "Ethernet" | "WiFi" | null => {
    if (!networks) {
        return null;
    }

    for (const net of networks) {
        if (net.interface.startsWith("eth")) {
            return "Ethernet";
        }
        if (net.interface.startsWith("wlan")) {
            return "WiFi";
        }
    }

    return null;
};

export const useTransformLicenseToTableData = (licenses: License[]): DeviceTableData[] => {
    const device = useDeviceStatus();
    const groups = useSelector(selectGroups);

    return useMemo(() => {
        const sortedLicenses = licenses.sort((a, b) => {
            if (!a.revocationDate && b.revocationDate) {
                return -1;
            } else if (a.revocationDate && !b.revocationDate) {
                return 1;
            }

            if (!a.device?.name && b.device?.name) {
                return 1;
            } else if (a.device?.name && !b.device?.name) {
                return -1;
            }

            if (a.device?.name && b.device?.name) {
                return a.device.name.localeCompare(b.device.name);
            } else {
                return 0;
            }
        });

        return sortedLicenses.map((item) => ({
            id: item.id,
            status: device.getSummaryIndicatorHint(item),
            onlineStatus: item.device?.isOnline ? "Online" : "Offline",
            policyStatus: getPolicyStatus(item, groups),
            licenseStatus: getLicenseActivationStatus(item),
            name: item.device?.name || null,
            comment: item.comment || null,
            groupName: item.groupName,
            codename: item.device?.product || null,
            serial: item.device?.serial || null,
            memoryTotal: item.device?.lastState?.memory.total || null,
            memoryAvailable: item.device?.lastState?.memory.available || null,
            memoryLow: item.device?.lastState?.memory?.isLow ? "Yes" : "No",
            batteryLevel: item.device?.lastState?.battery.percentage
                ? `${item.device.lastState.battery.percentage} %`
                : null,
            batteryVoltage: item.device?.lastState?.battery.voltage
                ? `${item.device.lastState.battery.voltage} mV`
                : null,
            batteryPlugged: item.device?.lastState?.battery.plugged
                ? getDeviceBatteryPluggedDesc(item.device?.lastState?.battery.plugged)
                : null,
            batteryStatus: item.device?.lastState?.battery.status
                ? getDeviceBatteryStatusDesc(item.device.lastState.battery.status)
                : null,
            batteryHealth: item.device?.lastState?.battery.health
                ? getDeviceBatteryHealthDesc(item.device.lastState.battery.health)
                : null,
            description: item.device?.description || null,
            displaySize: item.device?.lastState?.display?.size || null,
            displayRotation: item.device?.lastState?.display?.rotation || null,
            displayDensity: item.device?.lastState?.properties?.["ro.sf.lcd_density"] || null,
            systemVersion: item.device?.lastState?.version.emteriaVersion || null,
            systemUptime: getHumanReadableDeviceUptime(
                item.device?.lastState?.datetime?.elapsedMs,
                item.device?.isOnline,
            ),
            systemTime: getSystemTime(item),
            systemTimezone: item.device?.lastState?.properties?.["persist.sys.timezone"] || null,
            wifiNetwork:
                item.device?.lastState?.wifi?.enabled && item.device?.lastState?.wifi?.ssid
                    ? item.device?.lastState?.wifi?.ssid
                    : null,
            onlineSince: getDeviceOnlineSince(item.device),
            ipv4: getIPs(item.device?.lastState || undefined).ipv4,
            ipv6: getIPs(item.device?.lastState || undefined).ipv6,
            connection: getInternetConnection(item.device?.lastState?.networks),
            groupId: item.groupId,
            code: item.activationCode,
            imei: item.device?.lastState?.telephony?.imei || null,
        }));
    }, [licenses, device, groups]);
};

export type ColumnId =
    | "moveLicenseCheckbox"
    | "status"
    | "summarizedStatus"
    | "onlineStatus"
    | "policyStatus"
    | "licenseStatus"
    | "code"
    | "name"
    | "comment"
    | "groupName"
    | "codename"
    | "serial"
    | "memoryTotal"
    | "memoryAvailable"
    | "memoryLow"
    | "batteryLevel"
    | "batteryVoltage"
    | "batteryPlugged"
    | "batteryStatus"
    | "batteryHealth"
    | "displaySize"
    | "displayRotation"
    | "displayDensity"
    | "systemVersion"
    | "systemUptime"
    | "systemTime"
    | "systemTimezone"
    | "wifiNetwork"
    | "onlineSince"
    | "ipv4"
    | "ipv6"
    | "description"
    | "imei"
    | "connection";
const columnHelper = createCustomColumnHelper<DeviceTableData, ColumnId>();

export const tableColumns = [
    columnHelper.accessor("id", {
        id: "moveLicenseCheckbox",
        header: "Selection",
        enableSorting: false,
        enableHiding: false,
        cell: (info) => <MoveLicenseCell value={info.getValue()} />,
    }),
    columnHelper.group({
        header: "Status",
        columns: [
            columnHelper.accessor("status", {
                id: "status",
                header: "Status",
                enableSorting: false,
                cell: (info) => <StatusCell value={info.getValue()} />,
            }),
            columnHelper.accessor("onlineStatus", {
                id: "onlineStatus",
                header: "Online",
                meta: { filterVariant: "deviceOnline" },
            }),
            columnHelper.accessor("policyStatus", {
                id: "policyStatus",
                header: "Policy",
                meta: { filterVariant: "policyStatus" },
            }),
            columnHelper.accessor("licenseStatus", {
                id: "licenseStatus",
                header: "License",
                meta: { filterVariant: "license" },
            }),
        ],
    }),
    columnHelper.group({
        header: "General",
        columns: [
            columnHelper.accessor("code", {
                id: "code",
                header: "Code",
                sortingFn: "text",
            }),
            columnHelper.accessor("name", {
                id: "name",
                header: "Name",
            }),
            columnHelper.accessor("comment", {
                id: "comment",
                header: "Comment",
            }),
            columnHelper.accessor("groupName", {
                id: "groupName",
                header: "Group",
                meta: { filterVariant: "groupName" },
            }),
            columnHelper.accessor("description", {
                id: "description",
                header: "Description",
            }),
        ],
    }),
    columnHelper.group({
        header: "Hardware",
        columns: [
            columnHelper.accessor("codename", {
                id: "codename",
                header: "Codename",
            }),
            columnHelper.accessor("serial", {
                id: "serial",
                header: "Serial",
                sortingFn: "text",
            }),
            columnHelper.accessor("imei", {
                id: "imei",
                header: "IMEI",
            }),
        ],
    }),
    columnHelper.group({
        header: "Memory",
        columns: [
            columnHelper.accessor("memoryTotal", {
                id: "memoryTotal",
                header: "Total",
                cell: (info) => <FileSizeCell value={info.getValue()} />,
            }),
            columnHelper.accessor("memoryAvailable", {
                id: "memoryAvailable",
                header: "Available",
                cell: (info) => <FileSizeCell value={info.getValue()} />,
            }),
            columnHelper.accessor("memoryLow", {
                id: "memoryLow",
                header: "Low",
            }),
        ],
    }),
    columnHelper.group({
        header: "Battery",
        columns: [
            columnHelper.accessor("batteryLevel", {
                id: "batteryLevel",
                header: "Level",
            }),
            columnHelper.accessor("batteryVoltage", {
                id: "batteryVoltage",
                header: "Voltage",
            }),
            columnHelper.accessor("batteryPlugged", {
                id: "batteryPlugged",
                header: "Plugged",
            }),
            columnHelper.accessor("batteryStatus", {
                id: "batteryStatus",
                header: "Status",
            }),
            columnHelper.accessor("batteryHealth", {
                id: "batteryHealth",
                header: "Health",
            }),
        ],
    }),
    columnHelper.group({
        header: "Display",
        columns: [
            columnHelper.accessor("displaySize", {
                id: "displaySize",
                header: "Size",
            }),
            columnHelper.accessor("displayRotation", {
                id: "displayRotation",
                header: "Rotation",
            }),
            columnHelper.accessor("displayDensity", {
                id: "displayDensity",
                header: "Density",
            }),
        ],
    }),
    columnHelper.group({
        header: "System",
        columns: [
            columnHelper.accessor("systemVersion", {
                id: "systemVersion",
                header: "Version",
                sortingFn: (a, b) =>
                    semver.compare(a.original.systemVersion || "0.0.0", b.original.systemVersion || "0.0.0"),
                meta: { filterVariant: "systemVersion" },
            }),
            columnHelper.accessor("systemUptime", {
                id: "systemUptime",
                header: "Uptime",
            }),
            columnHelper.accessor("systemTime", {
                id: "systemTime",
                header: "Time",
            }),
            columnHelper.accessor("systemTimezone", {
                id: "systemTimezone",
                header: "Timezone",
            }),
        ],
    }),
    columnHelper.group({
        header: "Connection",
        columns: [
            columnHelper.accessor("wifiNetwork", {
                id: "wifiNetwork",
                header: "SSID",
            }),
            columnHelper.accessor("onlineSince", {
                id: "onlineSince",
                header: "Online since",
            }),
            columnHelper.accessor("ipv4", {
                id: "ipv4",
                header: "IPv4",
            }),
            columnHelper.accessor("ipv6", {
                id: "ipv6",
                header: "IPv6",
            }),
            columnHelper.accessor("connection", {
                id: "connection",
                header: "Type",
            }),
        ],
    }),
];
