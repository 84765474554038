import makeStyles from "@mui/styles/makeStyles";

const additionalPadding = 150;
export const useStyles = makeStyles({
    paneBox: {
        position: "absolute",
        top: 0,
        height: `calc(100vh - ${additionalPadding}px)`,
        marginTop: 120,
        width: "70%",
        right: 0,
        overflow: "auto",
        zIndex: 10,
    },
    modalContainer: {
        position: "relative",
    },
    tableCell: {
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    sortIcon: {
        position: "relative",
        top: "2px",
        fontSize: "16px",
    },
    createButton: {
        marginTop: 12,
    },
    sortingIconContainer: {
        fontWeight: 600,
        display: "flex",
    },
    noResultsRow: {
        textAlign: "center",
        padding: "20px",
    },
    searchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10,
    },
});
