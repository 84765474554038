import { isArray, mergeWith } from "lodash";
import { LocalStorage } from "src/services/localStorageService";
import { PartialDeep } from "type-fest";

import { RootState } from "./index";

export const persistentStore = {
    get(): PartialDeep<RootState> | null {
        const _store = LocalStorage.getItem<Partial<RootState>>("store");
        if (!_store) {
            return null;
        }
        return _store;
    },
    set(value: PartialDeep<RootState>): void {
        const _store = mergeWith(this.get(), value, (obj: PartialDeep<RootState>, src: PartialDeep<RootState>) => {
            if (isArray(obj)) {
                return src;
            }
        });
        LocalStorage.setItem("store", _store);
    },
};
