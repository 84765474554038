import { useField, UseFieldConfig } from "react-final-form";

import {
    Chip,
    FormControl,
    FormControlProps,
    FormHelperText,
    InputLabel,
    ListSubheader,
    MenuItem,
    MenuProps,
    Select,
    SelectProps,
    styled,
    Theme,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import makeStyles from "@mui/styles/makeStyles";

const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: theme.palette.white[50],
}));
const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.blue[150],
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.red[50],
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
        backgroundColor: theme.palette.shadow[100],
    },
}));
const StyledMenuItem = styled(MenuItem)({
    paddingTop: "2px",
    paddingBottom: "2px",
    height: "auto",
});
const StyledFormLabel = styled(InputLabel)(({ theme }) => ({
    "&.Mui-focused": {
        borderColor: theme.palette.blue[150],
    },
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));
const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    lineHeight: "1.4",
    color: "black",
    fontSize: "1rem",
    backgroundColor: theme.palette.white[50],
    paddingTop: "2px",
    paddingBottom: "2px",
    fontWeight: "bold",
}));
const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));

const customMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    PaperProps: {
        style: {
            maxHeight: 500,
        },
    },
};
export const useStyles = makeStyles((theme: Theme) => ({
    placeholder: {
        color: theme.palette.grey[500],
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
}));

export type CategorySelectOptionValue = {
    label: string;
    value: number;
    disabled: boolean;
};

export interface CategoryOption {
    category: string;
    options: CategorySelectOptionValue[];
}

interface CategoriesSelectProps extends Omit<SelectProps, "value"> {
    name: string;
    options: CategoryOption[];
    value?: number | string;
    helperText?: string;
    size?: "small" | "medium";
    config?: UseFieldConfig<CategoryOption, CategoryOption>;
}

export const CategoriesSelectNoForm = ({
    options,
    size = "small",
    variant = "outlined",
    fullWidth = false,
    className,
    onChange,
    value,
    ...rest
}: CategoriesSelectProps) => {
    const handleChange: SelectInputProps["onChange"] = (event, child) => {
        if (onChange) {
            onChange(event, child);
        }
    };

    return (
        <StyledFormControl {...{ size, variant, fullWidth, className }}>
            <StyledFormLabel>{rest.label}</StyledFormLabel>
            <Select
                style={{ backgroundColor: "inherit" }}
                {...rest}
                value={value}
                data-testid="select-field"
                onChange={handleChange}
                MenuProps={customMenuProps}
            >
                {options.map((category) => [
                    <StyledListSubheader key={category.category}>{category.category}</StyledListSubheader>,
                    ...category.options.map((option) => (
                        <MenuItem
                            key={`${category.category}-${option.value}`}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </MenuItem>
                    )),
                ])}
            </Select>
        </StyledFormControl>
    );
};

export type SimpleValue = { label: string; value: string | number | undefined; disabled?: boolean };
type SelectOption = { category: string; options: SimpleValue[] };
type MixedOption = SimpleValue | SelectOption;

interface MixedSelectProps extends Omit<SelectProps, "value"> {
    name: string;
    options: MixedOption[];
    helperText?: string;
    size?: "small" | "medium";
    config?: UseFieldConfig<SelectOption, SelectOption>;
    withoutHelperText?: boolean;
}

const isCategoryOption = (option: MixedOption): option is SelectOption =>
    "options" in option && Array.isArray(option.options);

export const SelectField = ({
    name,
    config,
    options,
    helperText,
    style,
    size = "small",
    variant = "outlined",
    fullWidth = false,
    className,
    onChange,
    withoutHelperText = false,
    ...rest
}: MixedSelectProps) => {
    const { input, meta } = useField(name, config);
    const error = meta.touched && meta.error;

    const handleChange: SelectInputProps["onChange"] = (event, child) => {
        input.onChange(event);

        if (onChange) {
            onChange(event, child);
        }
    };

    return (
        <StyledFormControl {...{ size, style, error, variant, fullWidth, className }}>
            <StyledFormLabel>{rest.label}</StyledFormLabel>
            <StyledSelect
                {...rest}
                {...input}
                data-testid="select-field"
                onChange={handleChange}
                MenuProps={customMenuProps}
            >
                {options.map((option) =>
                    isCategoryOption(option) ? (
                        [
                            <StyledListSubheader key={option.category}>{option.category}</StyledListSubheader>,
                            ...option.options.map((subOption) => (
                                <StyledMenuItem
                                    key={`${option.category}-${String(subOption.value)}`}
                                    value={subOption.value}
                                    disabled={subOption.disabled}
                                >
                                    {subOption.label}
                                </StyledMenuItem>
                            )),
                        ]
                    ) : (
                        <StyledMenuItem
                            key={`${option.label}-${String(option.value)}`}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </StyledMenuItem>
                    ),
                )}
            </StyledSelect>
            {!withoutHelperText ? <StyledFormHelperText>{error || helperText}</StyledFormHelperText> : null}
        </StyledFormControl>
    );
};

export const UncontrolledSelectField = ({
    options,
    className,
    fullWidth = false,
    ...props
}: SelectProps & { options: SimpleValue[] }) => {
    const styles = useStyles();

    const handleRenderValue = (value: unknown) => {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption ? selectedOption.label : <p className={styles.placeholder}>{props.placeholder}</p>;
    };

    return (
        <StyledFormControl {...{ className, fullWidth }} size="small">
            <StyledFormLabel>{props.label}</StyledFormLabel>
            <StyledSelect
                {...props}
                MenuProps={customMenuProps}
                renderValue={() => handleRenderValue(props.value)}
                displayEmpty
            >
                {options.map((option) => (
                    <StyledMenuItem key={option.label} value={option.value} disabled={option.disabled}>
                        {option.label}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
        </StyledFormControl>
    );
};

interface ControlledSelectFieldProps extends Omit<SelectProps, "value"> {
    name: string;
    options: SimpleValue[];
    helperText?: string;
    config?: UseFieldConfig<SimpleValue, SimpleValue>;
    withoutHelperText?: boolean;
    formControlProps?: FormControlProps;
}

export const ControlledSelectField = (props: ControlledSelectFieldProps) => {
    const styles = useStyles();
    const { name, config, options, helperText, onChange, withoutHelperText, formControlProps, ...rest } = props;
    const { input, meta } = useField(name, config);
    const error = meta.touched && meta.error;

    const handleChange: SelectInputProps["onChange"] = (event, child) => {
        input.onChange(event);
        if (onChange) {
            onChange(event, child);
        }
    };
    const handleRenderValue = (value: unknown) => {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption ? selectedOption.label : <p className={styles.placeholder}>{rest.placeholder}</p>;
    };

    return (
        <StyledFormControl {...{ size: "small", variant: "outlined", fullWidth: false, error, ...formControlProps }}>
            <StyledFormLabel>{rest.label}</StyledFormLabel>
            <StyledSelect
                {...rest}
                {...input}
                data-testid="select-field"
                onChange={handleChange}
                renderValue={handleRenderValue}
                MenuProps={customMenuProps}
                displayEmpty
            >
                {options.map((item) => (
                    <StyledMenuItem key={item.value} value={item.value} disabled={item.disabled}>
                        {item.label}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
            {!withoutHelperText ? <StyledFormHelperText>{error || helperText}</StyledFormHelperText> : null}
        </StyledFormControl>
    );
};

type ControlledMultiSelectFieldProps = Omit<SelectProps, "multiple" | "value"> & {
    name: string;
    options: SimpleValue[];
    helperText?: string;
    config?: UseFieldConfig<SelectOption, SelectOption>;
    withoutHelperText?: boolean;
    formControlProps?: FormControlProps;
};
export const ControlledMultiSelectField = (props: ControlledMultiSelectFieldProps) => {
    const styles = useStyles();
    const { name, config, options, helperText, onChange, withoutHelperText, formControlProps, ...rest } = props;
    const { input, meta } = useField(name, config);
    const error = meta.touched && meta.error;

    const handleChange: SelectInputProps["onChange"] = (event, child) => {
        input.onChange(event);
        if (onChange) {
            onChange(event, child);
        }
    };

    const renderValue = (value: SelectProps["value"]) => (
        <div className={styles.chips}>
            {(value as string[]).map((item) => (
                <Chip key={item} label={item} size="small" color="primary" className={styles.chip} />
            ))}
        </div>
    );

    return (
        <StyledFormControl {...{ size: "small", variant: "outlined", fullWidth: true, error, ...formControlProps }}>
            <StyledFormLabel>{props.label}</StyledFormLabel>
            <StyledSelect
                {...rest}
                {...input}
                onChange={handleChange}
                renderValue={renderValue}
                MenuProps={customMenuProps}
                multiple
            >
                {options.map((option) => (
                    <StyledMenuItem key={option.label} value={option.value} disabled={option.disabled}>
                        {option.label}
                    </StyledMenuItem>
                ))}
            </StyledSelect>
            {!withoutHelperText ? <StyledFormHelperText>{error || helperText}</StyledFormHelperText> : null}
        </StyledFormControl>
    );
};
