import { Icon as _Icon, IconProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    sizeXSmall: {
        fontSize: "12px !important",
        width: "18px !important",
        height: "18px !important",
    },
    sizeSmall: {
        fontSize: "14px !important",
        width: "24px !important",
        height: "24px !important",
    },
    sizeMedium: {
        fontSize: "18px !important",
        width: "32px !important",
        height: "32px !important",
    },
    sizeLarge: {
        fontSize: "1.35rem !important",
        width: "2rem !important",
        height: "1.5rem !important",
    },
});

export enum IconSize {
    xsmall = "xsmall",
    small = "small",
    medium = "medium",
    large = "large",
}

interface Props extends IconProps {
    name: string;
    size?: IconSize;
    className?: string;
}

export function Icon({ name, size = IconSize.xsmall, className = "", ...props }: Props): JSX.Element {
    const classes = useStyles();
    const params = () => {
        const paramClasses = [];

        switch (size) {
            case IconSize.xsmall:
                paramClasses.push(classes.sizeXSmall);
                break;
            case IconSize.small:
                paramClasses.push(classes.sizeSmall);
                break;
            case IconSize.medium:
                paramClasses.push(classes.sizeMedium);
                break;
            case IconSize.large:
                paramClasses.push(classes.sizeLarge);
                break;
        }
        return paramClasses.join(" ");
    };

    return (
        <>
            <_Icon className={`${name} ${classes.icon} ${params()} ${className}`} {...props}>
                {props.children}
            </_Icon>
        </>
    );
}
