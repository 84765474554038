import { Device, DeviceId, Group, License, Subscription } from "@dashboard/devices/types";
import { daysToDate } from "@dashboard/devices/utils/dates";
import { Provisioning } from "@dashboard/provisioning/types/index";
import { createEntityAdapter } from "@reduxjs/toolkit";

export const subscriptionsAdapter = createEntityAdapter<Subscription>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const provisioningsAdapter = createEntityAdapter<Provisioning>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const groupsAdapter = createEntityAdapter<Group>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const licensesAdapter = createEntityAdapter<License>({});

export const devicesAdapter = createEntityAdapter<Device>({});

type SubscriptionsNormalized = Record<number, Subscription>;

type LicensesNormalized = Record<number, License>;

type DevicesNormalized = Record<DeviceId, Device>;

type ProvisioningsNormalized = Record<number, Provisioning>;

type NormalizedEntities = {
    subscriptions: SubscriptionsNormalized;
    licenses: LicensesNormalized;
    devices: DevicesNormalized;
    provisionings: ProvisioningsNormalized;
};

const sorterDeviceName = (a: License, b: License) => (a?.device?.name ?? "").localeCompare(b?.device?.name ?? "");

export const sortLicensesByReduce = (licenses: License[]): License[] => {
    const [licensesUsed, licensesFree] = licenses.reduce<[License[], License[]]>(
        (acc, license) => {
            return license.device ? [acc[0].concat(license), acc[1]] : [acc[0], acc[1].concat(license)];
        },
        [[], []],
    );
    const [licensesUsedActive, licensesUsedRevoked] = licensesUsed.reduce<[License[], License[]]>(
        (acc, license) => {
            return !license.revocationDate ? [acc[0].concat(license), acc[1]] : [acc[0], acc[1].concat(license)];
        },
        [[], []],
    );
    const [licensesFreeActive, licensesFreeRevoked] = licensesFree.reduce<[License[], License[]]>(
        (acc, license) => {
            return !license.revocationDate ? [acc[0].concat(license), acc[1]] : [acc[0], acc[1].concat(license)];
        },
        [[], []],
    );

    const _sortedLicenses = [
        ...licensesUsedActive.sort(sorterDeviceName),
        ...licensesFreeActive,
        ...licensesUsedRevoked.sort(sorterDeviceName),
        ...licensesFreeRevoked,
    ];
    return _sortedLicenses;
};

export const sortLicensesBySort = (licenses: License[]): License[] =>
    licenses.sort((a, b) => {
        if (a.device && b.device) {
            if (!a.revocationDate || !b.revocationDate) {
                return !a.revocationDate ? -1 : !b.revocationDate ? 0 : 1;
            }
            if (!a.revocationDate && !b.revocationDate) {
                return (a?.device?.name ?? "").localeCompare(b?.device?.name ?? "");
            }
            if (a.revocationDate && b.revocationDate) {
                return 0;
            }
            return (a?.device?.name ?? "").localeCompare(b?.device?.name ?? "");
        }
        if (a.device || b.device) {
            return a.device ? -1 : b.device ? 1 : 0;
        }
        return !a.revocationDate ? -1 : !b.revocationDate ? 1 : 0;
    });

export const normalizeSubscriptions = (subscriptions: API.Subscription[]): SubscriptionsNormalized => {
    const newSubs: SubscriptionsNormalized = {};

    subscriptions.forEach((item) => {
        const subscription: Subscription = { ...item, isExpired: false };

        if (subscription?.serviceName === "CustomerService") {
            return;
        }
        if (!subscription?.name) {
            subscription.name = `${subscription?.variantName} ${subscription.chargeId}`;
        }
        if (subscription?.expirationDate) {
            subscription.isExpired = daysToDate(subscription.expirationDate) <= 0;
        }

        newSubs[subscription.id] = subscription;
    });

    return subscriptions;
};

export const normalizeLicenses = (licenses: License[]): Pick<NormalizedEntities, "licenses" | "devices"> => {
    const newLicenses: LicensesNormalized = {};
    const newDevices: DevicesNormalized = {};

    licenses.forEach((license) => {
        newLicenses[license.id] = license;
        const { device } = license;
        if (device) {
            newDevices[device.id] = device;
        }
    });

    return {
        licenses: newLicenses,
        devices: newDevices,
    };
};

export const normalizeProvisionings = (provisionings: API.Provisioning[]): ProvisioningsNormalized => {
    const normalizedProvisionings: ProvisioningsNormalized = {};

    provisionings.forEach((provisioning) => {
        normalizedProvisionings[provisioning.id] = provisioning;
    });

    return normalizedProvisionings;
};
