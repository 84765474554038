import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { BasicApiResponse } from "src/types";

export type DeviceActivityType = "OsUpdate" | "LowMemory" | "LowBattery";

export type OsUpdatePayload = {
    version: {
        emteriaVersion: {
            new: string;
            old: string;
        };
    };
};
type LowMemoryPayload = {
    memory: {
        isLow: {
            new: boolean;
            old: boolean;
        };
    };
};
export type LowBatteryPayload = {
    battery: {
        percentage: {
            new: string;
            old: string;
        };
    };
};

export type DeviceActivity = {
    id: number;
    deviceId: number;
    time: string;
    value: OsUpdatePayload | LowMemoryPayload | LowBatteryPayload;
    type: DeviceActivityType;
};

type DeviceActivityResponse = {
    count: number;
    list: DeviceActivity[];
};

type FetchDeviceActivitiesResponse = AxiosResponse<BasicApiResponse & { model: DeviceActivityResponse }>;
export const fetchDeviceActivities = async (pageSize = 50) => {
    const endpoint = await RouteService.getEventsRoute();
    const { data } = await httpService.get<null, FetchDeviceActivitiesResponse>(endpoint, {
        params: {
            pageSize,
        },
    });
    return data.model.list;
};
