import { memo, useEffect, useState } from "react";
import { useSelector } from "src/store";
import {
    selectLicenseListStatusByGroupId,
    selectLicensesListStatus,
} from "src/ui/containers/dashboard/licenses/store/selectors";
import { LoadingIcon } from "src/ui/shared/Loading";

import { IconSize } from "@dashboard/devices/components/shared";
import { selectLicensePageSize, selectSearchQuery } from "@dashboard/devices/store";
import { License as LicenseType } from "@dashboard/devices/types";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import License from "../License/License";
import { NoBorderTableCell, TableCell, useStyles } from "./Licenses.style";

const HEADERS = [
    { id: "action", label: "" },
    { id: "indicator", label: "" },
    { id: "license", label: "License" },
    { id: "device", label: "Device" },
    { id: "hardware", label: "Hardware" },
    { id: "system", label: "System" },
    { id: "connection", label: "Connection" },
    { id: "ips", label: "IPs" },
    { id: "status", label: "Status" },
] as const;

interface Props {
    licenses: LicenseType[];
    groupId: number;
}

const Licenses = (props: Props) => {
    const classes = useStyles();

    const searchQuery = useSelector(selectSearchQuery);
    const globalPageSize = useSelector(selectLicensePageSize);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(globalPageSize);

    const listsStatus = useSelector(selectLicensesListStatus);
    const listStatus = useSelector((state) => selectLicenseListStatusByGroupId(state, props.groupId));
    const loading = listsStatus === "pending" || listStatus === "pending";
    const licensesOnPage = props.licenses.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setPageNumber(page);
    };
    const handlePageSizeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (!event || !event.target) {
            return;
        }
        const { value } = event.target;
        if (!value) {
            return;
        }
        setPageSize(Number(value));
    };

    useEffect(() => {
        setPageSize(globalPageSize);
    }, [globalPageSize]);
    useEffect(() => {
        if (searchQuery) {
            setPageNumber(0);
        }
    }, [searchQuery]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {HEADERS.map((item) => (
                            <TableCell key={item.id} data-testid={`license-header-${item.label}`}>
                                {item.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && !licensesOnPage.length ? (
                        <TableRow>
                            <NoBorderTableCell align="center" colSpan={HEADERS.length}>
                                <div className={classes.loadingContainer}>
                                    <LoadingIcon size={IconSize.large} />
                                </div>
                            </NoBorderTableCell>
                        </TableRow>
                    ) : null}
                    {!loading && !licensesOnPage.length ? (
                        <TableRow>
                            <NoBorderTableCell align="center" colSpan={HEADERS.length}>
                                No devices in this group yet
                            </NoBorderTableCell>
                        </TableRow>
                    ) : null}
                    {licensesOnPage.length
                        ? licensesOnPage.map((item) => <License license={item} key={item.id} />)
                        : null}
                </TableBody>
            </Table>
            <Box className={classes.paginationBox}>
                <TablePagination
                    component="div"
                    count={props.licenses.length}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowsPerPage={pageSize}
                    page={pageNumber}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePageSizeChange}
                />
            </Box>
        </TableContainer>
    );
};

export default memo(Licenses);
