import * as yup from "yup";

const zipCodeRegex = /^[a-zA-Z0-9\s-]+$/;
const phoneRegex = /^\+?[0-9\s-]+$/;
const invalidCharsRegex = /^[^+@!]*$/;

export const profileValidationSchema = yup.object({
    name: yup
        .string()
        .required("Full name is required")
        .max(30, "Name must be 30 characters or less")
        .matches(invalidCharsRegex, "Name cannot contain special characters")
        .label("Full name"),
    password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .label("Password"),
    password_repeat: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match")
        .required("Please confirm your password")
        .label("Confirm password"),
    address: yup.string().max(30).label("Address"),
    zipCode: yup.string().max(30).matches(zipCodeRegex, "Invalid zip code format").label("Zip code"),
    city: yup.string().max(30).label("City"),
    companyVAT: yup.string().max(30).label("VAT number"),
    companyName: yup.string().max(30).label("Company name"),
    fieldOfApplication: yup.string().max(50).label("Industry"),
    phoneNumber: yup.string().matches(phoneRegex, "Enter a valid phone number").min(7).max(30).label("Phone number"),
    typeOfUsage: yup.string().required("Type of usage is required").label("Type of usage"),
    country: yup.string().required().label("Country"),
    privacyChecked: yup
        .boolean()
        .oneOf([true], "Acceptance of the privacy policy is required")
        .required()
        .label("Privacy policy"),
    eulaChecked: yup
        .boolean()
        .oneOf([true], "Acceptance of the Terms of Service and End User License Agreement is required")
        .required()
        .label("Terms of service"),
});
