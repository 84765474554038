import _ from "lodash";
import React from "react";
import { EmteriaRoles } from "src/data/roles";
import { fetchAllSubscriptions, fetchWorkspacesAll } from "src/ui/containers/admin/api";
import { withRequiredRole } from "src/ui/navigation/utils/withRequiredRole";
import { LoadingBox } from "src/ui/shared/Loading";

import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { ContentContainer, PageContainer } from "@dashboard/shared/styles";
import { TableBody, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { MinimalTable, MinimalTableHeader, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast } from "@shared/toasts/Toasts";

import TableItem from "./TableItem";

const expirationRowsPerPage = 50;

const findOrganizationName = (subscription: API.Subscription, workspaces: API.Workspace[]): string => {
    const matchingWorkspace = workspaces.find((workspace) => workspace.subscription.id === subscription.id);
    return matchingWorkspace?.organizationName || "-";
};

const expirationHeaders = [
    { id: "id", label: "Id" },
    { id: "organizationName", label: "Organization" },
    { id: "charge", label: "Charge" },
    { id: "limit", label: "Limit" },
    { id: "createdDate", label: "Created Date" },
    { id: "expirationDate", label: "Expiration Date" },
] as const;

type SubscriptionsState = {
    data: API.Subscription[];
    loading: boolean;
};
type WorkspaceState = {
    data: API.Workspace[];
    loading: boolean;
};
const DefaultState = {
    data: [],
    loading: false,
};

const AdminExpirationsPage = () => {
    const [subscriptions, setSubscriptions] = React.useState<SubscriptionsState>(DefaultState);
    const [workspaces, setWorkspaces] = React.useState<WorkspaceState>(DefaultState);
    const [pageIndex, setPageIndex] = React.useState(0);

    const filteredSubscriptions = subscriptions.data.filter((subscription) => {
        if (!subscription.expirationDate || subscription.cancellationDate) {
            return false;
        }

        const licenseLimit = subscription.limits.find((limit) => limit.name === "LicenseLimit");
        const limit = licenseLimit?.value ?? 0;
        if (limit > 0 && limit < 5) {
            return false;
        }

        return true;
    });

    const displayedSubscriptions = _.orderBy(filteredSubscriptions, "expirationDate").slice(
        pageIndex * expirationRowsPerPage,
        (pageIndex + 1) * expirationRowsPerPage,
    );

    React.useLayoutEffect(() => {
        setSubscriptions((prev) => ({ ...prev, loading: true }));
        void fetchAllSubscriptions()
            .then((data) => setSubscriptions({ data, loading: false }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setSubscriptions({ data: [], loading: false });
            });
    }, []);

    React.useLayoutEffect(() => {
        setWorkspaces((prev) => ({ ...prev, loading: true }));
        void fetchWorkspacesAll()
            .then((data) => setWorkspaces({ data, loading: false }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setWorkspaces({ data: [], loading: false });
            });
    }, []);

    if (subscriptions.loading || workspaces.loading) {
        return <LoadingBox />;
    }

    return (
        <PageContainer>
            <PageTitleWithIcon title="Subscription expiration overview" iconName="fa-regular fa-calendar-times" />
            <ContentContainer>
                <MinimalTable>
                    <MinimalTableHeader>
                        <TableRow>
                            {expirationHeaders.map((item) => (
                                <MinimalTableHeaderCell key={item.id}>{item.label}</MinimalTableHeaderCell>
                            ))}
                        </TableRow>
                    </MinimalTableHeader>
                    <TableBody>
                        {displayedSubscriptions.map((subscription) => (
                            <TableItem
                                key={subscription.id}
                                subscription={subscription}
                                organizationName={findOrganizationName(subscription, workspaces.data)}
                            />
                        ))}
                    </TableBody>
                </MinimalTable>
                <TablePagination
                    component="div"
                    count={filteredSubscriptions.length}
                    rowsPerPageOptions={[]}
                    rowsPerPage={expirationRowsPerPage}
                    page={pageIndex}
                    onPageChange={(_e, page) => setPageIndex(page)}
                />
            </ContentContainer>
        </PageContainer>
    );
};

export default withRequiredRole(AdminExpirationsPage, [
    EmteriaRoles.EmteriaSalesRole,
    EmteriaRoles.EmteriaAccountantRole,
]);
