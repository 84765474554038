import { CSSProperties } from "react";

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    childrenBox: {
        display: "inline-block",
    },
}));

export type TooltipProps = Omit<MuiTooltipProps, "arrow" | "title"> & {
    hide?: boolean;
    title?: MuiTooltipProps["title"];
    childrenBoxStyles?: CSSProperties;
};

export const Tooltip = ({ children, hide = false, title = "", childrenBoxStyles = {}, ...rest }: TooltipProps) => {
    const classes = useStyles();

    if (hide) {
        return children;
    }

    return (
        <MuiTooltip
            arrow
            placement="top"
            title={title}
            PopperProps={{
                disablePortal: true,
                popperOptions: {
                    strategy: "fixed",
                    modifiers: [
                        {
                            name: "preventOverflow",
                            options: {
                                enabled: true,
                                boundariesElement: "window",
                            },
                        },
                    ],
                },
            }}
            {...rest}
        >
            <div className={classes.childrenBox} style={childrenBoxStyles}>
                {children}
            </div>
        </MuiTooltip>
    );
};
