import { DateTime } from "luxon";
import { Device } from "../types";
import { msToDuration, formatDuration } from "@dashboard/devices/utils/dates";

export const getHumanReadableDeviceUptime = (uptimeMs: number | undefined, isOnline: boolean | undefined) => {
    const uptimeFormatted = formatDuration(msToDuration(uptimeMs));
    return isOnline ? uptimeFormatted : null;
};

export const getDeviceOnlineSince = (device: Device | null) => {
    if (!device) {
        return null;
    }

    if (!device.lastOnline) {
        return null;
    }

    const lastOnlineDateTime = DateTime.fromISO(device.lastOnline);
    const currentDateTime = DateTime.now();
    const onlineSinceMs = Math.abs(currentDateTime.diff(lastOnlineDateTime).milliseconds);

    return getHumanReadableDeviceUptime(onlineSinceMs, device.isOnline);
};
