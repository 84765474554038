import { createUseStyles } from "react-jss";

import { createVersionCodeTag } from "@dashboard/files/utils";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { deleteBorder } from "@dashboard/shared/styles";
import { createRolloutTag } from "@dashboard/shared/tags";
import { FileInfo } from "@files/types";
import { TableCell } from "@mui/material";

const useStyles = createUseStyles({
    tagsBox: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxWidth: 400,
        maxHeight: 150,
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
    },
});

type Props = {
    index: number;
    collapseOpen: number;
    file: FileInfo;
};
export const TagsBoxCell = ({ index, collapseOpen, file }: Props) => {
    const classes = useStyles();

    const versionCodeTag = createVersionCodeTag(file);
    const rolloutTag = createRolloutTag(file.rolloutPercentage);
    const removableTags = file.metadata?.user?.tags;

    return (
        <TableCell style={deleteBorder(index, collapseOpen)}>
            <div className={classes.tagsBox}>
                {removableTags?.length ? removableTags.map((item) => <Tag key={item.key} item={item} />) : null}
                {versionCodeTag ? <Tag key={versionCodeTag.key} item={versionCodeTag} theme="light" /> : null}
                {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="extra" /> : null}
            </div>
        </TableCell>
    );
};
