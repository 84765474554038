import { DeviceState } from "@devices/types";
import { FileInfo } from "@files/types";
import { hasApkMetadata, hasUnsuccessfulAnalyzer, isApk } from "@files/utils";

type ApkFileStatus = "upToDate" | "toInstall" | "toUpdate" | "notApk" | "installed" | "invalidApk" | "processing";

export const getPrimaryButtonProps = (
    status: ApkFileStatus,
    unsuccesfulAnalyzerExist: boolean,
    mayInstallApp: boolean,
) => {
    switch (status) {
        case "processing":
            return { tooltip: "Processing...", iconClass: "fas fa-spinner fa-spin", disabled: true };
        case "toUpdate":
            return {
                tooltip: mayInstallApp ? "Update application" : "Your role does not allow you to update packages",
                disabled: unsuccesfulAnalyzerExist || !mayInstallApp,
                iconClass: "fa-solid fa-angles-up",
            };
        case "toInstall":
            return {
                tooltip: mayInstallApp ? "Install application" : "Your role does not allow you to install packages",
                disabled: unsuccesfulAnalyzerExist || !mayInstallApp,
                iconClass: "fa-solid fa-arrow-right-to-bracket",
            };
        case "upToDate":
            return { tooltip: "App is up to date", disabled: true, iconClass: "fa-solid fa-arrow-right-to-bracket" };
        case "invalidApk":
            return { tooltip: "Invalid application", disabled: true, iconClass: "fa-solid fa-arrow-right-to-bracket" };
        default:
            return {
                tooltip: "Not applicable for this file type",
                disabled: true,
                iconClass: "fa-solid fa-arrow-right-to-bracket",
            };
    }
};

export const getApkFileStatus = (file: FileInfo, packages?: DeviceState["packages"] | null): ApkFileStatus => {
    const isApkFile = isApk(file.filename);
    const installedPackage = packages?.find(
        (item) => item.package.toLowerCase() === file.metadata?.file?.apk?.manifest?.package.toLowerCase(),
    );

    const currentAppVersion = Number(installedPackage?.versionCode);
    const appVersionToInstall = Number(file.metadata?.file?.apk?.manifest?.versioncode);
    const unsuccesfulAnalyzerExist = hasUnsuccessfulAnalyzer(file);
    const apkMetadataExist = hasApkMetadata(file);

    if (unsuccesfulAnalyzerExist) {
        return "processing";
    }
    if (!isApkFile) {
        return "notApk";
    }
    if (!apkMetadataExist) {
        return "invalidApk";
    }
    if (!installedPackage) {
        return "toInstall";
    }
    if (currentAppVersion < appVersionToInstall) {
        return "toUpdate";
    }
    return "upToDate";
};
