import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    startIcon: {
        minWidth: 18,
        display: "flex",
        justifyContent: "center",
        marginRight: 12,
    },
    endIcon: {
        minWidth: 18,
        display: "flex",
        justifyContent: "center",
    },
    text: {
        fontSize: 14,
        lineHeight: 1,
        color: theme.palette.shadow[350],
        flexGrow: 1,
    },
    listItemBox: {
        padding: "0px 12px 0px 12px",
        "&:not(:first-child)": {
            marginTop: 8,
        },
        "&.MuiTypography-body1": {
            lineHeight: 1,
        },
    },
    listItem: {
        marginTop: 8,
        borderRadius: 6,
        "&:hover": {
            backgroundColor: theme.palette.blue[50],
            "& p": {
                color: theme.palette.black[100],
            },
            "& i": {
                color: theme.palette.black[100],
            },
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.blue[300],
            "& p": {
                color: theme.palette.white[50],
            },
            "& i": {
                color: theme.palette.white[50],
            },
        },
        "&.Mui-selected:hover": {
            backgroundColor: theme.palette.blue[50],
            "& p": {
                color: theme.palette.black[100],
            },
            "& i": {
                color: theme.palette.black[100],
            },
        },
        padding: "12px 12px",
    },
}));
