import clsx from "clsx";
import React from "react";
import { useField } from "react-final-form";
import { useSelector } from "src/store";
import { selectWorkspace } from "src/ui/containers/dashboard/workspaces/store/selectors";
import { CategoriesSelectNoForm } from "src/ui/shared/form/SelectField";

import { useVisibilityOptions } from "@dashboard/shared/useVisibilityOptions";
import { Workspace } from "@dashboard/workspaces/types";
import { InputAdornment, Typography } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { TextField } from "@shared/form/TextField";

import { InputItemContainer, InputItemLabel, useStyles } from "../styles";

interface Props {
    workspace: Workspace;
    editing?: boolean;
    isEditorOpen?: boolean;
}

export const Details = (props: Props) => {
    const styles = useStyles();
    const codenameField = useField("codename");
    const groupIdField = useField("groupId");
    const options = useVisibilityOptions();
    const workspace = useSelector(selectWorkspace);

    const onVisibilityChange: SelectInputProps["onChange"] = (e) => {
        const value = e.target.value;

        if (value === props.workspace.id) {
            groupIdField.input.onChange(null);
        }
        if (value !== props.workspace.id) {
            groupIdField.input.onChange(value);
        }
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (codenameField.input.value === "") {
            codenameField.input.onChange(e.target.value.toLowerCase().replace(/ /g, "_"));
        }
    };

    return (
        <>
            <Typography className={styles.sectionHeader}>Product</Typography>
            <div className={styles.sectionContainer}>
                <InputItemContainer>
                    <InputItemLabel>Product name</InputItemLabel>
                    <TextField
                        name="customBuildConfig.productName"
                        placeholder="Android Box 4001"
                        onBlur={onBlur}
                        className={styles.input}
                        fullWidth
                        required
                    />
                </InputItemContainer>
                <InputItemContainer>
                    <InputItemLabel>Codename</InputItemLabel>
                    <TextField
                        name="codename"
                        placeholder="android_box_4001"
                        className={clsx(styles.adornment, styles.input)}
                        disabled={props.isEditorOpen}
                        fullWidth
                        required
                        InputProps={
                            !props.editing
                                ? {
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              <span>{workspace?.guid ? `${workspace.guid}_` : ""}</span>
                                          </InputAdornment>
                                      ),
                                  }
                                : {}
                        }
                    />
                </InputItemContainer>
                <div className={styles.hints}>
                    <Typography className={styles.hintText}>
                        All codenames will have your workspace GUID as prefix.
                    </Typography>
                    <Typography className={styles.hintText}>
                        Codenames must be alphanumerical, they may contain underscores.
                    </Typography>
                    <Typography className={styles.hintText}>
                        Codename is very important - they cannot be changed once a device is flashed.
                    </Typography>
                </div>
                <InputItemContainer>
                    <InputItemLabel>Visibility</InputItemLabel>
                    <CategoriesSelectNoForm
                        name="groupId"
                        className={styles.input}
                        options={options}
                        defaultValue={groupIdField.input.value || props.workspace.id}
                        onChange={onVisibilityChange}
                        disabled={props.isEditorOpen}
                        fullWidth
                        required
                    />
                </InputItemContainer>
                <div className={styles.hints}>
                    <Typography className={styles.hintText}>Products cannot be moved once they are created.</Typography>
                    <Typography className={styles.hintText}>
                        Only devices in the same group will see updates for this product.
                    </Typography>
                </div>
            </div>
        </>
    );
};
