import { Tooltip } from "src/ui/shared/Tooltip";

import { styled } from "@mui/material";

export const Container = styled("div")({
    fontSize: 16,
    lineHeight: "18.62px",
    flexShrink: 0,
    flex: 0.3,
    display: "flex",
    alignItems: "center",
    minWidth: 140,
});

export const BuildVariantLabel = () => {
    return (
        <Container>
            Build variant
            <Tooltip
                title={
                    <>
                        Type of build:
                        <ul style={{ paddingLeft: 20 }}>
                            <li style={{ marginTop: 4 }}>
                                <strong>User:</strong> Maximum security and performance for production builds.
                            </li>
                            <li style={{ marginTop: 3 }}>
                                <strong>Debug:</strong> In-development release with debugging options enabled.
                            </li>
                            <li style={{ marginTop: 3 }}>
                                <strong>Engineering:</strong> Test builds for day-to-day development tasks.
                            </li>
                        </ul>
                    </>
                }
            >
                <i style={{ marginLeft: "8px" }} className="fas fa-info-circle" />
            </Tooltip>
        </Container>
    );
};
