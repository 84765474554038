import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    productContainer: {
        marginTop: 24,
    },
    buttonsContainer: {
        marginTop: 12,
        display: "flex",
        justifyContent: "flex-end",
    },
    deleteButton: {
        marginRight: 6,
    },
    tableContainer: {
        padding: "32px 0px 32px 0px",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    },
    alertContainer: {
        marginTop: 24,
    },
    boldText: {
        fontWeight: "bold",
    },
}));
