import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "src/store";
import { emailRegex } from "src/ui/containers/account/LoginPage/LoginPage";

import { updateGroup } from "@dashboard/devices/store";
import { GroupRoles } from "@dashboard/devices/types";
import { updateWorkspaceMembers } from "@dashboard/workspaces/store";
import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const initialValues: FormValues = {
    email: "",
    role: "User",
};
const GroupRolesArray: GroupRoles[] = ["Owner", "Manager", "User"];
const ContentBox = styled(DialogContent)({ width: 400, display: "flex", flexDirection: "column", gap: 10 });

type FormValues = {
    email: string;
    role: GroupRoles;
};
interface Props {
    id: number;
    name: "group" | "workspace";
    ownRole: GroupRoles | null;
    open: boolean;
    onClose: VoidFunction;
}

export const AddMemberDialog = (props: Props) => {
    const dispatch = useDispatch();
    const isManager = props.ownRole === "Manager";

    const options = React.useMemo(() => {
        return GroupRolesArray.map((item) => ({
            value: item,
            label: item,
        })).filter((item) => !(isManager && item.value === "Owner"));
    }, [isManager]);

    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };
    const onClose = (_: Record<string, never>, reason?: string) => {
        if (reason !== "backdropClick") {
            props.onClose();
        }
    };
    const validate = (values: FormValues) => {
        if (!values.email) {
            return { email: "Email address can't be empty" };
        }
        if (!emailRegex.test(values.email)) {
            return { email: "Invalid email address" };
        }
    };
    const onSubmit = async (values: FormValues) => {
        if (props.name === "group") {
            await dispatch(updateGroup({ id: props.id, addedMembers: { [values.email]: values.role } }))
                .unwrap()
                .then(() => {
                    showSuccessToast("Successfully added group member");
                    props.onClose();
                })
                .catch(({ message }) => showErrorToast(message));
        } else {
            await dispatch(
                updateWorkspaceMembers({ workspaceId: props.id, addedMembers: { [values.email]: values.role } }),
            )
                .unwrap()
                .then(() => {
                    showSuccessToast("Successfully added workspace member");
                    props.onClose();
                })
                .catch(({ message }) => showErrorToast(message));
        }
    };

    return (
        <Form<FormValues>
            {...{ onSubmit, validate, initialValues }}
            render={({ handleSubmit, submitting, form }) => (
                <Dialog open={props.open} onClose={onClose} maxWidth="md" onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogTitle style={{ paddingBottom: 5 }}>Add a {props.name} member</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <ContentBox>
                            <SelectField name="role" label="Role" options={options} required />
                            <TextField name="email" label="Email" required />
                        </ContentBox>
                        <DialogActions>
                            <SecondaryButton onClick={props.onClose} disabled={submitting}>
                                Close
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={submitting} loading={submitting}>
                                Add
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};
