import { useForm, useFormState } from "react-final-form";
import { UncontrolledSelectField } from "src/ui/shared/form/SelectField";
import { SwitchField } from "src/ui/shared/form/SwitchField";

import { Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Box, SelectProps, SwitchProps, Typography } from "@mui/material";

import { Provisioning } from "../types";
import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const options = [
    { label: "Apply provisioning settings manually in the SetupWizard", value: 0 },
    { label: "Apply provisioning settings automatically in the SetupWizard", value: 1 },
];

export const SetupAutomation = () => {
    const { value, onChange } = useCollapsiblePersistence("collapsedProvisioningSections", "SetupAutomation");
    const sharedClasses = useStyles();

    const parentForm = useForm();
    const { values: parentFormValues } = useFormState<Provisioning>();
    const onSetupChange: SelectProps["onChange"] = (e) => {
        parentForm.change("settings.automaticSetup", Boolean(e.target.value));
    };

    const onEulaChange: SwitchProps["onChange"] = (_e) => {
        parentForm.change("settings.automaticSetup", false);
    };

    return (
        <CollapsibleProvider initialValue={value} onChange={onChange}>
            <CollapsibleSectionSummary>
                <Typography className={sharedClasses.categoryTitle}>Setup automation</Typography>
            </CollapsibleSectionSummary>
            <CollapsibleSectionDetails>
                <SectionDescription>
                    The OS can be configured to apply provisioning settings automatically during initial SetupWizard.
                    This way no user interaction will be required during the first OS boot. Please note that accepting
                    emteria&#39;s End-User and Licensing Agreement is required for setup automation.
                </SectionDescription>
                <Box style={{ paddingLeft: 4, marginTop: 2, marginBottom: 20 }}>
                    <SwitchField
                        name="settings.acceptedEula"
                        onChange={onEulaChange}
                        labelPlacement="end"
                        label={
                            <Typography>
                                I agree to{" "}
                                <a href="https://emteria.com/legal/terms-of-use" target="_blank" rel="noreferrer">
                                    the terms and conditions
                                </a>{" "}
                                for using emteria products.
                            </Typography>
                        }
                    />
                </Box>
                <Row>
                    <UncontrolledSelectField
                        label="Initial OS setup"
                        options={options}
                        onChange={onSetupChange}
                        value={parentFormValues.settings?.automaticSetup ? 1 : 0}
                        disabled={!parentFormValues.settings?.acceptedEula}
                        fullWidth
                    />
                </Row>
            </CollapsibleSectionDetails>
        </CollapsibleProvider>
    );
};
