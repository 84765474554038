import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    tableContainer: {
        marginTop: 24,
    },
    tableCell: {
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    sortingIconContainer: {
        fontWeight: 600,
        display: "flex",
    },
    sortIcon: {
        position: "relative",
        top: "2px",
        fontSize: "16px",
        opacity: 1,
    },
    tableCellTitle: {
        fontWeight: 600,
    },
    noResultsRow: {
        textAlign: "center",
        padding: "20px",
    },
    loadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    actionsHeader: {
        marginRight: 10,
    },
});
