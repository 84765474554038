import React from "react";
import { Route, useLocation, Navigate, Outlet } from "react-router-dom";
import DeleteAccountPage from "src/ui/containers/account/DeleteAccountPage";
import EditAccountPage from "src/ui/containers/account/EditAccountPage/EditAccountPage";
import EditBillingPage from "src/ui/containers/account/EditBillingPage/EditBillingPage";
import LoginPage from "src/ui/containers/account/LoginPage/LoginPage";
import LogoutPage from "src/ui/containers/account/LogoutPage";
import PasswordChangePage from "src/ui/containers/account/PasswordChangePage";
import PasswordRecoveryPage from "src/ui/containers/account/PasswordRecoveryPage/PasswordRecoveryPage";
import PasswordResetPage from "src/ui/containers/account/PasswordResetPage";
import ProfilePage from "src/ui/containers/account/ProfilePage/ProfilePage";
import RegisterPage from "src/ui/containers/account/RegisterPage/RegisterPage";
import TwoFactorDisablePage from "src/ui/containers/account/TwoFactorPage/TwoFactorDisablePage";
import TwoFactorEnablePage from "src/ui/containers/account/TwoFactorPage/TwoFactorEnablePage";
import AdminProductsPage from "src/ui/containers/admin/components/AdminProductsPage/AdminProductsPage";
import AdminMembersPage from "src/ui/containers/admin/components/AdminMembersPage/AdminMembersPage";
import AdminFilesPage from "src/ui/containers/admin/components/AdminFilesPage/AdminFilesPage";
import UploadPage from "@dashboard/files/UploadPage";
import ReleaseNotesDevicePage from "src/ui/containers/embedded/release-notes/ReleaseNotesDevicePage";
import ReleaseNotesPage from "src/ui/containers/embedded/release-notes/ReleaseNotesPage";
import SolutionBuilderPage from "@dashboard/solution-builder/SolutionBuilderPage";
import AdminExpirationsPage from "src/ui/containers/admin/components/AdminExpirationPage/AdminExpirationsPage";
import AdminBuildsPage from "src/ui/containers/admin/components/AdminBuildsPage/AdminBuildsPage";
import DownloadsPage from "@dashboard/downloads/DownloadsPage";
import OverviewPage from "@dashboard/overview/OverviewPage";
import ProductPage from "@dashboard/products/ProductPage";
import { RepositoriesPage } from "@dashboard/repositories/RepositoriesPage";
import { ProvisioningPage } from "@dashboard/provisioning/ProvisioningPage";
import { UniversalLicensesPage } from "src/ui/containers/dashboard/licenses/UniversalLicensesPage";
import WorkspacesPage from "@dashboard/workspaces/WorkspacesPage";
import { ApmRoutes } from "@elastic/apm-rum-react";
import { PageForbidden, PageNotFound, RedirectToDashboard } from "./components/NavigationPages";
import ConfirmRegistrationPage from "src/ui/containers/account/ConfirmRegistrationPage/ConfirmRegistrationPage";
import InvitationPage from "@dashboard/invitation/InvitationPage";
import GroupsPage from "../containers/dashboard/groups/GroupsPage";
import { useSelector } from "src/store";
import { selectIsUserCredentialValid } from "src/store/auth";
import { CircularProgressBoxWithLabel } from "src/ui/shared/CircularProgressWithLabel";
import { usePrefetch } from "src/ui/utils/usePrefetch";
import DevicesPageWrapper from "@dashboard/devices/DevicesPageWrapper";

const ProtectedRoute = () => {
    const userCredentialValid = useSelector(selectIsUserCredentialValid);
    if (!userCredentialValid) {
        return <Navigate to="/login" />;
    }

    const { progress } = usePrefetch();
    if (progress !== 100) {
        return <CircularProgressBoxWithLabel value={progress} size="large" />;
    }

    return <Outlet />;
};

export default function Router() {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <ApmRoutes>
            <Route path="/invitation" element={<InvitationPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/register" element={<RegisterPage />} />

            <Route path="account">
                <Route path="confirm" element={<ConfirmRegistrationPage />} />
                <Route path="recover" element={<PasswordRecoveryPage />} />
                <Route path="reset" element={<PasswordResetPage />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="account">
                    <Route path="billing/edit" element={<EditBillingPage />} />
                    <Route path="password/edit" element={<PasswordChangePage />} />
                    <Route path="delete" element={<DeleteAccountPage />} />
                    <Route path="edit" element={<EditAccountPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="workspaces" element={<WorkspacesPage />} />
                    <Route path="2fa/disable" element={<TwoFactorDisablePage />} />
                    <Route path="2fa/enable" element={<TwoFactorEnablePage />} />
                </Route>

                <Route path="admin">
                    <Route path="expirations" element={<AdminExpirationsPage />} />
                    <Route path="builds/queue" element={<AdminBuildsPage />} />
                    <Route path="files" element={<AdminFilesPage />} />
                    <Route path="members" element={<AdminMembersPage />} />
                    <Route path="products" element={<AdminProductsPage />} />
                </Route>

                <Route path="dashboard">
                    <Route index element={<Navigate to="overview" replace />} />
                    <Route path="downloads" element={<DownloadsPage />} />
                    <Route path="files" element={<UploadPage />} />
                    <Route path="devices" element={<DevicesPageWrapper />} />
                    <Route path="groups" element={<GroupsPage />} />
                    <Route path="licenses" element={<UniversalLicensesPage />} />
                    <Route path="overview" element={<OverviewPage />} />
                    <Route path="products" element={<ProductPage />} />
                    <Route path="products/:codename/:androidVersion" element={<ProductPage />} />
                    <Route path="provisioning" element={<ProvisioningPage />} />
                    <Route path="provisioning/:provisioningId" element={<ProvisioningPage />} />
                    <Route path="repositories" element={<RepositoriesPage />} />
                    <Route path="solution-builder" element={<SolutionBuilderPage />} />
                </Route>
            </Route>

            <Route path="embedded">
                <Route path="release-notes" element={<ReleaseNotesPage />} />
                <Route path="release-notes/:device" element={<ReleaseNotesDevicePage />} />
            </Route>

            <Route path="/" exact element={<RedirectToDashboard />} />
            <Route path="/forbidden" element={<PageForbidden />} />
            <Route path="*" element={<PageNotFound />} />
        </ApmRoutes>
    );
}
