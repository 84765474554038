import { diff } from "deep-diff";
import exportFromJSON from "export-from-json";
import { FormApi } from "final-form";
import { useState } from "react";
import { Form } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { LocalStorage } from "src/services/localStorageService";
import { useDispatch, useSelector } from "src/store";
import * as Fragments from "src/ui/containers/dashboard/provisioning/components";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { ExpiredWrapper } from "src/ui/shared/ExpiredWrapper";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { LimitsAlert } from "src/ui/shared/LimitsAlert";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import { isValidZipUrl } from "src/ui/utils/validityChecker";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { useStyles } from "@dashboard/provisioning/ProvisioningPage.style";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning } from "@dashboard/provisioning/types";
import { PageContainer, SectionContainer } from "@dashboard/shared/styles";
import {
    selectIsWorkspaceProvisioningOverlimit,
    selectWorkspace,
    selectWorkspaceId,
} from "@dashboard/workspaces/store/selectors";
import { Box } from "@mui/material";

import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { CollapsibleGroupProvider } from "./components/CollapsibleSection";
import CreateProvisioningDialog from "./components/CreateProvisioningDialog";
import ExpandAllButton from "./components/ExpandAllButton";
import { deleteProvisioning, updateProvisioning } from "./store";
import { defaultProvisioning } from "./utils";
import { provisioningSettingsConverter } from "./utils/provisioningSettingsConverter";

const getAllSectionExpandedState = () => {
    const sectionsCount = 14;
    const collapsedSections = LocalStorage.getItem<Record<string, boolean>>("collapsedProvisioningSections");
    const collapsedSectionsValues = Object.values(collapsedSections || {});

    if (collapsedSectionsValues.length !== sectionsCount) {
        // some sections are expanded, some are collapsed
        return null;
    }
    if (collapsedSectionsValues.every((item) => item)) {
        // all section expanded
        return true;
    }
    if (collapsedSectionsValues.every((item) => !item)) {
        // all section collapsed
        return false;
    }
    return null;
};

type FormValues = Provisioning;

export const ProvisioningPage = () => {
    const { provisioningId: preselectedProvisioningId } = useParams<{ provisioningId: string }>();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);
    const workspaceId = useSelector(selectWorkspaceId);
    const isOverlimit = useSelector(selectIsWorkspaceProvisioningOverlimit);

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const selectedProvisioning =
        provisionings.find((p) => p.id.toString() === preselectedProvisioningId) ||
        provisionings[0] ||
        defaultProvisioning;

    const onValidate = (values: FormValues) => {
        if (values.settings?.bootAnimationUrl && !isValidZipUrl(values.settings.bootAnimationUrl)) {
            return { settings: { bootAnimationUrl: "Invalid ZIP URL" } };
        }
    };
    const onSubmit = async (values: FormValues) => {
        if (workspaceId) {
            await dispatch(updateProvisioning({ ...values, workspaceId }))
                .unwrap()
                .then(() => showSuccessToast("Provisioning settings saved"))
                .catch(({ message = "Update failed" }) => showErrorToast(message));
        }
    };
    const onDelete = async (
        provisioningId: number | null,
        form: FormApi<API.Provisioning, Partial<API.Provisioning>>,
    ) => {
        if (provisioningId) {
            await dispatch(deleteProvisioning(provisioningId))
                .unwrap()
                .then(() => {
                    showSuccessToast("Provisioning settings deleted");
                    const provisioning = provisionings.filter((item) => item.id !== provisioningId)[0];
                    form.reset(provisioning || defaultProvisioning);
                })
                .catch(({ message = "Deletion failed" }) => showErrorToast(message || "Deletion failed"))
                .finally(() => setDeleteDialogOpen(false));
        }
    };
    const exportProvisioningSettings = async (values: FormValues) => {
        if (values.settings) {
            const data = await provisioningSettingsConverter(values.settings);
            if (data) {
                exportFromJSON({
                    data,
                    fileName: `provisioning_${values.name}`,
                    exportType: exportFromJSON.types.json,
                });
            }
        }
    };

    return (
        <CollapsibleGroupProvider initialValue={getAllSectionExpandedState()}>
            <PageContainer>
                <PageTitleWithIcon title="Provisioning" iconName="fa-solid fa-sliders" />
                <Fragments.ProvisioningDescription />
                {workspace ? (
                    <Form<FormValues>
                        onSubmit={onSubmit}
                        validate={onValidate}
                        initialValues={selectedProvisioning}
                        render={({ handleSubmit, submitting, values, form }) => {
                            const formHasChanged = diff(form.getState().initialValues, values);
                            return (
                                <form onSubmit={handleSubmit}>
                                    {isOverlimit ? (
                                        <SectionContainer>
                                            <LimitsAlert action="createProvisioningSettings" />
                                        </SectionContainer>
                                    ) : null}
                                    <Fragments.SelectProvisionings
                                        setCreateDialogOpen={setCreateDialogOpen}
                                        setDeleteDialogOpen={setDeleteDialogOpen}
                                        submitting={submitting}
                                    />
                                    <ExpiredWrapper>
                                        <Fragments.SetupAutomation />
                                        <Fragments.DeviceRegistration />
                                        <Fragments.Screen />
                                        <Fragments.ProductBranding />
                                        <Fragments.ProductAdministration />
                                        <Fragments.DisabledComponents />
                                        <Fragments.HostedApks />
                                        <Fragments.RuntimePermissions />
                                        <Fragments.PrivateRepositories />
                                        <Fragments.ApplicationPreferences />
                                        <Fragments.SshKeys />
                                        <Fragments.Connectivity />
                                        <Fragments.SystemSettings />
                                        <Fragments.SystemProperties />
                                        <CreateProvisioningDialog
                                            open={createDialogOpen}
                                            onClose={() => setCreateDialogOpen(false)}
                                        />
                                        <Box className={classes.stickyFooter}>
                                            <ExpandAllButton />
                                            <SecondaryButton
                                                onClick={() => exportProvisioningSettings(values)}
                                                disabled={submitting || !workspaceId || !provisionings.length}
                                                startIcon={<i className="fas fa-solid fa-file-export" />}
                                            >
                                                Export
                                            </SecondaryButton>
                                            <ConfirmDialog
                                                title="Delete provisioning settings"
                                                content={
                                                    <p>
                                                        This will delete provisioning settings <b>{values.name}</b>. Are
                                                        you sure?
                                                    </p>
                                                }
                                                dangerButton
                                                primaryActionText="Delete"
                                                open={deleteDialogOpen}
                                                onConfirm={() => onDelete(values.id, form)}
                                                onClose={() => setDeleteDialogOpen(false)}
                                            />

                                            <Box className={classes.footerRightContainer}>
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={submitting || !provisionings.length || !formHasChanged}
                                                    loading={submitting}
                                                >
                                                    Save
                                                </PrimaryButton>
                                            </Box>
                                        </Box>
                                    </ExpiredWrapper>
                                </form>
                            );
                        }}
                    />
                ) : (
                    <FreshAccountMessage />
                )}
            </PageContainer>
        </CollapsibleGroupProvider>
    );
};
