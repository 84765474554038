import React from "react";
import { LocalStorage } from "src/services/localStorageService";
import { useSelector } from "src/store";

import { Product } from "@dashboard/products/types/index";
import { selectWorkspaceId } from "@dashboard/workspaces/store/selectors";

import { Workspace } from "../workspaces/types";

type ProductId = number | undefined;
type LocalStorageValue = Record<Workspace["id"], Product["id"]>;
type UseCurrentProduct = (products: Product[]) => [ProductId, (value: ProductId) => void];

export const useCurrentProduct: UseCurrentProduct = (products) => {
    const localStorageValue = LocalStorage.getItem<LocalStorageValue>("lastSelectedProduct");
    const workspaceId = useSelector(selectWorkspaceId);
    const localStorageId = workspaceId && localStorageValue ? localStorageValue[workspaceId] : undefined;
    const [id, setId] = React.useState<ProductId>(localStorageId || products[0]?.id);

    const setCurrentProductId = (value: number | undefined) => {
        if (workspaceId) {
            setId(value);
            LocalStorage.setItem("lastSelectedProduct", { ...localStorageValue, [workspaceId]: value });
        }
    };

    if (!localStorageId && id && workspaceId) {
        LocalStorage.setItem("lastSelectedProduct", { ...localStorageValue, [workspaceId]: id });
    }

    return [id, setCurrentProductId];
};
