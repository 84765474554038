import { useField, UseFieldConfig } from "react-final-form";

import {
    FormControl,
    FormControlLabel,
    FormControlProps,
    FormHelperText,
    styled,
    TextareaAutosize,
    TextareaAutosizeProps,
    Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    textArea: {
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: 4,
        padding: 4,
        color: theme.palette.text.primary,
        transition: "border-color 0.3s",
        "&::placeholder": {
            color: theme.palette.text.disabled,
            opacity: 1,
        },
    },
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    margin: "0 14px 0 14px",
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    marginBottom: 0,
    marginLeft: 0,
});

type Props = TextareaAutosizeProps & {
    name: string;
    config?: UseFieldConfig<string, string>;
    label?: string;
    helperText?: string;
    formControlProps?: FormControlProps;
};

export const TextareaField = ({ name, config, label, helperText, formControlProps, ...rest }: Props) => {
    const { input, meta } = useField(name, config);
    const error = meta.touched && meta.error;

    const classes = useStyles();

    return (
        <FormControl error={error} fullWidth {...formControlProps}>
            <StyledFormControlLabel
                {...{ label }}
                style={{ marginRight: 0 }}
                control={
                    <TextareaAutosize
                        {...input}
                        {...rest}
                        className={classes.textArea}
                        style={{
                            width: "100%",
                            ...rest.style,
                        }}
                    />
                }
            />
            <StyledFormHelperText>{error || helperText}</StyledFormHelperText>
        </FormControl>
    );
};
