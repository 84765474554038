import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "src/store";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { selectPaneEditEnabled, setPaneEditEnabled, updateLicense } from "@devices/store";
import { License } from "@devices/types";
import { getLicenseActivationStatus, getLicenseStatusTooltip } from "@devices/utils/license";
import { Box } from "@mui/material";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";
import { EditPrimaryButtons } from "@sidePanel/shared/EditActionButtons";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";

type FormValues = {
    comment: string | null;
};

const useStyles = createUseStyles({
    editBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10,
    },
});

const LicenseSummaryTab = (props: License) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editEnabled = useSelector(selectPaneEditEnabled);

    const onSubmit = async (payload: FormValues) => {
        try {
            await dispatch(updateLicense({ id: props.id, ...payload }));
            showSuccessToast("Successfully updated the license");
        } catch (error: unknown) {
            if (error instanceof Error) {
                showErrorToast(error.message);
            }
        }
        dispatch(setPaneEditEnabled(false));
    };

    return (
        <PaneElement.Container>
            <PaneElement.Header>License</PaneElement.Header>
            <PropertyRow>
                <PropertyKey>Id</PropertyKey>
                <PropertyValue>{props.id ?? "-"}</PropertyValue>
            </PropertyRow>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={{ comment: props.comment }}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        {editEnabled ? (
                            <Box className={classes.editBox}>
                                <PropertyRow>
                                    <PropertyKey>Comment</PropertyKey>
                                    <PropertyValue>
                                        <TextField name="comment" disabled={submitting} />
                                    </PropertyValue>
                                </PropertyRow>
                            </Box>
                        ) : (
                            <Box>
                                <PropertyRow>
                                    <PropertyKey>Comment</PropertyKey>
                                    <PropertyValue>
                                        <Box component="span">{props.comment ?? "-"}</Box>
                                    </PropertyValue>
                                </PropertyRow>
                            </Box>
                        )}
                        <PropertyRow>
                            <PropertyKey>Owner</PropertyKey>
                            <PropertyValue>{props.ownerEmail || "-"}</PropertyValue>
                        </PropertyRow>
                        <PropertyRow>
                            <PropertyKey>Status</PropertyKey>
                            <Tooltip placement="right" title={getLicenseStatusTooltip(props)}>
                                <span>{getLicenseActivationStatus(props)}</span>
                            </Tooltip>
                        </PropertyRow>
                        <EditPrimaryButtons loading={submitting} />
                    </form>
                )}
            />
        </PaneElement.Container>
    );
};
export default LicenseSummaryTab;
