import _ from "lodash";
import { RootState } from "src/store";

import { FileInfo } from "@dashboard/files/types";
import { isApk, isZip } from "@dashboard/files/utils";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { selectWorkspaceId } from "../../workspaces/store/selectors";

export const filesAdapter = createEntityAdapter<FileInfo>({});

const defaultSelectors = filesAdapter.getSelectors<RootState>((state) => state.files.items);

const selectFileById = defaultSelectors.selectById;
const selectFilesListStatus = (state: RootState) => state.files.status;
const selectFiles = createSelector(
    [defaultSelectors.selectAll, (state: RootState) => state.workspaces.currentWorkspaceId],
    (files, currentWorkspaceId) => {
        return files.filter((item) => item.workspaceId === currentWorkspaceId);
    },
);
const selectFilesShared = createSelector([(state: RootState) => state, selectFiles], (state, files) => {
    const workspaceId = selectWorkspaceId(state);
    return files.filter((item) => item.workspaceId === workspaceId && item.groupId === null);
});
const selectFilesByGroupId = createSelector(
    [selectFiles, (_state: RootState, groupId) => groupId],
    (files, groupId) => {
        return files.filter((item) => item.groupId === groupId);
    },
);
const selectFilesByGroupIdWithShared = createSelector(
    [selectFilesShared, selectFilesByGroupId, (_state: RootState, groupId: number) => groupId],
    (sharedFiles, groupFiles) => {
        return _.uniq([...sharedFiles, ...groupFiles]);
    },
);
const selectFilesWithApkFilter = createSelector(
    (state: RootState) => state,
    (state) => {
        const allFiles = selectFiles(state);
        return allFiles.filter((file) => isApk(file.filename));
    },
);
const selectFilesWithZipFilter = createSelector(
    (state: RootState) => state,
    (state) => {
        const workspaceFiles = selectFiles(state);
        return workspaceFiles.filter((file) => isZip(file.filename));
    },
);
const selectFilesByGroupIdsWithApkFilter = createSelector(
    [selectFilesShared, selectFilesWithApkFilter, (_state: RootState, groupIds: number[]) => groupIds],
    (workspacesApks, groupsApks, groupIds) => {
        return _.uniq([...groupsApks, ...workspacesApks.filter((file) => groupIds.includes(file.groupId || 0))]);
    },
);

export {
    selectFileById,
    selectFiles,
    selectFilesByGroupId,
    selectFilesByGroupIdWithShared,
    selectFilesByGroupIdsWithApkFilter,
    selectFilesListStatus,
    selectFilesShared,
    selectFilesWithApkFilter,
    selectFilesWithZipFilter,
};
