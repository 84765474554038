import { Workspace } from "../types";

type WorkspacesNormalized = Record<number, Workspace>;
export const normalizeWorkspaces = (workspaces: API.Workspace[]): WorkspacesNormalized => {
    const normalizedWorkspace: WorkspacesNormalized = {};

    workspaces.forEach((workspace) => {
        normalizedWorkspace[workspace.id] = { ...workspace, subscription: workspace.subscription.id };
    });

    return normalizedWorkspace;
};
