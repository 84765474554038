import { AxiosResponse } from "axios";
import { DeviceId } from "src/ui/containers/dashboard/devices/types";

import httpService from "./httpService";
import RouteService from "./routeService";

export interface VncSessionResponse {
    message: string;
    details: {
        message: string;
        type: string;
    }[];
    model: {
        type: string;
        host: string;
        token: string;
    };
}

export const createNvcSession = async (deviceId: DeviceId): Promise<AxiosResponse<VncSessionResponse>> => {
    const endpoint = await RouteService.getDevicesRoute();
    return httpService.post(endpoint + `${deviceId}/sessions/vnc`);
};
