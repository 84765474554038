import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import { LocalStorage } from "src/services/localStorageService";
import RouteService from "src/services/routeService";
import { ListResponse } from "src/types";

export interface FetchReleasesPayload {
    workspaceId: number;
    pageIndex?: number;
    pageSize?: number;
}

export const fetchReleases = async (payload: FetchReleasesPayload) => {
    const endpoint = await RouteService.getProductRoute();
    const fetchPublicProducts = LocalStorage.getItem<boolean>("fetchPublicProducts");
    const response: AxiosResponse<ListResponse<API.ReleaseFile>> = await httpService.getWithCache(
        endpoint + "downloads",
        {
            params: {
                WorkspaceId: payload?.workspaceId,
                PageIndex: payload?.pageIndex,
                PageSize: payload?.pageSize,
                includePublicProducts: fetchPublicProducts === null || fetchPublicProducts,
            },
            cache: {
                interpretHeader: false,
                ttl: 1000 * 60 * 60 * 4,
            },
        },
    );

    return response.data;
};
