import { styled } from "@mui/material";
import Badge from "@mui/material/Badge";

export const CustomBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        height: "16px",
        minWidth: "16px",
        padding: "0px 5px",
        fontSize: "11px",
        color: theme.palette.white[50],
        backgroundColor: theme.palette.green[50],
    },
}));
