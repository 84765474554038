import { useSelector } from "src/store";

import { selectBuilds } from "@dashboard/products/store/selectors/builds";
import { Product } from "@dashboard/products/types/index";
import { Paper, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { MinimalTable, MinimalTableHeader, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";

import BuildTableItem from "./BuildTableItem";

export const HEADERS = [
    { id: "status", label: "Status" },
    { id: "version", label: "Version" },
    { id: "created", label: "Created" },
    { id: "built", label: "Built" },
    { id: "compatibility", label: "Compatibility" },
    { id: "artifacts", label: "Artifacts" },
    { id: "tags", label: "Tags" },
    { id: "channel", label: "Channel" },
    { id: "actions", label: "Actions" },
] as const;

interface ProductProps {
    product: Product;
}

const BuildsTable = (props: ProductProps) => {
    const builds = useSelector(selectBuilds)?.filter((build) => build.productId === props.product.id);

    return (
        <TableContainer component={Paper}>
            <MinimalTable>
                <MinimalTableHeader>
                    <TableRow>
                        {HEADERS.map((item) => (
                            <MinimalTableHeaderCell align={item.label === "Actions" ? "right" : "left"} key={item.id}>
                                {item.label}
                            </MinimalTableHeaderCell>
                        ))}
                    </TableRow>
                </MinimalTableHeader>
                <TableBody>
                    {builds.length ? (
                        builds.map((build, index) => (
                            <BuildTableItem product={props.product} index={index} build={build} key={index} />
                        ))
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={HEADERS.length}>
                                There are no builds
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </MinimalTable>
        </TableContainer>
    );
};

export default BuildsTable;
