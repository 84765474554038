import exportFromJSON from "export-from-json";
import { useEffect, useReducer, useState } from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch, useSelector } from "src/store";

import { selectGroupById } from "@dashboard/groups/store/selectors";
import { fetchProductChangelog } from "@dashboard/products/store/index";
import { selectBuilds } from "@dashboard/products/store/selectors/builds";
import { selectProductChangelogsById } from "@dashboard/products/store/selectors/products";
import { Product } from "@dashboard/products/types/index";
import { ChangesDialog } from "@dashboard/shared/components/ChangesDialog";
import { selectWorkspace, selectWorkspaceById } from "@dashboard/workspaces/store/selectors";
import { Box, Typography } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { showErrorToast } from "@shared/toasts/Toasts";

import { EditDialog } from "../ProductDetails";
import { buildVariantOptions } from "../ProductDetails/fragments/Recipes";
import { ConfigurationModal } from "./Builds/ConfigurationDialog";
import { useStyles } from "./Product.style";

interface Props {
    product: Product;
}

const _Product = ({ product }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [configurationModal, setConfigurationModal] = useState(false);
    const [isEditorOpen, toggleEditor] = useReducer((state) => !state, false);
    const [isChangesOpen, setIsChangesOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const currentWorkspace = useSelector(selectWorkspace);
    const builds = useSelector(selectBuilds)?.filter((build) => build.productId === product.id);
    const isBuilding = builds.some((item) => item.buildStatus === "pending" || item.buildStatus === "running");
    const { productAbility } = permissionService();
    const cannotManageProducts = productAbility(currentWorkspace).cannot("manage", "Product");
    const productChanges = useSelector((state: RootState) => selectProductChangelogsById(state, product.id));

    const productVisibility = useSelector((state: RootState) => {
        if (product.groupId) {
            return selectGroupById(state, product.groupId)?.name;
        } else if (product.workspaceId) {
            return selectWorkspaceById(state, product.workspaceId)?.name;
        }
    });

    const handleExportConfig = () => {
        exportFromJSON({
            data: product,
            fileName: `config_${product.codename}`,
            exportType: exportFromJSON.types.json,
        });
    };

    const handleBuild = () => {
        setConfigurationModal(true);
    };

    const getBuildButtonTooltip = () => {
        if (cannotManageProducts) {
            return "Your role does not allow you to start builds";
        } else if (isBuilding) {
            return "Another build is active";
        } else {
            return "";
        }
    };

    const getBuildVariantLabel = (value: string): string => {
        const variant = buildVariantOptions.find((option) => option.value === value);
        return variant ? variant.label : "-";
    };

    useEffect(() => {
        const fetchProductChanges = async () => {
            setIsLoading(true);
            try {
                await dispatch(fetchProductChangelog({ id: product.id })).unwrap();
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message || "Something went wrong while fetching files");
            } finally {
                setIsLoading(false);
            }
        };

        void fetchProductChanges();
    }, [dispatch, product.id]);

    return (
        <>
            <Box className={classes.productContainer}>
                <Box className={classes.productDetails}>
                    <Box className={classes.productInfo}>
                        <Box display="flex">
                            <span className={classes.label}>Codename:</span>
                            <Typography>{product.codename}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Hardware:</span>
                            <Typography>{product.customBuildConfig?.rootDevice}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Android version:</span>
                            <Typography>{product.androidVersion}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Update compatibility:</span>
                            <Typography>{product.customBuildConfig?.compatibility}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Nightly period:</span>
                            <Typography>{product.nightlyPeriod || "-"}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Visibility:</span>
                            <Typography>{productVisibility}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Public access:</span>
                            <Typography>{product.isPublic ? "Yes" : "No"}</Typography>
                        </Box>
                        <Box display="flex">
                            <span className={classes.label}>Build variant:</span>
                            <Typography>
                                {getBuildVariantLabel(product.customBuildConfig?.buildVariant || "")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.buttonsContainer}>
                    <Box className={classes.leftButtons}>
                        <SecondaryButton
                            onClick={toggleEditor}
                            tooltipProps={{
                                placement: "right",
                                title: cannotManageProducts
                                    ? "Your role does not allow you to edit builds"
                                    : "Edit product configuration",
                            }}
                            disabled={cannotManageProducts}
                            size="large"
                        >
                            <i className="fa-solid fa-pen-to-square" />
                        </SecondaryButton>
                        <SecondaryButton
                            tooltipProps={{
                                title: "Show product changelog",
                            }}
                            loading={isLoading}
                            onClick={() => setIsChangesOpen(true)}
                            size="large"
                        >
                            <i className="fas fa-list" />
                        </SecondaryButton>
                        <SecondaryButton
                            onClick={handleExportConfig}
                            tooltipProps={{ title: "Export configuration" }}
                            size="large"
                        >
                            <i className="fas fa-file-export" />
                        </SecondaryButton>
                    </Box>
                    <PrimaryButton
                        className={classes.rightButton}
                        tooltipProps={{ title: getBuildButtonTooltip() }}
                        onClick={handleBuild}
                        disabled={isBuilding || cannotManageProducts}
                    >
                        Build
                    </PrimaryButton>
                </Box>
            </Box>
            {isEditorOpen && <EditDialog isEditorOpen={isEditorOpen} toggleDialog={toggleEditor} product={product} />}
            <ConfigurationModal
                product={product}
                open={configurationModal}
                onClose={() => setConfigurationModal(false)}
            />
            <ChangesDialog
                title="Product releases"
                onClose={() => setIsChangesOpen(false)}
                changes={productChanges}
                productId={product.id}
                open={isChangesOpen}
            />
        </>
    );
};

export default _Product;
