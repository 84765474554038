import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    label: {
        marginBottom: 5,
    },
    clickableLabel: {
        cursor: "pointer",
        fontSize: 16,
        marginBottom: 5,
        color: theme.palette.blue[150],
        "&:hover": {
            color: theme.palette.yellow[100],
        },
    },
    loadingBox: {
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
    },
}));

export const calculateBarValue = (count: number, total: number) => {
    const share = total !== 0 ? (count / total) * 100 : 0;
    return share === 0 ? 100 : share;
};
