import { Profile } from "@dashboard/devices/types";

export const validateProfile = (values: Profile) => {
    const errors: Partial<Record<keyof Profile, string>> = {};
    const invalidCharsRegex = /[+@!]/;

    if (!values.name) {
        errors.name = "Name is required";
    } else if (values.name.length > 30) {
        errors.name = "Name must be 30 characters or less";
    } else if (invalidCharsRegex.test(values.name)) {
        errors.name = "Name cannot contain special characters";
    }

    if (values.address) {
        if (values.address.length > 30) {
            errors.address = "Address must be 30 characters or less";
        } else if (invalidCharsRegex.test(values.address)) {
            errors.address = "Address cannot contain special characters";
        }
    }

    if (values.zipCode && !/^[a-zA-Z0-9\s-]+$/.test(values.zipCode)) {
        errors.zipCode = "Invalid zip code format";
    }

    if (values.companyVAT) {
        if (values.companyVAT.length > 30) {
            errors.companyVAT = "VAT ID must be 30 characters or less";
        } else if (invalidCharsRegex.test(values.companyVAT)) {
            errors.companyVAT = "VAT ID cannot contain special characters";
        }
    }

    if (values.phoneNumber) {
        if (!/^\+?[0-9\s-]+$/.test(values.phoneNumber)) {
            errors.phoneNumber = "Enter a valid phone number";
        } else if (values.phoneNumber.length < 7 || values.phoneNumber.length > 30) {
            errors.phoneNumber = "Phone number must be between 7 and 30 characters";
        }
    }

    if (values.fieldOfApplication) {
        if (values.fieldOfApplication.length > 30) {
            errors.fieldOfApplication = "Industry must be 30 characters or less";
        } else if (invalidCharsRegex.test(values.fieldOfApplication)) {
            errors.fieldOfApplication = "Industry cannot contain special characters";
        }
    }

    if (values.companyName) {
        if (values.companyName.length > 30) {
            errors.companyName = "Company name must be 30 characters or less";
        } else if (invalidCharsRegex.test(values.companyName)) {
            errors.companyName = "Company name cannot contain special characters";
        }
    }

    if (values.city) {
        if (values.city.length > 30) {
            errors.city = "City must be 30 characters or less";
        } else if (invalidCharsRegex.test(values.city)) {
            errors.city = "City cannot contain special characters";
        }
    }

    if (!values.country) {
        errors.country = "Country is required";
    }

    if (!values.typeOfUsage) {
        errors.typeOfUsage = "Type of usage is required";
    }

    return errors;
};
