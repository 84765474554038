import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 5,
    },
    tagsBox: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxWidth: 400,
        maxHeight: 150,
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
    },
    link: {
        textDecoration: "underline",
    },
    isUploading: {
        backgroundColor: theme.palette.shadow[200],
    },
}));
