import React from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";

import { selectFilesWithApkFilter } from "@dashboard/files/store/selectors";
import { FileInfo } from "@dashboard/files/types";
import { ProductApplication } from "@dashboard/products/types";
import { Checkbox, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { Tooltip } from "@shared/Tooltip";

const useStyles = makeStyles({
    text: { width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
});

const getApplicationName = (fileId: string, files: FileInfo[]) => {
    const app = files.find((item) => item.id === fileId);
    return app?.filename || "";
};
interface ApplicationTableItemProps {
    application: ProductApplication;
    onCheckboxChange: (fileId: string, checked: boolean) => void;
    onRemove: (fileId: string) => void;
}

const ApplicationTableItem: React.FC<ApplicationTableItemProps> = ({ application, onCheckboxChange, onRemove }) => {
    const classes = useStyles();
    const applications = useSelector(selectFilesWithApkFilter, shallowEqual);

    return (
        <TableRow key={application.source.fileId}>
            <MinimalTableCell>
                <Tooltip title={getApplicationName(application.source.fileId, applications)}>
                    <Typography className={classes.text}>
                        {getApplicationName(application.source.fileId, applications)}
                    </Typography>
                </Tooltip>
            </MinimalTableCell>
            <MinimalTableCell align="center">
                <Checkbox
                    checked={application.privileged}
                    onChange={(e) => onCheckboxChange(application.source.fileId, e.target.checked)}
                />
            </MinimalTableCell>
            <MinimalTableCell align="right">
                <DangerOutlinedButton
                    onClick={() => onRemove(application.source.fileId)}
                    tooltipProps={{ title: "Delete" }}
                >
                    <i className="fas fa-trash-alt" />
                </DangerOutlinedButton>
            </MinimalTableCell>
        </TableRow>
    );
};

export default ApplicationTableItem;
