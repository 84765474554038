import clsx from "clsx";
import { FormApi } from "final-form";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { TextareaField } from "src/ui/shared/form/TextareaField";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";
import * as yup from "yup";

import { IconSize } from "@dashboard/devices/components/shared/index";
import { addProductVariable, deleteProductVariable, fetchProductVariables } from "@dashboard/products/api/index";
import { InputItemLabel, useStyles as useSharedStyles } from "@dashboard/products/components/ProductDetails/styles";
import { ProductVariable } from "@dashboard/products/types/index";
import { Box, TableBody, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { LoadingIcon } from "@shared/Loading";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles(() => ({
    form: { display: "flex", flexDirection: "column", gap: "10px" },
    container: { width: 700 },
    variableList: { marginTop: "20px" },
    addButtonBox: { display: "flex", justifyContent: "flex-end" },
    loadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    section: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 16,
        marginBottom: 12,
    },
    keyInput: {
        marginTop: 16,
    },
}));

const maxSize = 10240;
const validationSchema = yup.object({
    key: yup.string().required("Key is required"),
    value: yup.string().required("Value is required").max(maxSize, "These can be up to 10KiB in size"),
    description: yup.string().optional(),
});

type Props = {
    productId: number;
};

type Secret = Omit<ProductVariable, "id">;
type FormValues = Secret;

export const Variables = ({ productId }: Props) => {
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [deletingKey, setDeletingKey] = useState<string | null>(null);
    const [productVariables, setProductVariables] = useState<Secret[]>([]);
    const validate = useValidationSchema(validationSchema);

    const handleAddVariable = async (payload: Secret, form: FormApi<FormValues>) => {
        try {
            await addProductVariable({
                productId,
                variableData: payload,
            });
            setProductVariables([
                ...productVariables,
                { key: payload.key, value: payload.value, description: payload.description },
            ]);
            showSuccessToast("Successfully added product variable");
            form.restart();
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Failed to add variable. Please try again.");
        }
    };

    const handleDeleteVariable = async (keyToDelete: string) => {
        setDeletingKey(keyToDelete);
        try {
            await deleteProductVariable(productId, keyToDelete);
            setProductVariables(productVariables.filter((variable) => variable.key !== keyToDelete));
            showSuccessToast("Successfully deleted product variable");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Failed to delete variable. Please try again.");
        } finally {
            setDeletingKey(null);
        }
    };

    useEffect(() => {
        const fetchVariables = async () => {
            try {
                setIsLoading(true);
                const data = await fetchProductVariables(productId);
                setProductVariables(data);
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
                setProductVariables([]);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchVariables();
    }, [productId]);

    return (
        <Box className={clsx(sharedClasses.sectionContainer, classes.container)}>
            <Typography className={sharedClasses.sectionHeader}>Product variables</Typography>
            <Form<FormValues>
                validate={validate}
                onSubmit={handleAddVariable}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Box className={clsx(classes.section, classes.keyInput)}>
                            <InputItemLabel>Key</InputItemLabel>
                            <TextField name="key" variant="outlined" fullWidth style={{ flex: 0.9 }} />
                        </Box>
                        <Box className={classes.section}>
                            <InputItemLabel>Value</InputItemLabel>
                            <TextareaField
                                name="value"
                                id="value"
                                minRows={3}
                                maxRows={5}
                                formControlProps={{ style: { flex: 0.9 } }}
                            />
                        </Box>
                        <Box className={classes.section}>
                            <InputItemLabel>Description</InputItemLabel>
                            <TextField name="description" id="description" style={{ flex: 0.9 }} />
                        </Box>
                        <div className={classes.addButtonBox}>
                            <PrimaryOutlinedButton type="submit" disabled={submitting} loading={submitting}>
                                Add
                            </PrimaryOutlinedButton>
                        </div>
                    </form>
                )}
            />
            <Box className={classes.variableList}>
                <MinimalTable>
                    <MinimalTableHeader>
                        <MinimalTableHeaderCell>Key</MinimalTableHeaderCell>
                        <MinimalTableHeaderCell>Description</MinimalTableHeaderCell>
                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                    </MinimalTableHeader>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <MinimalTableCell colSpan={3}>
                                    <div className={classes.loadingBox}>
                                        <LoadingIcon size={IconSize.large} />
                                    </div>
                                </MinimalTableCell>
                            </TableRow>
                        ) : productVariables.length === 0 ? (
                            <TableRow>
                                <MinimalTableCell colSpan={3} align="center">
                                    No items available
                                </MinimalTableCell>
                            </TableRow>
                        ) : (
                            productVariables.map((variable) => (
                                <TableRow key={variable.key}>
                                    <MinimalTableCell>{variable.key}</MinimalTableCell>
                                    <MinimalTableCell>{variable.description || "-"}</MinimalTableCell>
                                    <MinimalTableCell align="right">
                                        <DangerOutlinedButton
                                            onClick={() => handleDeleteVariable(variable.key)}
                                            loading={deletingKey === variable.key}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </DangerOutlinedButton>
                                    </MinimalTableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </MinimalTable>
            </Box>
        </Box>
    );
};
