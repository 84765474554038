import { DateTime } from "luxon";
import { RootState, useSelector } from "src/store";

import { DeviceState } from "@devices/types";
import { convertToLocalDate, dateTimeFormat } from "@devices/utils/dates";
import { selectGroupById } from "@groups/store/selectors";
import { PaneElement } from "@sidePanel/shared/PaneElement";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";

enum PolicyComplianceStatus {
    NoGroupPolicy = "No group policy defined",
    NoDevicePolicy = "-",
    Compliant = "Compliant",
    NotCompliant = "Not compliant",
}

const displayPolicyCompliance = (devicePolicy: DeviceState["policy"], hasPolicy: boolean): PolicyComplianceStatus => {
    if (!devicePolicy) {
        return PolicyComplianceStatus.NoDevicePolicy;
    }
    if (!hasPolicy) {
        return PolicyComplianceStatus.NoGroupPolicy;
    }

    return devicePolicy.successful ? PolicyComplianceStatus.Compliant : PolicyComplianceStatus.NotCompliant;
};

interface Props {
    deviceState: DeviceState;
    groupId: number;
}

function SystemTab({ deviceState, groupId }: Props): JSX.Element {
    const { version, properties, policy, locale } = deviceState || {};

    const group = useSelector((state: RootState) => selectGroupById(state, groupId));

    const groupHasPolicy = !!group?.policy?.length;

    const timezone = properties?.["persist.sys.timezone"] || "";
    const zone = timezone !== "" ? timezone : "UTC";
    const formattedDateTime = deviceState?.datetime?.currentMs
        ? DateTime.fromMillis(deviceState.datetime.currentMs, { zone }).toFormat(dateTimeFormat)
        : "-";

    const policyReceivedDate = policy?.received ? convertToLocalDate(policy.received, "SECONDS", "dateTime") : "-";

    return (
        <PaneElement.Container>
            <PaneElement.Section>
                <PaneElement.Header>Version</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Emteria version</PropertyKey>
                    <PropertyValue>{version?.emteriaVersion ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Android version</PropertyKey>
                    <PropertyValue>{version?.androidVersion ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Android timestamp</PropertyKey>
                    <PropertyValue>{version?.androidTimestamp ?? "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>

            <PaneElement.Section>
                <PaneElement.Header>System time</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Date &amp; time</PropertyKey>
                    <PropertyValue>{formattedDateTime}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Timezone</PropertyKey>
                    <PropertyValue>{timezone}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>

            <PaneElement.Section>
                <PaneElement.Header>Policy state</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Last change</PropertyKey>
                    <PropertyValue>{policyReceivedDate}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Expected policy</PropertyKey>
                    <PropertyValue>{policy?.hash ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Policy compliance</PropertyKey>
                    <PropertyValue>{displayPolicyCompliance(policy, groupHasPolicy)}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>

            <PaneElement.Section withoutBorder>
                <PaneElement.Header>Locale</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Locale language</PropertyKey>
                    <PropertyValue>{locale?.localeLanguage ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Locale country</PropertyKey>
                    <PropertyValue>{locale?.localeCountry ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Locale variant</PropertyKey>
                    <PropertyValue>{locale?.localeVariant ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Display name</PropertyKey>
                    <PropertyValue>{locale?.displayName ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Display country</PropertyKey>
                    <PropertyValue>{locale?.displayCountry ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Display variant</PropertyKey>
                    <PropertyValue>{locale?.displayVariant ?? "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
        </PaneElement.Container>
    );
}
export default SystemTab;
