import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";

import { updateUniversalLicense } from "@dashboard/devices/store";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@dashboard/devices/types";
import { selectGroups } from "@dashboard/groups/store/selectors";
import { TableRow } from "@mui/material";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";

type FormValues = { groupId: number | null };

type Props = {
    license: License;
    isLoading: boolean;
    handleDelete: (license: License) => void;
};

export const LicenseGroup = (props: Props) => {
    const dispatch = useDispatch();
    const groups = useSelector(selectGroups);
    const isSubscriptionExpired = useSelector(selectSubscriptionExpired);

    const onSubmit = async (values: FormValues) => {
        await dispatch(updateUniversalLicense({ id: props.license.id, groupId: values.groupId }))
            .unwrap()
            .then(() => showSuccessToast("Target group changed successful"))
            .catch(({ message = "Target group change failed" }) => showErrorToast(message));
    };

    return (
        <TableRow>
            <MinimalTableCell align="left">{props.license.activationCode}</MinimalTableCell>
            <MinimalTableCell align="left">
                <Form<FormValues>
                    onSubmit={onSubmit}
                    initialValues={{ groupId: props.license.universalGroupId }}
                    render={({ handleSubmit, form, submitting }) => (
                        <form onSubmit={handleSubmit}>
                            <Tooltip title="Subscription expired" hide={!isSubscriptionExpired}>
                                <SelectField
                                    name="groupId"
                                    variant="standard"
                                    onChange={form.submit}
                                    style={{ width: 300 }}
                                    disabled={submitting || isSubscriptionExpired}
                                    options={groups.map((item) => ({
                                        label: item.name,
                                        value: item.id,
                                    }))}
                                />
                            </Tooltip>
                        </form>
                    )}
                />
            </MinimalTableCell>
            <MinimalTableCell align="right">
                <DangerOutlinedButton onClick={() => props.handleDelete(props.license)} loading={props.isLoading}>
                    <i className="fas fa-trash-alt" />
                </DangerOutlinedButton>
            </MinimalTableCell>
        </TableRow>
    );
};
