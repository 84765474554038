import { RootState } from "src/store";

import { ListStatus } from "@dashboard/devices/store";
import {
    createProvisioning as apiCreateProvisioning,
    deleteProvisioning as apiDeleteProvisioning,
    fetchProvisionings as apiFetchProvisioning,
    updateProvisioning as apiUpdateProvisioning,
} from "@dashboard/provisioning/api";
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";

import { normalizeProvisionings } from "../../devices/store/helpers";
import { Provisioning } from "../types";

export const provisioningAdapter = createEntityAdapter<Provisioning>({
    selectId: (provisioning) => provisioning.id,
});

export const fetchProvisionings = createAsyncThunk("provisionings/fetch", apiFetchProvisioning);
export const createProvisioning = createAsyncThunk("provisionings/createProvisioning", apiCreateProvisioning);
export const updateProvisioning = createAsyncThunk("provisionings/updateProvisioning", apiUpdateProvisioning);
export const deleteProvisioning = createAsyncThunk("provisionings/deleteProvisioning", apiDeleteProvisioning);

export type ProvisioningState = {
    list: {
        items: EntityState<Provisioning>;
        status: ListStatus | null;
    };
    error: null | unknown;
    useImport: boolean;
};

const initialState: ProvisioningState = {
    list: {
        items: provisioningAdapter.getInitialState(),
        status: null,
    },
    error: null,
    useImport: false,
};

const slice = createSlice({
    name: "provisioning",
    initialState,
    reducers: {
        setUseImport: (state, { payload = false }) => {
            state.useImport = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProvisionings.pending, (state) => {
                state.list.status = "pending";
            })
            .addCase(fetchProvisionings.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";
                const provisionings = normalizeProvisionings(payload);
                provisioningAdapter.setAll(state.list.items, provisionings);
            })
            .addCase(fetchProvisionings.rejected, (state) => {
                state.list.status = "rejected";
            })
            .addCase(createProvisioning.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";
                const provisionings = normalizeProvisionings([payload]);
                provisioningAdapter.addOne(state.list.items, provisionings[payload.id]);
            })
            .addCase(deleteProvisioning.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";
                provisioningAdapter.removeOne(state.list.items, payload);
            })
            .addCase(updateProvisioning.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";
                const provisionings = normalizeProvisionings([payload]);
                provisioningAdapter.updateOne(state.list.items, {
                    id: payload.id,
                    changes: provisionings[payload.id],
                });
            });
    },
});

export const localState = (state: RootState): ProvisioningState => state.provisionings;

export default slice.reducer;

export const { setUseImport } = slice.actions;
