import { Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import { recoverPassword } from "src/services/accountService";
import { getHomepageUrl } from "src/services/configService";
import ApmLink from "src/ui/shared/ApmLink";
import { CustomCard, CustomCardContent, CustomCardHeader, CustomCardText, CustomCardTitle } from "src/ui/shared/Card";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";

import { InputAdornment } from "@mui/material";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { emailRegex } from "../LoginPage/LoginPage";
import { useStyles } from "./PasswordRecoveryPageStyles";

interface FormValues {
    email: string;
}

const PasswordRecoveryPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.email) {
            errors.email = "Email is required";
        } else if (!emailRegex.test(values.email)) {
            errors.email = "Invalid email address";
        }

        return errors;
    };

    const onSubmit = async (values: FormValues) => {
        try {
            const { message } = await recoverPassword(values);
            showSuccessToast(message);
            navigate("/login");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        }
    };

    return (
        <div className={classes.container}>
            <ApmLink href={getHomepageUrl()}>
                <img src="./img/logos/emteria-colored.webp" alt="emteria logo" className={classes.logo} />
            </ApmLink>

            <CustomCard className={classes.card}>
                <CustomCardHeader title={<CustomCardTitle>Password Recovery</CustomCardTitle>} />
                <CustomCardContent>
                    <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <CustomCardText className={classes.description}>
                                    You will receive a link with recovery instructions to the provided email address.
                                </CustomCardText>

                                <TextField
                                    placeholder="Email address"
                                    name="email"
                                    type="text"
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-fw fa-envelope" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <PrimaryButton type="submit" disabled={submitting} loading={submitting} fullWidth>
                                    Recover password
                                </PrimaryButton>
                            </form>
                        )}
                    />

                    <ul className={classes.linksContainer}>
                        <li>
                            <Link to="/register">Create new account</Link>
                        </li>
                        <li>
                            <Link to="/login">Login with existing account</Link>
                        </li>
                    </ul>
                </CustomCardContent>
            </CustomCard>
        </div>
    );
};

export default PasswordRecoveryPage;
