import _ from "lodash";

import { Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    artifact: {
        display: "flex",
        alignItems: "center",
        marginRight: 6,
        color: theme.palette.blue[300],
    },
}));

const getArtifactIcon = (artifact: API.Artifact | string) => {
    switch (artifact) {
        case "OTA":
            return {
                icon: "fas fa-cloud-download-alt",
                tooltip: "OS update package",
            };
        case "OsFirmware":
            return {
                icon: "fas fa-microchip",
                tooltip: "Initial device firmware",
            };
        case "SignedFiles":
            return {
                icon: "fas fa-file-signature",
                tooltip: "Signed intermediate files",
            };
        case "Manifest":
            return {
                icon: "fas fa-file-alt",
                tooltip: "Build revision trace",
            };
        default:
            return {
                icon: "fas fa-certificate",
                tooltip: artifact,
            };
    }
};

const artifactsOrder = ["OsFirmware", "OTA", "SignedFiles", "Manifest"];

export const Artifacts = (props: { artifacts: API.Artifact[] }) => {
    const classes = useStyles();
    const orderedArtifacts = _.sortBy(props.artifacts, (artifact) => artifactsOrder.indexOf(artifact));
    return (
        <>
            {orderedArtifacts.map((artifact) => {
                const { icon, tooltip } = getArtifactIcon(artifact);
                return (
                    <Tooltip arrow key={artifact} placement="top" title={tooltip} className={classes.artifact}>
                        <i className={icon} />
                    </Tooltip>
                );
            })}
        </>
    );
};
