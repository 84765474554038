import { PrimaryButton } from "src/ui/shared/CustomButton";

import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 5,
        paddingTop: 10,
        paddingRight: 5,
    },
    content: {
        paddingTop: 20,
        paddingBottom: 30,
        width: 450,
    },
});
interface Props extends Omit<DialogProps, "onClose"> {
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmClaimDialog = (props: Props) => {
    const classes = useStyles();

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            props.onConfirm();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Dialog {...props} onKeyDown={onKeyDown}>
            <DialogTitle className={classes.title}>
                <Typography variant="h6">Free starter plan</Typography>
                <IconButton onClick={props.onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.content}>
                <span>
                    Start your journey with the free plan, allowing up to 3 devices. Enjoy Over-The-Air Android OS
                    updates and remotely manage your devices through our Mobile Device Management tools.
                </span>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={props.onConfirm}>Claim</PrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
