import { useState } from "react";
import { DangerButton, PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    dialogContent: {
        width: 400,
        fontFamily: "Segoe UI",
    },
});

interface Props {
    title?: string;
    content?: string | JSX.Element;
    open?: boolean;
    onConfirmDisabled?: boolean;
    dangerButton?: boolean;
    primaryActionText?: string;
    onConfirm: () => Promise<void> | void;
    onClose: () => void;
}

export function ConfirmDialog({
    title = "Confirmation",
    content = "Are you sure?",
    open = false,
    dangerButton = false,
    onConfirmDisabled,
    primaryActionText = "Confirm",
    onConfirm,
    onClose,
}: Props): JSX.Element {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            await onConfirm();
            onClose();
        } catch (error) {
            if (error instanceof Error) {
                showErrorToast(error.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !onConfirmDisabled && !loading) {
            await handleConfirm();
        }
        if (e.key === "Escape") {
            onClose();
        }
    };

    return (
        <Dialog open={open} maxWidth="sm" onKeyDown={onKeyDown}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>{content}</DialogContent>
            <DialogActions style={{ marginTop: 10 }}>
                <SecondaryButton onClick={onClose} disabled={loading}>
                    Abort
                </SecondaryButton>
                {dangerButton ? (
                    <DangerButton onClick={handleConfirm} disabled={onConfirmDisabled || loading} loading={loading}>
                        {primaryActionText}
                    </DangerButton>
                ) : (
                    <PrimaryButton onClick={handleConfirm} disabled={onConfirmDisabled || loading} loading={loading}>
                        {primaryActionText}
                    </PrimaryButton>
                )}
            </DialogActions>
        </Dialog>
    );
}
