import { compareVersions } from "compare-versions";
import _ from "lodash";
import { DateTime } from "luxon";

import { FileInfo, FileStatus, FileTableItem } from "@dashboard/files/types";

export const normalizeFiles = (files: FileInfo[]) => {
    const sortedByDate = _.orderBy(files, (file) => DateTime.fromISO(file.createdDate).toMillis(), ["desc"]);

    const uniqueFiles = sortedByDate.reduce((acc: Record<string, FileInfo>, currentFile) => {
        // If file has no metadata, it is not an APK file and we can't compare versions
        if (!currentFile.metadata?.file) {
            const uniqueKey = `${currentFile.filename}-${currentFile.createdDate}`;
            acc[uniqueKey] = currentFile;
            return acc;
        }

        const currentFilePackageName = currentFile.metadata?.file?.apk?.manifest?.package || "";

        const currentFileVersion = currentFile.metadata?.file?.apk?.manifest?.versioncode || "0";
        const accVersion = acc[currentFilePackageName]?.metadata?.file?.apk?.manifest?.versioncode || "0";

        // Update the accumulator if it doesn't have the file or if the current file's version is newer
        if (!acc[currentFilePackageName] || compareVersions(currentFileVersion, accVersion) > 0) {
            acc[currentFilePackageName] = currentFile;
        }
        return acc;
    }, {});

    return Object.values(uniqueFiles);
};

export const hasApkMetadata = (file: FileInfo) => {
    const packageExists = file.metadata?.file?.apk?.manifest?.package;
    const versionCodeExists = file.metadata?.file?.apk?.manifest?.versioncode;
    return Boolean(packageExists && versionCodeExists);
};

export const isApk = (filename: string) => filename.toLowerCase().endsWith(".apk");
export const isZip = (filename: string) => filename.toLowerCase().endsWith(".zip");

export const createVersionCodeTag = (file: FileTableItem | FileInfo | null) => {
    const { versioncode } = file?.metadata?.file?.apk?.manifest ?? {};
    if (versioncode) {
        return {
            type: "scoped",
            key: "version",
            value: versioncode,
        } as API.Tag;
    }

    return null;
};

export const hasUnsuccessfulAnalyzer = (data: FileInfo | FileInfo[]): boolean => {
    if (Array.isArray(data)) {
        return data.some((item) => hasUnsuccessfulAnalyzer(item));
    }
    if (data.status === FileStatus.Pending) {
        return true;
    }
    if (!data.analyzers) {
        return false;
    }
    return data.analyzers.some((analyzer) => analyzer.status !== "successful");
};
