import React, { useEffect } from "react";
import { CommandPayload } from "src/data/commands";

import { Device, Group } from "@dashboard/devices/types";
import { JSONEditor as JsonEditorType } from "@json-editor/json-editor/dist/jsoneditor.js";
import { showErrorToast } from "@shared/toasts/Toasts";

import { JsonEditorCommandType, updateEditor, validateEditor } from "../helpers/jsonEditor";
import { useCommands } from "../hooks";
import { InputItemContainer, useStyles } from "../styles";

let rawEditor: JsonEditorType | null = null;

type Props = {
    device?: Device;
    group?: Group;
    editorRef: React.RefObject<HTMLDivElement>;
    submitRef: React.RefObject<HTMLButtonElement>;
    isPolicy?: boolean;
    toggleDialog: () => void;
};

export const JsonEditor = ({ device, group, editorRef, submitRef, isPolicy, toggleDialog }: Props) => {
    const styles = useStyles();

    const policyCommands = group?.policy || [];

    const { createMdmCommand, updatePolicyCommands } = useCommands();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const errors = validateEditor(rawEditor);
        if (errors) {
            showErrorToast(`Error: validation failed: ${errors[0]?.message as string} `);
            return;
        }

        const payload = rawEditor && (rawEditor.getValue() as CommandPayload);
        if (!payload) {
            showErrorToast("Something went wrong. Please try again");
            return;
        }

        if (isPolicy && group) {
            const policyPayload = Array.isArray(policyCommands) && [...policyCommands, payload];
            await updatePolicyCommands({ groupId: group.id, policy: JSON.stringify(policyPayload) });
        } else {
            await createMdmCommand({ groupId: group?.id, deviceId: device?.id, json: payload });
        }
        toggleDialog();
    };

    useEffect(() => {
        rawEditor = updateEditor(rawEditor, editorRef, JsonEditorCommandType.Raw);

        // destroy the editor to prevent duplicates when component is unmounting
        return () => {
            if (rawEditor) {
                rawEditor.destroy();
            }
        };
    }, [editorRef]);

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className={styles.sectionContainer}>
                    <InputItemContainer>
                        {/* filled out by jsoneditor */}
                        <div id="json-editor" ref={editorRef}></div>
                        <button ref={submitRef} type="submit" hidden />
                    </InputItemContainer>
                </div>
            </form>
        </React.Fragment>
    );
};
