import { useDispatch, useSelector } from "src/store";

import { addBatchSelection, selectPaneEditEnabled, setBatchMode, setPaneEditEnabled, setPaneTab } from "@devices/store";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { License } from "@devices/types";
import { Tooltip } from "@shared/Tooltip";

import MenuContainer from "./MenuContainer";
import { EditItem } from "./MenuItems";

export const MenuLicense = (props: License) => {
    const dispatch = useDispatch();
    const editEnabled = useSelector(selectPaneEditEnabled);
    const isExpired = useSelector(selectSubscriptionExpired);

    const onMove = () => {
        dispatch(setBatchMode({ type: "move", entity: "license" }));
        dispatch(addBatchSelection(props.id));
    };
    const onEdit = () => {
        dispatch(setPaneEditEnabled(true));
        dispatch(setPaneTab("SUMMARY"));
    };

    return (
        <MenuContainer>
            <Tooltip style={{ width: "100%" }} title="Subscription expired" hide={!isExpired}>
                <EditItem label="Edit license" onClick={onEdit} disabled={editEnabled || isExpired} />
            </Tooltip>
            <EditItem label="Move license" onClick={onMove} disabled={editEnabled} iconName="fas fa-file" />
        </MenuContainer>
    );
};
