import { createUseStyles } from "react-jss";

import { styled, TableCell as MuiTableCell } from "@mui/material";

export const useStyles = createUseStyles({
    loadingContainer: {
        height: "50px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    paginationBox: { display: "flex", justifyContent: "center" },
});

export const TableCell = styled(MuiTableCell)({
    border: "none",
    fontWeight: "bold",
});
export const NoBorderTableCell = styled(MuiTableCell)({
    border: "none",
});
