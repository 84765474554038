import { theme } from "src/providers/ThemeProvider";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    fileUpload: {
        fontSize: "40px",
    },
});
interface Props {
    color?: string;
}
const ArrowFileUp = ({ color = theme.palette.blue[350] }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.fileUpload}>
                <i className="fa-solid fa-file-arrow-up" style={{ color }}></i>
            </div>
        </div>
    );
};

export default ArrowFileUp;
