import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    section: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 30px",
    },
    separator: { width: 1, backgroundColor: theme.palette.shadow[150], height: 613 },
    formBox: { maxWidth: 300, padding: "100px 0" },
    infoText: {
        color: theme.palette.blue[350],
        display: "flex",
        flexDirection: "column",
        fontSize: 16,
        fontWeight: "lighter",
        textAlign: "center",
        paddingTop: 30,
    },
    listBox: { display: "flex", flexDirection: "column", justifyContent: "flex-start" },
    listDescription: { fontSize: 22, color: theme.palette.blue[350], fontWeight: "lighter" },
    listRow: { display: "flex", alignItems: "center", marginBottom: 20 },
    listCircle: {
        border: "2px solid " + theme.palette.blue[150],
        borderRadius: "50%",
        height: 28,
        width: 28,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circleText: {
        color: theme.palette.blue[150],
        fontSize: 18,
        lineHeight: 28,
        textAlign: "center",
    },
    listDescriptionBox: { marginBottom: 20 },
    listText: { fontSize: 18, color: theme.palette.blue[350], fontWeight: "lighter", marginLeft: 5 },
    footerText: { fontSize: 19 },
    rightSideTitle: { marginBottom: 12, fontSize: 26 },
}));
