import { DeviceState } from "@devices/types";
import { Box } from "@mui/material";
import { PaneElement } from "@sidePanel/shared/PaneElement";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";

interface Props {
    deviceState: DeviceState;
}

function NetworkTab({ deviceState }: Props): JSX.Element {
    const { properties, networks = [], wifi, ethernet, telephony } = deviceState;

    const adbSetting = deviceState?.settings?.filter((setting) => setting.key === "adb_enabled")[0];
    const ntpSetting = deviceState?.settings?.filter((setting) => setting.key === "ntp_server")[0];

    return (
        <PaneElement.Container>
            <PaneElement.Section>
                <PaneElement.Header>General</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>ADB status</PropertyKey>
                    <PropertyValue>{adbSetting ? adbSetting?.value : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Hostname</PropertyKey>
                    <PropertyValue>{properties?.["net.hostname"] ? properties["net.hostname"] : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>NTP server</PropertyKey>
                    <PropertyValue>{ntpSetting ? ntpSetting?.value : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>DNS1</PropertyKey>
                    <PropertyValue>{properties?.["net.dns1"] ? properties["net.dns1"] : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>DNS2</PropertyKey>
                    <PropertyValue>{properties?.["net.dns2"] ? properties["net.dns2"] : "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Telephony </PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>IMEI</PropertyKey>
                    <PropertyValue>{telephony?.imei ? telephony.imei : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow style={{ alignItems: "flex-start" }}>
                    <PropertyKey>SIMs</PropertyKey>
                    <PropertyValue>
                        {telephony?.sims?.length
                            ? telephony.sims.map((sim) => (
                                  <Box key={sim.id} style={{ marginBottom: 8 }}>
                                      {Object.entries(sim).map(([key, value]) => (
                                          <div key={key}>
                                              <strong>{key.toUpperCase()}:</strong> {value.toString() || "-"}
                                          </div>
                                      ))}
                                  </Box>
                              ))
                            : "-"}
                    </PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Interfaces</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>
                        <Box style={{ fontSize: 14, fontWeight: 600 }}>HW Interface</Box>
                    </PropertyKey>
                    <Box style={{ display: "flex", width: "100%" }}>
                        <Box style={{ width: "50%", paddingRight: 3 }}>
                            <PropertyValue>
                                <Box style={{ fontSize: 14, fontWeight: 600 }}>HW Address</Box>
                            </PropertyValue>
                        </Box>
                        <Box style={{ width: "50%", paddingLeft: 3 }}>
                            <PropertyValue>
                                <Box style={{ fontSize: 14, fontWeight: 600 }}>IPs</Box>
                            </PropertyValue>
                        </Box>
                    </Box>
                </PropertyRow>
                {networks.map((network, i) => (
                    <PropertyRow key={i}>
                        <PropertyKey>{network?.interface}</PropertyKey>
                        <Box style={{ display: "flex", width: "100%" }}>
                            <Box style={{ width: "50%", paddingRight: 3 }}>
                                <PropertyValue>{network?.hw}</PropertyValue>
                            </Box>
                            <Box style={{ width: "50%", paddingLeft: 3 }}>
                                <PropertyValue>
                                    {network?.ips?.map((ip) => (
                                        <div key={ip}>{ip}</div>
                                    ))}
                                </PropertyValue>
                            </Box>
                        </Box>
                    </PropertyRow>
                ))}
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Ethernet mode</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Interface</PropertyKey>
                    <PropertyValue>{ethernet?.interface ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Mode</PropertyKey>
                    <PropertyValue>{ethernet?.mode ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>IP address</PropertyKey>
                    <PropertyValue>{ethernet?.ip ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Gateway</PropertyKey>
                    <PropertyValue>{ethernet?.gateway ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Netmask</PropertyKey>
                    <PropertyValue>{ethernet?.netmask ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>DNS</PropertyKey>
                    <PropertyValue>{ethernet?.dns ?? "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Ethernet proxy</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Mode</PropertyKey>
                    <PropertyValue>{ethernet?.proxyMode ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Host</PropertyKey>
                    <PropertyValue>{ethernet?.proxyHost ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Port</PropertyKey>
                    <PropertyValue>{ethernet?.proxyPort ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>PAC URL</PropertyKey>
                    <PropertyValue>{"-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section withoutBorder>
                <PaneElement.Header>Wifi</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Status</PropertyKey>
                    <PropertyValue>{wifi?.enabled ? "Enabled" : "Disabled"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>SSID</PropertyKey>
                    <PropertyValue>{wifi?.enabled && wifi?.ssid ? wifi?.ssid : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>RSSI</PropertyKey>
                    <PropertyValue>{wifi?.strength ?? "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
        </PaneElement.Container>
    );
}
export default NetworkTab;
