import { useState } from "react";

import { Group } from "@devices/types";
import { MdmCommands } from "@devices/types/commands";
import { Table, TableBody, TablePagination, TableRow } from "@mui/material";
import { TableHeader } from "@shared/table/TableHeader";
import { TableHeaderCell } from "@shared/table/TableHeaderCell";

import { PaneElement } from "../PaneElement";
import GroupCommandItem from "./GroupCommandItem";

const rowsPerPage = 25;
export const HEADERS = [
    { id: "collapse", label: "", align: "left" },
    { id: "date", label: "Date", align: "left" },
    { id: "statistics", label: "Statistics", align: "center" },
    { id: "command", label: "Command", align: "left" },
    { id: "actions", label: "Actions", align: "right" },
] as const;
export type Header = (typeof HEADERS)[number];

interface GroupCommandsTableProps {
    commands: MdmCommands;
    groupId: Group["id"];
}
const GroupCommandsTable = ({ commands, groupId }: GroupCommandsTableProps) => {
    const [pageIndex, setPageIndex] = useState(0);
    const paginatedFiles = commands.slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage);

    return (
        <>
            <PaneElement.TableContainer style={{ maxHeight: "77%", marginBottom: 10 }}>
                <Table stickyHeader aria-label="simple table">
                    <TableHeader>
                        <TableRow>
                            {HEADERS.map((item) => (
                                <TableHeaderCell key={item.id} item={item} cellProps={{ align: item.align }} />
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {paginatedFiles.map((command, index) => (
                            <GroupCommandItem index={index} command={command} key={command?.id} groupId={groupId} />
                        ))}
                    </TableBody>
                </Table>
            </PaneElement.TableContainer>
            <TablePagination
                style={{ marginBottom: 10 }}
                component="div"
                count={commands.length}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={(_, page) => {
                    setPageIndex(page);
                }}
            />
        </>
    );
};

export default GroupCommandsTable;
