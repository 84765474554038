import axios, { AxiosResponse } from "axios";

import { getApiGatewayUrl } from "./configService";

class RouteService {
    static mainBackendVersion: keyof Omit<API.ConfigRoutes["main"], "jwKs"> = "v2";
    static productBackendVersion: keyof API.ConfigRoutes["product"] = "v1";
    static storageBackendVersion: keyof API.ConfigRoutes["storage"] = "v1";

    private static routes: API.ConfigRoutes | null = null;

    private static async getRoutes(): Promise<API.ConfigRoutes> {
        if (this.routes) {
            return this.routes;
        }
        const response = await axios.get<object, AxiosResponse<API.ConfigRoutes>>(
            getApiGatewayUrl() + "/v1/configs/routes",
        );
        this.routes = response.data;
        return this.routes;
    }

    static async getProductRoute() {
        const routes = await this.getRoutes();
        return routes.product[RouteService.productBackendVersion];
    }
    static async getStorageRoute() {
        const routes = await this.getRoutes();
        return routes.storage[RouteService.storageBackendVersion];
    }
    static async getJwksRoute() {
        const routes = await this.getRoutes();
        return routes.main.jwKs;
    }
    static async getApiKeysRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].apikeys;
    }
    static async getAccountsRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].accounts;
    }
    static async getDevicesRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].devices;
    }
    static async getEventsRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].events;
    }
    static async getGatewaysRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].gateways;
    }
    static async getGroupsRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].groups;
    }
    static async getImagesRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].images;
    }
    static async getLicensesRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].licenses;
    }
    static async getProvisioningsRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].provisionings;
    }
    static async getSubscriptionsRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].subscriptions;
    }
    static async getTokensRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].tokens;
    }
    static async getVouchersRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].vouchers;
    }
    static async getWorkspacesRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].workspaces;
    }
    static async getPaymentRoute() {
        const routes = await this.getRoutes();
        return routes.main[RouteService.mainBackendVersion].payments;
    }
}

export default RouteService;
