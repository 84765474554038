import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";

import { toggleFilters, updateSearchQuery } from "@dashboard/devices/store";
import { devicesPageFilterState, selectActiveFilters, selectSearchQuery } from "@dashboard/devices/store/selectors";
import { Box, colors, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CustomBadge } from "@shared/CustomBadge";
import { SecondaryButton } from "@shared/CustomButton";

import { Menu } from "./Menu";
import { Search } from "./Search";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        zIndex: 1,
        background: theme.palette.blue[350],
        borderRadius: "4px",
        padding: "4px 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    box: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    badge: {
        height: "16px",
        minWidth: "16px",
        padding: "0px 5px",
        fontSize: "11px",
        color: theme.palette.white[50],
        backgroundColor: colors.green[500],
    },
}));

export const Header = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const query = useSelector(selectSearchQuery);
    const filters = useSelector(devicesPageFilterState);
    const activeFilters = useSelector(selectActiveFilters, shallowEqual);

    const onToggle = () => dispatch(toggleFilters(!filters.isOpen));
    const onClear = () => dispatch(updateSearchQuery(""));
    const onChange = (value?: string | number | readonly string[]) => {
        if (typeof value === "string") {
            dispatch(updateSearchQuery(value.toLowerCase()));
        }
    };

    return (
        <Box className={classes.header}>
            <Box className={classes.box}>
                <Search handleChange={onChange} handleClear={onClear} defaultValue={query} />

                <CustomBadge badgeContent={activeFilters.length} overlap="rectangular">
                    <SecondaryButton onClick={onToggle} size="small" data-testid="filter_toggler">
                        <i className="fas fa-filter" />
                    </SecondaryButton>
                </CustomBadge>
            </Box>

            <Menu />
        </Box>
    );
};
