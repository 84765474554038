import { createUseStyles } from "react-jss";

import { Typography } from "@mui/material";

const useStyles = createUseStyles({
    subTitle: {
        fontWeight: 600,
        display: "block",
        marginBottom: "4px",
    },
});

export function TopicHeader({ children }: React.ComponentProps<"span">): JSX.Element {
    const classes = useStyles();

    return <Typography className={classes.subTitle}>{children}</Typography>;
}
