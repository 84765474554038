import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";

const useStyles = makeStyles((theme: Theme) => ({
    expiredSubscription: {
        backgroundColor: theme.palette.yellow[50],
    },
}));

const currentDate = new Date();

type Props = {
    subscription: API.Subscription;
    organizationName: string;
};
const TableItem = (props: Props) => {
    const classes = useStyles();
    const licenseLimit = props.subscription.limits.find((limit) => limit.name === "LicenseLimit");

    const isExpired = props.subscription.expirationDate
        ? new Date(props.subscription.expirationDate) <= currentDate
        : false;

    return (
        <TableRow className={isExpired ? classes.expiredSubscription : ""}>
            <MinimalTableCell align="left">{props.subscription.id}</MinimalTableCell>
            <MinimalTableCell align="left">{props.organizationName}</MinimalTableCell>
            <MinimalTableCell align="left">{props.subscription.chargeId}</MinimalTableCell>
            <MinimalTableCell align="left">{licenseLimit ? licenseLimit.value : "-"}</MinimalTableCell>
            <MinimalTableCell align="left">
                {convertToLocalDate(props.subscription.creationDate, "ISO", "dateTime")}
            </MinimalTableCell>
            <MinimalTableCell align="left">
                {props.subscription.expirationDate
                    ? convertToLocalDate(props.subscription.expirationDate, "ISO", "dateTime")
                    : "-"}
            </MinimalTableCell>
        </TableRow>
    );
};

export default TableItem;
