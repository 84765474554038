import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { palette } from "./palette";

const defaultTheme = createTheme();

export const theme = createTheme({
    palette: {
        primary: {
            main: palette.colorBluePrimary,
            light: palette.colorBlueAccent,
        },
        red: {
            50: palette.colorRedEmber,
            100: palette.colorRedEmberLight,
            150: palette.colorRedGrape,
        },
        orange: {
            50: palette.colorOrangeLight,
            100: palette.colorOrangeDark,
        },
        green: {
            50: palette.colorGreenEmerald,
        },
        blue: {
            50: palette.colorBluePrimaryLight2,
            100: palette.colorBlueAlice,
            150: palette.colorBlueAccent,
            200: palette.colorBlueNavy,
            250: palette.colorBluePrimaryLight,
            300: palette.colorBluePrimary,
            350: palette.colorBlueAliceDarker,
        },
        black: {
            50: palette.colorBlackAlt1,
            100: palette.colorBlack,
        },
        shadow: {
            50: palette.colorWhiteSnow,
            100: palette.colorWhiteGhost,
            150: palette.colorGrayLightAlt2,
            200: palette.colorGrayCloud,
            250: palette.colorGrayLightAlt,
            300: palette.colorGrayLight,
            350: palette.colorGrayDark,
        },
        white: {
            50: palette.colorWhite,
        },
        yellow: {
            50: palette.colorYellowTransparent,
            100: palette.colorYellowPastel,
        },
    },
    typography: {
        fontFamily: "Segoe UI, sans-serif",
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                root: { backgroundColor: palette.colorWhite },
                scrollButtons: {
                    "&.Mui-disabled": {
                        opacity: 0.3,
                    },
                },
                indicator: {
                    backgroundColor: palette.colorBlueNavy,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 50,
                    height: 50,
                    textTransform: "none",
                    "&.Mui-selected": {
                        color: palette.colorBlueNavy,
                        fontWeight: 600,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorSecondary: {
                    "&$checked": {
                        color: palette.colorBlueAccent,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: palette.colorBlueAccent,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: "none",
                    borderColor: palette.colorRedGrape,
                    backgroundColor: palette.colorRedGrape,
                    color: palette.colorWhite,

                    "&:hover": {
                        borderColor: palette.colorRedGrape,
                        backgroundColor: palette.colorRedGrape,
                        color: palette.colorWhite,
                        filter: "brightness(0.92)",
                    },
                    "&:disabled": {
                        borderColor: palette.colorGrayLightAlt2,
                        backgroundColor: palette.colorGrayLightAlt2,
                    },
                },

                containedPrimary: {
                    backgroundColor: palette.colorBlueAccent,
                    borderColor: palette.colorBlueAccent,
                    color: palette.colorWhite,

                    "&:hover": {
                        backgroundColor: palette.colorBlueAccent,
                        borderColor: palette.colorBlueAccent,
                        color: palette.colorWhite,
                        filter: "brightness(0.92)",
                    },
                },

                containedSecondary: {
                    borderColor: palette.colorWhite,
                    backgroundColor: palette.colorWhite,
                    color: palette.colorGrayDark,

                    "&:hover": {
                        borderColor: palette.colorWhite,
                        backgroundColor: palette.colorWhite,
                        color: palette.colorGrayDark,
                        filter: "brightness(0.92)",
                    },
                },

                outlined: {
                    textTransform: "none",
                    backgroundColor: palette.colorWhite,
                    borderColor: palette.colorRedGrape,
                    color: palette.colorRedGrape,
                    boxShadow: defaultTheme.shadows[2],

                    "&:hover": {
                        borderColor: palette.colorRedEmberLight,
                        color: palette.colorRedEmberLight,
                        boxShadow: defaultTheme.shadows[4],
                        filter: "brightness(0.96)",
                    },
                    "&:disabled": {
                        borderColor: palette.colorGrayLightAlt2,
                        backgroundColor: palette.colorGrayLightAlt2,
                    },
                },

                outlinedPrimary: {
                    backgroundColor: palette.colorWhite,
                    borderColor: palette.colorBlueAccent,
                    color: palette.colorBlueAccent,
                    boxShadow: defaultTheme.shadows[2],

                    "&:hover": {
                        backgroundColor: palette.colorWhite,
                        color: palette.colorBlueNavy,
                        borderColor: palette.colorBlueNavy,
                        boxShadow: defaultTheme.shadows[4],
                        filter: "brightness(0.96)",
                    },
                },
            },
            defaultProps: {
                disableRipple: true,
            },
        },
    },
});

export const ThemeProvider = (props: React.PropsWithChildren<object>) => {
    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
        </StyledEngineProvider>
    );
};
