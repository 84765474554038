import _, { flatten } from "lodash";
import { RootState } from "src/store";

import { ListStatus, localState } from "@dashboard/devices/store";
import {
    filterLicensesByProperties,
    filterLicensesBySearchQuery,
    licensesAdapter,
} from "@dashboard/devices/store/helpers";
import { selectSearchQuery } from "@dashboard/devices/store/selectors";
import { devicesPageFilterState, selectFilterProperties } from "@dashboard/devices/store/selectors/filters";
import { selectSubscription } from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@dashboard/devices/types";
import { selectGroupById, selectGroups, selectGroupsIdsBySubscriptionId } from "@dashboard/groups/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

const defaultSelectors = licensesAdapter.getSelectors<RootState>((state) => state.devices.list.licenses.items);

const selectLicenseById = defaultSelectors.selectById;
const selectLicenseListStatusByGroupId = (state: RootState, groupId: number): ListStatus | null => {
    if (!groupId) {
        return null;
    }

    return localState(state)?.list?.licenses?.statusByGroupId[groupId];
};
const selectLicenses = createSelector(
    [
        selectSubscription,
        (state: RootState) => selectGroups(state),
        (state: RootState) => defaultSelectors.selectAll(state),
    ],
    (subscription, groups, licenses) => {
        const subscriptionGroups = subscription ? groups.filter((item) => subscription.groupIds.includes(item.id)) : [];
        const subscriptionLicenseIds = _.uniq(subscriptionGroups.map((item) => item.licenseIds).flat());
        return licenses.filter((item) => subscriptionLicenseIds.includes(item.id));
    },
);
const selectLicensesByGroupId = createSelector(
    (state: RootState) => state,
    (state: RootState, groupId: number) => selectGroupById(state, groupId),
    (state, group) => {
        if (!group || !group?.licenseIds?.length) {
            return [];
        }

        return group.licenseIds.reduce<License[]>((acc, id) => {
            const license = selectLicenseById(state, id);
            return license ? [...acc, license] : acc;
        }, []);
    },
);
const selectLicensesByGroupIds = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupIds: number[] | undefined) => groupIds,
    (state, groupIds) => {
        if (Array.isArray(groupIds) && !groupIds.length) {
            return [];
        }

        const licenses = groupIds?.map((groupId) => selectLicensesByGroupId(state, groupId));

        return flatten(licenses) ?? [];
    },
);
const selectLicensesBySubscriptionId = createSelector(
    (state: RootState) => state,
    (_state: RootState, subscriptionId: number) => subscriptionId,
    (state, subscriptionId) => {
        const groupIds = selectGroupsIdsBySubscriptionId(state, subscriptionId);
        if (!groupIds) {
            return [];
        }
        return groupIds.reduce<License[]>((acc, groupId) => {
            const licenses = selectLicensesByGroupId(state, groupId);
            return licenses ? [...acc, ...licenses] : acc;
        }, []);
    },
);
const selectLicensesEntities = defaultSelectors.selectEntities;
const selectLicensesListStatus = (state: RootState): ListStatus | null => {
    return localState(state)?.list.licenses.status;
};
const selectLicensesWithFilter = (state: RootState) => {
    const groups = selectGroups(state);
    const groupFilter = selectFilterProperties(state);
    const filteredGroups = !groupFilter["group_name"].length
        ? groups
        : groups.filter((group) => groupFilter["group_name"].includes(group.name));

    const groupIds = filteredGroups.map((group) => group.id);
    const licenses = selectLicensesByGroupIds(state, groupIds);

    const { isActive, properties: filterProperties } = devicesPageFilterState(state) || {};
    const searchQuery = selectSearchQuery(state);

    const filteredLicenses = isActive
        ? filterLicensesByProperties({
              filters: filterProperties,
              licenses,
              groups: filteredGroups,
          })
        : licenses;

    const filteredLicensesBySearch = searchQuery
        ? filteredLicenses.filter((license) =>
              filterLicensesBySearchQuery({
                  license,
                  searchQuery,
                  groups: filteredGroups,
              }),
          )
        : filteredLicenses;

    return filteredLicensesBySearch;
};
const selectLicensesByGroupIdWithFilter = (state: RootState, groupId: number): License[] => {
    const groupLicenses = selectLicensesByGroupId(state, groupId);
    const groups = selectGroups(state);

    const filters = devicesPageFilterState(state);
    const filterProperties = filters?.properties;
    const _licenses = filters.isActive
        ? filterLicensesByProperties({
              filters: filterProperties,
              licenses: groupLicenses,
              groups: groups,
          })
        : groupLicenses;
    const searchQuery = selectSearchQuery(state);
    const filteredLicensesBySearch = searchQuery
        ? _licenses.filter((license) =>
              filterLicensesBySearchQuery({
                  license,
                  searchQuery,
                  groups: groups,
              }),
          )
        : _licenses;

    return filteredLicensesBySearch;
};
const selectUniversalLicenses = createSelector(selectLicenses, (licenses) =>
    licenses.filter((license) => !!license.universalGroupName),
);
const selectLicensesCountBySubscriptionId = (state: RootState, subscriptionId: number): number => {
    const licenses = selectLicensesBySubscriptionId(state, subscriptionId);
    return licenses.length || 0;
};

export {
    selectLicenseById,
    selectLicenseListStatusByGroupId,
    selectLicenses,
    selectLicensesByGroupId,
    selectLicensesByGroupIdWithFilter,
    selectLicensesByGroupIds,
    selectLicensesBySubscriptionId,
    selectLicensesCountBySubscriptionId,
    selectLicensesEntities,
    selectLicensesListStatus,
    selectLicensesWithFilter,
    selectUniversalLicenses,
};
