interface Network {
    hw: string;
    ips: string[];
    interface: string;
}

export interface Connection {
    interface: string | null;
    ip: string | null;
}

const getFirstIPv4 = (ips: string[]): string | null => {
    return ips.find((ip) => ip.includes(".")) || null;
};

const getFirstIPv6 = (ips: string[]): string | null => {
    return ips.find((ip) => ip.includes(":")) || null;
};

export const getInternetConnection = (networks: Network[] | undefined): Connection => {
    if (!networks) {
        return { interface: null, ip: null };
    }

    for (const net of networks) {
        if (net.interface.startsWith("eth") || net.interface.startsWith("wlan")) {
            const ip = getFirstIPv4(net.ips) || getFirstIPv6(net.ips);
            if (ip) {
                return { interface: net.interface, ip: ip };
            }
        }
    }

    return { interface: null, ip: null };
};
