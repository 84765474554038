import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { permissionService } from "src/services/permissionService/permissionService";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CategoryOption } from "src/ui/shared/form/SelectField";
import { UploadFileField } from "src/ui/shared/form/UploadFileField";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { Tooltip } from "src/ui/shared/Tooltip";

import { UploadProgressBar } from "@dashboard/files/components/UploadProgressBar";
import UploadTable from "@dashboard/files/components/UploadTable/UploadTable";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { useVisibilityOptions } from "@dashboard/shared/useVisibilityOptions";
import { selectWorkspace, selectWorkspaces } from "@dashboard/workspaces/store/selectors";

import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { UploadProvider, useUploadContext } from "./context/UploadContext";
import { useStyles } from "./UploadPage.style";

// 256 MB in bytes
export const maxFileSize = 256 * 1024 * 1024;

interface FormValues {
    files: File[];
    target: number | undefined;
}

const findFirstEnabledOptionValue = (options: CategoryOption[]) => {
    const allOptions = [...options.map((item) => item.options).flat()];
    const option = allOptions.find((item) => !item.disabled);
    return option?.value;
};

const UploadPageContent = () => {
    const classes = useStyles();
    const currentWorkspace = useSelector(selectWorkspace);
    const { groupAbility } = permissionService();
    const { uploadStarted } = useUploadContext();

    const groups = useSelector(selectGroups);
    const workspaces = useSelector(selectWorkspaces);
    const options = useVisibilityOptions();
    const preselectedOption = findFirstEnabledOptionValue(options);
    const allOptionsDisabled = options.every((category) => category.options.every((option) => option.disabled));

    const validate = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};
        if (values.files) {
            for (const file of values.files) {
                if (file.size > maxFileSize) {
                    errors.files = "One or more files exceed the maximum size limit of 256 MB.";
                    break;
                }
            }
        }
        return errors;
    };

    const checkIsUploadDisabled = (values: FormValues) => {
        const group = groups.find((item) => item.id === values.target);
        const workspace = workspaces?.find((item) => item.id === values.target);
        const mayManageFile = groupAbility(group || null, workspace || null).can("manage", "File");
        return !mayManageFile || allOptionsDisabled || uploadStarted;
    };

    return (
        <PageContainer>
            <PageTitleWithIcon title="File management hub" iconName="fa-regular fa-file" />
            <SectionDescription>
                Upload, manage, and organize your files and applications with custom tags.
            </SectionDescription>
            {currentWorkspace ? (
                <Form<FormValues>
                    onSubmit={() => undefined}
                    validate={validate}
                    initialValues={{ target: preselectedOption }}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.dropzoneContainer}>
                                {checkIsUploadDisabled(values) ? (
                                    <Tooltip
                                        placement="top"
                                        title="You don't have any group or workspace to upload file"
                                        className={classes.uploadTooltipContainer}
                                    >
                                        <UploadFileField name="files" disabled />
                                    </Tooltip>
                                ) : (
                                    <UploadFileField name="files" />
                                )}
                                {values.files ? (
                                    <div className={classes.uploadContainer}>
                                        <div className={classes.contentBox}>
                                            <UploadProgressBar filesToUpload={values.files} />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <UploadTable />
                        </form>
                    )}
                />
            ) : (
                <FreshAccountMessage />
            )}
        </PageContainer>
    );
};

const UploadPage = () => {
    return (
        <UploadProvider>
            <UploadPageContent />
        </UploadProvider>
    );
};

export default UploadPage;
