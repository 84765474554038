import { Icon, IconSize } from "@dashboard/devices/components/shared/Icon";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export function LoadingIcon({ size, className }: { size?: IconSize; className?: string }): JSX.Element {
    return <Icon name="fas fa-spinner fa-spin" className={className} size={size} />;
}

const useStyles = makeStyles(() => ({
    loadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexGrow: 1,
    },
}));
export const LoadingBox = (props: BoxProps) => {
    const classes = useStyles();

    return (
        <Box {...props} className={classes.loadingBox}>
            <LoadingIcon size={IconSize.large} />
        </Box>
    );
};
