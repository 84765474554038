import { useEffect } from "react";

type FetchDataFunction<T> = () => Promise<T>;

type Options = {
    frequency?: number;
    condition?: boolean;
};

const defaultOptions: Options = {
    frequency: 30000,
    condition: true,
};

const useFetchInterval = <T>(fetchData: FetchDataFunction<T>, options = defaultOptions): void => {
    const mergedOptions = {
        ...defaultOptions,
        ...options,
    };
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (mergedOptions.condition) {
            interval = setInterval(async () => {
                await fetchData();
            }, mergedOptions.frequency);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [mergedOptions.condition, fetchData, mergedOptions.frequency]);
};

export default useFetchInterval;
