import { omit } from "lodash";
import queryString from "query-string";
import { RootState } from "src/store";
import { persistentStore } from "src/store/persistentStore";
import { disableNotification } from "store/account";

import {
    claimStarterSubscription,
    closeDetails,
    FilterState,
    resetFiltersToDefault,
    setPaneTab,
    setViewGroupsCollapseAll,
    setViewGroupsCollapseById,
    setViewGroupsExpandById,
    setViewPageSize,
    showDetails,
    toggleFilters,
    updateFilterProperty,
    updateSearchQuery,
} from "@dashboard/devices/store";
import { isAnyOf, ListenerMiddlewareInstance, UnsubscribeListener } from "@reduxjs/toolkit";

export const initDeviceListMiddleware = (listenerMiddleware: ListenerMiddlewareInstance): UnsubscribeListener => {
    const clearFilters = (filters: Partial<FilterState>) => {
        if (filters && filters.properties) {
            for (const key in filters.properties) {
                const propertyKey = key as keyof FilterState["properties"];
                const propertyValues = filters.properties[propertyKey];
                if (propertyValues && Array.isArray(propertyValues)) {
                    const index = propertyValues.indexOf("Enterprise");
                    if (index !== -1) {
                        propertyValues.splice(index, 1);
                    }
                }
            }
        }
    };

    return listenerMiddleware.startListening({
        matcher: isAnyOf(
            disableNotification,
            toggleFilters,
            updateFilterProperty,
            setViewPageSize,
            setViewGroupsCollapseById,
            resetFiltersToDefault,
            setViewGroupsExpandById,
            setViewGroupsCollapseAll,
            claimStarterSubscription?.fulfilled,
        ),
        effect: (action, listenerApi) => {
            const state = listenerApi.getState() as RootState;

            const filters = omit(state?.devices?.filters, ["isOpen"]);
            const view = state?.devices?.view;
            const disabledNotifications = state?.account?.disabledNotifications;

            clearFilters(filters);

            persistentStore.set({
                account: { disabledNotifications },
                devices: {
                    filters,
                    view,
                },
            });
        },
    });
};

export const initUrlMiddleware = (listenerMiddleware: ListenerMiddlewareInstance): UnsubscribeListener => {
    return listenerMiddleware.startListening({
        matcher: isAnyOf(updateSearchQuery, showDetails, setPaneTab, closeDetails),
        effect: (_action, listenerApi) => {
            const state = listenerApi.getState() as RootState;
            const details = state.devices.details;
            const url = window.location.href;
            const options = { skipEmptyString: true, skipNull: true };
            const query = {
                search: state.devices.search.query,
                licenseId: details.type === "license" ? details.selectedId : null,
                groupId: details.type === "group" ? details.selectedId : null,
                tab: details.selectedId ? state.devices.pane.tab : null,
            };

            if (new URL(url).pathname.startsWith("/dashboard/devices")) {
                window.history.pushState({}, "", queryString.stringifyUrl({ url, query }, options));
            }

            if (!state.devices.details.isOpen) {
                window.history.pushState({}, "");
            }
        },
    });
};
