import {
    AccessorFn,
    AccessorFnColumnDef,
    AccessorKeyColumnDef,
    DeepKeys,
    DeepValue,
    DisplayColumnDef,
    GroupColumnDef,
    IdentifiedColumnDef,
    RowData,
} from "@tanstack/react-table";

type CustomColumnHelper<T extends RowData, ColumnId = string> = {
    accessor: <
        Accessor extends AccessorFn<T> | DeepKeys<T>,
        V extends Accessor extends AccessorFn<T, infer TReturn>
            ? TReturn
            : Accessor extends DeepKeys<T>
            ? DeepValue<T, Accessor>
            : never,
    >(
        accessor: Accessor,
        column: Accessor extends AccessorFn<T>
            ? DisplayColumnDef<T, V> & { id: ColumnId }
            : IdentifiedColumnDef<T, V> & { id: ColumnId },
    ) => Accessor extends AccessorFn<T>
        ? AccessorFnColumnDef<T, V> & { id: ColumnId }
        : AccessorKeyColumnDef<T, V> & { id: ColumnId };
    display: (column: DisplayColumnDef<T> & { id: ColumnId }) => DisplayColumnDef<T, unknown> & { id: ColumnId };
    group: (column: GroupColumnDef<T>) => GroupColumnDef<T, unknown>;
};

export const createCustomColumnHelper = <T extends RowData, ColumnId = string>(): CustomColumnHelper<T, ColumnId> => {
    return {
        accessor: (accessor, column) => {
            const columnWithId: { id: ColumnId } = column;

            if (typeof accessor === "function") {
                return {
                    ...column,
                    accessorFn: accessor as AccessorFn<T>,
                    id: columnWithId.id,
                };
            } else {
                return {
                    ...column,
                    accessorKey: accessor as DeepKeys<T>,
                    id: columnWithId.id,
                };
            }
        },
        display: (column) => ({
            ...column,
            id: column.id,
        }),
        group: (column) => ({
            ...column,
            id: column.id,
        }),
    } as CustomColumnHelper<T, ColumnId>;
};
