import { setIn } from "final-form";
import React from "react";
import * as yup from "yup";

const setInError = (errors: Record<string, string>, innerError: yup.ValidationError): Record<string, string> => {
    return setIn(errors, innerError.path ?? "", innerError.message) as Record<string, string>;
};
const emptyObj: Record<string, string> = Object.create(null) as Record<string, string>;
const makeValidate = (
    schema: yup.ObjectSchema<Record<string, unknown>>,
): ((values: yup.InferType<typeof schema>) => Promise<undefined | Record<string, string>>) =>
    async function validate(values: yup.InferType<typeof schema>) {
        try {
            await schema.validate(values, { abortEarly: false });
        } catch (errors: unknown) {
            if (errors instanceof yup.ValidationError) {
                return errors.inner.reduce(setInError, emptyObj);
            }
        }
    };
export const useValidationSchema = (
    schema: yup.ObjectSchema<Record<string, unknown>>,
): ((values: yup.InferType<typeof schema>) => Promise<undefined | Record<string, string>>) => {
    return React.useMemo(() => makeValidate(schema), [schema]);
};
