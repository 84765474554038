import React from "react";
import { createUseStyles } from "react-jss";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import { selectLegacyDeviceOverviewEnabled } from "src/store/beta";
import CreateGroupDialog from "src/ui/containers/dashboard/groups/components/CreateGroupDialog";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import {
    Dropdown,
    DropdownItem,
    DropdownList,
    Icon,
    IconSize,
    SelectPageSize,
} from "@dashboard/devices/components/shared";
import {
    BatchActionState,
    selectLicensePageSize,
    setBatchMode,
    setViewGroupsCollapseAll,
    setViewPageSize,
} from "@dashboard/devices/store";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";

import ExportCSV from "./ExportCSV";

const useStyles = createUseStyles({
    container: { display: "flex" },
    dropdown: { marginRight: 4 },
    btnIcon: {
        marginRight: 6,
    },
    menuItem: {
        height: "1.85rem",
    },
});

type SelectBatchActionMode = (entity: BatchActionState["entity"], type: BatchActionState["type"]) => void;

export const Menu = () => {
    const [isCreateGroupDialogOpen, setCreateGroupDialogOpen] = React.useState(false);
    const [isViewOpen, setIsViewOpen] = React.useState(false);
    const [isManageOpen, setIsManageOpen] = React.useState(false);
    const [isPageSizeOpen, setIsPageSizeOpen] = React.useState(false);

    const anchorRefView = React.useRef(null);
    const anchorRefManage = React.useRef(null);

    const classes = useStyles();
    const dispatch = useDispatch();
    const pageSize = useSelector(selectLicensePageSize);
    const workspace = useSelector(selectWorkspace);
    const isExpired = useSelector(selectSubscriptionExpired);
    const isLegacyDeviceOverviewEnabled = useSelector(selectLegacyDeviceOverviewEnabled);

    const { groupAbility } = permissionService();

    const selectBatchActionMode: SelectBatchActionMode = (entity, type) => {
        dispatch(setBatchMode({ type, entity }));
        setIsManageOpen(false);
    };
    const handleCollapseGroups = (isCollapsed: boolean) => {
        dispatch(setViewGroupsCollapseAll(isCollapsed));
        setIsViewOpen(false);
    };
    const handlePageSizeChange: SelectInputProps["onChange"] = (event) => {
        if (event.target.value) {
            dispatch(setViewPageSize(event.target.value as number));
            setIsPageSizeOpen(false);
            setIsViewOpen(false);
        }
    };
    const handlePageSizeToggle = () => {
        setIsPageSizeOpen((prev) => !prev);
    };
    const handleViewOpenToggle = () => {
        setIsViewOpen((prev) => !prev);
    };
    const handleManageOpenToggle = () => {
        setIsManageOpen((prev) => !prev);
    };

    React.useEffect(() => {
        if (isViewOpen) {
            setIsPageSizeOpen(false);
        }
    }, [isViewOpen]);

    return (
        <Box className={classes.container}>
            <Dropdown className={classes.dropdown}>
                <div ref={anchorRefView}>
                    {isLegacyDeviceOverviewEnabled ? (
                        <SecondaryButton
                            size="small"
                            startIcon={<i className="fas fa-eye" />}
                            onClick={handleViewOpenToggle}
                        >
                            View
                        </SecondaryButton>
                    ) : null}
                </div>
                <DropdownList
                    isOpen={isViewOpen}
                    onClickAway={() => setIsViewOpen(false)}
                    anchorRef={anchorRefView}
                    isBlockedOnClickAway={isPageSizeOpen}
                >
                    <DropdownItem className={classes.menuItem} onClick={() => handleCollapseGroups(true)}>
                        <Icon name="fas fa-minus" size={IconSize.small} className={classes.btnIcon} />
                        Collapse groups
                    </DropdownItem>
                    <DropdownItem className={classes.menuItem} onClick={() => handleCollapseGroups(false)}>
                        <Icon name="fas fa-arrows-alt-v" size={IconSize.small} className={classes.btnIcon} />
                        Expand groups
                    </DropdownItem>
                    <DropdownItem>
                        <Icon name="fas fa-list" size={IconSize.small} className={classes.btnIcon} />
                        <Box marginRight={1.6} onClick={() => setIsPageSizeOpen(true)}>
                            Display rows
                        </Box>
                        <SelectPageSize
                            id="view-page-size-select"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            open={isPageSizeOpen}
                            onClick={handlePageSizeToggle}
                        />
                    </DropdownItem>
                </DropdownList>
            </Dropdown>
            <Dropdown>
                <div ref={anchorRefManage}>
                    <SecondaryButton
                        onClick={handleManageOpenToggle}
                        size="small"
                        startIcon={<i className="fa-solid fa-gear" />}
                    >
                        Manage
                    </SecondaryButton>
                </div>
                <DropdownList
                    isOpen={isManageOpen}
                    onClickAway={() => setIsManageOpen(false)}
                    anchorRef={anchorRefManage}
                >
                    <DropdownItem
                        className={classes.menuItem}
                        onClick={() => setCreateGroupDialogOpen(true)}
                        disabled={groupAbility(null, workspace).cannot("create", "Group") || isExpired}
                        tooltipProps={{ title: "Subscription expired", hide: !isExpired }}
                    >
                        <Icon name="fa-solid fa-plus" size={IconSize.small} className={classes.btnIcon} />
                        Add group
                    </DropdownItem>
                    <DropdownItem className={classes.menuItem} onClick={() => selectBatchActionMode("license", "move")}>
                        <Icon
                            name="fa-solid fa-arrow-right-arrow-left"
                            size={IconSize.small}
                            className={classes.btnIcon}
                        />
                        Move licenses
                    </DropdownItem>
                    <ExportCSV />
                </DropdownList>
            </Dropdown>
            <CreateGroupDialog open={isCreateGroupDialogOpen} onClose={() => setCreateGroupDialogOpen(false)} />
        </Box>
    );
};
