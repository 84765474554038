import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    content: { width: 410, minHeight: 300, margin: "12px 0px", background: "#FAFAFC", borderRadius: "6px" },
    empty: { height: 150, display: "flex", justifyContent: "center", alignItems: "center" },
    container: {
        padding: 20,
        minHeight: 400,
        maxHeight: 550,
    },
    dialogTitleBox: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        fontSize: 20,
    },
    informationText: {
        fontWeight: 600,
    },
    closeBtn: {
        cursor: "pointer",
        color: "black",
        fontSize: 20,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "scroll",
        overflowX: "hidden",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
    },
    title: {
        marginLeft: 10,
    },
});
