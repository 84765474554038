import { compareVersions } from "compare-versions";
import { useState } from "react";

import { FileDetailsModal } from "@dashboard/shared/components/MoreInformationFileModal/FileDetailsModal";
import { alternatingBg, deleteBorder } from "@dashboard/shared/styles";
import { Device } from "@devices/types";
import { hasApkMetadata } from "@files/utils";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { TableItemProps } from "@shared/table/Table";
import { TagsBoxCell } from "@sidePanel/shared/TagsBoxCell";

import { ActionsCell } from "./ActionsCell";
import { useStyles } from "./TableItem.style";

const TableItem = <T extends Device>(props: TableItemProps<T>) => {
    const classes = useStyles();
    const [collapseOpen, setCollapseOpen] = useState(-1);
    const [modalOpen, setModalOpen] = useState(false);
    const collapsed = collapseOpen === props.index;

    const manifest = hasApkMetadata(props.file) ? props.file.metadata?.file?.apk?.manifest : null;

    const groupedFilesByName = props.allFiles
        .filter(
            (f) => manifest && f.metadata?.file?.apk?.manifest?.package == manifest?.package && f.id != props.file.id,
        )
        .sort((a, b) =>
            compareVersions(
                b.metadata?.file?.apk?.manifest?.versioncode ?? "0",
                a.metadata?.file?.apk?.manifest?.versioncode ?? "0",
            ),
        );

    const toggleModal = () => {
        setModalOpen((prevState) => !prevState);
    };

    return (
        <>
            <TableRow style={alternatingBg(props.index)}>
                <TableCell align="center" style={deleteBorder(props.index, collapseOpen)}>
                    <IconButton
                        className={classes.collapseBtn}
                        disabled={!groupedFilesByName.length}
                        onClick={() => setCollapseOpen((prev) => (prev === props.index ? -1 : props.index))}
                        size="large"
                    >
                        {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell style={deleteBorder(props.index, collapseOpen)}>
                    <div>{manifest?.package ?? props.file.filename}</div>
                </TableCell>
                <TagsBoxCell index={props.index} collapseOpen={collapseOpen} file={props.file} />
                <ActionsCell {...props} collapseOpen={collapseOpen} setDetailsModalOpen={toggleModal} />
            </TableRow>
            {groupedFilesByName.map((file) => (
                <TableRow
                    key={file.id}
                    style={{
                        ...alternatingBg(props.index),
                        display: collapseOpen === props.index ? "" : "none",
                    }}
                >
                    <TableCell style={deleteBorder(props.index, collapseOpen)}></TableCell>
                    <TableCell style={deleteBorder(props.index, collapseOpen)}></TableCell>
                    <TagsBoxCell {...props} collapseOpen={collapseOpen} file={file} />
                    <ActionsCell
                        {...props}
                        collapseOpen={collapseOpen}
                        file={file}
                        selectedItem={props.selectedItem}
                        setDetailsModalOpen={toggleModal}
                    />
                </TableRow>
            ))}
            <FileDetailsModal isOpen={modalOpen} closeDialog={() => setModalOpen(false)} file={props.file} />
        </>
    );
};

export default TableItem;
