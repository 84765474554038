import semver from "semver";
import * as yup from "yup";

const ProductApplicationSchema = yup.object().shape({
    privileged: yup.boolean().required(),
    source: yup
        .object()
        .shape({
            type: yup.string().oneOf(["storage"]).required(),
            fileId: yup.string().required(),
        })
        .required(),
});
const ProductBootAnimationSchema = yup.object().shape({
    source: yup
        .object()
        .shape({
            type: yup.string().oneOf(["storage"]).required(),
            fileId: yup.string().required(),
        })
        .required(),
});

const customBuildConfigSchema = yup.object().shape({
    productName: yup.string().required().label("Product name"),
    rootDevice: yup.string().required().label("Hardware platform"),
    applications: yup.array().of(ProductApplicationSchema).optional().default(undefined),
    bootanimation: ProductBootAnimationSchema.optional().default(undefined),
    provisioningId: yup.number().nullable().default(null),
    compatibility: yup
        .string()
        .required()
        .test(
            "is-valid-version",
            "Compatibility must be a valid version string (e.g., >=1.0.0, >2.0, =1.2.3)",
            (value) => semver.validRange(value) !== null,
        )
        .label("Compatibility"),
});
export const productSchema = yup.object().shape({
    id: yup.number().required(),
    codename: yup.string().required().label("Codename"),
    groupId: yup.number().nullable(),
    nightlyPeriod: yup.number().nullable(),
    androidVersion: yup.string().required(),
    workspaceId: yup.number().nullable(),
    customBuildConfig: customBuildConfigSchema.required(),
});
