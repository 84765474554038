import queryString from "query-string";
import React from "react";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router";
import { resetPassword } from "src/services/accountService";
import { getHomepageUrl } from "src/services/configService";
import ApmLink from "src/ui/shared/ApmLink";
import { CustomCard, CustomCardContent, CustomCardHeader, CustomCardText, CustomCardTitle } from "src/ui/shared/Card";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { PageContainer } from "@dashboard/shared/styles";
import { InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    card: { width: 400 },
    description: { margin: 0, marginBottom: 20 },
    logo: {
        paddingBottom: 40,
    },
});

type FormValues = {
    password: string;
    passwordRepeat: string;
};

const PasswordResetPage = () => {
    const [showPassword, setShowwPassord] = React.useState(false);
    const [showRepeatPassword, setShowwRepeatPassord] = React.useState(false);

    const classes = useStyles();
    const navigate = useNavigate();

    const { search } = useLocation();
    const { id, token } = queryString.parse(search, {
        decode: false,
    });

    const onSubmit = async (values: FormValues) => {
        if (!id || !token) {
            return null;
        }

        try {
            await resetPassword({
                id: id.toString(),
                token: token.toString(),
                password: values.password,
                passwordRepeat: values.passwordRepeat,
            });
            showSuccessToast("Password reset successfully");
            navigate("/login");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        }
    };

    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.password) {
            errors.password = "New password cannot be empty";
        }

        if (!values.passwordRepeat) {
            errors.passwordRepeat = "New password must be repeated";
        }

        if (values.password !== values.passwordRepeat) {
            errors.passwordRepeat = "Passwords do not match";
        }

        return errors;
    };

    if (!id || !token) {
        showErrorToast("Please try again");
        navigate("/login");
        return null;
    }

    return (
        <PageContainer className={classes.container}>
            <ApmLink href={getHomepageUrl()}>
                <img src="./img/logos/emteria-colored.webp" alt="emteria logo" className={classes.logo} />
            </ApmLink>
            <CustomCard className={classes.card}>
                <CustomCardHeader title={<CustomCardTitle>Reset Password</CustomCardTitle>} />
                <CustomCardContent>
                    <Form<FormValues>
                        onSubmit={onSubmit}
                        validate={onValidate}
                        render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <CustomCardText className={classes.description}>
                                    Please choose a new password for your emteria account.
                                </CustomCardText>

                                <TextField
                                    placeholder="Enter new password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    style={{ marginBottom: 4 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-fw fa-key" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i
                                                    className="fa-solid fa-eye"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => setShowwPassord((prev) => !prev)}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    placeholder="Repeat new password"
                                    name="passwordRepeat"
                                    type={showRepeatPassword ? "text" : "password"}
                                    fullWidth
                                    style={{ marginBottom: 16 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fas fa-fw fa-key" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i
                                                    className="fa-solid fa-eye"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => setShowwRepeatPassord((prev) => !prev)}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <PrimaryButton type="submit" loading={submitting} disabled={submitting} fullWidth>
                                    Submit
                                </PrimaryButton>
                            </form>
                        )}
                    />
                </CustomCardContent>
            </CustomCard>
        </PageContainer>
    );
};

export default PasswordResetPage;
