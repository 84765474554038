import clsx from "clsx";
import _ from "lodash";
import React from "react";

import { Clear } from "@mui/icons-material";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    inputContainer: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        maxWidth: "380px",
        width: "100%",
        marginRight: "3px",
    },
    input: {
        border: "0px solid transparent",
        borderRadius: "3px",
        padding: "2px 2px 2px 28px",
        width: "100%",
        height: 30,
    },
    searchIcon: {
        position: "absolute",
        left: "8px",
        top: "10px",
    },
    clearInputIcon: {
        position: "absolute",
        right: "6px",
        top: "8px",
        cursor: "pointer",
        color: theme.palette.white[50],
        backgroundColor: theme.palette.shadow[300],
        borderRadius: "50%",
        "&.MuiSvgIcon-root": {
            height: "16px",
            width: "16px",
        },
    },
}));

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
    handleChange: (value?: string | number | readonly string[]) => void;
    handleClear?: () => void;
    debounceWait?: number;
}

export const Search = ({ handleChange, handleClear, debounceWait = 700, ...props }: Props) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const classes = useStyles();

    const onChange: _.DebouncedFunc<React.ChangeEventHandler<HTMLInputElement>> = _.debounce((e) => {
        handleChange(e.target.value);
    }, debounceWait);
    const onClear = () => {
        if (ref.current) {
            ref.current.value = "";
        }
        if (handleClear) {
            handleClear();
        }
    };

    React.useEffect(() => {
        handleChange(props.defaultValue);
        //We're silencing eslint here to avoid unwanted re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.inputContainer}>
            <i className={`${classes.searchIcon} fas fa-search`} />
            <input {...props} className={clsx(classes.input, props.className)} onChange={onChange} ref={ref} />
            {ref.current?.value ? <Clear className={classes.clearInputIcon} onClick={onClear} /> : null}
        </div>
    );
};
