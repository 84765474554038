import axios, { AxiosError, AxiosResponse } from "axios";
import authService from "src/services/authService";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, ListResponse } from "src/types";

import { unpackError } from "@dashboard/devices/api/helpers";
import { showErrorToast } from "@shared/toasts/Toasts";

import { FileInfo } from "../types";

export enum SourceType {
    User = 0,
    Device = 1,
    Microservice = 2,
}

type GetPresignedUrlSuccessResponse = { uploadPresignedUrl: string; fileId: string };

export const apiDeleteFile = async (fileId: string) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        await httpService.delete(endpoint + "files", { data: [fileId] });
        return fileId;
    } catch (error) {
        const errorObj = unpackError(error);
        showErrorToast(errorObj.message);
        throw errorObj;
    }
};

type ApiUploadFile = {
    fileToUpload: File;
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    controller: AbortController;
    setUploadedFileIds: React.Dispatch<React.SetStateAction<string[]>>;
};
type ApiUploadProps = { workspaceId: number; groupId: number | null; filename: string };
type GetPresignedUrlErrorReponse = {
    model: {
        uploadPresignedUrl: null;
        fileId: null;
    };
    message: string;
};

export const getPresignedUrlForFileUpload = async (apiUploadProps: ApiUploadProps) => {
    const requestBody = {
        filename: apiUploadProps.filename,
        groupId: apiUploadProps.groupId,
        workspaceId: apiUploadProps.workspaceId,
    };

    const endpoint = await RouteService.getStorageRoute();
    try {
        const response: AxiosResponse<ApiResponse<GetPresignedUrlSuccessResponse>> = await httpService.post(
            endpoint + "files",
            requestBody,
        );
        return response;
    } catch (error) {
        const err = error as AxiosError<GetPresignedUrlErrorReponse>;
        showErrorToast(err.response?.data?.message || "Unexpected error ocurred");
        throw error;
    }
};

export const apiUploadFile = async (payload: ApiUploadProps & ApiUploadFile) => {
    const userId = authService.getCurrentUser()?.sub;

    const response = await getPresignedUrlForFileUpload(payload);
    if (response.data) {
        payload.setUploadedFileIds((prev) => [...prev, response.data.model.fileId]);
        const presignedUrl = response.data.model.uploadPresignedUrl;
        const presignedApi = axios.create({ url: presignedUrl });
        const file = await presignedApi.put(presignedUrl, payload.fileToUpload, {
            headers: { "Content-Type": payload.fileToUpload.type },
            onUploadProgress: payload.onUploadProgress,
            signal: payload.controller.signal,
        });

        if (!file.config.data || !userId) {
            throw new Error("Unexpected error");
        }

        const newFile: FileInfo = {
            id: response.data.model.fileId,
            contentType: file.config.data.type,
            createdDate: new Date().toISOString(),
            modifiedDate: new Date().toISOString(),
            filename: file.config.data.name,
            groupId: payload.groupId,
            workspaceId: payload.workspaceId,
            ownerId: userId,
            size: file.config.data.size,
            rolloutPercentage: 0,
            status: 0,
            source: 0,
        };

        return newFile;
    } else {
        showErrorToast("Couldn't upload file, please try again");
        throw new Error("Couldn't upload file, please try again");
    }
};

export interface ApiFilesFetchPayload {
    workspaceId: number;
    pageIndex: number;
    pageSize: number;
    withPagination: boolean;
}
export interface ApiFilesFetchResponse {
    files: API.File[];
    count: number;
}
export const apiFilesFetch = async (props: ApiFilesFetchPayload): Promise<ApiFilesFetchResponse> => {
    const endpoint = await RouteService.getStorageRoute();
    const response: AxiosResponse<ApiResponse<ListResponse<API.File>>> = await httpService.get(endpoint + "files", {
        params: {
            PageIndex: props.pageIndex,
            PageSize: props.pageSize,
            WorkspaceId: props.workspaceId,
            UploadStatus: 0,
        },
    });

    if (props.withPagination) {
        return {
            files: response.data.model.list,
            count: response.data.model.count,
        };
    }

    if (response.data.model.count / props.pageSize > props.pageIndex) {
        const nextPageData = await apiFilesFetch({ ...props, pageIndex: props.pageIndex + 1 });
        return {
            files: [...response.data.model.list, ...nextPageData.files],
            count: response.data.model.count,
        };
    }
    return {
        files: response.data.model.list,
        count: response.data.model.count,
    };
};
interface ApiUpdateFileMetadataPayload {
    fileId: string;
    metadata: { tags: API.Tag[] };
}
export const apiUpdateFileMetadata = async (payload: ApiUpdateFileMetadataPayload) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        await httpService.patch<
            { metadata: ApiUpdateFileMetadataPayload["metadata"] },
            AxiosResponse<BasicApiResponse>
        >(`${endpoint}files/${payload.fileId}`, { metadata: payload.metadata });
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};
export const getPresignedDownloadUrl = async (fileId: string) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        const response: AxiosResponse<{ model: string }> = await httpService.get(`${endpoint}files/${fileId}/link`);
        return response.data.model;
    } catch (error) {
        showErrorToast("Something went wrong during the file upload, please try again");
    }
};

type ApiUpdateFilePayload = Partial<Omit<FileInfo, "metadata">> & { id: string };
export const apiUpdateFile = async (payload: ApiUpdateFilePayload) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        await httpService.patch<ApiUpdateFilePayload, AxiosResponse<BasicApiResponse>>(
            `${endpoint}files/${payload.id}`,
            payload,
        );
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};

export const apiGetFileLink = async (payload: number) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        const response: AxiosResponse<ApiResponse<string>> = await httpService.get(`${endpoint}files/${payload}/link`);
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const invokeFileDownload = async (id: string | number, fileName: string, productFile?: boolean) => {
    try {
        const productEndpoint = await RouteService.getProductRoute();
        const storageEndpoint = await RouteService.getStorageRoute();
        const response: AxiosResponse<ApiResponse<string>> = await httpService.get(
            `${productFile ? productEndpoint : storageEndpoint}files/${id}/link`,
        );

        const link = document.createElement("a");
        link.href = response.data.model;
        link.download = fileName;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        showErrorToast("Download failed, please try again");
    }
};

type ApiUpdateFileRolloutPayload = { id: string; rolloutPercentage: number };
export const apiUpdateFileRollout = async (payload: ApiUpdateFileRolloutPayload) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        await httpService.patch<null, AxiosResponse<BasicApiResponse>>(`${endpoint}files/${payload.id}/rollout`, null, {
            headers: { "Accept": "text/plain" },
            params: { rolloutPercentage: payload.rolloutPercentage },
        });
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};
