import { ValueOf } from "type-fest";

import { FilterState } from "@dashboard/devices/store/selectors";
import { Group, License } from "@dashboard/devices/types";
import { getDeviceOnlineSince, getHumanReadableDeviceUptime } from "@dashboard/devices/utils/device";
import { getLicenseActivationStatus } from "@dashboard/devices/utils/license";

import { getIPs } from "./device";

function testCondition({ values = [], target = "" }: { values: ValueOf<FilterState["properties"]>; target: string }) {
    let result = null;
    if (values && values.length) {
        result = values.some((filterValue) => filterValue.toLowerCase() === target.toLowerCase());
    }

    return result;
}

interface FilterLicensesByProperties {
    licenses: License[];
    filters: FilterState["properties"];
    groups: Group[];
}

export function filterLicensesByProperties({ licenses, filters, groups }: FilterLicensesByProperties): License[] {
    const filteredData = licenses.filter((license) => {
        let filterResults = [];

        if (filters["license_activationStatus"].length) {
            filterResults.push(
                testCondition({
                    values: filters["license_activationStatus"],
                    target: getLicenseActivationStatus(license),
                }),
            );
        }

        const selectedGroup = groups.find((group) => group.id === license.groupId);
        const deviceStatus = license?.device?.isOnline ? "Online" : "Offline";
        const policyStatus = !selectedGroup?.policy?.length
            ? "Compliant"
            : license?.device?.lastState?.policy?.successful
            ? "Compliant"
            : "Non-compliant";

        const codename = license.device?.product || "";
        const version = license.device?.lastState?.version.emteriaVersion || "";

        if (filters["device_status"].length && deviceStatus) {
            const _deviceStatus = testCondition({
                values: filters["device_status"],
                target: deviceStatus,
            });
            filterResults.push(_deviceStatus);
        }
        if (filters["device_status"].length && !deviceStatus) {
            filterResults.push(false);
        }

        if (filters["policy_status"].length) {
            const _policyStatus = testCondition({
                values: filters["policy_status"],
                target: policyStatus,
            });
            filterResults.push(_policyStatus);
        }
        if (filters["policy_status"].length && !policyStatus) {
            filterResults.push(false);
        }
        if (filters["codename"].length) {
            const _codename = testCondition({
                values: filters["codename"],
                target: codename,
            });
            filterResults.push(_codename);
        }
        if (filters["system_version"].length) {
            const _version = testCondition({
                values: filters["system_version"],
                target: version,
            });
            filterResults.push(_version);
        }

        filterResults = filterResults.filter((value) => value !== null);

        return filterResults.length >= 0 ? filterResults.every((item) => item) : false;
    });

    return filteredData;
}

interface ShowEmptyGroups {
    searchQuery: string;
    filters: FilterState["properties"];
}

export const showEmptyGroups = ({ searchQuery, filters }: ShowEmptyGroups): boolean => {
    if (searchQuery) {
        return false;
    }

    if (
        filters["device_status"].length ||
        filters["license_activationStatus"].length ||
        filters["policy_status"].length
    ) {
        return false;
    }

    return true;
};

interface FilterLicensesBySearchQuery {
    license: License;
    searchQuery: string;
    groups: Group[];
}

export const filterLicensesBySearchQuery = ({ license, searchQuery, groups }: FilterLicensesBySearchQuery): boolean => {
    const ips = getIPs(license?.device?.lastState || undefined);
    const deviceOnlineSince = getDeviceOnlineSince(license?.device);
    const uptimeHumanReadable = getHumanReadableDeviceUptime(
        license?.device?.lastState?.datetime?.elapsedMs,
        license?.device?.isOnline,
    );
    const licenseStatus = getLicenseActivationStatus(license);
    const deviceOnline = license?.device?.isOnline ? "Online" : "Offline";

    const group = groups.find((g) => g.id === license.groupId);
    const policyStatus = !group?.policy?.length
        ? "Compliant"
        : license?.device?.lastState?.policy?.successful
        ? "Compliant"
        : "Non-compliant";

    const properties = [
        licenseStatus,
        license?.activationCode,
        license?.comment,
        deviceOnline,
        license?.device?.name,
        license?.device?.description,
        license?.device?.product,
        license?.device?.serial,
        license?.device?.lastState?.version.emteriaVersion,
        uptimeHumanReadable,
        deviceOnlineSince,
        license?.device?.lastState?.wifi?.ssid,
        policyStatus,
        ips.ipv4,
        ips.ipv6,
        license?.groupName,
    ];

    return properties.some((property) => property?.toLowerCase().includes(searchQuery.toLowerCase()));
};

interface FilterGroupsBySearchQuery {
    group: Group;
    searchQuery: string;
}

export const filterGroupsBySearchQuery = ({ group, searchQuery }: FilterGroupsBySearchQuery): boolean => {
    const properties = [group.name, group.description];
    const results = properties.some((property) => property?.toLowerCase().includes(searchQuery.toLowerCase()));
    return results;
};
