import { DateTime } from "luxon";
import authService from "src/services/authService";
import { getReduxVersion } from "src/services/configService";
import { RootState } from "src/store";

import { createSelector, createSlice } from "@reduxjs/toolkit";

export type AuthState = {
    token: string | null;
    hasTotp: boolean;
    isTotpValid: boolean;
    reduxVersion: string | null;
    tokenExpiresAt: string | null;
    refreshTokenExpiresAt: string | null;
};

const currentUser = authService.getCurrentUser();
const currentSession = authService.getSession();
const currentReduxVersion = getReduxVersion();
const isTotpValid = authService.isUserTotpValid();

const initialState = {
    token: currentSession?.access_token || null,
    hasTotp: currentUser?.tft ? true : false,
    isTotpValid,
    reduxVersion: currentReduxVersion,
    tokenExpiresAt: currentSession?.expires_at || null,
    refreshTokenExpiresAt: currentSession?.refresh_token_expires_at || null,
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
});

export const localState = (state: RootState): AuthState => state?.auth;

export const selectIsTokenStale = (state: RootState) => {
    const now = DateTime.now();

    if (state.auth.refreshTokenExpiresAt) {
        return DateTime.fromISO(state.auth.refreshTokenExpiresAt) < now;
    }
    if (state.auth.tokenExpiresAt) {
        return DateTime.fromISO(state.auth.tokenExpiresAt) < now;
    }

    return true;
};

export const selectIsUserCredentialValid = (state: RootState) => {
    const tokenIsStale = selectIsTokenStale(state);
    const isCredentialInvalid = tokenIsStale || (state.auth.hasTotp && !state.auth.isTotpValid);
    return !isCredentialInvalid;
};

export const isLoggedIn = createSelector(
    (state: RootState) => state.auth,
    (state) => {
        const { token, hasTotp } = state;
        return hasTotp ? false : Boolean(token);
    },
);

export default slice.reducer;
