const firstAndLastDoubleQuote = /^"|"$/g;

export const unpackError = (error) => {
    if (error?.response?.data?.message) {
        return {
            message: error.response.data.message,
        };
    }

    if (error?.response?.data?.ErrorMessage) {
        return {
            message: error.response.data.ErrorMessage,
        };
    }

    if (error?.response?.data?.error_description) {
        return { message: error.response.data.error_description };
    }

    return error?.request?.response
        ? {
              message: error?.request?.response.replace(firstAndLastDoubleQuote, ""),
          }
        : new Error(error);
};
