import { shallowEqual, useSelector } from "react-redux";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectLicenses } from "src/ui/containers/dashboard/licenses/store/selectors";

import { defineAbility, MongoAbility } from "@casl/ability";
import { selectSubscriptions } from "@dashboard/devices/store/selectors/subscriptions";
import { Device, Group, License } from "@dashboard/devices/types";
import { FileInfo } from "@dashboard/files/types";
import { selectWorkspace, selectWorkspaces } from "@dashboard/workspaces/store/selectors";
import { Workspace } from "@dashboard/workspaces/types";

import authService from "../authService";
import { getEnv } from "../configService";

type GroupAbilities = ["create" | "delete" | "update", "Group"];
type LicenseAbilities = ["manage" | "create", "License"];
type WorkspaceAbilities = ["update" | "cancel", "Workspace"];
type FileAbilities = ["manage", "File"];
type AppAbilities = ["install" | "uninstall", "App"];
type TagAbilities = ["manage", "Tag"];
type MemberAbilities = ["manage", "Member"];
type UniversalLicenseAbilities = ["manage", "UniversalLicense"];
type ProductAbilities = ["manage", "Product"];

type Abilities =
    | GroupAbilities
    | LicenseAbilities
    | WorkspaceAbilities
    | FileAbilities
    | AppAbilities
    | TagAbilities
    | MemberAbilities
    | UniversalLicenseAbilities
    | ProductAbilities;

const getEffectiveRole = (group: Group | null, workspace: Workspace | null, email?: string) => {
    const workspaceRole = workspace?.members.find((member) => member.email === email)?.role || null;
    const groupRole = group?.members.find((member) => member.email === email)?.role || null;

    if (groupRole === "Owner" || workspaceRole === "Owner") {
        return "Owner";
    }
    if (groupRole === "Manager" || workspaceRole === "Manager") {
        return "Manager";
    }
    if (groupRole === "User" || workspaceRole === "User") {
        return "User";
    }
    return null;
};

export const permissionService = (email?: string) => {
    const groups = useSelector(selectGroups, shallowEqual);
    const licenses = useSelector(selectLicenses, shallowEqual);
    const workspaces = useSelector(selectWorkspaces, shallowEqual);
    const currentWorkspace = useSelector(selectWorkspace, shallowEqual);
    const subscriptions = useSelector(selectSubscriptions, shallowEqual);

    const emailAdrress = getEnv() === "Test" ? email || "test@emteria.com" : authService.getCurrentUser()?.email;

    return {
        workspaceAbility: (workspace: Workspace | null) => {
            const role = getEffectiveRole(null, workspace, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner") {
                    can(["cancel"], "Workspace");
                }
                if (role === "Owner" || role === "Manager") {
                    can(["update"], "Workspace");
                    can(["manage"], "File");
                    can(["manage"], "Member");
                }
                if (role === "Owner" || role === "Manager" || role === "User") {
                    can(["create"], "License");
                }
            });
        },
        groupAbility: (group: Group | null, workspace: Workspace | null) => {
            const role = getEffectiveRole(group, workspace, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner" || role === "Manager") {
                    can(["create", "delete", "update"], "Group");
                    can(["manage"], "File");
                    can(["manage"], "Member");
                    can(["manage"], "UniversalLicense");
                    can(["install", "uninstall"], "App");
                }
            });
        },
        licenseAbility: (license: License | null, workspace?: Workspace) => {
            const group = groups.find((item) => item.id === license?.groupId);
            const role = getEffectiveRole(group || null, workspace || currentWorkspace, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner") {
                    can(["manage"], "License");
                }
            });
        },
        fileAbility: (file: FileInfo) => {
            const group = groups.find((item) => item.id === file.groupId);
            const workspace = workspaces.find((item) => item.id === file.workspaceId);

            const role = getEffectiveRole(group || null, workspace || null, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner" || role === "Manager") {
                    can(["manage"], "Tag");
                }
            });
        },
        deviceAbility: (device: Device) => {
            const license = licenses.find((item) => item.id === device.licenseId);
            const group = groups.find((item) => item.id === license?.groupId);
            const subscription = subscriptions.find((item) => item.id === group?.subscriptionId);
            const workspace = workspaces.find((item) => item.subscription === subscription?.id);

            const role = getEffectiveRole(group || null, workspace || null, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner" || role === "Manager") {
                    can(["install", "uninstall"], "App");
                }
            });
        },
        productAbility: (workspace: Workspace | null) => {
            const role = getEffectiveRole(null, workspace, emailAdrress);

            return defineAbility<MongoAbility<Abilities>>((can, _cannot) => {
                if (role === "Owner" || role === "Manager") {
                    can(["manage"], "Product");
                }
            });
        },
    };
};
