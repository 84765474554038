import { ValueOf } from "type-fest";

export function getEnumKeyByEnumValue<R extends string | number, T extends { [key: string]: R }>(
    myEnum: T,
    enumValue: ValueOf<T>,
): string {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : "";
}

export function getEnumValueByKey<R extends string | number, T extends { [key: string]: R }>(
    myEnum: T,
    enumValue: ValueOf<T>,
) {
    const indexOfS = Object.keys(myEnum).indexOf(enumValue as string);
    return Object.values(myEnum)[indexOfS];
}
