import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { EmteriaRoles } from "src/data/roles";
import { useDispatch } from "src/store";
import { withRequiredRole } from "src/ui/navigation/utils/withRequiredRole";
import { DangerButton, PrimaryButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";
import * as yup from "yup";

import { updateGroup } from "@dashboard/devices/store";
import { GroupRoles } from "@dashboard/devices/types";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionContainer, SectionDescription, SectionHeader } from "@dashboard/shared/styles";
import { updateWorkspaceMembers } from "@dashboard/workspaces/store";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type FormValues = {
    id: number;
    email: string;
    target: "workspace" | "group";
};
type ExtendedFormValues = FormValues & { role: GroupRoles };

const initialValues: FormValues = {
    id: 0,
    email: "-",
    target: "workspace",
};
const extendedInitialValues: ExtendedFormValues = {
    ...initialValues,
    role: "User",
};

const validationSchema = yup.object({
    id: yup.number().required().label("Target Id"),
    email: yup.string().email().max(50).required().label("User email"),
    target: yup.string().oneOf(["group", "workspace"]).required().label("Target"),
});
const extendedValidationSchema = validationSchema.shape({
    role: yup.string().oneOf(["Owner", "Manager", "User"]).required().label("User role"),
});

const targetOptions = [
    { label: "Group", value: "group" },
    { label: "Workspace", value: "workspace" },
];
const roleOptions = [
    { label: "Owner", value: "Owner" },
    { label: "Manager", value: "Manager" },
    { label: "User", value: "User" },
];
const useStyles = makeStyles((theme: Theme) => ({
    section: { paddingBottom: 0 },
    formBox: { display: "flex", flexDirection: "column", gap: theme.spacing(1.25), padding: "20px 0" },
    buttonBox: { display: "flex", justifyContent: "flex-end" },
}));

const AdminMembersPage = () => {
    const [isAdding, setIsAdding] = React.useState(false);
    const [isChanging, setIsChanging] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);

    const validate = useValidationSchema(validationSchema);
    const extendedValidate = useValidationSchema(extendedValidationSchema);

    const classes = useStyles();
    const dispatch = useDispatch();

    const onAddMember = async (values: ExtendedFormValues, form: FormApi<ExtendedFormValues>) => {
        try {
            setIsAdding(true);

            if (values.target === "group") {
                await dispatch(updateGroup({ id: values.id, addedMembers: { [values.email]: values.role } })).unwrap();
            }
            if (values.target === "workspace") {
                await dispatch(
                    updateWorkspaceMembers({ workspaceId: values.id, addedMembers: { [values.email]: values.role } }),
                ).unwrap();
            }
            showSuccessToast("Member added successfully");
            form.restart(extendedInitialValues);
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong while adding the member");
        } finally {
            setIsAdding(false);
        }
    };
    const onChangeMember = async (values: ExtendedFormValues, form: FormApi<ExtendedFormValues>) => {
        try {
            setIsChanging(true);

            if (values.target === "group") {
                await dispatch(
                    updateGroup({ id: values.id, changedMembers: { [values.email]: values.role } }),
                ).unwrap();
            }
            if (values.target === "workspace") {
                await dispatch(
                    updateWorkspaceMembers({ workspaceId: values.id, changedMembers: { [values.email]: values.role } }),
                ).unwrap();
            }
            showSuccessToast("Member changed successfully");
            form.restart(extendedInitialValues);
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong while changing the member");
        } finally {
            setIsChanging(false);
        }
    };
    const onRemoveMember = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            setIsRemoving(true);

            if (values.target === "group") {
                await dispatch(updateGroup({ id: values.id, removedMembers: [values.email] })).unwrap();
            }
            if (values.target === "workspace") {
                await dispatch(
                    updateWorkspaceMembers({ workspaceId: values.id, removedMembers: [values.email] }),
                ).unwrap();
            }
            showSuccessToast("Member removed successfully");
            form.restart(initialValues);
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong while removing the member");
        } finally {
            setIsRemoving(false);
        }
    };

    return (
        <PageContainer>
            <PageTitleWithIcon title="Members" iconName="fa-solid fa-users" />
            <SectionDescription>
                This page allows to add, update, and remove members from both workspaces and groups. It focuses solely
                on managing the membership of these entities. The target ID refers to the ID of the workspace or group
                whose members we want to manage.
            </SectionDescription>

            <Form<ExtendedFormValues>
                onSubmit={onAddMember}
                initialValues={extendedInitialValues}
                validate={extendedValidate}
                render={({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <SectionContainer className={classes.section}>
                            <SectionHeader>Add Member</SectionHeader>
                            <Box className={classes.formBox}>
                                <SelectField name="target" label="Target" options={targetOptions} />
                                <TextField name="id" label="Target Id" type="number" />
                                <SelectField name="role" label="User role" options={roleOptions} />
                                <TextField name="email" label="User email" />
                            </Box>
                            <Box className={classes.buttonBox}>
                                <PrimaryButton type="submit" disabled={pristine || isAdding} loading={isAdding}>
                                    Add
                                </PrimaryButton>
                            </Box>
                        </SectionContainer>
                    </form>
                )}
            />
            <Form<ExtendedFormValues>
                onSubmit={onChangeMember}
                initialValues={extendedInitialValues}
                validate={extendedValidate}
                render={({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <SectionContainer className={classes.section}>
                            <SectionHeader>Change Member</SectionHeader>
                            <Box className={classes.formBox}>
                                <SelectField name="target" label="Target" options={targetOptions} />
                                <TextField name="id" label="Target id" type="number" />
                                <SelectField name="role" label="User role" options={roleOptions} />
                                <TextField name="email" label="User email" />
                            </Box>
                            <Box className={classes.buttonBox}>
                                <PrimaryButton type="submit" disabled={pristine || isChanging} loading={isChanging}>
                                    Change
                                </PrimaryButton>
                            </Box>
                        </SectionContainer>
                    </form>
                )}
            />
            <Form<FormValues>
                onSubmit={onRemoveMember}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <SectionContainer className={classes.section}>
                            <SectionHeader>Remove Member</SectionHeader>
                            <Box className={classes.formBox}>
                                <SelectField name="target" label="Target" options={targetOptions} />
                                <TextField name="id" label="Target id" type="number" />
                                <TextField name="email" label="User email" />
                            </Box>
                            <Box className={classes.buttonBox}>
                                <DangerButton type="submit" disabled={pristine || isRemoving} loading={isRemoving}>
                                    Remove
                                </DangerButton>
                            </Box>
                        </SectionContainer>
                    </form>
                )}
            />
        </PageContainer>
    );
};
export default withRequiredRole(AdminMembersPage, [EmteriaRoles.EmteriaAdminRole]);
