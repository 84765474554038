import { RootState, useDispatch, useSelector } from "src/store";

import { showSuccessToast } from "@shared/toasts/Toasts";

import { selectWorkspaceId } from "../../workspaces/store/selectors";
import { deleteFile, uploadFile } from "../store";

interface UploadProps {
    fileToUpload: File;
    controller: AbortController;
    groupId?: number;
    onUploadProgress: (progressEvent: ProgressEvent) => void;
    setUploadedFileIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const useUploadFile = () => {
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.files.status);
    const workspaceId = useSelector(selectWorkspaceId);

    const upload = async (props: UploadProps) => {
        if (workspaceId) {
            return dispatch(
                uploadFile({
                    ...props,
                    workspaceId,
                    groupId: props.groupId || null,
                    filename: props.fileToUpload.name,
                }),
            );
        }
    };

    const isUninitialized = status === undefined;
    const isLoading = status === "pending" || status === null;
    const isError = status === "rejected";
    const isSuccess = status === "fulfilled";

    return { upload, isUninitialized, isLoading, isError, isSuccess };
};
export const useDeleteFile = () => {
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.files.status);
    const removeFile = async (fileId: string) => {
        const response = await dispatch(deleteFile(fileId));
        if (response.meta.requestStatus === "fulfilled") {
            showSuccessToast("Successfully deleted file");
            return response;
        } else {
            return null;
        }
    };

    const isUninitialized = status === undefined;
    const isLoading = status === "pending" || status === null;
    const isError = status === "rejected";
    const isSuccess = status === "fulfilled";

    return { removeFile, isUninitialized, isLoading, isError, isSuccess };
};
