import React from "react";
import { Navigate } from "react-router-dom";
import { EmteriaRoles } from "src/data/roles";
import authService from "src/services/authService";

const checkUserRole = (role: EmteriaRoles): boolean => {
    switch (role) {
        case EmteriaRoles.EmteriaAdminRole:
            return authService.isUserAdmin();
        case EmteriaRoles.EmteriaSalesRole:
            return authService.isUserMerchant();
        case EmteriaRoles.EmteriaDeveloperRole:
            return authService.isUserDeveloper();
        case EmteriaRoles.EmteriaSupportRole:
            return authService.isUserSupporter();
        case EmteriaRoles.EmteriaAccountantRole:
            return authService.isUserAccountant();
        default:
            return false;
    }
};

export const withRequiredRole = (WrappedComponent: React.ComponentType, roles: EmteriaRoles[]) => {
    const ComponentWithRoleCheck: React.FC = (props) => {
        const hasAccess = roles.some(checkUserRole);

        if (!hasAccess) {
            return <Navigate to="/forbidden" />;
        }

        return <WrappedComponent {...props} />;
    };

    return ComponentWithRoleCheck;
};
