import fileSize from "filesize";
import { useState } from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { createRolloutTag } from "src/ui/containers/dashboard/shared/tags";
import { DangerOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { CategoriesSelectNoForm } from "src/ui/shared/form/SelectField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import { Tooltip } from "src/ui/shared/Tooltip";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { CellWithTooltip } from "@dashboard/devices/components/shared/CellWithTooltip";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { invokeFileDownload } from "@dashboard/files/api";
import { useDeleteFile } from "@dashboard/files/hooks/entities";
import { updateFile } from "@dashboard/files/store";
import { FileStatus, FileTableItem } from "@dashboard/files/types/index";
import { createVersionCodeTag } from "@dashboard/files/utils";
import { FileDetailsModal } from "@dashboard/shared/components/MoreInformationFileModal/FileDetailsModal";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { useVisibilityOptions } from "@dashboard/shared/useVisibilityOptions";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { SelectChangeEvent, TableCell, TableRow, Typography } from "@mui/material";

import FileIcon from "../FileIcon";
import { useStyles } from "./TableItem.style";

interface FileUploadTableItemProps {
    file: FileTableItem;
    setSelectedFileId: React.Dispatch<React.SetStateAction<string | null>>;
}

const TableItem = (props: FileUploadTableItemProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { removeFile } = useDeleteFile();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState({
        isFileDeleting: false,
        isFileDownloading: false,
    });

    const versionCodeTag = createVersionCodeTag(props.file);
    const removableTags = props.file.metadata?.user?.tags;
    const rolloutTag = createRolloutTag(props.file.rolloutPercentage);
    const hasTags = removableTags?.length || versionCodeTag || rolloutTag;
    const isUploading = props.file.status === FileStatus.Pending;

    const isExpired = useSelector(selectSubscriptionExpired);
    const currentWorkspace = useSelector(selectWorkspace);
    const currentWorkspaceGroups = useSelector(selectGroups);

    const currentGroup = currentWorkspaceGroups.find((item) => item.id === props.file.groupId);

    const { groupAbility, fileAbility } = permissionService();

    const mayManageFile = groupAbility(currentGroup || null, currentWorkspace).can("manage", "File");
    const mayManageTag = fileAbility(props.file).can("manage", "Tag");

    const options = useVisibilityOptions();

    const handleDelete = async () => {
        setLoading({ ...loading, isFileDeleting: true });
        await removeFile(props.file.id);
        setLoading({ ...loading, isFileDeleting: false });
    };
    const handleDownload = async () => {
        setLoading({ ...loading, isFileDownloading: true });
        await invokeFileDownload(props.file.id, props.file.filename);
        setLoading({ ...loading, isFileDownloading: false });
    };

    const handleAccessibilityChange = async (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value;

        const isGroupId = value !== currentWorkspace?.id;

        if (typeof value === "number" && currentWorkspace) {
            try {
                await dispatch(
                    updateFile({
                        id: props.file.id,
                        groupId: isGroupId ? value : null,
                        workspaceId: isGroupId ? null : value,
                    }),
                ).unwrap();
                showSuccessToast("File visibility updated successfully");
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
            }
        }
    };
    const getSelectTooltip = () => {
        const result = { title: "Change visibility" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageFile) {
            result.title = "You are not allowed to change visibility in this group";
        }
        return result;
    };
    const getTagBtnTooltip = () => {
        const result = { title: "Manage tags" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageTag) {
            result.title = "Your group or workspace role does not allow you to manage tags";
        }
        return result;
    };
    const getDownloadBtnTooltip = () => {
        const result = { title: "Download file" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        return result;
    };

    if (!currentWorkspace) {
        return null;
    }

    return (
        <>
            <TableRow className={isUploading ? classes.isUploading : ""}>
                <FileIcon file={props.file} />
                <CellWithTooltip title={props.file.filename} maxLength={10} maxWidth={150} testId="file-name-cell">
                    {props.file.filename}
                </CellWithTooltip>
                <CellWithTooltip
                    title={fileSize(props.file.size || 0)}
                    maxLength={10}
                    testId="file-size-cell"
                    style={{ minWidth: 100 }}
                >
                    {fileSize(props.file.size || 0)}
                </CellWithTooltip>
                <CellWithTooltip
                    title={convertToLocalDate(props.file.createdDate, "ISO", "dateTime")}
                    maxLength={20}
                    testId="file-created-cell"
                >
                    {convertToLocalDate(props.file.createdDate, "ISO", "date")}
                </CellWithTooltip>
                <TableCell>
                    <Tooltip {...getSelectTooltip()}>
                        <CategoriesSelectNoForm
                            name="target"
                            variant="filled"
                            value={currentGroup?.id || currentWorkspace.id}
                            options={options}
                            style={{ width: 250, backgroundColor: "inherit" }}
                            fullWidth
                            onChange={handleAccessibilityChange}
                            disabled={!mayManageFile || isExpired || props.file.status === FileStatus.Pending}
                        />
                    </Tooltip>
                </TableCell>
                <TableCell>
                    <div className={classes.tagsBox}>
                        {removableTags?.length ? removableTags.map((item) => <Tag key={item.key} item={item} />) : null}
                        {versionCodeTag ? <Tag key={versionCodeTag.key} item={versionCodeTag} theme="light" /> : null}
                        {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="extra" /> : null}
                        {!hasTags ? <Typography>-</Typography> : null}
                    </div>
                </TableCell>
                <TableCell align="right">
                    <div className={classes.buttonBox}>
                        <SecondaryButton
                            tooltipProps={getTagBtnTooltip()}
                            onClick={() => props.setSelectedFileId(props.file.id)}
                            disabled={isUploading || !mayManageTag || isExpired}
                        >
                            <i className="fa-solid fa-gear" />
                        </SecondaryButton>
                        <SecondaryButton
                            tooltipProps={{ title: "Show details" }}
                            onClick={() => setModalOpen(true)}
                        >
                            <i className="fas fa-info-circle" />
                        </SecondaryButton>
                        <SecondaryButton
                            tooltipProps={getDownloadBtnTooltip()}
                            onClick={handleDownload}
                            disabled={isUploading || loading.isFileDownloading || isExpired}
                            loading={loading.isFileDownloading}
                        >
                            <i className="fa-solid fa-download" />
                        </SecondaryButton>
                        <DangerOutlinedButton
                            tooltipProps={{
                                title: mayManageFile ? "Delete file" : "You role does not allow to delete files",
                            }}
                            onClick={() => setDeleteModalOpen(true)}
                            data-testid="file-remove-button"
                            disabled={!mayManageFile || loading.isFileDeleting}
                            loading={loading.isFileDeleting}
                        >
                            <i className="fa-solid fa-trash-alt" />
                        </DangerOutlinedButton>
                    </div>
                </TableCell>
            </TableRow>
            <ConfirmDialog
                title="Delete file"
                content={
                    <p>
                        Are you sure you want to delete{" "}
                        <span style={{ fontWeight: "bold" }}>{props.file.filename}</span>?
                    </p>
                }
                dangerButton
                primaryActionText="Delete"
                open={deleteModalOpen}
                onConfirm={handleDelete}
                onClose={() => setDeleteModalOpen(false)}
            />
            <FileDetailsModal isOpen={modalOpen} closeDialog={() => setModalOpen(false)} file={props.file} />
        </>
    );
};

export default TableItem;
