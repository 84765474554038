import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    productContainer: {
        minWidth: 450,
        maxWidth: "fit-content",
        border: "1px solid " + theme.palette.black[100],
        borderRadius: 6,
        padding: 16,
        marginTop: 24,
        backgroundColor: theme.palette.white[50],
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 16,
        marginBottom: 16,
    },
    productDetails: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    productInfo: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 20,
    },
    leftButtons: {
        display: "flex",
        gap: 6,
    },
    rightButton: {
        marginLeft: "auto",
    },
    label: {
        fontWeight: "bold",
        marginRight: 10,
        display: "flex",
        alignItems: "center",
    },
}));
