import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    adornment: {
        "& .MuiOutlinedInput-root": {
            paddingLeft: 0,
        },
        "& .MuiInputAdornment-root": {
            padding: "20px 14px",
            backgroundColor: theme.palette.divider,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
        pageTitleBox: {
            display: "flex",
        },
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    inputBox: {
        marginTop: 8,
    },
    alertBox: {
        display: "inline-block",
        marginTop: 12,
    },
    popoverContentCotainer: {
        display: "flex",
        flexDirection: "column",
    },
}));
