import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse } from "src/types";

import { unpackError } from "./helpers";

interface UpdatePayload {
    id: number;
    name: string;
    description: string | null;
    addedGroupIds?: number[];
    cancelled?: boolean;
}
type UpdateResponse = AxiosResponse<{ subscriptions?: [API.Subscription] }>;
export const updateSubscription = async (payload: UpdatePayload) => {
    const { id, ...rest } = payload;
    try {
        const endpoint = await RouteService.getSubscriptionsRoute();
        const { data } = await httpService.patch<Omit<UpdatePayload, "id">, UpdateResponse>(
            endpoint + String(id),
            rest,
        );
        return data.subscriptions || [];
    } catch (error) {
        throw unpackError(error);
    }
};
export const cancelSubscription = async (id: number) => {
    try {
        const endpoint = await RouteService.getSubscriptionsRoute();
        const { data } = await httpService.patch<{ cancelled: boolean }, UpdateResponse>(endpoint + String(id), {
            cancelled: true,
        });
        return data.subscriptions || [];
    } catch (error) {
        throw unpackError(error);
    }
};
export const claimStarterSubscription = async () => {
    try {
        const endpoint = await RouteService.getVouchersRoute();
        const { data } = await httpService.get<ApiResponse<API.Subscription>>(endpoint);
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
