import { InputHTMLAttributes } from "react";
import { UseFieldConfig, useField } from "react-final-form";

type Value = string | number | readonly string[] | undefined;
interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    config?: UseFieldConfig<Value, Value>;
}

export const FileSelectField = ({ name, config, ...rest }: Props) => {
    const { input } = useField(name, config);

    return (
        <input
            type="file"
            onChange={({ target }) => input.onChange(target.files)}
            {...rest}
            style={{ display: "none", ...rest.style }}
        />
    );
};
