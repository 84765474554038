import _ from "lodash";

import { Subscription } from "@dashboard/devices/types";

export const getDefaultSubscription = (subscriptions: Subscription[]) => {
    let bestNonStarterActiveSubscription: Subscription | null = null;
    let bestStarterSubscription: Subscription | null = null;
    let bestLicenseLimitSubscription: Subscription | null = null;
    let maxLicenseLimit = -1;

    _.orderBy(subscriptions, ["creationDate"], ["desc"]).forEach((subscription) => {
        const limit = subscription.limits.find((l) => l.name === "LicenseLimit")?.value || 0;

        if (subscription.status === "Active" && subscription.variantName !== "Starter") {
            bestNonStarterActiveSubscription = subscription;
        }
        if (subscription.variantName === "Starter") {
            bestStarterSubscription = subscription;
        }
        if (limit > maxLicenseLimit) {
            maxLicenseLimit = limit;
            bestLicenseLimitSubscription = subscription;
        }
    });

    return (
        bestNonStarterActiveSubscription || bestStarterSubscription || bestLicenseLimitSubscription || subscriptions[0]
    );
};
