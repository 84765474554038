import { RootState } from "src/store";

import { ListStatus } from "@dashboard/devices/store";
import { provisioningsAdapter } from "@dashboard/devices/store/helpers";
import { createSelector } from "@reduxjs/toolkit";

const defaultSelectors = provisioningsAdapter.getSelectors<RootState>((state) => state.provisionings.list.items);

const selectProvisioningListStatus = (state: RootState): ListStatus | null => {
    return state.provisionings.list.status;
};
const selectProvisionings = createSelector(
    [(state: RootState) => state.workspaces.currentWorkspaceId, defaultSelectors.selectAll],
    (workspaceId, allProvisionings) => {
        return allProvisionings.filter((item) => item.workspaceId === workspaceId);
    },
);

export { selectProvisioningListStatus, selectProvisionings };
