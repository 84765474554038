import clsx from "clsx";
import { useState } from "react";
import { createUseStyles } from "react-jss";

import { alternatingBg, deleteBorder } from "@dashboard/shared/styles";
import { useCommands } from "@devices/components/Commands/hooks";
import { Device } from "@devices/types";
import {
    DeviceMdmCommandStatusIcon,
    MdmCommand,
    MdmCommandOrigin,
    MdmCommandOriginIcon,
    MdmCommandStatus,
} from "@devices/types/commands";
import { convertToLocalDate } from "@devices/utils/dates";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { Tooltip } from "@shared/Tooltip";

import { getCommandName } from "./CommandTab";

const useStyles = createUseStyles({
    commandPropertyValue: {
        fontSize: "0.9rem",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    icons: {
        marginTop: "2px",
        marginRight: "8px",
        fontSize: "0.9rem",
        color: grey[500],
    },
    collapsingRowContent: {
        padding: 10,
        width: "100%",
        minHeight: 36,
        fontSize: "0.9rem",
        textAlign: "left",
        wordWrap: "break-word",
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    borderBottomVisible: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    customCollapse: {
        "& .MuiCollapse-wrapper": {
            maxWidth: "650px",
        },
    },
    deleteButton: {
        marginLeft: 6,
    },
    btnBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface DeviceCommandItemProps {
    command: MdmCommand;
    deviceId: Device["id"];
    index: number;
}

const DeviceCommandItem = ({ command, deviceId, index }: DeviceCommandItemProps) => {
    const classes = useStyles();
    const { id, payload, origin: commandOrigin, created, creator, status, updated } = command;
    const [open, setOpen] = useState(-1);

    const {
        createMdmCommand,
        deleteMdmCommand,
        loadingState: { createLoading, deleteLoading },
    } = useCommands();

    const getCommandOriginIcon = (origin: string) => {
        if (origin.startsWith(MdmCommandOrigin.DeviceOrigin)) {
            return MdmCommandOriginIcon[MdmCommandOrigin.DeviceOrigin];
        } else if (origin.startsWith(MdmCommandOrigin.GroupOrigin)) {
            return MdmCommandOriginIcon[MdmCommandOrigin.GroupOrigin];
        } else {
            return "fas fa-solid fa-question";
        }
    };

    const handleSubmitCommand = async () => {
        await createMdmCommand({ deviceId, json: JSON.parse(payload) });
    };

    const handleDeleteCommand = async () => {
        await deleteMdmCommand({ deviceId, command });
    };

    const createdDate = convertToLocalDate(created, "ISO", "dateTime");
    const lastUpdatedDate = convertToLocalDate(updated, "ISO", "dateTime");

    const commandJson = JSON.parse(payload);
    const commandPayload = JSON.stringify(commandJson, null, 2);

    const originTooltipText = (origin: string): string => {
        if (origin.startsWith("device")) {
            return "Created for device";
        } else if (origin.startsWith("group")) {
            return "Created for group";
        } else {
            return "";
        }
    };

    return (
        <>
            <TableRow key={id} style={alternatingBg(index)}>
                <TableCell align="center" style={deleteBorder(index, open)}>
                    <IconButton
                        className={classes.collapseBtn}
                        onClick={() => setOpen(open === index ? -1 : index)}
                        size="large"
                    >
                        {open === index ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell style={deleteBorder(index, open)}>
                    <Tooltip title={convertToLocalDate(created, "ISO", "dateTime")}>
                        <span>{convertToLocalDate(created, "ISO", "date")}</span>
                    </Tooltip>
                </TableCell>
                <TableCell align="center" style={deleteBorder(index, open)}>
                    <Tooltip placement="top" title={command.report || MdmCommandStatus[status]}>
                        <i
                            className={clsx(
                                classes.icons,
                                classes.commandPropertyValue,
                                DeviceMdmCommandStatusIcon[status] ?? "fas fa-solid fa-question",
                            )}
                        />
                    </Tooltip>
                </TableCell>
                <TableCell align="center" style={deleteBorder(index, open)}>
                    <Tooltip placement="top" title={originTooltipText(commandOrigin)}>
                        <i
                            className={clsx(
                                classes.icons,
                                classes.commandPropertyValue,
                                getCommandOriginIcon(commandOrigin),
                            )}
                        />
                    </Tooltip>
                </TableCell>
                <TableCell align="left" style={deleteBorder(index, open)} className={clsx(classes.overflow)}>
                    {getCommandName(payload)}
                </TableCell>
                <TableCell align="right" style={deleteBorder(index, open)}>
                    <Box className={classes.btnBox}>
                        <SecondaryButton
                            tooltipProps={{ title: "Resend command" }}
                            onClick={handleSubmitCommand}
                            disabled={createLoading}
                            loading={createLoading}
                        >
                            <i className="fa-solid fa-arrows-rotate" />
                        </SecondaryButton>
                        <DangerOutlinedButton
                            style={{ marginLeft: 6 }}
                            tooltipProps={{ title: "Delete command" }}
                            onClick={handleDeleteCommand}
                            disabled={command.status !== MdmCommandStatus.Pending || deleteLoading}
                            loading={deleteLoading}
                        >
                            <i className="fas fa-trash-alt" />
                        </DangerOutlinedButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow className={open === index ? classes.borderBottomVisible : ""} style={alternatingBg(index)}>
                <TableCell
                    colSpan={6}
                    className={clsx(classes.overflow, classes.customCollapse)}
                    style={{ paddingTop: 0, paddingBottom: 0, border: "0px", ...deleteBorder(index, open) }}
                >
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                        <div className={classes.collapsingRowContent}>
                            <span className={classes.collapsePropertyTitle}>Creator:</span> {creator}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Created:</span> {createdDate}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Updated:</span> {lastUpdatedDate}
                            <br />
                            <br />
                            <div>
                                <pre>{commandPayload}</pre>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DeviceCommandItem;
