import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { createFdroidRepo } from "@dashboard/devices/store";
import { CreateFdroidRepoPayload } from "@dashboard/provisioning/api";
import { DialogSubTitle, DialogTitle } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Dialog, DialogActions, DialogContent, InputAdornment } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { useStyles } from "../RepositoriesPage.style";
import { validationSchema } from "./CreateFdroidRepoDialog.validation";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

type FormValues = Omit<CreateFdroidRepoPayload, "id" | "subscriptionId">;

export const CreateFdroidRepoDialog = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentWorkspace = useSelector(selectWorkspace);
    const validate = useValidationSchema(validationSchema);

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        if (currentWorkspace?.subscription) {
            dispatch(createFdroidRepo({ subscriptionId: currentWorkspace?.subscription, ...values }))
                .unwrap()
                .then(() => {
                    form.restart();
                    showSuccessToast("Successfully created a repository");
                })
                .catch(({ message = "Failed to create a private repository. Please try again" }) => {
                    showErrorToast(message);
                })
                .finally(() => props.onClose());
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, form }) => (
                <Dialog open={props.isOpen} scroll="paper" maxWidth="sm" onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogContent>
                        <DialogTitle>Create F-Droid repository</DialogTitle>
                        <DialogSubTitle>
                            Create your own private F-Droid repository and use it to distribute custom Android
                            applications.
                        </DialogSubTitle>
                        <form>
                            <TextField
                                name="name"
                                label="Name"
                                fullWidth
                                className={classes.adornment}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            https://apps.emteria.com/fdroid/
                                        </InputAdornment>
                                    ),
                                }}
                                required
                            />
                            <TextField
                                className={classes.inputBox}
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                            />
                            <TextField className={classes.inputBox} name="title" label="Title" fullWidth />
                            <TextField
                                multiline
                                className={classes.inputBox}
                                name="description"
                                label="Description"
                                fullWidth
                            />
                        </form>
                        <DialogActions>
                            <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
                            <PrimaryButton onClick={handleSubmit}>Create</PrimaryButton>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
        />
    );
};
