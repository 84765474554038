import * as yup from "yup";

export const validationSchema = yup.object({
    name: yup
        .string()
        .min(3)
        .max(64)
        .matches(/^\w+$/, "Name must not contain special characters.")
        .required()
        .label("Name"),
    password: yup
        .string()
        .min(3)
        .max(64)
        .matches(
            /^[ \w\d.,:+#@-]*$/,
            "Password can only contain letters, numbers, spaces, and special characters like ., : + @ -.",
        )
        .required()
        .label("Password"),
    title: yup
        .string()
        .min(3)
        .max(64)
        .matches(
            /^[ \w\d.,:+#@-]*$/,
            "Title can only contain letters, numbers, spaces, and special characters like ., : + @ -.",
        )
        .required()
        .label("Title"),
    description: yup
        .string()
        .min(3)
        .max(64)
        .matches(
            /^[ \w\d.,:+#@-]*$/,
            "Description can only contain letters, numbers, spaces, and special characters like ., : + @ -.",
        )
        .required()
        .label("Description"),
});
