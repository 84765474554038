import { useCallback } from "react";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import { UncontrolledTextField } from "src/ui/shared/form/TextField";
import { showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        minWidth: 760,
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 8,
    },
    label: {
        minWidth: 120,
        maxWidth: 120,
    },
    copyButton: {
        marginLeft: 8,
    },
});

interface Props {
    file: API.ReleaseFile;
    isOpen: boolean;
    toggleDialog: VoidFunction;
}

export const InformationModal = ({ file, isOpen, toggleDialog }: Props) => {
    const classes = useStyles();

    const copyToClipboard = useCallback((text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={toggleDialog} scroll="paper" maxWidth="lg">
            <DialogTitle>Release details</DialogTitle>
            <DialogContent className={classes.container}>
                <div className={classes.textContainer}>
                    <Typography className={classes.label}>SHA256 hash:</Typography>

                    <UncontrolledTextField value={file.hashSha256} disabled fullWidth />
                    <SecondaryButton
                        className={classes.copyButton}
                        onClick={() => copyToClipboard(file.hashSha256)}
                        tooltipProps={{ title: "Copy" }}
                    >
                        <i className="fa-solid fa-copy" />
                    </SecondaryButton>
                </div>
                <div className={classes.textContainer}>
                    <Typography className={classes.label}>MD5 hash:</Typography>

                    <UncontrolledTextField value={file.hashMd5} disabled fullWidth />
                    <SecondaryButton
                        className={classes.copyButton}
                        onClick={() => copyToClipboard(file.hashMd5)}
                        tooltipProps={{ title: "Copy" }}
                    >
                        <i className="fa-solid fa-copy" />
                    </SecondaryButton>
                </div>
                <div className={classes.textContainer}>
                    <Typography className={classes.label}>Visibility:</Typography>
                    <UncontrolledTextField value={file.isPublic ? "Public" : "Private"} disabled fullWidth />
                </div>
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={toggleDialog}>Close</SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};
