import CryptoJS from "crypto-js";

export enum NotificationId {
    StarterSubscription = 1,
}

export const createRule = (email: string, id: NotificationId): string => {
    const emailHash = CryptoJS.MD5(email).toString();
    return `${emailHash}-${id}`;
};

type Payload = {
    rules: string[];
    email: string;
    notificationId: NotificationId;
};

export const isNotificationDisabled = (payload: Payload): boolean => {
    const { rules, email, notificationId } = payload;
    if (!rules.length) {
        return false;
    }

    const emailHash = CryptoJS.MD5(email).toString();
    return rules.some((r) => r === `${emailHash}-${notificationId}`);
};
