import { useEffect } from "react";

interface MetaTags {
    title: string;
    description: string;
}

const useMetaTags = (seo: MetaTags) => {
    useEffect(() => {
        document.title = seo.title;

        let metaDescription: HTMLMetaElement | null = document.querySelector("meta[name=\"description\"]");
        if (!metaDescription) {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            document.head.appendChild(metaDescription);
        }
        metaDescription.content = seo.description;

        return () => {
            if (metaDescription) {
                document.head.removeChild(metaDescription);
            }
        };
    }, [seo.title, seo.description]);
};

export default useMetaTags;
