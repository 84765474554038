import { useSelector } from "src/store";

import { selectBatchActionIsActiveDevice, selectBatchActionIsActiveLicense } from "@devices/store";
import { Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { TableHeader } from "@shared/table/TableHeader";

const useStyles = makeStyles((theme: Theme) => ({
    removeItemIcon: {
        cursor: "pointer",
        color: theme.palette.red[150],
        borderRadius: 5,
        border: "1px solid " + theme.palette.red[150],
        fontSize: "16px",
        boxSizing: "border-box",
        padding: 10,
    },
}));

interface PaneTableProps {
    firstColumn: string;
    tableItems: TableRowItem[];
    handleRemoveBatchItem: (id: number) => void;
}

interface TableRowItem {
    name?: string;
    activationCode?: string;
    id: number;
}

const PaneTable = ({ firstColumn, tableItems, handleRemoveBatchItem }: PaneTableProps) => {
    const classes = useStyles();
    const isBatchActionLicense = useSelector(selectBatchActionIsActiveLicense);
    const isBatchActionDevice = useSelector(selectBatchActionIsActiveDevice);

    return (
        <TableContainer component={Paper} style={{ overflow: "hidden" }}>
            <Table aria-label="simple table" style={{ tableLayout: "fixed" }}>
                <TableHeader>
                    <TableRow>
                        <TableCell align="left">{firstColumn}</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {tableItems.map(({ id, activationCode, name }) => {
                        return (
                            <TableRow key={id}>
                                <TableCell align="left">
                                    <div>{isBatchActionLicense || isBatchActionDevice ? activationCode : name}</div>
                                </TableCell>
                                <TableCell align="right">
                                    <DangerOutlinedButton
                                        className={classes.removeItemIcon}
                                        onClick={() => handleRemoveBatchItem(id)}
                                    >
                                        <i className="fa-solid fa-trash-alt" />
                                    </DangerOutlinedButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaneTable;
