import clsx from "clsx";
import { compare, compareVersions } from "compare-versions";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getReleasedImages } from "src/services/imageService";
import { LoadingIcon } from "src/ui/shared/Loading";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { Box, CardActions, CardContent, Container, Grid, List, ListItem, Typography, useTheme } from "@mui/material";
import { showErrorToast } from "@shared/toasts/Toasts";

import { Card } from "./CustomCard";
import { LightTooltip, useStyles } from "./ReleaseNotesDevicePage.style";
import { ReleasedImage } from "./types";

const getImage = (images: ReleasedImage[]): ReleasedImage => {
    const liveImage = images?.find((i) => i.channel === "live");
    const betaImage = images?.find((i) => i.channel === "beta");
    return liveImage || betaImage || images[0];
};

const getMajorReleaseVersion = (image: ReleasedImage) => {
    return parseInt(image.version.split(".")[0], 10) || 0;
};

const getChannelTooltip = (channel: ReleasedImage["channel"]) => {
    switch (channel) {
        case "live":
            return "Most tested OS releases, recommended for everyday use";
        case "nightly":
            return "Automatic builds of the latest state of the code base";
        case "beta":
            return "Early access version of next planned release builds";
        case "none":
            return "Deprecated releases, temporarily kept for their changelog";
        default:
            return "";
    }
};

interface Accumulator {
    [key: string]: ReleasedImage;
}
const getLatestReleaseByMajorVersion = (images: ReleasedImage[]) => {
    const latestReleaseMap = images.reduce((acc: Accumulator, image) => {
        const majorVersion = getMajorReleaseVersion(image);

        const key = `${majorVersion}-${image.channel}`;
        if (!acc[key] || compare(image.version, acc[key].version, ">")) {
            acc[key] = image;
        }

        return acc;
    }, {});

    return Object.values(latestReleaseMap);
};

const ReleaseNotesDevicePage = () => {
    const theme = useTheme();
    const params = useParams();
    const classes = useStyles();

    const [selectedDeviceImages, setSelectedDeviceImages] = useState<ReleasedImage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const image = getImage(selectedDeviceImages);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const releasedImages = await getReleasedImages();

                const filteredImages = releasedImages.filter(
                    (i) => i.codename === params.device && i.channel !== "none",
                );
                const sortedImages = getLatestReleaseByMajorVersion(filteredImages).sort((a, b) =>
                    compareVersions(b.version, a.version),
                );
                setSelectedDeviceImages(sortedImages);
            } catch (error) {
                showErrorToast("Failed fetching released images");
            } finally {
                setLoading(false);
            }
        };

        void fetchData();
    }, [params.device]);

    if (loading) {
        return (
            <Box className={classes.container}>
                <LoadingIcon className={classes.spinner} size={IconSize.large} />
            </Box>
        );
    }

    if (selectedDeviceImages.length === 0) {
        return (
            <Box className={classes.container}>
                <Typography className="text-center" variant="h5" gutterBottom>
                    No release notes found for this device.
                </Typography>
            </Box>
        );
    }

    return (
        <Container className={classes.container} maxWidth={false}>
            <Grid
                container
                spacing={1}
                className={classes.centeredGrid}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid className={classes.gridItem} item sm={12} md={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.boldTitle} variant="body1" gutterBottom>
                                Device name in emteria Installer
                            </Typography>
                            <Box>
                                <Typography variant="body2">{selectedDeviceImages[0].codename}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid className={classes.gridItem} item sm={12} md={6}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.boldTitle} variant="body1" gutterBottom>
                                Latest stable Android version
                            </Typography>
                            <Box>
                                <Typography variant="body2">
                                    {`Android ${getMajorReleaseVersion(image)} | emteria version ${image.version} | `}
                                    <LightTooltip placement="top" title={getChannelTooltip(image.channel)}>
                                        <span>{image.channel}</span>
                                    </LightTooltip>
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid className={classes.gridItem} item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.boldTitle} variant="body1">
                                Change log
                            </Typography>
                            <Box style={{ marginLeft: "0.8rem" }}>
                                <List style={{ listStyleType: "disc" }}>
                                    {image.changes.map((change, index) => (
                                        <ListItem key={index} style={{ display: "list-item" }} disableGutters>
                                            <Typography variant="body2">{change}</Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid className={classes.gridItem} item xs={12}>
                    <Card className={clsx(classes.card, classes.downloadCard)}>
                        <a href="/register" target="_blank">
                            <Box style={{ color: theme.palette.blue[300] }}>
                                <CardContent>
                                    <Typography className={classes.boldTitle} variant="body1" gutterBottom>
                                        Official download
                                    </Typography>
                                    <Box>
                                        <Typography variant="body2">
                                            Register to download in emteria installer
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions style={{ justifyContent: "end" }}>
                                    <Icon
                                        size={IconSize.large}
                                        name="fas fa-fw fa-download"
                                        className={classes.downloadIcon}
                                    />
                                </CardActions>
                            </Box>
                        </a>
                    </Card>
                    <Box style={{ float: "right", marginTop: 15 }}>
                        <a style={{ color: theme.palette.blue[150] }} href="/login" target="_blank">
                            Login to download
                        </a>
                    </Box>
                </Grid>
                <Grid className={classes.gridItem} item xs={12}>
                    <Typography className={classes.availableAndroidVersionsTitle} variant="body1" gutterBottom>
                        AVAILABLE ANDROID VERSIONS
                    </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {selectedDeviceImages.map((i, index) => (
                        <Grid className={classes.gridItem} key={index} item xs={12} sm={4}>
                            <Box className={classes.card}>
                                <Typography variant="body1" gutterBottom>
                                    Android {getMajorReleaseVersion(i)}
                                </Typography>
                                <Typography variant="body2">
                                    {`Version ${i.version} | `}
                                    <LightTooltip placement="top" title={getChannelTooltip(i.channel)}>
                                        <span>{i.channel}</span>
                                    </LightTooltip>
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReleaseNotesDevicePage;
