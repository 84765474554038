import { FormApi } from "final-form";
import { Form } from "react-final-form";
import semver from "semver";
import { useDispatch, useSelector } from "src/store";
import { TextareaField } from "src/ui/shared/form/TextareaField";

import { BuildConfig } from "@dashboard/products/api/index";
import {
    buildVariantOptions,
    defaultBuildVariantOption,
} from "@dashboard/products/components/ProductDetails/fragments/Recipes";
import { InputItemLabel } from "@dashboard/products/components/ProductDetails/styles";
import { createBuild } from "@dashboard/products/store/index";
import { selectBuilds } from "@dashboard/products/store/selectors/builds";
import { Build, Product } from "@dashboard/products/types/index";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    InputAdornment,
    SelectChangeEvent,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { ControlledMultiSelectField, SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";

import { BuildVariantLabel } from "./shared";

const useStyles = makeStyles((theme: Theme) => ({
    versionBox: {
        marginBottom: 4,
    },
    inputContainer: {
        display: "flex",
        alignItems: "center",
        gap: 30,
        marginTop: 12,
    },
    subtitle: {
        padding: "0 24px 0",
    },
    hintsBox: {
        textAlign: "end",
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
        gap: theme.spacing(0.5),
    },
    hint: {
        fontSize: 12,
    },
    inputLabel: {
        marginBottom: 4,
        minWidth: 140,
    },
    fromVersionContainer: {
        width: "100%",
    },
    variantLabel: {
        marginBottom: 4,
        minWidth: 140,
        display: "flex",
        alignItems: "center",
    },
}));

type Props = Omit<DialogProps, "onClose"> & {
    onClose: () => void;
    product: Product;
};
type FormValues = BuildConfig;
type SelectHandler = (e: SelectChangeEvent<unknown>, form: FormApi<FormValues, Partial<FormValues>>) => void;
type TextAreaHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    form: FormApi<FormValues, Partial<FormValues>>,
) => void;

const getNextVersion = (builds: Build[], androidVersion: string): string => {
    const relevantBuilds = builds.map((build) => build.version).sort(semver.compare);

    if (relevantBuilds.length === 0) {
        return `${androidVersion}.0.0`;
    }

    const latestVersion = relevantBuilds[relevantBuilds.length - 1];

    const nextVersion = semver.inc(latestVersion, "patch");

    return nextVersion || `${androidVersion}.0.0`;
};
const validate = (values: FormValues, androidVersion: string) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    const versionPattern = new RegExp(`^${androidVersion}\\.\\d+\\.\\d+$`);
    if (!versionPattern.test(values.version)) {
        errors.version = `Version must be in the format ${androidVersion}.X.Y, where X and Y are numbers.`;
    }

    if (values.rolloutPercentage < 0 || values.rolloutPercentage > 100) {
        errors.rolloutPercentage = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};

export const ConfigurationModal = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const productAndroidVersion = props.product.androidVersion.slice(0, 2);
    const builds = useSelector(selectBuilds)
        ?.filter((build) => build.productId === props.product.id)
        .filter((build) => build.buildStatus === "succeeded");

    const fromVersionBuilds = builds.filter((build) => build.artifacts && build.artifacts.includes("Manifest"));
    const preselectedVersion = getNextVersion(builds, productAndroidVersion);
    const fromVersionOptions = [
        { label: "Create new build based on the latest state", value: "empty" },
        ...fromVersionBuilds.map((build) => ({
            label: build.version || "",
            value: build.version || "",
        })),
    ];
    const incrementalFromVersionsOptions = builds
        .filter((build) => build.artifacts && build.artifacts.includes("SignedFiles"))
        .map((build) => ({ label: build.version || "", value: build.version || "" }));

    const handleVersionChange: SelectHandler = (e, form) => {
        if (typeof e.target.value !== "string") {
            return;
        }
        if (e.target.value === "empty") {
            form.change("configSnapshot", undefined);
        } else {
            const selectedBuild = fromVersionBuilds.find((build) => build.version === e.target.value);
            if (selectedBuild?.configSnapshot) {
                form.change("configSnapshot", selectedBuild.configSnapshot);
            }
        }
    };
    const handleChangesChange: TextAreaHandler = (e, form) => {
        if (e.target.value.length) {
            form.change("changes", [e.target.value]);
        } else {
            form.change("changes", []);
        }
    };
    const onSubmit = (values: FormValues) => {
        const filteredValues = { ...values };

        if (filteredValues.configOverride?.manifest?.fromVersion === "empty") {
            delete filteredValues.configOverride.manifest;
        }

        if (filteredValues.configOverride.buildVariant === props.product.customBuildConfig?.buildVariant) {
            delete filteredValues.configOverride.buildVariant;
        }

        dispatch(createBuild({ productId: props.product.id, config: filteredValues }))
            .unwrap()
            .catch(({ message = "Something went wrong during creating new build" }) => showErrorToast(message))
            .finally(() => props.onClose());
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        const { submitting, active } = form.getState();

        if (e.key === "Enter" && !submitting && !active) {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            initialValues={{
                version: preselectedVersion,
                rolloutPercentage: 100,
                configOverride: {
                    manifest: { fromVersion: "empty" },
                    ota: { incrementalFromVersions: [] },
                    buildVariant: defaultBuildVariantOption,
                },
            }}
            validate={(values) => validate(values, productAndroidVersion)}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, form }) => (
                <Dialog fullWidth {...props} onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogTitle>New OS build</DialogTitle>
                    <Typography variant="body1" className={classes.subtitle}>
                        Initiate a new OS build by clicking &quot;Start&quot;. Build configuration parameters below are
                        all optional.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <Box className={classes.inputContainer}>
                                <InputItemLabel className={classes.inputLabel}>OS version</InputItemLabel>
                                <TextField name="version" fullWidth style={{ marginBottom: 4 }} />
                            </Box>
                            <div className={classes.hintsBox}>
                                <Typography className={classes.hint}>
                                    Semantic version number in form &lt;Android
                                    Version&gt;.&lt;number&gt;.&lt;number&gt;
                                </Typography>
                            </div>

                            <Box className={classes.inputContainer}>
                                <InputItemLabel className={classes.inputLabel}>Source config</InputItemLabel>
                                <SelectField
                                    fullWidth
                                    name="configOverride.manifest.fromVersion"
                                    options={fromVersionOptions}
                                    onChange={(e) => handleVersionChange(e, form)}
                                />
                            </Box>

                            <Box className={classes.inputContainer}>
                                <InputItemLabel className={classes.inputLabel}>Delta updates</InputItemLabel>
                                <Tooltip
                                    className={classes.fromVersionContainer}
                                    placement="top"
                                    hide={!!incrementalFromVersionsOptions.length}
                                    title="There are no versions to choose from"
                                >
                                    <ControlledMultiSelectField
                                        name="configOverride.ota.incrementalFromVersions"
                                        options={incrementalFromVersionsOptions}
                                        disabled={!incrementalFromVersionsOptions.length}
                                    />
                                </Tooltip>
                            </Box>
                            <div className={classes.hintsBox}>
                                <Tooltip title="A new build will always generate a full update that can be installed on any device. To generate incremental updates for specific versions, you must select those versions here">
                                    <Typography className={classes.hint}>
                                        Version numbers used as base for incremental updates
                                    </Typography>
                                </Tooltip>
                            </div>

                            <Box className={classes.inputContainer}>
                                <InputItemLabel className={classes.inputLabel}>Changelog</InputItemLabel>
                                <TextareaField
                                    name="changes"
                                    minRows={5}
                                    onChange={(e) => handleChangesChange(e, form)}
                                    placeholder={
                                        "- Fixed bug in network module\n- Improved battery performance\n- Added support for new hardware"
                                    }
                                />
                            </Box>

                            <Box className={classes.inputContainer}>
                                <InputItemLabel className={classes.inputLabel}>Rollout</InputItemLabel>
                                <TextField
                                    name="rolloutPercentage"
                                    type="number"
                                    fullWidth
                                    required
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </Box>

                            <Box className={classes.inputContainer}>
                                <BuildVariantLabel />
                                <SelectField
                                    fullWidth
                                    name="configOverride.buildVariant"
                                    options={buildVariantOptions}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <SecondaryButton onClick={props.onClose} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={submitting} loading={submitting}>
                                Start
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};
