import { useReducer, useState } from "react";
import { createUseStyles } from "react-jss";
import authService from "src/services/authService";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch, useSelector } from "src/store";
import { selectGroupsMembersByWorkspace } from "src/ui/containers/dashboard/groups/store/selectors";
import { updateWorkspaceMembers } from "src/ui/containers/dashboard/workspaces/store";
import { MinimalTableHeaderCell } from "src/ui/shared/table/MinimalTableComponents";
import { TableHeader } from "src/ui/shared/table/TableHeader";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { GroupMember, GroupRoles } from "@dashboard/devices/types";
import { AddMemberDialog } from "@dashboard/shared/components/AddMemberDialog";
import { SectionContainer, SectionHeader } from "@dashboard/shared/styles";
import { Paper, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { PrimaryButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { MemberListItem } from "../../shared/components/MemberListItem";
import { selectWorkspace, selectWorkspaceMemberRoleByEmail } from "../store/selectors";
import { Workspace } from "../types";
import { GuestMemberListItem } from "./GuestMemberListItem";

const useStyles = createUseStyles({
    container: { marginBottom: 10, marginTop: 16 },
    btnsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 20,
    },
});

interface Props {
    workspace: Workspace;
    onRerenderRequest: () => void;
}
export const MembersTable = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useReducer((state) => !state, false);
    const [removedMembers, setRemovedMembers] = useState<string[]>([]);
    const [changedMembers, setChangedMembers] = useState<{ [x: string]: GroupRoles }>({});

    const classes = useStyles();
    const dispatch = useDispatch();
    const profile = authService.getCurrentUser();
    const isExpired = useSelector(selectSubscriptionExpired);
    const currentWorkspace = useSelector(selectWorkspace);
    const currentUserRole = useSelector((state: RootState) =>
        selectWorkspaceMemberRoleByEmail(state, currentWorkspace?.id, profile?.email || null),
    );
    const { workspaceAbility } = permissionService();
    const mayManageMember = workspaceAbility(currentWorkspace).can("manage", "Member");

    const allMembers = useSelector(selectGroupsMembersByWorkspace);
    const isCurrentUserRemoved = removedMembers.includes(profile?.email || "");

    const disableSaveMembers = !removedMembers.length && !Object.keys(changedMembers).length;

    const guestMembers: GroupMember[] = allMembers
        .filter((member) => {
            const isAlreadyMember = props.workspace.members.some((m) => m.email === member.email);
            return !isAlreadyMember;
        })
        .map((member) => ({
            ...member,
            role: "Guest",
        }));

    const sortedMembers = [...props.workspace.members].sort((a, b) => {
        return a.email.localeCompare(b.email);
    });
    const sortedGuestMembers = [...guestMembers].sort((a, b) => a.email.localeCompare(b.email));

    const onRoleChange = (role: GroupRoles, email: string) => {
        const newChangedMembers = Object.assign({}, changedMembers);
        newChangedMembers[email] = role;
        setChangedMembers(newChangedMembers);
    };
    const onRemove = (checked: boolean, email: string) => {
        if (checked) {
            setRemovedMembers((prev) => [...prev, email]);
        } else {
            setRemovedMembers((prev) => prev.filter((member) => member !== email));
        }
    };
    const onSave = () => {
        setLoading(true);
        dispatch(
            updateWorkspaceMembers({
                workspaceId: props.workspace.id,
                removedMembers,
                changedMembers,
            }),
        )
            .unwrap()
            .then(() => showSuccessToast("Members updated"))

            .catch(({ message }) => {
                showErrorToast(message || "Failed to update members");
                props.onRerenderRequest();
            })
            .finally(() => {
                setLoading(false);
                setRemovedMembers([]);
                setChangedMembers({});
            });
    };
    const isAllowedDeleteCurrentUser = () => {
        return removedMembers.some((email) => email === profile?.email);
    };
    const isSaveButtonDisabled = () => {
        if (loading) {
            return true;
        }
        if (isAllowedDeleteCurrentUser()) {
            return false;
        }
        if (!mayManageMember) {
            return true;
        }
        if (isCurrentUserRemoved) {
            return false;
        }
        if (disableSaveMembers) {
            return true;
        }
        return false;
    };

    return (
        <SectionContainer>
            <SectionHeader>Members of this workspace</SectionHeader>
            <TableContainer component={Paper} className={classes.container}>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <MinimalTableHeaderCell align="left">E-mail</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell align="left">Role</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {sortedMembers.map((member, index) => (
                            <MemberListItem
                                index={index}
                                member={member}
                                loading={loading}
                                key={member.id}
                                handleRole={onRoleChange}
                                handleRemove={onRemove}
                                groupId={null}
                                removeInProgress={removedMembers.includes(member.email)}
                            />
                        ))}
                        {sortedGuestMembers.map((guest) => (
                            <GuestMemberListItem key={guest.id} member={guest} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className={classes.btnsContainer}>
                <PrimaryOutlinedButton
                    disabled={!mayManageMember || isExpired}
                    onClick={setDialogOpen}
                    tooltipProps={{ title: "Subscription expired", hide: !isExpired }}
                >
                    Add member
                </PrimaryOutlinedButton>
                <PrimaryButton onClick={onSave} disabled={isSaveButtonDisabled()} loading={loading}>
                    Save
                </PrimaryButton>
            </div>

            <AddMemberDialog
                id={props.workspace.id}
                name="workspace"
                ownRole={currentUserRole}
                open={dialogOpen}
                onClose={setDialogOpen}
            />
        </SectionContainer>
    );
};
