import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        marginTop: 15,
        marginBottom: 30,
        justifyContent: "space-between",
        gap: 10,
    },
    cardHeader: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    changeLog: {
        marginLeft: 8,
    },
    tableHeaders: {
        fontWeight: "bold",
    },
    noImagesBox: {
        display: "flex",
        flex: 1,
        marginTop: 36,
        justifyContent: "center",
    },
    flexContainer: {
        display: "flex",
        flexGrow: 1,
    },
    expandMoreIcon: {
        marginRight: 10,
    },
    logsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        backgroundColor: theme.palette.shadow[50],
        padding: "0px 6px",
    },
}));
