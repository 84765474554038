import "@fortawesome/fontawesome-free/css/all.css";
import "flag-icon-css/css/flag-icon.css";
import "react-toastify/dist/ReactToastify.css";

import TagManager from "react-gtm-module-custom-domain";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "src/providers/ThemeProvider";
import store from "src/store";
import Router from "src/ui/navigation/Router";

import { getEnv } from "./services/configService";
import euporiaService from "./services/euporiaService";
import { SubscriptionExpirationBanner } from "./ui/containers/dashboard/workspaces/components/SubscriptionExpirationBanner";
import { Navigation } from "./ui/navigation/Navigation";

euporiaService.initApmContext();
euporiaService.updateUserContext();
euporiaService.updateCustomContext();
euporiaService.initIdleTracker();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") || undefined;

if (getEnv() !== "Development") {
    TagManager.initialize({
        gtmId: "GTM-THMCS39K",
        customURL: "https://gtm.emteria.com/gtm.js",
    });
}

const App = () => (
    <ReduxProvider store={store}>
        <BrowserRouter basename={baseUrl}>
            <ThemeProvider>
                <SubscriptionExpirationBanner />
                <ToastContainer style={{ top: "74px", right: "10px" }} theme="colored" stacked limit={5} />
                <Navigation>
                    <Router />
                </Navigation>
            </ThemeProvider>
        </BrowserRouter>
    </ReduxProvider>
);

export default App;
