import _ from "lodash";
import { RootState } from "src/store";

import { localState } from "@dashboard/devices/store";
import { Group, License } from "@dashboard/devices/types";
import { selectGroupById } from "@dashboard/groups/store/selectors";
import { selectLicenseById, selectLicensesByGroupId } from "@dashboard/licenses/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

import { selectSubscriptionVariantByGroupId, selectSubscriptionVariantByLicenseId } from "./subscriptions";

type EntityTypes = "subscription" | "group" | "license" | "device";
export type BatchActionState = {
    isActive: boolean;
    type: "move" | null;
    entity: EntityTypes | null;
    selection: number[] | number[];
    targetId: number | null;
};
export const selectBatchState = (state: RootState): BatchActionState => localState(state).batchAction;
export const selectBatchActionIsActive = (state: RootState): boolean => selectBatchState(state).isActive;
export const selectBatchActionEntity = (state: RootState): EntityTypes | null => selectBatchState(state).entity;
export const selectBatchActionType = (state: RootState): BatchActionState["type"] => selectBatchState(state).type;
export const selectBatchActionSelection = (state: RootState): number[] => selectBatchState(state)?.selection ?? [];
export const selectBatchActionSelectionTransformed = createSelector([selectBatchActionSelection], (selected) => {
    return _.mapValues(_.keyBy(selected), () => true);
});
export const selectBatchActionTargetId = (state: RootState): number | null => selectBatchState(state)?.targetId;

export const selectBatchActionIsEntityLicense = createSelector(
    selectBatchActionEntity,
    (entity) => entity === "license",
);
export const selectBatchActionIsEntityDevice = createSelector(selectBatchActionEntity, (entity) => entity === "device");
export const selectBatchActionIsEntityGroup = createSelector(selectBatchActionEntity, (entity) => entity === "group");
export const selectBatchActionIsTypeMove = createSelector(selectBatchActionType, (type) => type === "move");
export const selectBatchActionIsActiveLicense = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityLicense,
    (isActive, isLicense) => isActive && isLicense,
);
export const selectBatchActionIsActiveDevice = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityDevice,
    (isActive, isDevice) => isActive && isDevice,
);
export const selectBatchActionIsActiveGroup = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityGroup,
    (isActive, isGroup) => isActive && isGroup,
);
export const selectBatchActionSelectionEntities = createSelector(
    selectBatchActionSelection,
    selectBatchActionIsActiveGroup,
    selectBatchActionIsActiveLicense,
    (state: RootState) => state,
    (selection, isGroup, isLicense, state) => {
        return selection
            .map((id) => {
                if (isGroup) {
                    return selectGroupById(state, id);
                } else if (isLicense) {
                    return selectLicenseById(state, id);
                }
                return null;
            })
            .filter((item) => !!item) as License[] | Group[];
    },
);

// returns the subscription variant of  the first license with device,
// so that other licenses could check for alignment with it
export const selectBatchActionSubscriptionVariant = createSelector(
    selectBatchActionSelectionEntities,
    selectBatchActionIsActiveGroup,
    selectBatchActionIsActiveLicense,
    (state: RootState) => state,
    (selectionEntities, isGroup, isLicense, state) => {
        if (selectionEntities.length === 0) {
            return null;
        }

        let selectionWithDevice: Group | License | null = null;
        if (isGroup) {
            const selection = (selectionEntities as Group[]).find(
                (group) => selectLicensesByGroupId(state, group.id).filter((license) => license.device).length > 0,
            );
            selectionWithDevice = selection ?? selectionWithDevice;
        } else if (isLicense) {
            const selection = (selectionEntities as License[]).find((license) => license.device);
            selectionWithDevice = selection ?? selectionWithDevice;
        }
        const id = selectionWithDevice?.id;

        if (!id) {
            return null;
        }

        if (isGroup) {
            return selectSubscriptionVariantByGroupId(state, id);
        } else if (isLicense) {
            return selectSubscriptionVariantByLicenseId(state, id);
        } else {
            return null;
        }
    },
);

export const selectBatchActionIsLicenseSelected = createSelector(
    (_state: RootState, licenseId: number) => licenseId,
    selectBatchActionSelection,
    (licenseId, selection) => {
        return selection.includes(licenseId);
    },
);
export const selectBatchActionIsLicenseSelectable = createSelector(
    (_state: RootState, license: License) => license,
    selectBatchActionSelection,
    selectBatchActionSubscriptionVariant,
    (state: RootState) => state,
    (license, selection, subscriptionVariant, state) => {
        // licenses with devices should have check for same subscription variant
        if (license.device) {
            const parentSubscriptionVariant = selectSubscriptionVariantByLicenseId(state, license.id);
            // nothing selected yet, should be allowed to check
            if (selection.length === 0) {
                return true;
            } else if (subscriptionVariant && parentSubscriptionVariant) {
                return subscriptionVariant === parentSubscriptionVariant;
            } else {
                return true;
            }
        } else {
            return true;
        }
    },
);
