import clsx from "clsx";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { Sort } from "@dashboard/files/components/UploadTable/UploadTable.utils";
import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";

import { CustomBadge } from "../CustomBadge";
import { useStyles } from "./TableHeader.style";

type Item = {
    id: string;
    label: string;
};

interface TableHeaderProps {
    item: Item;
    sort?: Sort<string>;
    activeFiltersCount?: number;
    onSort?: (orderBy: string) => void;
    onFilter?: (item: Item) => void;
    cellProps?: TableCellProps;
    sortableColumnIds?: string[];
    filterableColumnIds?: string[];
}

export const TableHeaderCell = (props: TableHeaderProps) => {
    const classes = useStyles();
    const isSortable = props.sortableColumnIds?.includes(props.item.id);
    const isFilterable = props.filterableColumnIds?.includes(props.item.id);

    const onSort = () => {
        if (props.onSort) {
            props.onSort(props.item.id);
        }
    };
    const onFilter = () => {
        if (props.onFilter) {
            props.onFilter(props.item);
        }
    };

    if (isSortable) {
        return (
            <TableCell
                align="left"
                className={clsx(props.item.id === "contentType" ? classes.typeCell : "")}
                sortDirection={props.sort?.orderDirection}
                {...props.cellProps}
            >
                <TableSortLabel
                    active={props.sort?.orderBy === props.item.id}
                    onClick={onSort}
                    className={classes.sortLabel}
                    direction={props.sort?.orderDirection}
                >
                    {props.item.label}
                </TableSortLabel>
            </TableCell>
        );
    }
    if (isFilterable) {
        return (
            <TableCell align="left" {...props.cellProps}>
                {props.item.label}
                <CustomBadge
                    className={clsx(classes.badge, classes.withPointer)}
                    badgeContent={props.activeFiltersCount}
                    onClick={onFilter}
                    data-testid="tag-filter-button"
                    overlap="rectangular"
                >
                    <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
                </CustomBadge>
            </TableCell>
        );
    }

    return (
        <TableCell align="left" {...props.cellProps}>
            {props.item.label}
        </TableCell>
    );
};
