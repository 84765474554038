import React, { useState } from "react";
import { updateStripeCard } from "src/services/accountService";
import authService from "src/services/authService";
import { PrimaryButton } from "src/ui/shared/CustomButton";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const useStyles = makeStyles({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 5,
        paddingTop: 10,
        paddingRight: 5,
    },
    content: {
        paddingBottom: 15,
        paddingTop: 0,
    },
    inputBox: {
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
        padding: "10px 0",
    },
    submit: {
        marginTop: 10,
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
}

const EditStripeCard = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [consent, setConsent] = useState(false);

    const stripe = useStripe();
    const classes = useStyles();
    const elements = useElements();

    const toggleConsent: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConsent(e.currentTarget.checked);
    };

    const doSubmit = async (paymentMethodId: string | undefined) => {
        if (!paymentMethodId) {
            showErrorToast("Payment method ID is missing.");
            setLoading(false);
            return;
        }

        try {
            const { message } = await updateStripeCard(paymentMethodId);
            showSuccessToast(message);
            setSuccess(true);
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        const cardElement = elements?.getElement(CardElement);

        if (!stripe || !elements || !cardElement) {
            return;
        }

        e.preventDefault();
        setLoading(true);

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                email: authService.getCurrentUser()?.email,
            },
        });

        if (result.error) {
            setLoading(false);
            showErrorToast(result.error.message);
        } else {
            await doSubmit(result.paymentMethod?.id);
        }
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle className={classes.title}>
                <h4>Change default card</h4>
                <IconButton onClick={props.onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div className={classes.inputBox}>
                    <input type="checkbox" disabled={loading} onChange={toggleConsent} style={{ marginTop: "4px" }} />

                    <Typography>
                        I hereby acknowledge that this card will be used for future off-session payments.
                    </Typography>
                </div>
                <form onSubmit={handleSubmit}>
                    <CardElement />
                    <PrimaryButton
                        type="submit"
                        className={classes.submit}
                        disabled={loading || success || !consent}
                        loading={loading && !success}
                        fullWidth
                    >
                        {!loading && success ? <i className="far fa-check-circle" /> : "Update"}
                    </PrimaryButton>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditStripeCard;
