import http from "./httpService";
import RouteService from "./routeService";

let dataFetched = false;
let stripePromise, stripeConfig;

const subscriptionPlans = {
    starterPackage: {
        name: "starterPackage",
        title: "Starter",
        description:
            "Specifically designed with private users and PoC development in mind. Activate emteria.OS, enable OTA updates and MDM.",
        price: 0,
        btnText: "Start for free",
        isQuantitative: false,
        opensForm: false,
    },
    basicPackage: {
        name: "basicPackage",
        title: "Basic",
        description:
            "Build your IoT products based on emteria.OS. Provide regular OTA updates for each device to keep them secure and up to date.",
        price: 149000,
        btnText: "Contact sales",
        isQuantitative: true,
        availableQuantity: "100, 500 or 1.000 devices",
        opensForm: true,
    },
    premiumPackage: {
        name: "premiumPackage",
        title: "Premium",
        description:
            "All features to remotely manage, monitor and control an Android device fleet from a browser with emteria Device Hub.",
        price: 349000,
        btnText: "Contact sales",
        favorite: true,
        isQuantitative: true,
        availableQuantity: "100, 500 or 1.000 devices",
        opensForm: true,
    },
    enterprisePackage: {
        name: "enterprisePackage",
        title: "Enterprise",
        description:
            "Receive an individual offer that fits your needs. It can include additional features, a custom version of emteria.OS and more.",
        list: [
            "Priority customer service",
            "Custom builds and features",
            "Guaranteed service availability",
            "Fleet analytics on request",
        ],
        btnText: "Contact sales",
        isQuantitative: true,
        availableQuantity: "starting at 1.000",
        opensForm: true,
    },
};

const fetchPaymentData = async () => {
    try {
        const endpoint = await RouteService.getPaymentRoute();
        const { data } = await http.get(endpoint + "info");
        stripeConfig = data.paymentConfig.stripeConfiguration;

        const priceData = data.subscriptionPlansPrice;
        Object.keys(subscriptionPlans).forEach((planKey) => {
            const priceKey = planKey + "Price";
            if (priceKey in priceData) {
                subscriptionPlans[planKey].price = priceData[priceKey];
            }
        });

        dataFetched = true;
    } catch (ex) {
        // we ignore this for now
    }
};

const getPlans = async () => {
    if (!dataFetched) {
        await fetchPaymentData();
    }

    return subscriptionPlans;
};

const getStripeConfig = async () => {
    if (!dataFetched) {
        await fetchPaymentData();
    }

    return stripeConfig;
};

const getStripePromise = async (loadStripe) => {
    if (!dataFetched) {
        await fetchPaymentData();
    }

    if (!stripePromise) {
        loadStripe.setLoadParameters({ advancedFraudSignals: false });
        stripePromise = await loadStripe(stripeConfig.publishableKey);
    }

    return stripePromise;
};

const paymentService = {
    getPlans,
    getStripeConfig,
    getStripePromise,
};

export default paymentService;
