import { AppPermission, AppPreference, Provisioning, SystemSetting } from "../types";

export const optionsSystemSettingSections: SystemSetting["section"][] = ["Global", "Secure", "System"];
export const optionsType: AppPreference["type"][] = ["Bool", "Float", "Int", "Long", "Set", "String"];
export const optionsAction: AppPermission["action"][] = ["Grant", "Revoke"];
export const defaultProvisioning: Omit<Provisioning & { provisioningId: number | "new" }, "id" | "workspaceId"> = {
    name: "",
    provisioningId: "new",
    settings: {
        acceptedEula: false,
        appPermissions: [],
        appPreferences: [],
        authorizedSshKeys: [],
        automaticSetup: false,
        bootAnimationUrl: "",
        deviceManager: null,
        disabledComponents: [],
        enableAdbServer: false,
        enableBluetooth: false,
        enableMdmClient: false,
        enableSftpServer: false,
        enableSshServer: false,
        enableVncServer: false,
        enableWifi: false,
        fDroidRepos: [],
        fontScaling: 0,
        hostedApks: [],
        orientationLock: false,
        screenDensity: 0,
        screenFrequency: 0,
        screenHeight: 0,
        screenRotation: 0,
        screenWidth: 0,
        systemProperties: [],
        systemSettings: [],
        universalLicenses: [],
        wallpaperUrl: "",
    },
};
