import clsx from "clsx";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import useQRCodeGenerator from "react-hook-qrcode-svg";
import { useSelector } from "react-redux";
import { selectLicenses } from "src/ui/containers/dashboard/licenses/store/selectors";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { UncontrolledSelectField } from "src/ui/shared/form/SelectField";
import { UncontrolledTextField } from "src/ui/shared/form/TextField";
import { Tooltip } from "src/ui/shared/Tooltip";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { JsonTextarea } from "@dashboard/shared/components/JsonTextarea";
import { TopicHeader } from "@dashboard/shared/components/TopicHeader";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    SelectChangeEvent,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";

import { enrollmentLink } from "../EnrollmentTab";
import * as Utils from "./GenerateQRCodeDialog.utils";
import { useStyles } from "./GenerateQRCodeDialogStyles";

const QrCode = (props: { value: string; advancedMode: boolean }) => {
    const { path, viewBox } = useQRCodeGenerator(props.value, Utils.qrCodeLevel, Utils.qrCodeBorder);
    const classes = useStyles();
    const theme = useTheme();
    return (
        <div className={clsx(classes.qrContainer, props.advancedMode && classes.advancedModeQrPosition)}>
            <svg width={Utils.qrCodeSize} height={Utils.qrCodeSize} viewBox={viewBox} stroke="none">
                <rect width="100%" height="100%" fill={theme.palette.white[50]} />
                <path d={path} fill={theme.palette.black[100]} />
            </svg>
        </div>
    );
};

type SelectInputHandler = (
    e: SelectChangeEvent<unknown>,
    form: FormApi<Utils.FormValues, Partial<Utils.FormValues>>,
) => void;
type TextInputHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: FormApi<Utils.FormValues, Partial<Utils.FormValues>>,
) => void;

interface Props {
    isOpen: boolean;
    groupId: number;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const GenerateQRCodeDialog = (props: Props) => {
    const [advancedMode, setAdvancedMode] = React.useState(false);
    const [jsonError, setJsonError] = React.useState<string | null>(null);
    const [version, setVersion] = React.useState("v40300000");
    const [deviceActivation, setDeviceActivation] = React.useState(0);
    const [wifiConnection, setWifiConnection] = React.useState(0);

    const classes = useStyles();
    const validate = useValidationSchema(Utils.schema);
    const allLicenses = useSelector(selectLicenses);

    const groupUniversalLicenses = allLicenses.filter((license) => license.universalGroupId === props.groupId);
    const groupUniversalLicensesIds = groupUniversalLicenses.map((license) => license.activationCode);
    const universalLicenses = !groupUniversalLicensesIds.length
        ? Utils.noUniversalLicenses
        : groupUniversalLicensesIds.map((item) => ({
              label: item,
              value: item,
          }));

    const onEditorChange = (value: string, form: FormApi<Utils.FormValues>) => {
        try {
            const obj: Utils.FormValues = JSON.parse(value);
            form.restart(obj);
            setJsonError(null);
        } catch (error) {
            setJsonError("Invalid JSON format");
        }
    };
    const onAdvanceModeChange = () => {
        setAdvancedMode(!advancedMode);
        setJsonError(null);
    };
    const onWifiConnectionChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "number") {
            setWifiConnection(e.target.value);
        }
        if (e.target.value === 0) {
            form.reset({
                ...values,
                "android.app.extra.PROVISIONING_WIFI_SSID": undefined,
                "android.app.extra.PROVISIONING_WIFI_PASSWORD": undefined,
                "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE": undefined,
            });
        }
    };
    const onSystemApplicationChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        form.reset({ ...values, "android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED": !!e.target.value });
    };
    const onEncryptionChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        form.reset({ ...values, "android.app.extra.PROVISIONING_SKIP_ENCRYPTION": !!e.target.value });
    };
    const onLicenseChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "string") {
            form.reset({
                ...values,
                "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE": { "UL": e.target.value },
            });
        }
    };
    const onVersionChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        const value = e.target.value;
        const option = Utils.versionMDM.find((item) => item.value === value);

        if (option && typeof value === "string") {
            setVersion(value);
            form.reset({
                ...values,
                "android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM": option.checksum,
                "android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION": option.url,
            });
        }
    };
    const onDeviceActivationChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "number") {
            setDeviceActivation(e.target.value);
        }
        if (e.target.value === 0) {
            form.reset({ ...values, "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE": undefined });
        }
    };
    const onWifiSSIDChange: TextInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "string") {
            form.reset({ ...values, "android.app.extra.PROVISIONING_WIFI_SSID": e.target.value });
        }
    };
    const onWifiPAsswordChange: TextInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "string") {
            form.reset({ ...values, "android.app.extra.PROVISIONING_WIFI_PASSWORD": e.target.value });
        }
    };
    const onSecurityTypeChange: SelectInputHandler = (e, form) => {
        const values = form.getState().values;
        if (typeof e.target.value === "string") {
            form.reset({ ...values, "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE": e.target.value });
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} classes={{ paper: classes.dialog }}>
            <Form<Utils.FormValues>
                initialValues={Utils.initialValues}
                validate={validate}
                onSubmit={() => undefined}
                render={({ values, form }) => (
                    <form>
                        <div className={classes.dialogTitleContainer}>
                            <div className={classes.dialogTitle}>
                                <Typography variant="h5">Enroll your device</Typography>
                            </div>
                            <div className={classes.modeContainer}>
                                <TopicHeader>Advanced mode</TopicHeader>
                                <Switch checked={advancedMode} onChange={onAdvanceModeChange} color="primary" />
                            </div>
                        </div>
                        <DialogContent className={classes.dialogContent}>
                            {advancedMode ? (
                                <Box className={classes.dataContainer} style={{ alignItems: "flex-end" }}>
                                    <JsonTextarea<Utils.FormValues>
                                        value={values}
                                        error={jsonError}
                                        onChange={onEditorChange}
                                        note="Note: JSON is valid, you can override configuration"
                                    />
                                    <QrCode value={JSON.stringify(values, undefined, 2)} advancedMode />
                                </Box>
                            ) : null}

                            {!advancedMode ? (
                                <div className={classes.dataContainer}>
                                    <div className={classes.contentBox}>
                                        <UncontrolledSelectField
                                            value={version}
                                            label="Emteria MDM"
                                            options={Utils.versionMDM}
                                            onChange={(e) => onVersionChange(e, form)}
                                            className={classes.input}
                                            fullWidth
                                        />
                                        <Tooltip
                                            title="Make this group a target of any universal license to enable device activation"
                                            hide={!!groupUniversalLicensesIds.length}
                                            placement="right-start"
                                        >
                                            <UncontrolledSelectField
                                                label="Device activation"
                                                value={deviceActivation}
                                                options={Utils.activationOptions}
                                                disabled={!groupUniversalLicensesIds.length}
                                                onChange={(e) => onDeviceActivationChange(e, form)}
                                                className={classes.input}
                                                fullWidth
                                            />
                                        </Tooltip>

                                        {deviceActivation ? (
                                            <UncontrolledSelectField
                                                label="Universal license"
                                                value={
                                                    values["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]?.["UL"]
                                                }
                                                options={universalLicenses}
                                                disabled={!groupUniversalLicensesIds.length}
                                                onChange={(e) => onLicenseChange(e, form)}
                                                className={classes.input}
                                                fullWidth
                                            />
                                        ) : null}

                                        <UncontrolledSelectField
                                            label="Device encryption"
                                            value={values["android.app.extra.PROVISIONING_SKIP_ENCRYPTION"] ? 1 : 0}
                                            options={Utils.encryptionOptions}
                                            onChange={(e) => onEncryptionChange(e, form)}
                                            className={classes.input}
                                            fullWidth
                                        />
                                        <UncontrolledSelectField
                                            label="System applications"
                                            value={
                                                values["android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED"]
                                                    ? 1
                                                    : 0
                                            }
                                            options={Utils.systemAppsOptions}
                                            onChange={(e) => onSystemApplicationChange(e, form)}
                                            className={classes.input}
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.contentBox}>
                                        <UncontrolledSelectField
                                            label="WIFI connection"
                                            value={wifiConnection}
                                            options={Utils.wifiOptions}
                                            onChange={(e) => onWifiConnectionChange(e, form)}
                                            className={classes.input}
                                            fullWidth
                                        />

                                        {wifiConnection ? (
                                            <>
                                                <UncontrolledTextField
                                                    value={values["android.app.extra.PROVISIONING_WIFI_SSID"]}
                                                    label="WIFI SSID"
                                                    onChange={(e) => onWifiSSIDChange(e, form)}
                                                    className={classes.input}
                                                    fullWidth
                                                />
                                                <UncontrolledTextField
                                                    value={values["android.app.extra.PROVISIONING_WIFI_PASSWORD"]}
                                                    label="WIFI password"
                                                    onChange={(e) => onWifiPAsswordChange(e, form)}
                                                    className={classes.input}
                                                    fullWidth
                                                />
                                                <UncontrolledSelectField
                                                    value={values["android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE"]}
                                                    label="Security type"
                                                    onChange={(e) => onSecurityTypeChange(e, form)}
                                                    options={Utils.securityTypeOptions}
                                                    className={classes.input}
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                    <QrCode value={JSON.stringify(values, undefined, 2)} advancedMode={false} />
                                </div>
                            ) : null}

                            <DialogActions className={classes.actionBox}>
                                <SecondaryButton onClick={() => window.open(enrollmentLink, "_blank")}>
                                    Help
                                </SecondaryButton>
                                <PrimaryButton onClick={() => props.onClose(false)}>Close</PrimaryButton>
                            </DialogActions>
                        </DialogContent>
                    </form>
                )}
            />
        </Dialog>
    );
};

export default GenerateQRCodeDialog;
