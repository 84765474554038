import { AxiosResponse } from "axios";

import httpService from "./httpService";
import RouteService from "./routeService";

export const redeemVoucher = async (code: string) => {
    const endpoint = await RouteService.getVouchersRoute();
    const { data } = await httpService.post<undefined, AxiosResponse<[API.Subscription]>>(endpoint + code);
    return data[0];
};
