import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    subTitle: {
        fontSize: "18px",
        fontWeight: "normal",
        marginBottom: "20px",
    },
    formContainer: {
        width: "100%",
        paddingBottom: "20px",
    },
    formLabel: {
        fontSize: "16px",
    },
    tableValueCell: {
        width: "60%",
    },
    sectionHeader: {
        padding: "15px 0",
        fontSize: "20px",
        fontWeight: "bold",
    },
    actionRow: {
        paddingTop: "20px",
        display: "flex",
        justifyContent: "space-between",
    },
    requiredLabel: {
        width: "30%",
        paddingTop: 0,
        "&::after": {
            content: "\"*\"",
            color: theme.palette.red[150],
            marginLeft: "0.25rem",
        },
    },
}));
