import React from "react";
import { useDispatch, useSelector } from "src/store";
import { disableNotification, selectNotificationDisabled } from "src/store/account";
import { NotificationId } from "src/store/helpers/notifications";
import ApmLink from "src/ui/shared/ApmLink";
import { TextButton } from "src/ui/shared/CustomButton";
import { CustomTileContainer, CustomTileSpacing, CustomTileText, CustomTileTitle } from "src/ui/shared/CustomTile";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { Icon, IconSize } from "@dashboard/devices/components/shared/Icon";
import { claimStarterSubscription } from "@dashboard/devices/store";
import { selectSubscriptions } from "@dashboard/devices/store/selectors/subscriptions";
import { ConfirmClaimDialog } from "@dashboard/overview/components/ConfirmClaimDialog";
import { RedeemVoucherModal } from "@dashboard/overview/components/RedeemVoucherModal";
import { fetchWorkspaces } from "@dashboard/workspaces/store";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    guideSubheader: { marginBottom: "10px" },
    titleBox: { margin: "14px 20px 4px 20px" },
    subTitleBox: { borderBottom: "1px solid " + theme.palette.shadow[150], padding: "0px 20px" },
    voucherBox: {
        borderBottom: "1px solid " + theme.palette.shadow[150],
        padding: "10px 20px",
        paddingTop: 0,
        alignContent: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    notificationIcon: {
        color: theme.palette.blue[250],
        "--fa-animation-duration": "2s",
        "--fa-fade-opacity": 0.6,
    },
    linkBox: { borderBottom: "1px solid " + theme.palette.shadow[150], padding: "10px 20px" },
    forumBox: { padding: "10px 20px" },
    container: {
        padding: 0,
        marginTop: 20,
    },
}));

export const GettingStartedCard = (
    props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => {
    const [showVoucherModal, setShowVoucherModal] = React.useState(false);
    const [showStarterSubscriptionModal, setShowStarterSubscriptionModal] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const subscriptions = useSelector(selectSubscriptions);
    const notificationDisabled = useSelector((state) =>
        selectNotificationDisabled(state, NotificationId.StarterSubscription),
    );
    const showNotifications = !notificationDisabled && !subscriptions.length;

    const confirmStarterSubscriptionClaim = async () => {
        try {
            await dispatch(claimStarterSubscription()).unwrap();
            await dispatch(fetchWorkspaces()).unwrap();
            showSuccessToast("Starter subscription successfully claimed");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Couldn't claim the starter subscription");
        } finally {
            setShowStarterSubscriptionModal(false);
        }
    };
    const _abortStarterSubscriptionClaim = () => {
        dispatch(disableNotification(NotificationId.StarterSubscription));
        setShowStarterSubscriptionModal(false);
    };
    const cancelStarterSubscriptionClaim = () => {
        if (showStarterSubscriptionModal) {
            setShowStarterSubscriptionModal(false);
        }
    };

    return (
        <>
            <CustomTileContainer {...props} className={classes.container}>
                <CustomTileSpacing>
                    <CustomTileTitle>Getting started</CustomTileTitle>
                    <CustomTileText className={classes.guideSubheader}>
                        Helpful guides and links to start using Emteria features
                    </CustomTileText>
                </CustomTileSpacing>

                <Box className={classes.voucherBox}>
                    <ApmLink
                        to="#"
                        parent="Dashboard overview (Getting started)"
                        title="Redeem voucher"
                        role="button"
                        onClick={(event: MouseEvent) => {
                            event.preventDefault();
                            setShowVoucherModal(true);
                        }}
                    >
                        <Typography>Redeem voucher</Typography>
                    </ApmLink>
                    {showNotifications ? (
                        <TextButton onClick={() => setShowStarterSubscriptionModal(true)} style={{ padding: 0 }}>
                            <Icon
                                size={IconSize.large}
                                className={classes.notificationIcon}
                                name="fa-solid fa-bell fa-fade"
                            />
                        </TextButton>
                    ) : null}
                </Box>
                <Box className={classes.linkBox}>
                    <ApmLink
                        href="https://emteria.com/kb/access-get-emteria-os"
                        parent="Dashboard overview (Getting started)"
                        title="OS installer tutorial"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <Typography>OS installer tutorial</Typography>
                    </ApmLink>
                </Box>
                <Box className={classes.linkBox}>
                    <ApmLink
                        href="https://emteria.com/kb"
                        parent="Dashboard overview (Getting started)"
                        title="Knowledge base"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <Typography>Knowledge base</Typography>
                    </ApmLink>
                </Box>
                <Box className={classes.forumBox}>
                    <ApmLink
                        href="https://forum.emteria.com/"
                        parent="Dashboard overview (Getting started)"
                        title="Discussion forum"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        <Typography>Discussion forum</Typography>
                    </ApmLink>
                </Box>
            </CustomTileContainer>
            <RedeemVoucherModal open={showVoucherModal} onClose={() => setShowVoucherModal(false)} />
            <ConfirmClaimDialog
                open={showStarterSubscriptionModal}
                onClose={cancelStarterSubscriptionClaim}
                onConfirm={confirmStarterSubscriptionClaim}
            />
        </>
    );
};
