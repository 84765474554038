import clsx from "clsx";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { LocalStorage } from "src/services/localStorageService";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";

import { selectSubscription, selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { Subscription } from "@dashboard/devices/types";
import { daysToDate, isToday } from "@dashboard/devices/utils/dates";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, SnackbarProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const threeDays = 3;
const thirtyDays = 30;

export const useStyles = makeStyles((theme: Theme) => ({
    orange: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.orange[50],
        },
    },
    red: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.red[100],
        },
    },
    white: {
        color: theme.palette.white[50],
    },
    black: {
        color: theme.palette.black[100],
    },
    closeIcon: {
        cursor: "pointer",
        marginLeft: 8,
    },
    detailsBtn: {
        marginLeft: 8,
    },
}));

const getMessage = (days: number) => {
    if (days === 0) {
        return "Your subscription expires today. Please renew your plan to maintain access to all features.";
    }
    if (days < 0) {
        return "Your subscription has expired. Please renew your plan to regain access to all features.";
    } else {
        return `Your subscription is expiring in ${days} days. Please renew your plan to keep access to all features.`;
    }
};

export const isSubscriptionExpiringSoon = (subscription: Subscription | null) => {
    if (subscription?.cancellationDate) {
        return false;
    }
    const now = DateTime.now();
    const expirationDate = DateTime.fromISO(subscription?.expirationDate || "");

    const daysUntilExpiration = expirationDate.diff(now, "days").days;

    return daysUntilExpiration <= thirtyDays;
};

export const SubscriptionExpirationBanner = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const subscription = useSelector(selectSubscription);
    const isExpired = useSelector(selectSubscriptionExpired);
    const daysLeft = subscription?.expirationDate ? daysToDate(subscription.expirationDate) : null;
    const inReminderPeriod = isSubscriptionExpiringSoon(subscription);
    const storageDates: Record<string, string> | null = LocalStorage.getItem("lastExpirationBannerDate");
    const lastExpirationDate = subscription && storageDates ? storageDates[subscription.id] : null;

    const hideBanner = () => {
        if (subscription) {
            const nowInMilliseconds = DateTime.now().toMillis();
            LocalStorage.setItem("lastExpirationBannerDate", {
                ...storageDates,
                [subscription.id]: nowInMilliseconds.toString(),
            });
            setOpen(false);
        }
    };
    const handleClose: SnackbarProps["onClose"] = (_, reason) => {
        if (reason !== "clickaway") {
            hideBanner();
        }
    };
    const onRenewClick = () => {
        window.open("https://emteria.com/p/subscription", "_blank");
    };
    const onDetailsClick = () => {
        navigate("/account/workspaces");
    };

    useEffect(() => {
        if (!lastExpirationDate && inReminderPeriod) {
            setOpen(true);
        } else if (lastExpirationDate && !isToday(+lastExpirationDate) && inReminderPeriod) {
            const threeDaysPassed = daysLeft ? daysLeft % threeDays === 0 : false;
            setOpen(threeDaysPassed);
        } else {
            setOpen(false);
        }
    }, [subscription?.cancellationDate, daysLeft, inReminderPeriod, lastExpirationDate]);

    if (!daysLeft || !inReminderPeriod) {
        return null;
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            className={isExpired ? classes.red : classes.orange}
            message={<span className={isExpired ? classes.white : classes.black}>{getMessage(daysLeft)}</span>}
            action={
                <>
                    <SecondaryButton size="small" onClick={onDetailsClick}>
                        See details
                    </SecondaryButton>
                    <PrimaryButton size="small" onClick={onRenewClick} className={classes.detailsBtn}>
                        Contact sales
                    </PrimaryButton>

                    <CloseIcon
                        className={clsx(classes.closeIcon, isExpired ? classes.white : classes.black)}
                        onClick={hideBanner}
                    />
                </>
            }
        />
    );
};
