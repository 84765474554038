import { useEffect, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "src/store";

import { CommandPayload } from "@data/commands";
import { NewCommandDialog } from "@devices/components/Commands/NewCommandDialog";
import { refetchDeviceMdmCommands, refetchGroupMdmCommands, selectPaneStatus } from "@devices/store";
import { selectDeviceCommands, selectDevicesByGroupId } from "@devices/store/selectors/devices";
import { Device, Group } from "@devices/types";
import { MdmCommands } from "@devices/types/commands";
import { selectGroupCommands } from "@groups/store/selectors";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";
import { withExpiredWrapper } from "@shared/ExpiredWrapper";
import { showErrorToast } from "@shared/toasts/Toasts";

import { PaneElement } from "../PaneElement";
import DeviceCommandsTable from "./DeviceCommandTable";
import GroupCommandsTable from "./GroupCommandTable";

const useStyles = makeStyles({
    tooltipWrapper: {
        display: "block",
        height: "100%",
    },
    headerBox: { display: "flex", justifyContent: "space-between", paddingBottom: 10 },
    btnBox: { display: "flex", gap: 5 },
});

export const getCommandName = (payload: string): string => {
    const parsedJson: CommandPayload = JSON.parse(payload);
    if (!parsedJson) {
        return "-";
    }
    return parsedJson.caption || parsedJson.command;
};

const sortCommands = (isGroup: boolean, commands: MdmCommands | undefined): MdmCommands => {
    if (!commands) {
        return [];
    }

    const sorted = [...commands].sort((a, b) => Number(b.id) - Number(a.id));

    // group commands with the same fingerprint
    return isGroup
        ? sorted.reduce((acc: MdmCommands, command) => {
              const { fingerprint, status } = command;
              const existingModel = acc.find((item) => item.fingerprint === fingerprint);

              if (existingModel) {
                  existingModel.statuses?.push(status);
              } else {
                  acc.push({ ...command, statuses: [status] });
              }

              return acc;
          }, [])
        : sorted;
};

interface CommandsProps {
    device?: Device;
    group?: Group;
    setDialogOpen: VoidFunction;
}
const DeviceCommands = ({ device, setDialogOpen }: CommandsProps) => {
    if (!device) {
        return null;
    }

    const classes = useStyles();
    const isDeviceActivated = device.lastState;
    const commands = useSelector((state) => selectDeviceCommands(state, device.id));
    const sortedCommands = sortCommands(false, commands);

    return (
        <PaneElement.Container>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Last commands</PaneElement.Header>

                <SecondaryButton
                    tooltipProps={{
                        title: isDeviceActivated ? "Create command" : "There are no active devices in this group",
                    }}
                    onClick={setDialogOpen}
                >
                    <i className="fas fa-solid fa-square-plus" />
                </SecondaryButton>
            </Box>

            {sortedCommands.length ? (
                <DeviceCommandsTable commands={sortedCommands} deviceId={device.id} />
            ) : (
                <PaneElement.NoData message="No commands to show" />
            )}
        </PaneElement.Container>
    );
};
const GroupCommands = ({ group, setDialogOpen }: CommandsProps) => {
    if (!group) {
        return null;
    }
    const classes = useStyles();
    const groupDevices = useSelector((state) => selectDevicesByGroupId(state, group.id));
    const anyActivatedLicenses = groupDevices.some((device) => device?.lastState);

    const groupCommands = useSelector((state) => selectGroupCommands(state, group.id));
    const sortedCommands = sortCommands(true, groupCommands);

    return (
        <PaneElement.Container>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Last commands</PaneElement.Header>
                <SecondaryButton
                    onClick={setDialogOpen}
                    disabled={!anyActivatedLicenses}
                    tooltipProps={{
                        title: anyActivatedLicenses ? "Create command" : "There are no active devices in this group",
                    }}
                >
                    <i className="fas fa-solid fa-square-plus" />
                </SecondaryButton>
            </Box>

            {sortedCommands.length ? (
                <GroupCommandsTable commands={sortedCommands} groupId={group.id} />
            ) : (
                <PaneElement.NoData message="No commands to show" />
            )}
        </PaneElement.Container>
    );
};

type Props = { device: Device; group?: undefined } | { group: Group; device?: undefined };

const CommandTab = (props: Props) => {
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useReducer((state) => !state, false);

    const paneStatus = useSelector((state) => selectPaneStatus(state));

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        intervalRef.current = setInterval(async () => {
            if (props.device) {
                await dispatch(refetchDeviceMdmCommands({ deviceId: props.device.id }))
                    .unwrap()
                    .catch(({ message }) => showErrorToast(message));
            } else if (props.group) {
                await dispatch(refetchGroupMdmCommands({ groupId: props.group.id }))
                    .unwrap()
                    .catch(({ message }) => showErrorToast(message));
            }
        }, 5000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };

        // ESLint is muted to avoid the need for memoizing a dependent component. We want
        // the useEffect to run only once during the component's mounting.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (paneStatus === "pending") {
        return <PaneElement.Loader />;
    }

    return (
        <>
            {props.device ? <DeviceCommands device={props.device} setDialogOpen={setDialogOpen} /> : null}
            {props.group ? <GroupCommands group={props.group} setDialogOpen={setDialogOpen} /> : null}
            <NewCommandDialog
                title="New command"
                device={props.device}
                group={props.group}
                isOpen={dialogOpen}
                toggleDialog={setDialogOpen}
            />
        </>
    );
};

export default withExpiredWrapper(CommandTab);
