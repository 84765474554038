import { capitalize } from "lodash";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";

import {
    closeBatchMode,
    removeBatchSelection,
    selectBatchActionEntity,
    selectBatchActionIsActiveDevice,
    selectBatchActionSelectionEntities,
    selectBatchActionTargetId,
    setBatchTargetId,
    updateGroup,
} from "@devices/store";
import { selectGroups } from "@groups/store/selectors";
import { Box, Icon, SelectChangeEvent, Theme, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { permissionService } from "@services/permissionService/permissionService";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { CategoriesSelectNoForm } from "@shared/form/SelectField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip as Tooltip } from "@shared/Tooltip";
import { selectWorkspace } from "@workspaces/store/selectors";

import { PaneElement } from "../shared/PaneElement";
import PaneTable from "./PaneTable";

const useStyle = makeStyles<Theme>((_theme) => ({
    headTooltipIcon: {
        fontSize: 16,
    },
    licensesContainer: {
        maxHeight: 475,
        overflowY: "auto",
    },
    container: { padding: 15, width: "100%" },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 15,
    },
}));

export const MoveLicensePanel = () => {
    const theme = useTheme();
    const classes = useStyle();
    const dispatch = useDispatch();
    const batchActionEntity = useSelector(selectBatchActionEntity);
    const isBatchActionDevice = useSelector(selectBatchActionIsActiveDevice);
    const batchActionSelectionEntities = useSelector(selectBatchActionSelectionEntities, shallowEqual);
    const currentWorkspace = useSelector(selectWorkspace);
    const batchTargetId = useSelector(selectBatchActionTargetId);
    const selectionCount = batchActionSelectionEntities.length;

    const confirmAllowed = selectionCount > 0 && !!batchTargetId;
    const workspaceGroups = useSelector(selectGroups);

    const { groupAbility } = permissionService();

    const groupOptions = {
        category: "Groups",
        options: workspaceGroups.map((group) => {
            const mayManageGroup = groupAbility(group, currentWorkspace).can("update", "Group");
            return { label: group.name, value: group.id, disabled: !mayManageGroup };
        }),
    };

    const onChange = (e: SelectChangeEvent<unknown>) => {
        if (typeof e.target.value === "number") {
            dispatch(setBatchTargetId(e.target.value));
        }
    };
    const handleConfirmClose = () => {
        dispatch(closeBatchMode());
    };
    const handleConfirm = () => {
        if (batchTargetId) {
            dispatch(
                updateGroup({
                    id: batchTargetId,
                    addedLicenseIds: batchActionSelectionEntities.map((item) => item.id),
                }),
            )
                .unwrap()
                .then(() => showSuccessToast("Moved license(s)"))
                .catch(({ message = "Couldn't move license(s)" }) => showErrorToast(message));
        }
    };

    if (!batchActionEntity) {
        return null;
    }

    return (
        <Box>
            <PaneElement.HeaderBox>
                <Typography variant="subtitle1">Licenses to move</Typography>
                <SecondaryButton onClick={() => dispatch(closeBatchMode())}>
                    <i className="fas fa-times" />
                </SecondaryButton>
            </PaneElement.HeaderBox>
            <Box className={classes.container}>
                <Box className={classes.licensesContainer}>
                    {!batchActionSelectionEntities.length ? (
                        <Typography align="left">Please select items to move</Typography>
                    ) : null}
                    {selectionCount ? (
                        <PaneTable
                            handleRemoveBatchItem={(id: number) => dispatch(removeBatchSelection(id))}
                            tableItems={batchActionSelectionEntities}
                            firstColumn={capitalize(`${batchActionEntity}s`)}
                        />
                    ) : null}
                </Box>
                <Box className={classes.footer}>
                    {isBatchActionDevice && (
                        <Tooltip placement="top" title="Only groups of equal subscription variant allowed">
                            <Icon className={`far fa-question-circle ${classes.headTooltipIcon}`} />
                        </Tooltip>
                    )}

                    <CategoriesSelectNoForm
                        name="target"
                        value={batchTargetId || undefined}
                        options={[groupOptions]}
                        disabled={!selectionCount}
                        onChange={onChange}
                        label="Select group *"
                        style={{ marginRight: 20, backgroundColor: theme.palette.white[50] }}
                        fullWidth
                    />

                    <SecondaryButton onClick={handleConfirmClose} style={{ marginRight: 4 }}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton onClick={handleConfirm} disabled={!confirmAllowed}>
                        Confirm
                    </PrimaryButton>
                </Box>
            </Box>
        </Box>
    );
};
