import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" },
    circularBox: { position: "relative", display: "inline-flex" },
    labelBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    label: { color: theme.palette.grey[700] },
}));

type Props = Omit<CircularProgressProps, "size"> & {
    value: number;
    size: "small" | "regular" | "large";
};

export const CircularProgressWithLabel = (props: Props) => {
    const classes = useStyles();

    let circularSize;
    let fontSize;

    switch (props.size) {
        case "small":
            circularSize = 40;
            fontSize = 10;
            break;
        case "large":
            circularSize = 70;
            fontSize = 16;
            break;
        default:
            circularSize = 55;
            fontSize = 14;
    }

    return (
        <Box className={classes.circularBox}>
            <CircularProgress variant="determinate" {...props} size={circularSize} />
            <Box className={classes.labelBox}>
                <Typography style={{ fontSize }} className={classes.label}>{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

export const CircularProgressBoxWithLabel = (props: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <CircularProgressWithLabel {...props} />
        </Box>
    );
};
