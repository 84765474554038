import { useCommands } from "@devices/components/Commands/hooks";
import { MdmPolicyCommand } from "@devices/types";
import { DragDropContext, Droppable, DroppableProvided, DropResult } from "@hello-pangea/dnd";
import { Table, TableBody, TableCell, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TableHeader } from "@shared/table/TableHeader";
import { PaneElement } from "@sidePanel/shared/PaneElement";

import PolicyCommandItem from "./PolicyCommandItem";

const useStyles = makeStyles((theme: Theme) => ({
    disabledTable: {
        backgroundColor: theme.palette.shadow[100],
    },
}));

interface PolicyCommandsTableProps {
    groupId: number;
    policyCommands: MdmPolicyCommand[];
}

const PolicyCommandsTable = ({ groupId, policyCommands }: PolicyCommandsTableProps) => {
    const classes = useStyles();

    const {
        updatePolicyCommands,
        loadingState: { updatePolicyLoading },
    } = useCommands();

    const handleDragEnd = async (result: DropResult) => {
        // Dropped outside of the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        // No change in order
        if (sourceIndex === destinationIndex) {
            return;
        }

        const updatedPolicyCommands = [...policyCommands];

        // Reorder the array based on the drag-and-drop result
        const [movedPolicyCommand] = updatedPolicyCommands.splice(sourceIndex, 1);
        updatedPolicyCommands.splice(destinationIndex, 0, movedPolicyCommand);

        await updatePolicyCommands({ groupId, policy: JSON.stringify(updatedPolicyCommands) });
    };

    return (
        <PaneElement.TableContainer>
            <Table stickyHeader aria-label="simple table">
                <TableHeader>
                    <TableRow>
                        <TableCell size="small" width={50}></TableCell>
                        <TableCell size="small" align="center">
                            Index
                        </TableCell>
                        <TableCell size="small" align="left">
                            Command
                        </TableCell>
                        <TableCell size="small" align="right">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable" direction="vertical" isDropDisabled={updatePolicyLoading}>
                        {(droppableProvided: DroppableProvided) => (
                            <TableBody
                                className={updatePolicyLoading ? classes.disabledTable : ""}
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}
                            >
                                {policyCommands.map((c, index) => (
                                    <PolicyCommandItem
                                        index={index}
                                        policyCommands={policyCommands}
                                        policyCommand={c}
                                        key={index}
                                        groupId={groupId}
                                    />
                                ))}
                                {droppableProvided.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </DragDropContext>
            </Table>
        </PaneElement.TableContainer>
    );
};

export default PolicyCommandsTable;
