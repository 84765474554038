import { RootState } from "src/store";

import { createSelector } from "@reduxjs/toolkit";

import { buildsAdapter } from "..";
import { selectProducts } from "./products";

const defaultSelectors = buildsAdapter.getSelectors<RootState>((state) => state.products.list.builds.items);

const selectBuilds = createSelector([defaultSelectors.selectAll, selectProducts], (builds, products) => {
    const productsIds = products.map((item) => item.id);
    return builds.filter((item) => productsIds.includes(item.productId));
});

export { selectBuilds };
