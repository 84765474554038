import clsx from "clsx";
import { DateTime } from "luxon";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import authService from "src/services/authService";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import { Tooltip } from "src/ui/shared/Tooltip";

import { Icon, IconSize } from "@dashboard/devices/components/shared/Icon";
import { requestDownloadBuildLog, requestStreamBuildLog } from "@dashboard/products/api";
import { Dialog, DialogContent, useTheme } from "@mui/material";
import { showErrorToast } from "@shared/toasts/Toasts";

import { useStyles } from "./LogStreamer.style";

interface Props {
    buildId: number;
    hasLogs: boolean;
    disabled?: boolean;
}

export const LogStreamer = ({ buildId, disabled, hasLogs }: Props): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const [logStreamerOpen, setLogStreamerOpen] = useState(false);
    const [logs, setLogs] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const logContainerRef = useRef<HTMLDivElement>(null);
    const logParam = params.get("log");
    const token = authService.getSession()?.access_token;

    const downloadLog = async () => {
        setDownloading(true);
        try {
            const { data } = await requestDownloadBuildLog(buildId);
            if (!data?.size) {
                return showErrorToast("Log file is empty");
            }

            const link = document.createElement("a");
            link.href = URL.createObjectURL(data);
            link.download = `log${buildId}_${DateTime.now().toFormat("yyyy-LL-dd")}`;
            link.click();
        } catch (error) {
            showErrorToast("Failed downloading logs");
        } finally {
            setDownloading(false);
        }
    };
    const handleOpenStreamer = useCallback(async () => {
        if (!token) {
            return;
        }
        setLogStreamerOpen(true);
        try {
            const response = await requestStreamBuildLog(buildId, token);
            setLoading(true);
            if (!response.body) {
                throw new Error("Response body is null");
            }
            const reader = response.body.getReader();

            let isFirstChunk = true;
            const decoder = new TextDecoder();
            while (reader) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const decodedText = decoder.decode(value);

                // eslint-disable-next-line no-control-regex
                const cleanedText = decodedText.replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F]/g, "");
                setLogs((prevLogs) => prevLogs + cleanedText);

                if (isFirstChunk) {
                    setLoading(false);
                    isFirstChunk = false;
                }
            }
        } catch (error) {
            return showErrorToast("Error retrieving build logs, please try again");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [buildId, token, setLogStreamerOpen]);

    useLayoutEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logs]);

    useLayoutEffect(() => {
        if (logParam === String(buildId)) {
            void handleOpenStreamer();
        }
    }, [buildId, handleOpenStreamer, logParam]);

    return (
        <>
            <SecondaryButton
                onClick={handleOpenStreamer}
                disabled={!hasLogs || loading || disabled}
                loading={loading}
                tooltipProps={{ title: "Show build logs" }}
            >
                <i className="fa-solid fa-terminal" />
            </SecondaryButton>
            <Dialog open={logStreamerOpen} fullScreen disableEscapeKeyDown>
                <DialogContent style={{ padding: 0, overflow: "hidden", display: "flex", flexDirection: "column" }}>
                    <div className={classes.modalTitleBox}>
                        <div className={classes.titleLeftContainer}>
                            <div className={classes.modalBg}>{`Logs for build id: ${buildId}`}</div>
                            <Tooltip title="Download logs">
                                {!downloading ? (
                                    <button
                                        disabled={loading || !hasLogs}
                                        className={clsx("fas fa-cloud-arrow-down", classes.downloadBtn)}
                                        onClick={downloadLog}
                                    />
                                ) : (
                                    <Icon
                                        size={IconSize.small}
                                        name="fas fa-circle-notch fa-spin"
                                        style={{ color: theme.palette.white[50] }}
                                    />
                                )}
                            </Tooltip>
                        </div>
                        <div
                            className={clsx("fa-solid fa-times", classes.downloadBtn)}
                            onClick={() => setLogStreamerOpen(false)}
                        />
                    </div>
                    <div ref={logContainerRef} className={classes.dialogContent}>
                        {loading ? (
                            <Icon
                                size={IconSize.small}
                                name="fas fa-circle-notch fa-spin"
                                style={{ color: theme.palette.white[50] }}
                            />
                        ) : hasLogs ? (
                            <pre className={classes.logs}>{logs}</pre>
                        ) : (
                            <div>There are no logs for this build</div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
