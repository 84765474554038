export const products = [
    {
        rootDevice: "caas",
        deviceName: "Intel x86",
    },
    {
        rootDevice: "imx8mm",
        deviceName: "NXP i.MX8 MM",
    },
    {
        rootDevice: "imx8mn",
        deviceName: "NXP i.MX8 MN",
    },
    {
        rootDevice: "imx8mp",
        deviceName: "NXP i.MX8 MP",
    },
    {
        rootDevice: "imx8mq",
        deviceName: "NXP i.MX8 MQ",
    },
    {
        rootDevice: "rpi4",
        deviceName: "Raspberry Pi 4B",
    },
    {
        rootDevice: "rpi5",
        deviceName: "Raspberry Pi 5",
    },
    {
        rootDevice: "rockpi4b",
        deviceName: "Rock 4B",
    },
    {
        rootDevice: "rockpi5b",
        deviceName: "Rock 5",
    },
] as const;

export const androidVersionOptions: Record<string, string[]> = {
    caas: ["13", "14", "15"],
    rpi5: ["14", "15"],
    rpi4: ["11", "13", "14", "15"],
    rockpi4b: ["11"],
    imx8mp: ["11", "12", "13", "14"],
    imx8mm: ["11", "12", "13", "14"],
    imx8mn: ["11", "12", "13", "14"],
    imx8mq: ["11", "12", "13", "14"],
};
