import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "src/store";
import { MinimalTableCell } from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Popover, TableRow } from "@mui/material";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";

import { alternatingBg } from "../shared/styles";
import { DeleteProps } from "./RepositoriesPage";
import { useStyles } from "./RepositoriesPage.style";

interface Props {
    repo: API.FdroidRepo;
    index: number;
    handleOpenDeleteDialog: (repo: DeleteProps) => void;
}

const RepositoryTableItem = (props: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const provisionings = useSelector(selectProvisionings);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const relatedProvisionings = provisionings.reduce((accumulator: { id: number; name: string }[], provisioning) => {
        const hasRepo = provisioning.settings?.fDroidRepos.some((fdroidRepo) => fdroidRepo.id === props.repo.id);
        if (hasRepo) {
            accumulator.push({
                id: provisioning.id,
                name: provisioning.name,
            });
        }
        return accumulator;
    }, []);

    const handleProvisioningClick = (provisioningId: number) => {
        navigate(`/dashboard/provisioning/${provisioningId}`);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <TableRow style={alternatingBg(props.index)}>
            <MinimalTableCell maxWidth={300}>
                <a href={`${"https://apps.emteria.com/fdroid/"}${props.repo.name}`} target="_blank" rel="noreferrer">
                    {`${"https://apps.emteria.com/fdroid/"}${props.repo.name}`}
                </a>
            </MinimalTableCell>
            <MinimalTableCell maxWidth={220}>
                <Tooltip title={props.repo.title}>
                    <span>{props.repo.title}</span>
                </Tooltip>
            </MinimalTableCell>
            <MinimalTableCell maxWidth={220}>
                <Tooltip title={props.repo.description}>
                    <span>{props.repo.description}</span>
                </Tooltip>
            </MinimalTableCell>
            <MinimalTableCell maxWidth={100} align="center">
                {relatedProvisionings.length ? (
                    <>
                        <SecondaryButton onClick={handlePopoverOpen}>
                            <i className="fa-solid fa-check" />
                        </SecondaryButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            className={classes.popoverContentCotainer}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <div className={classes.popoverContentCotainer}>
                                {relatedProvisionings.map((item) => {
                                    return (
                                        <SecondaryButton key={item.id} onClick={() => handleProvisioningClick(item.id)}>
                                            {item.name}
                                        </SecondaryButton>
                                    );
                                })}
                            </div>
                        </Popover>
                    </>
                ) : (
                    <i className="fa-sharp fa-solid fa-xmark" />
                )}
            </MinimalTableCell>
            <MinimalTableCell maxWidth={80} align="right">
                <DangerOutlinedButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() =>
                        props.handleOpenDeleteDialog({
                            title: props.repo.title,
                            subscriptionId: props.repo.subscriptionId,
                            id: props.repo.id,
                        })
                    }
                >
                    <i className="fas fa-trash-alt" />
                </DangerOutlinedButton>
            </MinimalTableCell>
        </TableRow>
    );
};

export default RepositoryTableItem;
