import { useState } from "react";
import { useDispatch } from "src/store";

import { alternatingBg } from "@dashboard/shared/styles";
import { useCommands } from "@devices/components/Commands/hooks";
import { setPaneTab } from "@devices/store";
import { ComplexCommandNames } from "@devices/types/commands";
import { getPresignedDownloadUrl } from "@files/api";
import { FileInfo } from "@files/types";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { Tooltip } from "@shared/Tooltip";

import { useStyles } from "./AppTabTableItem.style";

interface DeviceAppsTableItemProps {
    label: string;
    system: boolean;
    enabled: boolean;
    package: string;
    running: boolean;
    launchable: boolean;
    versionCode: number;
    versionName: string;
}

const getLatestApk = (avaibleApks: FileInfo[]) =>
    avaibleApks.reduce((latest, current) => {
        const latestVersionCode = latest.metadata?.file?.apk?.manifest?.versioncode;
        const currentVersionCode = current.metadata?.file?.apk?.manifest?.versioncode;
        return latestVersionCode
            ? Number(currentVersionCode) > Number(latestVersionCode)
                ? current
                : latest
            : current;
    });

const getNewPackageVersion = (currentPackageVersion: number, avaibleApks: FileInfo[]) => {
    if (avaibleApks.length) {
        const latestApk = getLatestApk(avaibleApks);
        const latestPackageVersion = Number(latestApk.metadata?.file?.apk?.manifest?.versioncode);
        return latestPackageVersion > currentPackageVersion ? latestApk : null;
    }
    return null;
};

const AppTabTableItem = ({
    deviceApp,
    index,
    deviceId,
    apkFiles,
}: {
    deviceApp: DeviceAppsTableItemProps;
    index: number;
    deviceId: number;
    apkFiles: FileInfo[];
}) => {
    const classes = useStyles();
    const { running, label, package: packageName, versionCode, versionName, enabled, launchable } = deviceApp;
    const [open, setOpen] = useState(-1);
    const avaibleApks = apkFiles.filter((item) => item.metadata?.file?.apk?.manifest?.package === deviceApp.package);
    const newPackageVersion = getNewPackageVersion(deviceApp.versionCode, avaibleApks);
    const dispatch = useDispatch();

    const {
        createMdmCommand,
        loadingState: { createLoading },
    } = useCommands();

    const handleStartButton = async () => {
        const startCommandObj = {
            command: ComplexCommandNames.StartPackage,
            caption: "Start package",
            package: packageName,
        };

        await createMdmCommand({ deviceId, json: startCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };
    const handleStopButton = async () => {
        const stopCommandObj = {
            command: ComplexCommandNames.StopPackage,
            caption: "Stop package",
            package: packageName,
        };

        await createMdmCommand({ deviceId, json: stopCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };
    const handleUninstallButton = async () => {
        const uninstallCommandObj = {
            command: ComplexCommandNames.UninstallPackage,
            caption: "Uninstall package",
            package: packageName,
            version: versionCode,
        };

        await createMdmCommand({ deviceId, json: uninstallCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };
    const handleTooltipText = (isEnabled: boolean, isLaunchable: boolean): string => {
        if (!isEnabled) {
            return "Application is disabled";
        } else if (!isLaunchable) {
            return "Application is not launchable";
        } else {
            return "Start application";
        }
    };
    const displayActionIcon = (appEnabled: boolean, appLaunchable: boolean, appRunning: boolean) => {
        if (appEnabled && appLaunchable && !appRunning) {
            return (
                <SecondaryButton
                    tooltipProps={{ title: "Start application" }}
                    onClick={handleStartButton}
                    disabled={createLoading}
                    loading={createLoading}
                >
                    <i className="fas fa-play" />
                </SecondaryButton>
            );
        } else if (appLaunchable && appRunning) {
            return (
                <SecondaryButton
                    tooltipProps={{ title: "Stop application" }}
                    onClick={handleStopButton}
                    disabled={createLoading}
                    loading={createLoading}
                >
                    <i className="fas fa-stop" />
                </SecondaryButton>
            );
        } else {
            return (
                <>
                    <SecondaryButton disabled tooltipProps={{ title: handleTooltipText(enabled, launchable) }}>
                        <i className="fas fa-play" />
                    </SecondaryButton>
                </>
            );
        }
    };
    const installPackageForDevice = async (apk: FileInfo) => {
        const url = await getPresignedDownloadUrl(apk.id);
        if (url) {
            const commandObject = {
                url,
                package: apk.metadata?.file?.apk?.manifest?.package,
                version: apk.metadata?.file?.apk?.manifest?.versioncode,
            };

            await createMdmCommand({
                deviceId,
                json: { "command": "installPackage", "caption": "Install package", ...commandObject },
            }).then(() => {
                dispatch(setPaneTab("COMMANDS"));
            });
        }
    };

    return (
        <>
            <TableRow key={index} style={alternatingBg(index)}>
                <TableCell>
                    <IconButton
                        className={classes.collapseBtn}
                        onClick={() => setOpen(open === index ? -1 : index)}
                        size="large"
                    >
                        {open === index ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {running && (
                        <Tooltip placement="top" title="Application is running">
                            <div className={classes.iconContainer}>
                                <i className="fa-regular fa-circle-play" />
                            </div>
                        </Tooltip>
                    )}
                    {!enabled && (
                        <Tooltip placement="top" title="Application is hidden">
                            <div className={classes.iconContainer}>
                                <i className="fa-sharp fa-solid fa-eye-slash" />
                            </div>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>
                    <Tooltip placement="top" title={label}>
                        <div className={classes.textWrap}>{label}</div>
                    </Tooltip>
                </TableCell>
                <TableCell>{versionCode}</TableCell>
                <TableCell style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className={classes.buttonBox}>
                        {newPackageVersion && (
                            <SecondaryButton
                                tooltipProps={{ title: "Install update" }}
                                onClick={() => installPackageForDevice(newPackageVersion)}
                                disabled={createLoading}
                                loading={createLoading}
                            >
                                <i className="fa-solid fa-angles-up" />{" "}
                            </SecondaryButton>
                        )}
                        {displayActionIcon(enabled, launchable, running)}
                        {!deviceApp.system && (
                            <DangerOutlinedButton
                                tooltipProps={{ title: "Uninstall app" }}
                                onClick={handleUninstallButton}
                                disabled={createLoading}
                                loading={createLoading}
                            >
                                <i className="fa-solid fa-trash-alt" />
                            </DangerOutlinedButton>
                        )}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={open === index ? classes.borderBottomVisible : ""} style={alternatingBg(index)}>
                <TableCell colSpan={5} style={{ paddingTop: 0, paddingBottom: 0, border: "0px" }}>
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                        <div className={classes.collapsingRowContent}>
                            <span className={classes.collapsePropertyTitle}>Package name: </span>
                            {packageName}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Version name: </span>
                            {versionName}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Version code: </span>
                            {versionCode}
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AppTabTableItem;
