import fileSize from "filesize";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import {
    getDeviceBatteryHealthDesc,
    getDeviceBatteryPluggedDesc,
    getDeviceBatteryStatusDesc,
} from "@devices/DevicesPage.utils";
import { DeviceState } from "@devices/types";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";

interface Props {
    deviceState: DeviceState;
}

function HardwareTab({ deviceState }: Props): JSX.Element {
    const { memory, battery, display, properties } = deviceState;
    const { usb = [] } = deviceState;

    return (
        <PaneElement.Container>
            <PaneElement.Section>
                <PaneElement.Header>Memory</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Total memory</PropertyKey>
                    <PropertyValue>{memory?.total ? fileSize(memory?.total) : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Available memory</PropertyKey>
                    <PropertyValue>{memory?.available ? fileSize(memory?.available) : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Memory alert</PropertyKey>
                    <PropertyValue>{memory?.isLow ? "Memory is low" : "-"}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Battery</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Battery level</PropertyKey>
                    <PropertyValue>{battery?.percentage ? `${battery?.percentage}%` : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Battery voltage</PropertyKey>
                    <PropertyValue>{battery?.voltage ? `${battery?.voltage} mV` : "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Plugged</PropertyKey>
                    <PropertyValue>
                        {battery?.plugged ? getDeviceBatteryPluggedDesc(battery.plugged) : ""}
                    </PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Current status</PropertyKey>
                    <PropertyValue>{battery?.status ? getDeviceBatteryStatusDesc(battery.status) : ""}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Current health</PropertyKey>
                    <PropertyValue>{battery?.health ? getDeviceBatteryHealthDesc(battery?.health) : ""}</PropertyValue>
                </PropertyRow>
            </PaneElement.Section>
            <PaneElement.Section>
                <PaneElement.Header>Display</PaneElement.Header>
                <PropertyRow>
                    <PropertyKey>Name</PropertyKey>
                    <PropertyValue>{display?.name ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>ID</PropertyKey>
                    <PropertyValue>{display?.id ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Size</PropertyKey>
                    <PropertyValue>{display?.size ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Real size</PropertyKey>
                    <PropertyValue>{display?.realSize ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Rotation</PropertyKey>
                    <PropertyValue>{display?.rotation ?? "-"}</PropertyValue>
                </PropertyRow>
                <PropertyRow>
                    <PropertyKey>Density</PropertyKey>
                    <PropertyValue>
                        {properties && properties["ro.sf.lcd_density"] ? properties["ro.sf.lcd_density"] : "-"}
                    </PropertyValue>
                </PropertyRow>
            </PaneElement.Section>

            {usb.map((item, i) => (
                <PaneElement.Section key={i}>
                    <PaneElement.Header>{`USB device #${i + 1}`}</PaneElement.Header>
                    <PropertyRow>
                        <PropertyKey>System path</PropertyKey>
                        <PropertyValue>{item?.path ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>System ID</PropertyKey>
                        <PropertyValue>{item?.number ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Product ID</PropertyKey>
                        <PropertyValue>{item?.productId ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Product name</PropertyKey>
                        <PropertyValue>{item?.productName ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Vendor ID</PropertyKey>
                        <PropertyValue>{item?.vendorId ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Vendor name</PropertyKey>
                        <PropertyValue>{item?.vendorName ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Service class</PropertyKey>
                        <PropertyValue>{item?.class ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Device subclass</PropertyKey>
                        <PropertyValue>{item?.subclass ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Serial number</PropertyKey>
                        <PropertyValue>{item?.serial ?? "-"}</PropertyValue>
                    </PropertyRow>
                    <PropertyRow>
                        <PropertyKey>Protocol type</PropertyKey>
                        <PropertyValue>{item?.productId ?? "-"}</PropertyValue>
                    </PropertyRow>
                </PaneElement.Section>
            ))}
        </PaneElement.Container>
    );
}
export default HardwareTab;
