import { useSelector } from "src/store";
import { selectLegacyDeviceOverviewEnabled } from "src/store/beta";

import DevicesLegacyPage from "@dashboard/devices/components/Legacy/DevicesLegacyPage";
import DevicesPage from "@dashboard/devices/DevicesPage";

const DevicesPageWrapper = () => {
    const isLegacyDeviceOverviewEnabled = useSelector(selectLegacyDeviceOverviewEnabled);
    return isLegacyDeviceOverviewEnabled ? <DevicesLegacyPage /> : <DevicesPage />;
};

export default DevicesPageWrapper;
