import { Tooltip } from "src/ui/shared/Tooltip";

import { GroupMember } from "@dashboard/devices/types";
import { Box, TableRow, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";

const useStyles = makeStyles((theme: Theme) => ({
    borderless: {
        borderBottom: "none !important",
    },
    inputItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexShrink: 0,
        "& .MuiInputBase-root": {
            height: 36,
            fontSize: 14,
        },
    },
    statusLabel: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        fontSize: 12,
        padding: "2px 6px",
        borderRadius: 12,
        marginLeft: 4,
    },
}));

interface Props {
    member: GroupMember;
}
export const GuestMemberListItem = (props: Props) => {
    const classes = useStyles();

    return (
        <TableRow>
            <MinimalTableCell className={classes.borderless} align="justify">
                <Typography>
                    {props.member.email}{" "}
                    {props.member.status === "Pending" && (
                        <Tooltip
                            title={
                                props.member.status === "Pending"
                                    ? "Pending members received an invitation mail to join the group"
                                    : ""
                            }
                        >
                            <span className={classes.statusLabel}>Pending</span>
                        </Tooltip>
                    )}
                </Typography>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="justify">
                <Box className={classes.inputItem}>
                    <Typography>Direct member of workspace group(s) </Typography>
                </Box>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="right"></MinimalTableCell>
        </TableRow>
    );
};
