import { License } from "@dashboard/devices/types";
import { convertToLocalDate } from "@dashboard/devices/utils/dates";

export const getLicenseActivationStatus = (license: License) => {
    let result = "";

    if (license?.device) {
        result = "Used";
    }
    if (!license?.device) {
        result = "Unused";
    }

    return result;
};

export const getLicenseStatusTooltip = (license: License) => {
    let result = "";

    if (license.revocationReason) {
        result = license.revocationReason;
    }
    if (license.associationDate) {
        result = convertToLocalDate(license.associationDate, "ISO", "dateTime");
    }
    if (!license?.device) {
        result = convertToLocalDate(license.creationDate, "ISO", "dateTime");
    }

    return result;
};
