import { MenuItem as MuiMenuItem, Select as MuiSelect, SelectProps, styled } from "@mui/material";

const Select = styled(MuiSelect)({
    fontSize: "14px !important",
    width: "30px",
    paddingRight: "16px",
});
const MenuItem = styled(MuiMenuItem)({
    fontSize: "14px",
    height: "26px",
});

type Props = SelectProps & { _ref?: React.MutableRefObject<HTMLSelectElement | undefined> };
export function SelectPageSize(props: Props): JSX.Element {
    return (
        <Select {...props} ref={props._ref}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
        </Select>
    );
}
