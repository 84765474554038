import React from "react";
import { useSelector } from "src/store";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { invokeFileDownload } from "@dashboard/files/api";
import { ChangesDialog } from "@dashboard/shared/components/ChangesDialog";
import { TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { flexRender, Row } from "@tanstack/react-table";

import { InformationModal } from "./InformationModal";

const useStyles = makeStyles(() => ({
    actionContainer: {
        display: "flex",
        gap: 8,
        justifyContent: "flex-end",
    },
}));

interface TableItemProps {
    row: Row<API.ReleaseFile>;
    index: number;
}
const TableItem = ({ row }: TableItemProps) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isChangesDialogOpen, setIsChangesDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const classes = useStyles();
    const isExpired = useSelector(selectSubscriptionExpired);

    const cannotDownload = isExpired && !row.original.isPublic;

    const handleDownload = async () => {
        setLoading(true);
        await invokeFileDownload(row.original.id, row.original.name ?? "", true);
        setLoading(false);
    };

    return (
        <React.Fragment>
            <TableRow>
                {row
                    .getVisibleCells()
                    .map((cell) => flexRender(cell.column.columnDef.cell, { ...cell.getContext(), key: cell.id }))}
                <MinimalTableCell maxWidth={150}>
                    <div className={classes.actionContainer}>
                        <PrimaryButton
                            loading={loading}
                            disabled={loading || cannotDownload}
                            onClick={handleDownload}
                            tooltipProps={{
                                title: "Subscription expired",
                                hide: !cannotDownload,
                            }}
                        >
                            <i className="fa-solid fa-download" />
                        </PrimaryButton>
                        <SecondaryButton
                            tooltipProps={{
                                title: "Show changes",
                            }}
                            onClick={() => setIsChangesDialogOpen(true)}
                        >
                            <i className="fas fa-list" />
                        </SecondaryButton>
                        <SecondaryButton
                            tooltipProps={{ title: "Show details" }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <i className="fas fa-info-circle" />
                        </SecondaryButton>
                    </div>
                </MinimalTableCell>
            </TableRow>
            <InformationModal file={row.original} isOpen={isModalOpen} toggleDialog={() => setIsModalOpen(false)} />
            <ChangesDialog
                title="Product changes history"
                onClose={() => setIsChangesDialogOpen(false)}
                changes={row.original.changes}
                open={isChangesDialogOpen}
            />
        </React.Fragment>
    );
};

export default TableItem;
