import exportFromJSON from "export-from-json";
import { useReducer, useState } from "react";
import { useSelector } from "src/store";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { useCommands } from "@devices/components/Commands/hooks";
import { NewCommandDialog } from "@devices/components/Commands/NewCommandDialog";
import { ConfirmDialog } from "@devices/components/shared/index";
import { selectPaneStatus } from "@devices/store";
import { Group } from "@devices/types";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { withExpiredWrapper } from "@shared/ExpiredWrapper";

import { ImportGroupPolicyDialog } from "./ImportGroupPolicyDialog";
import PolicyCommandsTable from "./PolicyCommandsTable";

const useStyles = makeStyles({
    headerBox: { display: "flex", justifyContent: "space-between", paddingBottom: 16 },
    btnBox: { display: "flex", gap: 5, height: 32, position: "relative" },
});

export type Policy = {
    [key: string]: Record<string, unknown>;
};

function PolicyTab(group: Group): JSX.Element {
    const classes = useStyles();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleModal = () => setIsDeleteModalOpen((prev) => !prev);

    const policyCommands = group?.policy || [];
    const [newCommandDialogOpen, setNewCommandDialogOpen] = useReducer((state) => !state, false);
    const [importFileDialogOpen, setImportFileDialogOpen] = useReducer((state) => !state, false);

    const {
        clearPolicyCommands,
        loadingState: { createLoading, updatePolicyLoading, clearPolicyLoading },
    } = useCommands();

    const exportPolicy = () => {
        exportFromJSON({
            data: policyCommands ?? [],
            fileName: `policy_groupId_${group?.id}`,
            exportType: exportFromJSON.types.json,
        });
    };

    const onDeletePolicy = async () => {
        await clearPolicyCommands({ groupId: group.id, policy: JSON.stringify([]) });
    };

    const paneStatus = useSelector((state) => selectPaneStatus(state));
    if (paneStatus === "pending") {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Group policy</PaneElement.Header>
                <Box className={classes.btnBox}>
                    <SecondaryButton
                        disabled={createLoading}
                        loading={createLoading}
                        onClick={setNewCommandDialogOpen}
                        tooltipProps={{ title: "Add policy command" }}
                    >
                        <i className="fas fa-solid fa-square-plus" />
                    </SecondaryButton>
                    <SecondaryButton
                        disabled={updatePolicyLoading}
                        loading={updatePolicyLoading}
                        onClick={setImportFileDialogOpen}
                        tooltipProps={{ title: "Import policy commands" }}
                    >
                        <i className="fas fa-solid fa-file-import" />
                    </SecondaryButton>
                    <SecondaryButton
                        disabled={!policyCommands?.length || updatePolicyLoading}
                        onClick={exportPolicy}
                        tooltipProps={{ title: "Export policy commands" }}
                    >
                        <i className="fas fa-solid fa-file-export" />
                    </SecondaryButton>
                    <DangerOutlinedButton
                        disabled={!policyCommands?.length || clearPolicyLoading}
                        loading={clearPolicyLoading}
                        onClick={() => setIsDeleteModalOpen(true)}
                        tooltipProps={{ title: "Clear policy commands" }}
                    >
                        <i className="fas fa-solid fa-trash-alt" />
                    </DangerOutlinedButton>
                </Box>
            </Box>
            {policyCommands?.length ? (
                <PolicyCommandsTable groupId={group.id} policyCommands={policyCommands} />
            ) : (
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <PaneElement.NoData message="No group policy defined" />
                </Box>
            )}

            <NewCommandDialog
                title="New policy command"
                group={group}
                isOpen={newCommandDialogOpen}
                toggleDialog={setNewCommandDialogOpen}
                isPolicy={true}
            />

            <ImportGroupPolicyDialog
                open={importFileDialogOpen}
                groupId={group.id}
                policyCommands={policyCommands ?? []}
                onClose={setImportFileDialogOpen}
            />
            <ConfirmDialog
                title="Delete policy"
                content="Are you sure you want to delete this group policy?"
                dangerButton
                primaryActionText="Delete"
                open={isDeleteModalOpen}
                onConfirm={onDeletePolicy}
                onClose={toggleModal}
            />
        </PaneElement.Container>
    );
}
export default withExpiredWrapper(PolicyTab);
