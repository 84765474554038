import React from "react";
import { useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomTileContainer } from "src/ui/shared/CustomTile";
import { ExpiredWrapper } from "src/ui/shared/ExpiredWrapper";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { LoadingIcon } from "src/ui/shared/Loading";

import { selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectFilesListStatus } from "@dashboard/files/store/selectors";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { selectWorkspace } from "../workspaces/store/selectors";
import { DeviceActivityCard } from "./components/Card/DeviceActivity";
import EnrollmentProgressCard from "./components/Card/EnrollmentProgressCard";
import { Filter } from "./components/Card/Filter";
import { GettingStartedCard } from "./components/Card/GettingStartedCard";
import { ManagedAppsCard } from "./components/Card/ManagedAppsCard";
import OnlineStatusCard from "./components/Card/OnlineStatusCard";
import { OperatingSystemsCard } from "./components/Card/OperatingSystemsCard";
import PolicyComplianceCard from "./components/Card/PolicyComplianceCard";

const useStyles = makeStyles(() => ({
    filterContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        marginBottom: 16,
    },
    contentBox: { marginTop: 10 },
    appsLoadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "inherit",
    },
}));

const StatisticGridSection = (props: { filter: number[] }) => {
    const classes = useStyles();

    const groups = useSelector(selectGroups);
    const status = useSelector(selectFilesListStatus);

    const groupsIds = groups.map((group) => group.id);
    const groupsForStatisctics = props.filter.length ? props.filter : groupsIds;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <EnrollmentProgressCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid item xs={12} md={4}>
                <OnlineStatusCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid item xs={12} md={4}>
                <PolicyComplianceCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid item xs={12} md={8}>
                {status === "pending" ? (
                    <CustomTileContainer className={classes.appsLoadingBox}>
                        <LoadingIcon />
                    </CustomTileContainer>
                ) : (
                    <ManagedAppsCard filter={props.filter} />
                )}
            </Grid>
            <Grid item xs={12} md={4}>
                <OperatingSystemsCard filter={props.filter} />
            </Grid>
        </Grid>
    );
};

const getOverviewPageGroupFilter = (): number[] => {
    const storedValue = localStorage.getItem("overviewPageGroupFilter");
    return storedValue ? JSON.parse(storedValue) : [];
};

const OverviewPage = () => {
    const [filter, setFilter] = React.useState<number[]>(getOverviewPageGroupFilter());

    const classes = useStyles();

    const workspace = useSelector(selectWorkspace);
    const groups = useSelector(selectGroups);
    const isExpired = useSelector(selectSubscriptionExpired);

    const filteredGroups = filter.length > 0 ? groups.filter((group) => filter.includes(group.id)) : groups;
    const filteredGroupIds = filteredGroups.map((g) => g.id);
    const filteredDevices = useSelector((state) => selectDevicesByGroupIds(state, filteredGroupIds));

    return (
        <PageContainer>
            <div className={classes.filterContainer}>
                <div>
                    <PageTitleWithIcon title="Dashboard overview" iconName="fa-solid fa-chart-line" />
                    <SectionDescription>Monitor device status and events</SectionDescription>
                </div>
                {workspace ? (
                    <Box className={classes.filterContainer}>
                        {!isExpired ? <Filter filter={filter} setFilter={setFilter} /> : null}
                    </Box>
                ) : null}
            </div>
            {!workspace ? <FreshAccountMessage /> : null}
            {!workspace && <GettingStartedCard />}
            {workspace && (
                <ExpiredWrapper>
                    <Grid container spacing={1} className={classes.contentBox}>
                        <Grid item xs={12} md={8}>
                            <StatisticGridSection {...{ filter }} />
                            {!isExpired ? <GettingStartedCard style={{ marginTop: 8 }} /> : null}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DeviceActivityCard filteredDevices={filteredDevices} />
                        </Grid>
                    </Grid>
                </ExpiredWrapper>
            )}
        </PageContainer>
    );
};

export default OverviewPage;
