import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { theme } from "src/providers/ThemeProvider";
import { useDispatch, useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomTileContainer, CustomTileTitle } from "src/ui/shared/CustomTile";
import { Tooltip } from "src/ui/shared/Tooltip";

import { DeviceActivity, fetchDeviceActivities, LowBatteryPayload, OsUpdatePayload } from "@dashboard/devices/api";
import { showDetails } from "@dashboard/devices/store/index";
import { Device } from "@dashboard/devices/types";
import { useStyles as useSharedStyles } from "@dashboard/overview/components/Card/shared";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { List, ListItem, ListItemText, ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles({
    card: {
        minHeight: "100%",
    },
    centeredListItem: {
        justifyContent: "center",
    },
    activityName: {
        fontSize: 16,
    },
    deviceSummaryIndicator: {
        width: "4px",
        borderRadius: "18px",
        height: 46,
        marginRight: 16,
    },
    listSubHeader: {
        lineHeight: 0,
    },
    dateContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 0,
    },
});

const renderDetails = (activity: DeviceActivity) => {
    if (activity.type === "LowBattery") {
        const { battery } = activity.value as LowBatteryPayload;
        return {
            description: `Battery level at ${battery.percentage.new}%`,
            color: theme.palette.orange[100],
        };
    } else if (activity.type === "OsUpdate") {
        const { version } = activity.value as OsUpdatePayload;
        return {
            description: `OS update from v${version.emteriaVersion.old} to v${version.emteriaVersion.new}`,
            color: theme.palette.green[50],
        };
    } else if (activity.type === "LowMemory") {
        return {
            description: "Low RAM alert",
            color: theme.palette.orange[100],
        };
    } else {
        return {
            description: "Unknown event",
            color: theme.palette.blue[200],
        };
    }
};

type Props = {
    filteredDevices: (Device | null)[];
};

export const DeviceActivityCard = ({ filteredDevices }: Props) => {
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deviceEvents, setDeviceEvents] = useState<DeviceActivity[]>([]);
    const filteredDeviceIds = filteredDevices.map((d) => d?.id);
    const groups = useSelector(selectGroups);

    const deviceActivitiesToFetch = 20;
    const filteredEvents =
        filteredDeviceIds.length > 0 ? deviceEvents.filter((e) => filteredDeviceIds.includes(e.deviceId)) : [];

    useEffect(() => {
        void fetchDeviceActivities(deviceActivitiesToFetch)
            .then((data) => setDeviceEvents(data))
            .catch(() => {
                showErrorToast("An error occurred while device events");
            });
    }, []);

    const renderDeviceName = (deviceId: number) => {
        const device = filteredDevices.find((d) => d?.id === deviceId);
        return device?.name ?? "Device not found";
    };
    const renderDeviceGroupName = (deviceId: number) => {
        const deviceLicenseId = filteredDevices.find((d) => d?.id === deviceId)?.licenseId;
        return groups.find((group) => group.licenseIds.some((licenseId) => licenseId === deviceLicenseId))?.name;
    };

    const handleDeviceNameClick = (deviceId: number) => {
        const licenseId = filteredDevices.find((device) => device?.id === deviceId)?.licenseId;
        if (licenseId) {
            dispatch(
                showDetails({
                    type: "license",
                    selectedId: licenseId,
                }),
            );
            navigate("/dashboard/devices");
        }
    };

    const handleGroupNameClick = (deviceId: number) => {
        const licenseId = filteredDevices.find((device) => device?.id === deviceId)?.licenseId;
        const deviceGroupId = groups.find((group) => group.licenseIds.some((id) => id === licenseId))?.id;

        if (licenseId && deviceGroupId) {
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: deviceGroupId,
                }),
            );
            navigate("/dashboard/devices");
        }
    };

    return (
        <CustomTileContainer className={classes.card}>
            <CustomTileTitle>Activity feed</CustomTileTitle>
            {filteredEvents.length ? (
                <List style={{ padding: 0 }}>
                    {filteredEvents.map((e) => (
                        <ListItem key={e.id} disableGutters>
                            <div
                                className={classes.deviceSummaryIndicator}
                                style={{ backgroundColor: renderDetails(e).color }}
                            />
                            <ListItemText
                                primary={
                                    <Box
                                        onClick={() => handleDeviceNameClick(e.deviceId)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <Tooltip title="Open this device" placement="top">
                                            <Typography variant="body2" className={sharedClasses.clickableLabel}>
                                                {renderDeviceName(e.deviceId)}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                }
                                secondary={
                                    <Typography className={classes.activityName}>
                                        {renderDetails(e).description}
                                    </Typography>
                                }
                            />
                            <ListSubheader className={classes.listSubHeader}>
                                <Box onClick={() => handleGroupNameClick(e.deviceId)}>
                                    <Tooltip title={"Open this group"} placement="top">
                                        <Typography variant="caption" className={sharedClasses.clickableLabel}>
                                            {renderDeviceGroupName(e.deviceId)}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box className={classes.dateContainer}>
                                    <PaneElement.CellWithTimeElapsed
                                        style={{ borderBottom: "none", padding: 0 }}
                                        date={e.time}
                                    />
                                </Box>
                            </ListSubheader>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box className={classes.centeredListItem}>
                    <Typography align="center" style={{ borderBottom: "none", padding: 0 }}>
                        No device activity
                    </Typography>
                </Box>
            )}
        </CustomTileContainer>
    );
};
