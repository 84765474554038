import { FormApi } from "final-form";
import { Form, useForm } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { useStyles } from "@dashboard/provisioning/ProvisioningPage.style";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { SectionContainer } from "@dashboard/shared/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { selectWorkspace } from "../../workspaces/store/selectors";
import { createProvisioning } from "../store";
import { Provisioning } from "../types";
import { defaultProvisioning } from "../utils";

type FormValues = Provisioning;

type Props = {
    open: boolean;
    onClose: () => void;
};

const CreateProvisioningDialog = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentWorkspace = useSelector(selectWorkspace);
    const parentForm = useForm();

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const provisioningNames = provisionings.map((item) => item.name);

    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.name) {
            errors.name = "Settings name is not allowed to be empty";
        }
        if (provisioningNames.includes(values.name)) {
            errors.name = "Settings name must be unique";
        }

        return errors;
    };
    const onSubmit = async (values: FormValues) => {
        if (currentWorkspace?.id) {
            const { name, settings } = values;
            await dispatch(createProvisioning({ name, workspaceId: currentWorkspace.id, settings }))
                .unwrap()
                .then((newProvisioning) => {
                    showSuccessToast("Created new provisioning settings");
                    parentForm.initialize(newProvisioning);
                })
                .catch(({ message = "Update failed" }) => showErrorToast(message))
                .finally(() => props.onClose());
        }
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            void form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={defaultProvisioning}
            render={({ handleSubmit, submitting, valid, form }) => (
                <Dialog open={props.open} onKeyDown={(e) => onKeyDown(e, form)}>
                    <DialogTitle>Create new provisioning settings</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <form onSubmit={handleSubmit}>
                            <SectionContainer style={{ padding: 0 }}>
                                <TextField label="Settings name" fullWidth name="name" style={{ paddingBottom: 8 }} />
                            </SectionContainer>
                            <DialogActions style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                                <PrimaryButton type="submit" disabled={submitting || !valid} loading={submitting}>
                                    Create
                                </PrimaryButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            )}
        />
    );
};

export default CreateProvisioningDialog;
