import { useState } from "react";
import { useDispatch } from "src/store";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import {
    createDeviceMdmCommand,
    createGroupMdmCommand,
    deleteDeviceMdmCommand,
    deleteGroupMdmCommand,
    updatePolicy,
} from "@dashboard/devices/store";
import { Device, Group } from "@dashboard/devices/types";
import { MdmCommand } from "@dashboard/devices/types/commands";

interface CreateCommandProps {
    groupId?: Group["id"];
    deviceId?: Device["id"];
    json: Record<string, unknown>;
}
interface DeleteCommandProps {
    groupId?: Group["id"];
    deviceId?: Device["id"];
    command: MdmCommand;
}
interface UpdatePolicyProps {
    groupId: Group["id"];
    policy: string;
    onSuccess?: () => void;
}

export const useCommands = () => {
    const dispatch = useDispatch();

    const [loadingState, setLoadingState] = useState({
        createLoading: false,
        deleteLoading: false,
        updatePolicyLoading: false,
        clearPolicyLoading: false,
    });

    const createMdmCommand = async ({ groupId, deviceId, json }: CreateCommandProps) => {
        setLoadingState({ ...loadingState, createLoading: true });
        try {
            if (groupId) {
                await dispatch(createGroupMdmCommand({ groupId, json }))
                    .unwrap()
                    .then(() => {
                        showSuccessToast("Command sent");
                    });
            } else if (deviceId) {
                await dispatch(createDeviceMdmCommand({ deviceId, json }))
                    .unwrap()
                    .then(() => {
                        showSuccessToast("Command sent");
                    });
            }
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message ?? "Command sending failed");
        } finally {
            setLoadingState({ ...loadingState, createLoading: false });
        }
    };
    const deleteMdmCommand = async ({ groupId, deviceId, command }: DeleteCommandProps) => {
        setLoadingState({ ...loadingState, deleteLoading: true });
        try {
            if (deviceId) {
                await dispatch(deleteDeviceMdmCommand({ deviceId, commandId: command.id }))
                    .unwrap()
                    .then(() => {
                        showSuccessToast("Command was deleted");
                    });
            } else if (groupId) {
                await dispatch(deleteGroupMdmCommand({ groupId, command }))
                    .unwrap()
                    .then(() => {
                        showSuccessToast("Command was deleted");
                    });
            }
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message ?? "Command deletion failed");
        } finally {
            setLoadingState({ ...loadingState, deleteLoading: false });
        }
    };
    const updatePolicyCommands = async ({ groupId, policy, onSuccess }: UpdatePolicyProps) => {
        setLoadingState({ ...loadingState, updatePolicyLoading: true });

        try {
            await dispatch(updatePolicy({ groupId, policy }))
                .unwrap()
                .then(() => {
                    showSuccessToast("Policy commands updated");
                    if (onSuccess) {
                        onSuccess();
                    }
                });
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message ?? "Policy commands update failed");
        } finally {
            setLoadingState({ ...loadingState, updatePolicyLoading: false });
        }
    };
    const clearPolicyCommands = async ({ groupId, policy }: UpdatePolicyProps) => {
        setLoadingState({ ...loadingState, clearPolicyLoading: true });

        try {
            await dispatch(updatePolicy({ groupId, policy }))
                .unwrap()
                .then(() => {
                    showSuccessToast("Policy deleted");
                });
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message ?? "Policy commands deletion failed");
        } finally {
            setLoadingState({ ...loadingState, updatePolicyLoading: false });
        }
    };

    return { createMdmCommand, deleteMdmCommand, updatePolicyCommands, clearPolicyCommands, loadingState };
};
