import { TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";

const useStyles = makeStyles({
    text: { width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
});

interface Props {
    value: string;
    onRemove: () => void;
}

const TableItem = ({ value, onRemove }: Props) => {
    const classes = useStyles();

    return (
        <TableRow>
            <MinimalTableCell>
                <Typography className={classes.text}>{value}</Typography>
            </MinimalTableCell>
            <MinimalTableCell align="right">
                <DangerOutlinedButton onClick={onRemove} tooltipProps={{ title: "Delete" }}>
                    <i className="fas fa-trash-alt" />
                </DangerOutlinedButton>
            </MinimalTableCell>
        </TableRow>
    );
};

export default TableItem;
