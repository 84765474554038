import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: "flex",
        alignItems: "flex-start",
        borderBottom: "1px solid " + theme.palette.shadow[150],
        margin: "0.75rem 0",
        paddingBottom: 10,
    },
    label: {
        width: "30%",
        paddingTop: 0,
    },
    requiredLabel: {
        width: "30%",
        paddingTop: 0,
        "&::after": {
            content: "\"*\"",
            color: theme.palette.red[150],
            marginLeft: "0.25rem",
        },
    },
    phoneBox: { display: "flex", width: "100%" },
    footer: { display: "flex", flexDirection: "column", paddingTop: 10, gap: 5 },
    agreeBox: { width: 800 },
    checkboxLabel: {
        marginTop: 10,
        marginLeft: 10,
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "3rem",
    },
    subTitleContainer: {
        paddingTop: "0.5rem",
        paddingBottom: "0.25rem",
    },
    finishRegistrationContainer: {
        marginTop: "2rem",
        marginBottom: "2rem",
        display: "flex",
        justifyContent: "center",
    },
    phoneNumberContainer: {
        marginLeft: 10,
    },
}));
