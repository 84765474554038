import { shallowEqual } from "react-redux";
import { RootState, useDispatch, useSelector } from "src/store";

import { deleteUniversalLicense } from "@devices/store";
import { License } from "@devices/types";
import { selectGroupById } from "@groups/store/selectors";
import { permissionService } from "@services/permissionService/permissionService";
import { DangerOutlinedButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { selectWorkspace } from "@workspaces/store/selectors";

export const ActionCell = (props: License) => {
    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace);
    const location = useSelector((state: RootState) => selectGroupById(state, props.groupId), shallowEqual);

    const permission = permissionService();
    const cannotRemove = permission.groupAbility(location || null, workspace).cannot("manage", "UniversalLicense");

    const onRemove = async () => {
        try {
            await dispatch(deleteUniversalLicense(props.id)).unwrap();
            showSuccessToast("License is not universal anymore");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Failed to remove the universal license. Please try again");
        }
    };

    return (
        <MinimalTableCell align="right" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <DangerOutlinedButton
                onClick={onRemove}
                disabled={cannotRemove}
                tooltipProps={{
                    title: cannotRemove
                        ? "You role does not allow to delete universal license"
                        : "Delete universal license",
                }}
            >
                <i className="fa-solid fa-trash-alt" />
            </DangerOutlinedButton>
        </MinimalTableCell>
    );
};
