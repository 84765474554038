import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import euporiaService from "src/services/euporiaService";
import { useDispatch } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";

import { redeemVoucher } from "@dashboard/devices/store";
import { fetchWorkspaces } from "@dashboard/workspaces/store";
import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles({
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 5,
        paddingTop: 10,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 0,
        minWidth: 450,
    },
    text: { marginTop: 0, marginBottom: 15 },
    btnBox: {
        paddingLeft: 24,
        paddingRight: 24,
    },
});

interface FormValues {
    code: string;
}

export const RedeemVoucherModal = ({ onClose, ...props }: Omit<DialogProps, "onClose"> & { onClose: () => void }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const onSubmit = async (values: FormValues) => {
        try {
            const trimmedCode = values.code.trim();
            await dispatch(redeemVoucher(trimmedCode)).unwrap();
            await dispatch(fetchWorkspaces()).unwrap();
            navigate("/dashboard/devices");
            euporiaService.createTransaction("voucher", {
                voucher_form: "Redeem voucher form",
                voucher_code: trimmedCode,
            });
            showSuccessToast("Voucher successfully redeemed");
        } catch (error) {
            showErrorToast("Voucher redemption failed");
        }
    };

    return (
        <Dialog {...props}>
            <DialogTitle className={classes.title}>
                <Typography variant="h6">Redeem voucher</Typography>
                <IconButton onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <Form<FormValues>
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent className={classes.content}>
                            <span className={classes.text}>Please enter your voucher code:</span>
                            <TextField name="code" disabled={submitting} placeholder="Voucher code" />
                        </DialogContent>
                        <DialogActions className={classes.btnBox}>
                            <PrimaryButton type="submit" loading={submitting}>
                                Redeem
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};
