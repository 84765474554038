import _ from "lodash";
import { useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectGroups, selectGroupsMembersByWorkspace } from "src/ui/containers/dashboard/groups/store/selectors";

import { selectDevices } from "@dashboard/devices/store/selectors/devices";
import { Device, GroupMember } from "@dashboard/devices/types";
import { SourceType } from "@dashboard/files/api";
import { FileInfo, FileTableItem } from "@dashboard/files/types";
import { selectWorkspaces } from "@dashboard/workspaces/store/selectors";

const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;
const stableSort = (item: FileTableItem, orderBy: string) => {
    const value = item[orderBy as keyof FileTableItem];
    if (typeof value === "string") {
        return value.toLowerCase();
    }
    return value || 0;
};
const hasAllFilters = (tags: API.Tag[], filters: Record<string, boolean>) => {
    if (!Array.isArray(tags)) {
        return false;
    }

    const keys = new Set();
    tags.forEach((item) => {
        if (item && item.key) {
            keys.add(item.key);
        }
    });
    return Object.keys(filters).some((key) => keys.has(key));
};
export const getUploader = (sourceType: SourceType, member: GroupMember | undefined, device: Device | undefined) => {
    if (sourceType === SourceType.User && member?.email) {
        return member.email;
    } else if (sourceType === SourceType.Device && device?.name) {
        return device.name;
    } else if (sourceType === SourceType.Microservice) {
        return "OS CI";
    } else {
        return "-";
    }
};
export const getFilteredFiles = (files: FileTableItem[], filters: Record<string, boolean>): FileTableItem[] => {
    const activeFilters = _.pickBy(filters, (value) => value);

    if (isObjEmpty(activeFilters)) {
        return files;
    }

    return files.filter((item) => {
        const tags = item.metadata?.user?.tags;
        return tags ? hasAllFilters(tags, activeFilters) : false;
    });
};
export interface Sort<T> {
    orderBy: T;
    orderDirection: "asc" | "desc";
}
export const useGetSortedFiles = (files: FileInfo[], sort: Sort<string>) => {
    const groups = useSelector(selectGroups, shallowEqual);
    const devices = useSelector(selectDevices, shallowEqual);
    const workspaces = useSelector(selectWorkspaces, shallowEqual);
    const allWorkspaceGroupsMembers = useSelector(selectGroupsMembersByWorkspace, shallowEqual);

    const prevSort = useRef("");
    const prevFiles = useRef("");
    const sortedFilesFiles = useRef<FileTableItem[]>([]);

    if (JSON.stringify(files) === prevFiles.current && JSON.stringify(sort) === prevSort.current) {
        return sortedFilesFiles.current;
    }

    const transformedFiles: FileTableItem[] = files.map((file) => {
        const device = devices.find((item) => String(item.id) === file.ownerId);
        const group = groups.find((item) => item.id === file.groupId);
        const members = file.workspaceId ? allWorkspaceGroupsMembers : group?.members;
        const member = members?.find((item) => item.userId === file.ownerId);
        const workspaceName = workspaces.find((workspace) => workspace.id === file.workspaceId)?.name;

        return {
            ...file,
            groupName: group?.name || "-",
            uploader: getUploader(file.source, member, device),
            visibility: group?.name ?? workspaceName,
        };
    });

    prevSort.current = JSON.stringify(sort);
    prevFiles.current = JSON.stringify(files);
    sortedFilesFiles.current = _.orderBy(
        transformedFiles,
        [(item) => stableSort(item, sort.orderBy)],
        [sort.orderDirection],
    );

    return sortedFilesFiles.current;
};
