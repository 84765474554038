import { isDateInThePast } from "src/ui/containers/dashboard/devices/utils/dates";

import { getEnv } from "./configService";

// If you make any changes to this list, please add @tereidt to the review process and notify everyone in the Teams web channel.
export type LocalStorageKey =
    | "token"
    | "totp"
    | "reduxVersion"
    | "endpoint"
    | "campaign"
    | "betaFeatures"
    | "store"
    | "lastConfirmationFlow"
    | "drawerOpen"
    | "currentWorkspace"
    | "lastExpirationBannerDate"
    | "overviewPageGroupFilter"
    | "includeTerminatedWorkspaces"
    | "collapsedProvisioningSections"
    | "lastSelectedProduct"
    | "fetchPublicProducts"
    | "devicesPageVisibleColumns"
    | "devicesPageRowsPerPage";

type ExpireLocalStorage<T> = {
    value: T;
    expire: number;
};

function isExpireStorageType<T>(value: unknown): value is ExpireLocalStorage<T> {
    return (
        value !== null &&
        typeof value === "object" &&
        "value" in value &&
        "expire" in value &&
        typeof value.expire === "number"
    );
}
function parseValue(value: string) {
    try {
        if (typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            return value.toLowerCase() === "true";
        }

        if (!value.startsWith("{")) {
            return value;
        }

        return JSON.parse(value, (_key: string, val: unknown) => {
            if (typeof val === "string" && (val.toLowerCase() === "true" || val.toLowerCase() === "false")) {
                return val.toLowerCase() === "true";
            }

            if (typeof val === "string" && /^\d+\.\d+$/.test(val)) {
                return parseFloat(val);
            }

            return val;
        });
    } catch (error) {
        console.error("parseValue", error, value);
        return value;
    }
}
function stringifyValue(value: unknown) {
    try {
        return typeof value === "string" ? value : JSON.stringify(value);
    } catch (error) {
        if (getEnv() === "Development") {
            console.error("stringifyValue", error, value);
        }
        return null;
    }
}

export const LocalStorage = {
    setItem: (key: LocalStorageKey, payload: unknown, expire?: number) => {
        let value = null;

        if (expire) {
            value = stringifyValue({
                value: payload,
                expire: new Date().getTime() + expire,
            });
        }
        if (!expire) {
            value = stringifyValue(payload);
        }

        if (value) {
            localStorage.setItem(key, value);
        }
    },
    getItem: <T>(key: LocalStorageKey): T | null => {
        const storage = localStorage.getItem(key);
        if (!storage) {
            return null;
        }

        const value = parseValue(storage);

        const isExpireStorage = isExpireStorageType<T>(value);
        if (!isExpireStorage) {
            return value;
        }

        if (isDateInThePast(value.expire)) {
            localStorage.removeItem(key);
            return null;
        }

        return value.value;
    },
    removeItem: (key: LocalStorageKey) => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    },
};
