import { useField, UseFieldConfig } from "react-final-form";

import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormControlProps,
    FormHelperText,
    styled,
} from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    marginTop: 0,
    marginLeft: 33,
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));
const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    "&:hover": {
        backgroundColor: "transparent",
    },
    "&.Mui-checked": {
        color: theme.palette.blue[150],
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));
const StyledFormControlLabel = styled(FormControlLabel)({
    marginBottom: 0,
    padding: 0,
    margin: 0,
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    "& .MuiFormControlLabel-label": {
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
});

interface Props extends CheckboxProps {
    name: string;
    label?: React.ReactNode;
    helperText?: string;
    config?: UseFieldConfig<boolean, boolean>;
}

export const CheckboxField = ({ name, config, label, helperText, ...rest }: Props) => {
    const { input, meta } = useField(name, { ...config, type: "checkbox" });
    const error = meta.touched && meta.error;

    return (
        <FormControl error={error} component="fieldset">
            <StyledFormControlLabel control={<StyledCheckbox {...input} {...rest} />} label={label} />
            <StyledFormHelperText>{error || helperText}</StyledFormHelperText>
        </FormControl>
    );
};

interface UncontrolledCheckboxFieldProps extends CheckboxProps {
    label?: React.ReactNode;
    helperText?: string;
    controlProps?: FormControlProps;
}
export const UncontrolledCheckboxField = ({ controlProps, ...props }: UncontrolledCheckboxFieldProps) => {
    return (
        <FormControl {...controlProps}>
            <StyledFormControlLabel control={<StyledCheckbox {...props} />} label={props.label} />
            <StyledFormHelperText>{props.helperText}</StyledFormHelperText>
        </FormControl>
    );
};
