import React from "react";
import { useNavigate } from "react-router";
import { theme } from "src/providers/ThemeProvider";
import { RootState, useDispatch, useSelector } from "src/store";

import { IconSize } from "@dashboard/devices/components/shared/index";
import {
    defaultFilter,
    setViewGroupsCollapseAll,
    setViewGroupsExpandById,
    updateFilterProperties,
} from "@dashboard/devices/store";
import { filterLicensesByProperties } from "@dashboard/devices/store/helpers/index";
import { selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { Group, License } from "@dashboard/devices/types/index";
import { setColumnVisibility } from "@dashboard/devices/utils/columnVisibility";
import { selectGroupsByIds } from "@dashboard/groups/store/selectors";
import { selectLicensesByGroupIds } from "@dashboard/licenses/store/selectors";
import { CustomTileContainer, CustomTileLink, CustomTileText, CustomTileTitle } from "@shared/CustomTile";
import { LoadingIcon } from "@shared/Loading";
import { ProgressBar } from "@shared/ProgressBar";

import { calculateBarValue, useStyles } from "./shared";

const countOnlineDevices = (allLicenses: License[], allGroups: Group[]) =>
    filterLicensesByProperties({
        licenses: allLicenses,
        filters: {
            ...defaultFilter.properties,
            device_status: ["Online"],
            license_activationStatus: ["Used"],
        },
        groups: allGroups,
    }).length;

const getBarColor = (totalDevices: number, onlineDevices: number) => {
    if (onlineDevices === totalDevices) {
        return theme.palette.green[100];
    }
    if (onlineDevices === 0) {
        return theme.palette.red[100];
    }
    return theme.palette.orange[100];
};

const OnlineStatusCard = (props: { groupIds: number[] }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state: RootState) => state.devices.list.licenses.status);

    const groups = useSelector((state) => selectGroupsByIds(state, props.groupIds));
    const groupsNames = groups.map((group) => group.name);
    const licenses = useSelector((state) => selectLicensesByGroupIds(state, props.groupIds));
    const activeLicense = licenses.filter((license) => license.device).length;
    const devices = useSelector((state) => selectDevicesByGroupIds(state, props.groupIds));
    const onlineDevicesCount = countOnlineDevices(licenses, groups);

    const offlineDeviceCount = devices.length - onlineDevicesCount;
    const barValue = calculateBarValue(devices.length - onlineDevicesCount, devices.length);
    const barColor = getBarColor(devices.length, onlineDevicesCount);

    const filterByStatus = () => {
        if (onlineDevicesCount < devices.length) {
            dispatch(
                updateFilterProperties({
                    device_status: ["Offline"],
                    license_activationStatus: ["Used"],
                    group_name: groupsNames,
                }),
            );
        } else {
            dispatch(
                updateFilterProperties({
                    device_status: ["Online"],
                    license_activationStatus: ["Used"],
                    group_name: groupsNames,
                }),
            );
        }

        dispatch(setViewGroupsCollapseAll(true));
        dispatch(setViewGroupsExpandById(props.groupIds));
        navigate("/dashboard/devices");
    };
    const handleOnClick = () => {
        if (devices.length) {
            filterByStatus();
            setColumnVisibility("onlineStatus");
        }
    };

    return (
        <CustomTileContainer>
            <CustomTileTitle>Connection status</CustomTileTitle>
            {isLoading === "pending" ? (
                <div className={classes.loadingBox}>
                    <LoadingIcon size={IconSize.large} />
                </div>
            ) : (
                <React.Fragment>
                    {!devices.length ? <CustomTileText>No devices</CustomTileText> : null}
                    {devices.length && onlineDevicesCount === devices.length ? (
                        <CustomTileText>All devices online</CustomTileText>
                    ) : null}
                    {devices.length && onlineDevicesCount !== devices.length ? (
                        <CustomTileLink onClick={handleOnClick}>{`${offlineDeviceCount} offline device${
                            offlineDeviceCount > 1 ? "s" : ""
                        }`}</CustomTileLink>
                    ) : null}
                    {activeLicense ? <ProgressBar progress={barValue} color={barColor} /> : null}
                </React.Fragment>
            )}
        </CustomTileContainer>
    );
};

export default OnlineStatusCard;
