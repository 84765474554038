import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";
import semver from "semver";
import { useDispatch } from "src/store";

import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { deleteProductChangelog } from "@products/store/index";
import { Product } from "@products/types/index";
import { permissionService } from "@services/permissionService/permissionService";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { selectWorkspace } from "@workspaces/store/selectors";

const useStyles = makeStyles(() => ({
    versionBox: {
        marginBottom: 8,
    },
    topDistance: {
        marginTop: 12,
    },
    changeList: {
        paddingLeft: 16,
        marginBottom: 8,
    },
    title: {
        paddingBottom: 0,
    },
    version: {
        fontSize: 16,
        fontWeight: "bold",
    },
    dialogActions: {
        justifyContent: "space-between",
        padding: 24,
    },
    dialogActionsFlexEnd: {
        justifyContent: "flex-end",
    },
    editBtn: {
        alignSelf: "flex-end",
    },
    deleteButtonBox: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 20,
    },
    deleteButton: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    grow: {
        flexGrow: 1,
    },
    hidden: {
        display: "none",
    },
}));

interface ChangeLinesProps {
    change: string;
}
const ChangeLines = (props: ChangeLinesProps) => (
    <Box>
        {props.change.split("\n").map((line, lineIndex) => (
            <Typography key={lineIndex} variant="body2">
                {line}
            </Typography>
        ))}
    </Box>
);

interface VersionChangesProps {
    version: string;
    isEditing: boolean;
    changesList: string[];
    productId?: number;
}
const VersionChanges = (props: VersionChangesProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentWorkspace = useSelector(selectWorkspace);
    const { productAbility } = permissionService();
    const cannotManageProducts = productAbility(currentWorkspace).cannot("manage", "Product");

    const handleDelete = async () => {
        if (props.productId) {
            try {
                await dispatch(deleteProductChangelog({ productId: props.productId, version: props.version })).unwrap();
                showSuccessToast("Successfully deleted this version's changelog");
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message || "Failed to delete this version's changelog");
            }
        }
    };

    return (
        <Box key={props.version} className={classes.versionBox}>
            <Box className={classes.deleteButtonBox}>
                <Typography className={classes.version}>{`Version ${props.version}:`}</Typography>
                {props.isEditing ? (
                    <DangerOutlinedButton
                        onClick={handleDelete}
                        className={classes.deleteButton}
                        disabled={cannotManageProducts}
                        tooltipProps={{
                            title: cannotManageProducts
                                ? "Your role does not allow you to delete changelog"
                                : "Delete changelog",
                            placement: "top",
                        }}
                    >
                        <i className="fa-solid fa-trash-alt" />
                    </DangerOutlinedButton>
                ) : null}
            </Box>
            <Box className={classes.changeList}>
                {props.changesList.length > 0 ? (
                    props.changesList.map((change, index) => <ChangeLines key={index} change={change} />)
                ) : (
                    <Typography variant="body2">This version has no change log</Typography>
                )}
            </Box>
        </Box>
    );
};

interface ChangesListProps {
    isEditing: boolean;
    sortedChanges: [string, string[]][];
    productId?: number;
}
const ChangesList: React.FC<ChangesListProps> = (props: ChangesListProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.topDistance}>
            {props.sortedChanges.length > 0 ? (
                props.sortedChanges.map(([version, changesList]) => (
                    <VersionChanges
                        isEditing={props.isEditing}
                        key={version}
                        version={version}
                        changesList={changesList}
                        productId={props.productId}
                    />
                ))
            ) : (
                <Typography variant="body2">No release notes yet</Typography>
            )}
        </Box>
    );
};

interface ChangesDialogProps extends Omit<DialogProps, "onClose"> {
    onClose: () => void;
    changes: Product["changes"] | null;
    productId?: number;
}
export const ChangesDialog = ({ productId, changes, ...props }: ChangesDialogProps) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const sortedChanges = changes ? Object.entries(changes).sort(([a], [b]) => semver.compare(b, a)) : [];

    const handleEdit = () => {
        setIsEditing((prev) => !prev);
    };
    const handleOnClose = () => {
        props.onClose();
        setIsEditing(false);
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" || e.key === "Escape") {
            e.preventDefault();
            handleOnClose();
        }
    };

    return (
        <Dialog fullWidth {...props} onKeyDown={(e) => onKeyDown(e)}>
            <DialogTitle className={classes.title}>{props.title}</DialogTitle>
            <DialogContent>
                <ChangesList isEditing={isEditing} sortedChanges={sortedChanges} productId={productId} />
            </DialogContent>
            <DialogActions className={clsx(classes.dialogActions, !productId && classes.dialogActionsFlexEnd)}>
                {productId && sortedChanges.length > 0 && (
                    <SecondaryButton
                        onClick={handleEdit}
                        className={clsx(classes.editBtn, !sortedChanges.length && classes.hidden)}
                        tooltipProps={{ title: "Manage version changelogs" }}
                    >
                        <i className="fa-solid fa-cogs" />
                    </SecondaryButton>
                )}
                <SecondaryButton onClick={handleOnClose}>Close</SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};
