import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOldReleasedImages, getReleasedImages } from "src/services/imageService";

import { PageContainer } from "@dashboard/shared/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    capitalize,
    CardHeader,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { showErrorToast } from "@shared/toasts/Toasts";

import { useStyles } from "./ReleaseNotesPage.style";

interface State {
    releasedImages: API.ReleasedImage[];
    filteredImages: API.ReleasedImage[];
    allProductNames: { [key: string]: string };
    allChannels: string[];
    allVersions: string[];
    selectedProductNames: string[];
    selectedChannels: string[];
    selectedVersions: string[];
}

const ReleaseNotesPage = () => {
    const classes = useStyles();
    const location = useLocation();
    const [state, setState] = useState<State>({
        releasedImages: [],
        filteredImages: [],
        allProductNames: {},
        allChannels: [],
        allVersions: [],
        selectedProductNames: [],
        selectedChannels: ["Live", "Beta"],
        selectedVersions: [],
    });

    const handleOptionsChange = (parameter: keyof State, selected: string[]) => {
        setState((prevState) => {
            const newState = { ...prevState, [parameter]: selected };

            const filteredImages = prevState.releasedImages.filter((image) => {
                const matchesProductName =
                    !newState.selectedProductNames.length || newState.selectedProductNames.includes(image.productName);
                const matchesVersions =
                    !newState.selectedVersions.length ||
                    newState.selectedVersions.some((selectedVersion) =>
                        image.version.startsWith(selectedVersion.replace("Android ", "")),
                    );
                const matchesChannels =
                    !newState.selectedChannels.length || newState.selectedChannels.includes(capitalize(image.channel));

                return matchesProductName && matchesVersions && matchesChannels;
            });

            newState.filteredImages = filteredImages;

            return newState;
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [newImages, oldImages] = await Promise.all([getReleasedImages(), getOldReleasedImages()]);

                const images = [...newImages, ...oldImages];

                const productNames = Array.from(new Set(images.map((image) => image.productName))).sort();
                const sortedProductNames = Object.fromEntries(
                    Object.entries(productNames).sort(([, a], [, b]) => a.localeCompare(b)),
                );
                const channels = Array.from(
                    new Set(
                        images.map(
                            (image) => image.channel.charAt(0).toUpperCase() + image.channel.slice(1).toLowerCase(),
                        ),
                    ),
                ).sort((a, b) => {
                    const order = ["Live", "Beta", "Nightly", "None"];
                    return order.indexOf(a) - order.indexOf(b);
                });
                const versions = Array.from(new Set(images.map((image) => image.version.split(".")[0])))
                    .map(Number)
                    .sort((a, b) => b - a)
                    .map((version) => `Android ${version}`);

                const sortedImages = images.sort(
                    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
                );

                setState((prevState) => ({
                    ...prevState,
                    allProductNames: sortedProductNames,
                    allChannels: channels,
                    allVersions: versions,
                    releasedImages: sortedImages,
                    filteredImages: sortedImages,
                }));
                handleOptionsChange("selectedChannels", ["Live", "Beta"]);
            } catch (error) {
                showErrorToast("Failed fetching released images");
            }
        };
        void fetchData();
    }, [location.search]);

    return (
        <PageContainer>
            <Box className={classes.container}>
                <FormControl fullWidth>
                    <InputLabel className={classes.label} id="captions-select-label">
                        Product names
                    </InputLabel>
                    <Select
                        labelId="productNames-select-label"
                        multiple
                        value={state.selectedProductNames}
                        onChange={(e) => handleOptionsChange("selectedProductNames", e.target.value as string[])}
                        renderValue={(selected) => (Array.isArray(selected) ? selected.join(", ") : "")}
                    >
                        {Object.entries(state.allProductNames).map(([device, productName]) => (
                            <MenuItem key={device} value={productName}>
                                <Checkbox checked={state.selectedProductNames.includes(productName)} />
                                <ListItemText primary={productName} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={classes.label} id="versions-select-label">
                        Android versions
                    </InputLabel>
                    <Select
                        labelId="versions-select-label"
                        multiple
                        value={state.selectedVersions}
                        onChange={(e) => handleOptionsChange("selectedVersions", e.target.value as string[])}
                        renderValue={(selected) => (Array.isArray(selected) ? selected.join(", ") : "")}
                    >
                        {state.allVersions.map((version) => (
                            <MenuItem key={version} value={version}>
                                <Checkbox checked={state.selectedVersions.includes(version)} />
                                <ListItemText primary={version} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel className={classes.label} id="channels-select-label">
                        Release channels
                    </InputLabel>
                    <Select
                        labelId="channels-select-label"
                        multiple
                        value={state.selectedChannels}
                        onChange={(e) => handleOptionsChange("selectedChannels", e.target.value as string[])}
                        renderValue={(selected) => (Array.isArray(selected) ? selected.join(", ") : "")}
                    >
                        {state.allChannels.map((channel) => (
                            <MenuItem key={channel} value={channel}>
                                <Checkbox checked={state.selectedChannels.includes(channel)} />
                                <ListItemText primary={channel} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <Box className={classes.flexContainer}>
                            <Typography style={{ width: "45%" }} className={classes.tableHeaders}>
                                Product
                            </Typography>
                            <Typography style={{ width: "15%" }} className={classes.tableHeaders}>
                                Version
                            </Typography>
                            <Typography style={{ width: "15%" }} className={classes.tableHeaders}>
                                Date
                            </Typography>
                            <Typography style={{ width: "15%" }} className={classes.tableHeaders}>
                                Channel
                            </Typography>
                        </Box>
                    }
                />
            </Box>
            {state.filteredImages.length > 0 ? (
                state.filteredImages.map((image, index) => (
                    <Accordion key={index}>
                        <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Box className={classes.flexContainer}>
                                <ExpandMoreIcon className={classes.expandMoreIcon} />
                                <Box className={classes.flexContainer}>
                                    <Typography style={{ width: "45%" }}>{image.productName}</Typography>
                                    <Typography style={{ width: "15%" }}>{image.version}</Typography>
                                    <Typography style={{ width: "15%" }}>
                                        {new Date(image.timestamp).toLocaleDateString()}
                                    </Typography>
                                    <Typography style={{ width: "15%" }}>{capitalize(image.channel)}</Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.logsContainer}>
                            {image.changes.length > 0 ? (
                                image.changes.map((change, i) => (
                                    <Typography key={i} className={classes.changeLog}>
                                        - {change}
                                    </Typography>
                                ))
                            ) : (
                                <Typography>This release has no recorded changes</Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Box className={classes.noImagesBox}>
                    <Typography>No released images available</Typography>
                </Box>
            )}
        </PageContainer>
    );
};

export default ReleaseNotesPage;
