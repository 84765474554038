import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteApiKeys, getAccount, getApiKeys, getBillingCard, recreateApiKeys } from "src/services/accountService";
import { LocalStorage } from "src/services/localStorageService";
import paymentService from "src/services/paymentService";
import { useDispatch, useSelector } from "src/store";
import {
    selectLegacyDeviceOverviewEnabled,
    selectLegacyManagementEnabled,
    selectLegacyProvisioningEnabled,
    setBetaFeatureActive,
} from "src/store/beta";
import ApmLink from "src/ui/shared/ApmLink";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { Tooltip } from "src/ui/shared/Tooltip";

import { PageContainer } from "@dashboard/shared/styles";
import { Box, Card, CardHeader, Divider, List, ListItem, Switch, SwitchProps } from "@mui/material";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import EditStripeCard from "./EditStripeCard";
import { useStyles } from "./ProfilePage.style";

type FeatureSwitchProps = SwitchProps & {
    description: string;
};
const FeatureSwitch = ({ description, ...props }: FeatureSwitchProps) => {
    const classes = useStyles();
    return (
        <ListItem>
            <Box className={classes.switchBox}>
                <Switch {...props} inputProps={{ "aria-label": "primary checkbox" }} color="primary" />
                <Box className={classes.switchContent}>
                    <span className={classes.switchLabel}>{props.title}</span>
                    <div>{description}</div>
                </Box>
            </Box>
        </ListItem>
    );
};

const ProfilePage = () => {
    const [profile, setProfile] = useState<API.Profile>();
    const [billingAddress, setBillingAddress] = useState<API.BillingAddress | undefined>();
    const [billingCard, setBillingCard] = useState<API.BillingCard | null>(null);

    const [apiKeys, setApiKeys] = useState<string | undefined>();
    const [isRemovingApiKey, setIsRemovingApiKey] = useState(false);
    const [isRenewApiKey, setIsRenewApiKey] = useState(false);
    const [isModalShown, setIsModalShown] = useState(false);
    const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | Stripe | null>(null);

    const classes = useStyles();
    const dispatch = useDispatch();

    const isLegacyMdmManagementLinkVisible = useSelector(selectLegacyManagementEnabled);
    const isLegacyProvisioningPageEnabled = useSelector(selectLegacyProvisioningEnabled);
    const isLegacyDeviceOverviewEnabled = useSelector(selectLegacyDeviceOverviewEnabled);
    const fetchPublicProducts = LocalStorage.getItem<boolean>("fetchPublicProducts");

    const handleApiKeysRenew = async () => {
        setIsRenewApiKey(true);

        try {
            const { model, message } = await recreateApiKeys();
            setApiKeys(model);
            showSuccessToast(message);
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setIsRenewApiKey(false);
        }
    };
    const handleApiKeysDeletion = async () => {
        setIsRemovingApiKey(true);

        try {
            const { message } = await deleteApiKeys();
            showSuccessToast(message);
            setApiKeys("");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setIsRemovingApiKey(false);
        }
    };

    const handleFetchPublicProductsToggle: FeatureSwitchProps["onChange"] = (_, checked) => {
        LocalStorage.setItem("fetchPublicProducts", checked);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data } = await getAccount();
                setProfile(data.profile);
                setBillingAddress(data?.billingAddress);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            }

            try {
                const _billingCard = await getBillingCard();
                setBillingCard(_billingCard);
                if (_billingCard !== null) {
                    const promiseData = await paymentService.getStripePromise(loadStripe);
                    setStripePromise(promiseData as PromiseLike<Stripe | null> | Stripe | null);
                }
            } catch (error) {
                showErrorToast("Failed retrieving billing card");
            }

            try {
                const apiKeysData = await getApiKeys();
                setApiKeys(apiKeysData);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            }
        };

        void loadData();
    }, []);

    if (!profile) {
        return null;
    }

    return (
        (<PageContainer className={classes.container}>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={<span style={{ fontSize: 18 }}>Navigation settings</span>}
                />
                <List className={classes.list}>
                    <ListItem className={classes.warningBox}>
                        <i className="fas fa-fw fa-exclamation-triangle" />
                        Following settings may be deprecated or experimental.
                        Please send us your feedback and your requirements to<a href="mailto:support@emteria.com">support@emteria.com</a>
                    </ListItem>
                    <FeatureSwitch
                        checked={Boolean(isLegacyDeviceOverviewEnabled)}
                        title='Show legacy "Devices" page'
                        description="Enable this to see the old device overview page. Please note that it will be removed in future.
                        We encourage you to start using the new device page instead."
                        onChange={(_, checked) => {
                            dispatch(
                                setBetaFeatureActive({
                                    key: "LegacyDeviceOverviewLink",
                                    value: checked,
                                    userEmail: profile.email,
                                }),
                            );
                        }}
                    />
                    <Divider />
                    <FeatureSwitch
                        checked={Boolean(isLegacyMdmManagementLinkVisible)}
                        title='Show legacy "Management" link'
                        description="Enable this to see a link to the old device management UI. Please note that it will be removed in future.
                        We encourage you to start using the new device page instead."
                        onChange={(_, checked) => {
                            dispatch(
                                setBetaFeatureActive({
                                    key: "LegacyManagementLink",
                                    value: checked,
                                    userEmail: profile.email,
                                }),
                            );
                        }}
                    />
                    <Divider />
                    <FeatureSwitch
                        checked={Boolean(isLegacyProvisioningPageEnabled)}
                        title='Show legacy "Provisioning" page'
                        description="Enable this to see the old device provisioning page. Please note that it will be removed in future.
                        We encourage you to start using the new provisioning page instead."
                        onChange={(_, checked) => {
                            dispatch(
                                setBetaFeatureActive({
                                    key: "LegacyProvisioningLink",
                                    value: checked,
                                    userEmail: profile.email,
                                }),
                            );
                        }}
                    />
                </List>
            </Card>
            <Card className={classes.card}>
                <CardHeader className={classes.header} title={<span style={{ fontSize: 18 }}>Portal settings</span>} />
                <List className={classes.list}>
                    <FeatureSwitch
                        defaultChecked={fetchPublicProducts === null || fetchPublicProducts}
                        title="Show public products"
                        description="Show releases of public third-party products in the download page."
                        onChange={handleFetchPublicProductsToggle}
                    />
                </List>
            </Card>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Box className={classes.headerBox}>
                            <span style={{ fontSize: 18 }}>Profile data</span>
                            <div className={classes.headerButtonBox}>
                                <Link to="/account/edit">
                                    <PrimaryOutlinedButton
                                        size="small"
                                        startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                    >
                                        Edit
                                    </PrimaryOutlinedButton>
                                </Link>
                                <ApmLink parent="Account profile page" title="Delete account" to="/account/delete">
                                    <DangerOutlinedButton
                                        size="small"
                                        startIcon={<i className="fas fa-fw fa-trash-alt" />}
                                    >
                                        Delete
                                    </DangerOutlinedButton>
                                </ApmLink>
                            </div>
                        </Box>
                    }
                />

                <List>
                    <ListItem>
                        <span className={classes.headerColumn}>Email</span>
                        <span className={classes.valueColumn}>{profile?.email}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Authentication</span>
                        <span className={classes.valueColumn}>
                            {profile?.twoFactorEnabled ? <span>Two-factor</span> : <span>Password</span>}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Name</span>
                        <span className={classes.valueColumn}>{profile?.name}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Phone</span>
                        <span className={classes.valueColumn}>
                            {profile?.phoneCode}
                            {profile?.phoneNumber}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Address</span>
                        <span className={classes.valueColumn}>{profile?.address}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>City</span>
                        <span className={clsx(classes.valueColumn, classes.capitalizedColumn)}>{profile?.city}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Zip code</span>
                        <span className={classes.valueColumn}>{profile?.zipCode}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Country</span>
                        <span className={classes.valueColumn}>{profile?.country}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Notifications</span>
                        <span className={classes.valueColumn}>
                            {profile?.newsletterChecked ? <span>Extended</span> : <span>Standard</span>}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Usage</span>
                        <span className={classes.valueColumn}>
                            {profile?.typeOfUsage === "b" ? <span>Business use</span> : <span>Non-business use</span>}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Company</span>
                        <span className={classes.valueColumn}>{profile?.companyName}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>VAT ID</span>
                        <span className={classes.valueColumn}>{profile?.companyVAT}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Area</span>
                        <span className={classes.valueColumn}>{profile?.fieldOfApplication}</span>
                    </ListItem>
                </List>
            </Card>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Box className={classes.headerBox}>
                            <span style={{ fontSize: 18 }}>Billing address</span>
                            <Link to="/account/billing/edit">
                                <PrimaryOutlinedButton
                                    size="small"
                                    startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                >
                                    Edit
                                </PrimaryOutlinedButton>
                            </Link>
                        </Box>
                    }
                />

                <List>
                    <ListItem>
                        <span className={classes.headerColumn}>Name</span>
                        <span className={classes.valueColumn}>{billingAddress?.name}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Address</span>
                        <span className={classes.valueColumn}>{billingAddress?.address}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>City</span>
                        <span className={classes.valueColumn}>{billingAddress?.city}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Zip code</span>
                        <span className={classes.valueColumn}>{billingAddress?.zipCode}</span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Country</span>
                        <span className={classes.valueColumn}>{billingAddress?.country}</span>
                    </ListItem>
                </List>
            </Card>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Box className={classes.headerBox}>
                            <span style={{ fontSize: 18 }}>Payment method</span>
                            <Tooltip placement="top" title="Please contact support">
                                <PrimaryOutlinedButton
                                    startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                    onClick={() => setIsModalShown(true)}
                                    size="small"
                                    disabled
                                >
                                    Edit
                                </PrimaryOutlinedButton>
                            </Tooltip>
                        </Box>
                    }
                />
                <List>
                    <ListItem>
                        <span className={classes.headerColumn}>Card type</span>
                        <span className={clsx(classes.valueColumn, classes.capitalizedColumn)}>
                            {billingCard?.brand ?? "No card set"}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Card number</span>
                        <span className={classes.valueColumn}>
                            {billingCard?.lastFourDigits
                                ? `**** **** **** ${billingCard?.lastFourDigits}`
                                : "No card number"}
                        </span>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <span className={classes.headerColumn}>Expiration date</span>
                        <span className={classes.valueColumn}>
                            {billingCard?.expMonth ?? "MM"} / {billingCard?.expYear ?? "YY"}
                        </span>
                    </ListItem>
                </List>
            </Card>
            {stripePromise ? (
                // https://github.com/stripe/react-stripe-js/issues/273#issuecomment-1102947196
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (<Elements stripe={stripePromise}>
                    <EditStripeCard open={isModalShown} onClose={() => setIsModalShown(false)} />
                </Elements>)
            ) : null}
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Box className={classes.headerBox}>
                            <span style={{ fontSize: 18 }}>API key</span>
                            <div className={classes.headerButtonBox}>
                                <PrimaryOutlinedButton
                                    startIcon={<i className="fa-solid fa-arrow-rotate-left" />}
                                    onClick={() => handleApiKeysRenew()}
                                    loading={isRenewApiKey}
                                    disabled={isRenewApiKey || isRemovingApiKey}
                                    size="small"
                                >
                                    Renew
                                </PrimaryOutlinedButton>
                                <DangerOutlinedButton
                                    disabled={!apiKeys || isRemovingApiKey || isRenewApiKey}
                                    size="small"
                                    startIcon={<i className="fas fa-fw fa-trash-alt" />}
                                    onClick={() => handleApiKeysDeletion()}
                                    loading={isRemovingApiKey}
                                >
                                    <span>Delete</span>
                                </DangerOutlinedButton>
                            </div>
                        </Box>
                    }
                />
                <List>
                    <ListItem>
                        <span>{apiKeys || "No key set"}</span>
                    </ListItem>
                </List>
            </Card>
        </PageContainer>)
    );
};

export default ProfilePage;
