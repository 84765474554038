import { useEffect } from "react";
import authService from "src/services/authService";
import { getHomepageUrl } from "src/services/configService";
import euporiaService from "src/services/euporiaService";

import { PageContainer } from "@dashboard/shared/styles";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        color: theme.palette.blue[350],
    },
}));

const LogoutPage = () => {
    const classes = useStyles();

    useEffect(() => {
        const logout = async () => {
            await authService.logout();
            euporiaService.updateUserContext();
            window.location.href = getHomepageUrl();
        };
        void logout();
    }, []);

    return (
        <PageContainer className={classes.container}>
            <h1 className={classes.title}>Logging you out...</h1>
        </PageContainer>
    );
};

export default LogoutPage;
