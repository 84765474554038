import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch } from "src/store";
import { selectGroupsMembersByWorkspace } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectLicensesBySubscriptionId } from "src/ui/containers/dashboard/licenses/store/selectors";
import { DangerOutlinedButton, PrimaryButton } from "src/ui/shared/CustomButton";
import { CustomTileContainer, CustomTileTitle } from "src/ui/shared/CustomTile";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import { Tooltip } from "src/ui/shared/Tooltip";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { cancelSubscription } from "@dashboard/devices/store";
import { Subscription } from "@dashboard/devices/types";
import { selectFiles } from "@dashboard/files/store/selectors";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { SectionContainer, SectionHeader } from "@dashboard/shared/styles";
import {
    selectWorkspace,
    selectWorkspaceFdroidRepoLimit,
    selectWorkspaceFileLimit,
    selectWorkspaceLicenseLimit,
    selectWorkspaceProductLimit,
    selectWorkspaceProvisioningLimit,
    selectWorkspaceStreamLimit,
} from "@dashboard/workspaces/store/selectors";
import { Box, Typography } from "@mui/material";

import { useStyles } from "./SubscriptionSection.style";

type TileProps = {
    badge: string;
    label: string | number;
};

const Tile = (props: TileProps) => {
    const classes = useStyles();

    return (
        <Tooltip title={props.label} className={classes.tileTooltip}>
            <CustomTileContainer>
                <CustomTileTitle>{props.badge}</CustomTileTitle>
                <Box className={classes.tileContent}>
                    <Typography variant="subtitle2" align="center" className={classes.tileLabel}>
                        {props.label}
                    </Typography>
                </Box>
            </CustomTileContainer>
        </Tooltip>
    );
};

type Props = {
    subscription: Subscription;
};
export const SubscriptionSection = (props: Props) => {
    const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const files = useSelector(selectFiles);
    const products = useSelector(selectProducts);
    const provisionings = useSelector(selectProvisionings);
    const currentWorkspace = useSelector(selectWorkspace);
    const currentWorkspaceMembers = useSelector(selectGroupsMembersByWorkspace, shallowEqual);
    const provisioningLimit = useSelector(selectWorkspaceProvisioningLimit);
    const productLimit = useSelector(selectWorkspaceProductLimit);
    const fileLimit = useSelector(selectWorkspaceFileLimit);
    const licenseLimit = useSelector(selectWorkspaceLicenseLimit);
    const fdroidRepoLimit = useSelector(selectWorkspaceFdroidRepoLimit);
    const streamLimit = useSelector(selectWorkspaceStreamLimit);
    const licenses = useSelector((state: RootState) => selectLicensesBySubscriptionId(state, props.subscription.id));
    const deviceCount = licenses.filter((l) => l.device).length;

    const { workspaceAbility } = permissionService();
    const mayCancelSubscription = workspaceAbility(currentWorkspace).can("cancel", "Workspace");

    const confirmCancelSubscription = async (id: number) => {
        await dispatch(cancelSubscription(id))
            .unwrap()
            .then(() => {
                showSuccessToast("Subscription cancelled");
            })
            .catch(({ message = "Couldn't cancel subscription" }: { message: string }) => {
                showErrorToast(message);
            })
            .finally(() => {
                setCancelSubscriptionDialogOpen(false);
            });
    };

    return (
        <React.Fragment>
            <SectionContainer className={classes.section}>
                <SectionHeader>Subscription status</SectionHeader>
                <Box className={classes.tileBox}>
                    <Tile badge="Status" label={props.subscription.status} />
                    <Tile badge="Variant" label={props.subscription.variantName} />
                    <Tile badge="Charge ID" label={props.subscription.chargeId} />
                    <Tile badge="Expiration date" label={props.subscription.expirationDate || "-"} />
                    <Tile badge="Cancellation date" label={props.subscription.cancellationDate || "-"} />
                    <Tile badge="Revocation policy" label={licenseLimit > 200 ? "Self-governing" : "Upon request"} />
                </Box>
                <DangerOutlinedButton
                    data-testid="subscriptionSection.btn.cancel"
                    startIcon={<i className="fas fa-ban" />}
                    onClick={() => setCancelSubscriptionDialogOpen(true)}
                    disabled={!mayCancelSubscription || props.subscription.isCancelled}
                    tooltipProps={{
                        hide: mayCancelSubscription && !props.subscription.isCancelled,
                        title: props.subscription.isCancelled
                            ? "Subscription is already canceled"
                            : "You have no permission to cancel the subscription",
                    }}
                >
                    Cancel subscription
                </DangerOutlinedButton>
            </SectionContainer>
            <SectionContainer className={classes.section}>
                <SectionHeader>Subscription quota</SectionHeader>
                <Box className={classes.tileBox}>
                    <Tile badge="Group limit" label={`${props.subscription.groupIds.length}/unlimited`} />
                    <Tile badge="User limit" label={`${currentWorkspaceMembers.length}/unlimited`} />

                    <Tile badge="Device limit" label={`${deviceCount}/${licenseLimit}`} />
                    <Tile badge="Provisioning limit" label={`${provisionings.length}/${provisioningLimit}`} />
                    <Tile badge="Product limit" label={`${products.length}/${productLimit}`} />
                    <Tile badge="File limit" label={`${files.length}/${fileLimit}`} />
                    <Tile badge="Stream limit" label={`${0}/${streamLimit}`} />
                    <Tile
                        badge="FDroid repository limit"
                        label={`${props.subscription.fdroidRepos?.length || 0}/${fdroidRepoLimit}`}
                    />
                </Box>
                <PrimaryButton onClick={() => window.open("https://emteria.com/p/subscription", "_blank")}>
                    Purchase limit increase
                </PrimaryButton>
            </SectionContainer>

            <ConfirmDialog
                title="Cancel subscription"
                content="You are about to cancel this subscription. After cancellation, the subscription stays valid until the next payment date and expires automatically. Once expired, all devices assigned to this subscription via contained groups will lose their access to exclusive features like updates, MDM and others. Please note that this action cannot be undone. Do you want to continue?"
                open={cancelSubscriptionDialogOpen}
                onConfirm={() => confirmCancelSubscription(props.subscription.id)}
                onClose={() => setCancelSubscriptionDialogOpen(false)}
                dangerButton
            />
        </React.Fragment>
    );
};
