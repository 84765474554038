import { isArray } from "lodash";
import { ListResponse } from "src/types";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";

import httpService from "./httpService";
import RouteService from "./routeService";

const getCountriesList = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.get<ListResponse<API.Country>>(endpoint + "locations");
        return data.list;
    } catch (error) {
        showErrorToast("Failed loading countries list");
    }
};

const getCountryPhonesList = async () => {
    const countries = await getCountriesList();
    if (isArray(countries)) {
        return countries.map((country) => ({
            value: country.phone,
            label: `${country.country} (${country.phone})`,
        }));
    }
    return [];
};

const getSelectableCountryList = async () => {
    const countries = await getCountriesList();
    if (isArray(countries)) {
        return countries.map((country) => ({
            value: country.country,
            label: country.country,
        }));
    }
    return [];
};

const getMossCountries = async () => {
    const countries = await getCountriesList();
    const mossCountries: string[] = [];

    if (isArray(countries)) {
        countries.forEach((country) => {
            if (country.usesMOSS) {
                mossCountries.push(country.country);
            }
        });
    }

    return mossCountries;
};

const countryService = {
    getCountryPhonesList,
    getSelectableCountryList,
    getMossCountries,
};

export default countryService;
