import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    welcomeBox: {
        textAlign: "center",
        padding: "32px",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
        margin: "32px",
        position: "relative",
    },
    subscriptionContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
    },
    alert: {
        marginTop: "16px",
        fontSize: 16,
        padding: 16,
        color: theme.palette.black[100],
    },
    starterSubscriptionLink: {
        color: theme.palette.black[100],
        cursour: "pointer",
        "&:hover": {
            color: theme.palette.black[100],
        },
    },
    welcomeTitle: {
        marginTop: 16,
        marginBottom: 16,
    },
}));
