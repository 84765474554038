import { RootState } from "src/store";

import { ListStatus } from "@dashboard/devices/store";
import { createAsyncThunk, createSlice, EntityState, SliceCaseReducers } from "@reduxjs/toolkit";

import {
    apiDeleteFile,
    apiFilesFetch,
    ApiFilesFetchPayload,
    ApiFilesFetchResponse,
    apiUpdateFile,
    apiUpdateFileMetadata,
    apiUpdateFileRollout,
    apiUploadFile,
} from "../api";
import { FileInfo } from "../types";
import { filesAdapter } from "./selectors";

interface FilesSlice {
    status: ListStatus | null;
    items: EntityState<FileInfo>;
    filesCount: number;
}

export const fetchFiles = createAsyncThunk<ApiFilesFetchResponse, Partial<ApiFilesFetchPayload> | undefined>(
    "files/fetch",
    (arg, { getState }) => {
        const state = getState() as RootState;
        return apiFilesFetch({
            workspaceId: arg?.workspaceId || state.workspaces.currentWorkspaceId,
            pageIndex: arg?.pageIndex || 1,
            pageSize: arg?.pageSize || 50,
            withPagination: arg?.withPagination || false,
        });
    },
);
export const uploadFile = createAsyncThunk("files/upload", apiUploadFile);
export const deleteFile = createAsyncThunk("files/delete", apiDeleteFile);
export const updateFile = createAsyncThunk("files/update", apiUpdateFile);
export const updateFileMetadata = createAsyncThunk("files/updateMetadata", apiUpdateFileMetadata);
export const updateFileRollout = createAsyncThunk("files/updateFileRollout", apiUpdateFileRollout);

const filesSlice = createSlice<FilesSlice, SliceCaseReducers<FilesSlice>, "files">({
    name: "files",
    initialState: {
        status: null,
        filesCount: 0,
        items: filesAdapter.getInitialState(),
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFiles.fulfilled, (state, action) => {
                state.status = "fulfilled";
                filesAdapter.setAll(state.items, action.payload.files);
                state.filesCount = action.payload.count;
            })
            .addCase(fetchFiles.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(fetchFiles.pending, (state) => {
                state.status = "pending";
            })
            .addCase(uploadFile.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";
                filesAdapter.addOne(state.items, payload);
            })
            .addCase(deleteFile.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";
                filesAdapter.removeOne(state.items, payload);
            })
            .addCase(deleteFile.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(deleteFile.pending, (state) => {
                state.status = "pending";
            })
            .addCase(updateFile.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";
                const existingFile = state.items.entities[payload.id];
                const updatedChanges = {
                    ...payload,
                    workspaceId: existingFile?.workspaceId || null,
                };
                filesAdapter.updateOne(state.items, { id: payload.id, changes: updatedChanges });
            })
            .addCase(updateFile.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(updateFile.pending, (state) => {
                state.status = "pending";
            })
            .addCase(updateFileMetadata.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";

                const file = filesAdapter
                    .getSelectors()
                    .selectAll(state.items)
                    .find((s) => s.id === payload.fileId);

                filesAdapter.updateOne(state.items, {
                    id: payload.fileId,
                    changes: {
                        metadata: {
                            ...file?.metadata,
                            user: payload.metadata,
                        },
                    },
                });
            })
            .addCase(updateFileMetadata.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(updateFileMetadata.pending, (state) => {
                state.status = "pending";
            })
            .addCase(updateFileRollout.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";

                filesAdapter.updateOne(state.items, {
                    id: payload.id,
                    changes: {
                        rolloutPercentage: payload.rolloutPercentage,
                    },
                });
            })
            .addCase(updateFileRollout.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(updateFileRollout.pending, (state) => {
                state.status = "pending";
            });
    },
});

export const FilesReducer = filesSlice.reducer;
