import { styled, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: 100,
        float: "right",
        border: "0.6px dashed black",
    },
    content: {
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        paddingTop: theme.spacing(2),
    },
    button: {
        color: theme.palette.primary.main,
        height: "30.36px",
        fontSize: "10.71px",
        lineHeight: "14px",
    },
    sectionHeader: {
        fontSize: 18,
        lineHeight: "23.94px",
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        flexShrink: 0,
    },
    sectionContainer: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: theme.spacing(4),
    },
    commandParametersLabel: {
        position: "relative",
        top: 20,
    },
    input: {
        height: 40,
        flex: 0.7,
    },
    hints: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    switchButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    select: {
        "& .MuiInputBase-root": {
            height: "40px",
        },
    },
}));

export const InputItemContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: 20,
    flexShrink: 0,
});

export const InputItemLabel = styled(Typography)({
    fontSize: 18,
    paddingBottom: 5,
    lineHeight: "18.62px",
    flexShrink: 1,
    flex: 0.3,
});

export const HintText = styled(Typography)({
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 300,
    maxWidth: 306,
    lineHeight: "13.3px",
    paddingBottom: 10,
});

export const FieldsContainer = styled("div")({
    paddingTop: 24,
    paddingBottom: 8,
});

export const Row = styled("div")({
    display: "flex",
    gap: 8,
    paddingBottom: 8,
});
