import { useState } from "react";

import { Device } from "@dashboard/devices/types";
import { TagFilterDialog, useTagFilters } from "@dashboard/files/components/TagFilterDialog/TagFilterDialog";
import { getFilteredFiles, Sort, useGetSortedFiles } from "@dashboard/files/components/UploadTable/UploadTable.utils";
import { FileInfo } from "@dashboard/files/types";
import {
    Paper,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material";
import { TableHeaderCell } from "@shared/table/TableHeaderCell";

import { TableHeader } from "./TableHeader";

const defaultSort: Sort<"file"> = {
    orderDirection: "desc",
    orderBy: "file",
};

const rowsPerPage = 20;

export type Header = (typeof HEADERS)[number];
export const HEADERS = [
    { id: "packageName", label: "Package Name" },
    { id: "tags", label: "Tags" },
    { id: "actions", label: "Actions" },
] as const;
const filterableColumnIds: Partial<Header["id"]>[] = ["tags"];

export type DeviceOrNumber = number | Device;

export interface TableItemProps<T extends DeviceOrNumber> {
    file: FileInfo;
    allFiles: FileInfo[];
    selectedItem: T;
    index: number;
    setSelectedFileId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface Props<T extends DeviceOrNumber> {
    selectedItem: T;
    normalizedFiles: FileInfo[];
    allFiles: FileInfo[];
    component: <P extends T>(props: TableItemProps<P>) => JSX.Element;
    setSelectedFileId: React.Dispatch<React.SetStateAction<string | null>>;
    cellProps?: TableCellProps;
}

const Table = <T extends DeviceOrNumber>(props: Props<T>) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const { filters, activeFiltersCount, setFilters } = useTagFilters(props.normalizedFiles);
    const sortedFiles = useGetSortedFiles(props.normalizedFiles, defaultSort);
    const filteredFiles = getFilteredFiles(sortedFiles, filters);
    const paginatedFiles = filteredFiles.slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage);
    const TableComponent = props.component;

    return (
        <>
            <TableContainer component={Paper}>
                <MuiTable>
                    <TableHeader>
                        <TableRow>
                            <TableCell />
                            {HEADERS.map((item) => (
                                <TableHeaderCell
                                    key={item.id}
                                    item={item}
                                    sort={defaultSort}
                                    filterableColumnIds={filterableColumnIds}
                                    activeFiltersCount={activeFiltersCount}
                                    onFilter={() => setOpenFilterDialog((prev) => !prev)}
                                    cellProps={{
                                        align: item.label === "Actions" ? "right" : "left",
                                        ...props.cellProps,
                                    }}
                                />
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {paginatedFiles.map((file, index) => (
                            <TableComponent<T>
                                key={file.id}
                                file={file}
                                allFiles={props.allFiles}
                                index={index}
                                selectedItem={props.selectedItem}
                                setSelectedFileId={props.setSelectedFileId}
                            />
                        ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <TablePagination
                component="div"
                count={props.normalizedFiles.length}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={(_, page) => {
                    setPageIndex(page);
                }}
            />
            <TagFilterDialog
                filters={filters}
                setFilters={setFilters}
                open={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
            />
        </>
    );
};

export default Table;
