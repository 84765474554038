import { Form } from "react-final-form";

import { useCommands } from "@dashboard/devices/components/Commands/hooks";
import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { MdmPolicyCommand } from "@devices/types";
import { Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { FileSelectField } from "@shared/form/FileSelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles(() => ({
    dialogContent: {
        width: 400,
    },
    btnBox: {
        marginTop: 10,
    },
}));

interface Props {
    open: boolean;
    groupId: number;
    policyCommands: MdmPolicyCommand[];
    onClose: () => void;
}

type FormValues = {
    fileInput: string;
    files: FileList;
};

export const ImportGroupPolicyDialog = ({ open = false, groupId, policyCommands, onClose }: Props) => {
    const classes = useStyles();

    const { updatePolicyCommands } = useCommands();

    const handleFileUpload = async (file: File): Promise<string | null> => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                try {
                    resolve(event.target?.result?.toString() ?? null);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    const onSubmit = async (values: FormValues) => {
        const file = values.files[0];

        let fileContents = null;

        try {
            fileContents = await handleFileUpload(file);
        } catch (error: unknown) {
            if (error instanceof Error) {
                showErrorToast(error.message);
            }
        }

        if (fileContents) {
            let parsedCommands: MdmPolicyCommand[] = [];

            try {
                parsedCommands = JSON.parse(fileContents) as MdmPolicyCommand[];
            } catch (error) {
                showErrorToast("Failed to parse a file");
                return;
            }

            if (!Array.isArray(parsedCommands)) {
                showErrorToast("File contents are invalid");
                return;
            }

            const combinedCommands = [...policyCommands, ...parsedCommands];
            await updatePolicyCommands({ groupId, policy: JSON.stringify(combinedCommands) });
            onClose();
        } else {
            showErrorToast("Please try again");
        }
    };
    const onValidate = (values: FormValues) => {
        if (!values.files?.length) {
            return { fileInput: "Please upload the file first" };
        }
    };

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                onClick: onClose,
            }}
        >
            <Form<FormValues>
                onSubmit={onSubmit}
                validate={onValidate}
                render={({ handleSubmit, form, submitting }) => (
                    <form onSubmit={handleSubmit} id="editCommand">
                        <DialogTitle>Import group policy</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <p>
                                This dialog may be used to import multiple commands at once. It can be useful when
                                synchronizing policies between different groups to for restoring a policy backup.
                            </p>
                            <p>
                                <b>Warning: </b> Importing policy commands from external file may introduce command
                                duplicates. Please validate your policy after the import process finishes.
                            </p>
                            <TextField
                                fullWidth
                                name="fileInput"
                                disabled={true}
                                variant="standard"
                                value={form.getFieldState("files")?.value?.[0].name ?? "Select file"}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton component="label" size="large">
                                            <Icon name="fas fa-upload" color="primary" size={IconSize.small} />
                                            <FileSelectField name="files" />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </DialogContent>
                        <DialogActions className={classes.btnBox}>
                            <SecondaryButton onClick={onClose} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton onClick={handleSubmit} disabled={submitting} loading={submitting}>
                                Import
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
};
