import React from "react";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";

import { closeBatchMode, closeDetails } from "@devices/store";
import {
    selectBatchActionIsActive,
    selectBatchActionType,
    selectDetailsSelectedData,
    selectDetailsState,
} from "@devices/store/selectors";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { GroupSidePanel } from "./GroupPanel/GroupPanel";
import { LicenseSidePanel } from "./LicensePanel/LicensePanel";
import { MoveLicensePanel } from "./MoveLicensePanel/MoveLicensePanel";

const panelWidth = 800;

const useStyle = makeStyles<Theme, { isVisible: boolean }>((theme) => ({
    container: {
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 100,
        height: "100vh",
        width: panelWidth,
        backgroundColor: theme.palette.shadow[100],
        borderLeft: "1px solid" + theme.palette.grey[300],
        display: (props) => (props.isVisible ? "flex" : "none"),
        flexDirection: "column",
    },
}));

export const SidePanel = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const detailsState = useSelector(selectDetailsState);
    const selected = useSelector(selectDetailsSelectedData, shallowEqual);
    const isBatchActiveActive = useSelector(selectBatchActionIsActive);
    const batchActionType = useSelector(selectBatchActionType);

    const isVisible = isBatchActiveActive || detailsState.isOpen;
    const classes = useStyle({ isVisible });

    const handleKeyPress = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                dispatch(closeDetails());
                dispatch(closeBatchMode());
            }
        },
        [dispatch],
    );

    const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
            const panelNode = ref.current;
            const isInsidePanel = panelNode?.contains(event.target as Node);

            const dialogContainers = document.querySelectorAll(".MuiDialog-container");
            const isInsideDialog = Array.from(dialogContainers).some((dialog) => dialog.contains(event.target as Node));

            const popoverContainers = document.querySelectorAll(".MuiPopover-root");
            const isInsidePopover = Array.from(popoverContainers).some((popover) =>
                popover.contains(event.target as Node),
            );

            if (!isInsidePanel && !isInsideDialog && !isInsidePopover && !batchActionType) {
                dispatch(closeDetails());
                dispatch(closeBatchMode());
            }
        },
        [dispatch, batchActionType],
    );

    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <Box ref={ref} className={classes.container}>
            {!batchActionType && selected.group ? <GroupSidePanel {...selected.group} /> : null}
            {!batchActionType && selected.license ? <LicenseSidePanel {...selected.license} /> : null}
            {batchActionType === "move" ? <MoveLicensePanel /> : null}
        </Box>
    );
};
