import { AxiosResponse } from "axios";
import RouteService from "src/services/routeService";
import { ListResponse } from "src/types";

import httpService from "./httpService";

export const getOldReleasedImages = async () => {
    const endpoint = await RouteService.getImagesRoute();
    const url = endpoint + "released";
    const response = await httpService.get<string, AxiosResponse<ListResponse<API.ReleasedImage>>>(url);
    return response.data.list;
};

export const getReleasedImages = async () => {
    const endpoint = await RouteService.getProductRoute();
    const url = endpoint + "builds/released";
    const response = await httpService.get<string, AxiosResponse<ListResponse<API.ReleasedImage>>>(url);
    return response.data.list;
};
