import React, { useState } from "react";
import { useField } from "react-final-form";
import { shallowEqual, useSelector } from "react-redux";

import { selectFilesWithApkFilter } from "@dashboard/files/store/selectors";
import ApplicationTableItem from "@dashboard/products/components/ProductDetails/fragments/tableItems/ApplicationTableItem";
import { ProductApplication } from "@dashboard/products/types";
import { SelectProps } from "@mui/material";
import ItemManagementTable from "@shared/table/ItemManagmentTable";

const headers = ["Name", "Privileged", "Action"];

const generateProductApplication = (fileId: string): ProductApplication => {
    return {
        privileged: false,
        source: { type: "storage", fileId },
    };
};

export const Applications = () => {
    const [value, setValue] = useState<string>("");
    const applications = useSelector(selectFilesWithApkFilter, shallowEqual);

    const { input } = useField<ProductApplication[]>("customBuildConfig.applications");
    const selectedApplicationIds = input.value.map((item) => item.source.fileId);
    const allApplicationSelected = selectedApplicationIds.length === applications.length;
    const options = applications.map((item) => ({
        label: item.filename,
        value: item.id,
        disabled: selectedApplicationIds.includes(item.id),
    }));

    const onSelectChange: SelectProps["onChange"] = (e) => {
        setValue(String(e.target.value));
    };

    const onCheckboxChange = (fileId: string, checked: boolean) => {
        const newApplications = [...input.value].map((item) =>
            item.source.fileId === fileId ? { ...item, privileged: checked } : item,
        );
        input.onChange(newApplications);
    };

    const onAdd = () => {
        if (value) {
            const newApplication = generateProductApplication(value);
            input.onChange([...input.value, newApplication]);
            setValue("");
        }
    };
    const onRemove = (fileId: string) => {
        const newApplications = [...input.value].filter((item) => item.source.fileId !== fileId);
        input.onChange(newApplications);
    };

    return (
        <ItemManagementTable
            title="Applications"
            onAddItem={onAdd}
            columnHeaders={headers}
            inputType="select"
            dropdownOptions={options}
            onDropdownChange={onSelectChange}
            isAllSelected={allApplicationSelected}
            placeholder="Add application file (e.g., custom_app_1.0.0.apk)"
        >
            {input.value.map((app) => {
                return (
                    <ApplicationTableItem
                        application={app}
                        key={app.source.fileId}
                        onCheckboxChange={onCheckboxChange}
                        onRemove={onRemove}
                    />
                );
            })}
        </ItemManagementTable>
    );
};
