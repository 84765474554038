import clsx from "clsx";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    titleContainer: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        fontSize: 20,
    },
    title: {
        marginLeft: 8,
        fontSize: 20,
        fontWeight: "bold",
    },
}));

type Props = {
    iconName: string;
    title: string;
};

const PageTitleWithIcon = (props: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.titleContainer}>
            <i className={clsx(props.iconName, classes.icon)} />
            <Typography className={classes.title}>{props.title}</Typography>
        </div>
    );
};

export default PageTitleWithIcon;
