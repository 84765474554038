import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse } from "src/types";

import { EntityFetchTypes } from "@dashboard/devices/types";

import { unpackError } from "./helpers";

interface RequestFetchLicenseResponse {
    total: number;
    count: number;
    page: number;
    entries: API.License[];
}
interface RequestFetchLicensePayload {
    groupId: number;
    pageNumber?: number;
    pageSize?: number;
}
export const requestFetchLicense = async (payload: RequestFetchLicensePayload) => {
    const endpoint = await RouteService.getLicensesRoute();
    return httpService.get<RequestFetchLicenseResponse>(endpoint, { params: payload });
};

interface FetchLicenseResponse {
    groupId: number;
    licenses: RequestFetchLicenseResponse;
}
interface FetchLicensesPayload {
    groupId: number[];
    type?: EntityFetchTypes;
    pageSize?: number;
    pageNumber?: number;
}
export const fetchLicenses = async (payload: FetchLicensesPayload): Promise<FetchLicenseResponse[]> => {
    const groupIds = Array.isArray(payload.groupId) ? payload.groupId : [payload.groupId];
    const requests = groupIds.map((id) =>
        requestFetchLicense({ groupId: id, pageSize: payload.pageSize, pageNumber: payload.pageNumber }).then(
            ({ data }) => ({
                groupId: id,
                licenses: data,
            }),
        ),
    );
    const licenses = await Promise.all(requests);
    return licenses;
};

interface LicenseUpdatePayload {
    id: number;
    comment: string | null;
}
export const updateLicense = async ({ id, ...payload }: LicenseUpdatePayload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
        if (value !== null) {
            formData.append(key, value);
        }
    });

    try {
        const endpoint = await RouteService.getLicensesRoute();
        await httpService.patch<FormData, ApiResponse<API.License>>(endpoint + String(id), formData);
        return Promise.resolve();
    } catch (error) {
        throw Error("Could not update the license");
    }
};

interface LicenseRevokePayload {
    licenseId: number;
}

export const revokeLicense = async (payload: LicenseRevokePayload) => {
    try {
        const endpoint = await RouteService.getLicensesRoute();
        const response = await httpService.delete<LicenseRevokePayload, AxiosResponse<ApiResponse<API.License>>>(
            endpoint + `${payload.licenseId}/revoke`,
        );
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

interface LicenseGeneratePayload {
    groupId: number;
    grantCount: number;
}

export const generateLicenses = async (payload: LicenseGeneratePayload) => {
    try {
        const endpoint = await RouteService.getLicensesRoute();
        const response = await httpService.post<LicenseGeneratePayload, AxiosResponse<ApiResponse<API.License[]>>>(
            endpoint,
            payload,
        );
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
