import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    stickyFooter: {
        position: "sticky",
        bottom: 0,
        zIndex: 1,
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: theme.palette.shadow[50],
        padding: 20,
        width: "100%",
    },
    footerRightContainer: {
        display: "flex",
        marginLeft: "auto",
    },
    dialogContent: {
        width: 400,
        padding: 10,
    },
}));
