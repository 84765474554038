import queryString from "query-string";
import { RootState } from "src/store";

import { DeviceState } from "@dashboard/devices/types";

type DeviceIps = {
    ipv4: string | null;
    ipv6: string | null;
};
export const getIPs = (_deviceState?: Partial<DeviceState>) => {
    if (!_deviceState || !_deviceState.networks) {
        return {
            ipv4: null,
            ipv6: null,
        };
    }

    const IPs: DeviceIps = {
        ipv4: null,
        ipv6: null,
    };

    const allIPs: string[] = _deviceState.networks.flatMap((network) => {
        return network.ips || [];
    });

    allIPs.forEach((ip) => {
        if (ip.includes(".")) {
            IPs.ipv4 = ip;
        } else if (ip.includes(":")) {
            IPs.ipv6 = ip;
        }
    });

    return IPs;
};

export const getDevicesStoreFromUrl = () => {
    const url = window.location.href;
    const { query } = queryString.parseUrl(url);

    const result: Partial<RootState["devices"]> = {};

    if (query.tab && !Array.isArray(query.tab)) {
        result.pane = {
            tab: query.tab,
            editEnabled: false,
            status: null,
        };
    }
    if (query.search && !Array.isArray(query.search)) {
        result.search = {
            query: query.search,
        };
    }
    if (query.licenseId && !Array.isArray(query.licenseId)) {
        result.details = {
            isOpen: true,
            type: "license",
            selectedId: +query.licenseId,
        };
    }
    if (query.groupId && !Array.isArray(query.groupId)) {
        result.details = {
            isOpen: true,
            type: "group",
            selectedId: +query.groupId,
        };
    }

    return result;
};
