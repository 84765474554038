import clsx from "clsx";
import { LoadingIcon } from "src/ui/shared/Loading";
import { Tooltip } from "src/ui/shared/Tooltip";

import { IconSize } from "@dashboard/devices/components/shared/index";
import { FileStatus, FileTableItem } from "@dashboard/files/types/index";
import { hasUnsuccessfulAnalyzer } from "@dashboard/files/utils";
import { TableCell } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    icon: {
        fontSize: 20,
        marginLeft: 5,
    },
    tooltipBox: {
        width: 30,
    },
});

type IconInfo = {
    iconClass: string;
    tooltipText: string;
};

const fileExtensionToIconMap: Record<string, IconInfo> = {
    doc: { iconClass: "fa-solid fa-file-word", tooltipText: "Text" },
    docx: { iconClass: "fa-solid fa-file-word", tooltipText: "Text" },
    exe: { iconClass: "fa-solid fa-windows", tooltipText: "Windows executable" },
    zip: { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
};

const contentTypeToIconMap: Record<string, IconInfo> = {
    "image/jpeg": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "image/png": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "image/gif": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "text/plain": { iconClass: "fa-solid fa-file-lines", tooltipText: "Text" },
    "text/csv": { iconClass: "fa-solid fa-file-lines", tooltipText: "Text" },
    "application/json": { iconClass: "fa-solid fa-file-code", tooltipText: "JSON" },
    "application/zip": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "multipart/x-zip": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/zip-compressed": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/x-zip-compressed": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/vnd.android.package-archive": { iconClass: "fa-brands fa-android", tooltipText: "Android package" },
    "application/x-apk": { iconClass: "fa-brands fa-android", tooltipText: "Android package" },
};

const getIconByFileName = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase() || "";
    return fileExtensionToIconMap[extension] || { iconClass: "fa-solid fa-file", tooltipText: "Unknown type" };
};

interface FileIconProps {
    file: FileTableItem;
}

const FileIcon = (props: FileIconProps) => {
    const classes = useStyles();
    const displaySpinner = hasUnsuccessfulAnalyzer(props.file);
    const contentType = props.file.contentType ? contentTypeToIconMap[props.file.contentType] : null;
    const { iconClass, tooltipText } = contentType || getIconByFileName(props.file.filename);

    return (
        <TableCell>
            <div>
                {displaySpinner || props.file.status === FileStatus.Pending ? (
                    <Tooltip placement="top" title="Processing...">
                        <div className={classes.tooltipBox}>
                            <LoadingIcon size={IconSize.medium} />
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title={tooltipText}>
                        <i className={clsx(iconClass, classes.icon)}></i>
                    </Tooltip>
                )}
            </div>
        </TableCell>
    );
};

export default FileIcon;
