import clsx from "clsx";

import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        minHeight: "134px",
        border: "1px solid " + theme.palette.shadow[100],
        borderRadius: "8px",
        backgroundColor: theme.palette.white[50],
    },
}));

type Props = {
    children: React.ReactNode;
    className?: string;
};

export const Card = (props: Props) => {
    const classes = useStyles();
    return <Box className={clsx(classes.card, props.className)}>{props.children}</Box>;
};
