import clsx from "clsx";
import React, { ReactNode, useState } from "react";

import { useStyles as useSharedStyles } from "@dashboard/products/components/ProductDetails/styles";
import { Box, Paper, SelectProps, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryOutlinedButton } from "@shared/CustomButton";
import { SimpleValue, UncontrolledSelectField } from "@shared/form/SelectField";
import { UncontrolledTextField } from "@shared/form/TextField";
import { Tooltip } from "@shared/Tooltip";

import { MinimalTable, MinimalTableCell, MinimalTableHeader, MinimalTableHeaderCell } from "./MinimalTableComponents";

const useStyles = makeStyles({
    container: { width: 700 },
    tooltip: { display: "flex", width: "100%" },
    inputBox: { display: "flex", width: "100%", paddingTop: 15, justifyContent: "center", gap: 10 },
    table: { marginTop: 20 },
    select: { marginRight: 10 },
    text: { width: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
    errorText: { color: "red", marginTop: 5 },
});

type Props = {
    title: string;
    columnHeaders: string[];
    children: ReactNode;
    onAddItem: (item: string) => void;
    inputValidator?: (value: string) => string | null;
    isAllSelected?: boolean;
    inputType?: "text" | "select";
    dropdownOptions?: SimpleValue[];
    onDropdownChange?: SelectProps["onChange"];
    placeholder?: string;
};

const ItemManagementTable = ({
    title,
    columnHeaders,
    isAllSelected,
    onDropdownChange,
    onAddItem,
    inputValidator,
    inputType = "text",
    dropdownOptions = [],
    placeholder,
    children,
}: Props) => {
    const [input, setInput] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const classes = useStyles();
    const sharedClasses = useSharedStyles();

    const handleAdd = (value: string) => {
        if (inputValidator) {
            const validationError = inputValidator(input);
            if (validationError) {
                setError(validationError);
                return;
            }
        }
        setInput(value);
        setError(null);
        onAddItem(input);
        setInput("");
    };

    const handleSelectChange: SelectProps["onChange"] = (e, child) => {
        if (typeof e.target.value === "string") {
            setInput(e.target.value);
        }
        if (onDropdownChange) {
            onDropdownChange(e, child);
        }
    };

    return (
        <Box className={clsx(sharedClasses.sectionContainer, classes.container)}>
            <Typography className={sharedClasses.sectionHeader}>{title}</Typography>
            <Box className={classes.inputBox}>
                {inputType === "text" ? (
                    <UncontrolledTextField
                        variant="outlined"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        fullWidth
                        placeholder={placeholder}
                    />
                ) : (
                    <Tooltip
                        title="You have selected all available applications"
                        className={classes.tooltip}
                        hide={!isAllSelected}
                    >
                        <UncontrolledSelectField
                            placeholder={placeholder}
                            options={dropdownOptions}
                            variant="outlined"
                            value={input}
                            onChange={handleSelectChange}
                            fullWidth
                        />
                    </Tooltip>
                )}
                <PrimaryOutlinedButton onClick={() => handleAdd(input)} disabled={isAllSelected || !input}>
                    Add
                </PrimaryOutlinedButton>
            </Box>
            {error ? (
                <Typography variant="body2" className={classes.errorText}>
                    {error}
                </Typography>
            ) : null}
            <TableContainer component={Paper} className={classes.table}>
                <MinimalTable>
                    <MinimalTableHeader>
                        <TableRow>
                            {columnHeaders.map((header) => {
                                return (
                                    <MinimalTableHeaderCell align={header === "Action" ? "right" : "left"} key={header}>
                                        {header}
                                    </MinimalTableHeaderCell>
                                );
                            })}
                        </TableRow>
                    </MinimalTableHeader>
                    <TableBody>
                        {React.Children.count(children) > 0 ? (
                            children
                        ) : (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={columnHeaders.length}>
                                    No items
                                </MinimalTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
        </Box>
    );
};

export default ItemManagementTable;
