import clsx from "clsx";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import { AccountProfile, updateProfile } from "src/services/accountService";
import CountryService from "src/services/countryService";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { PageContainer } from "@dashboard/shared/styles";
import { Box, Divider, Typography } from "@mui/material";
import { PrimaryButton } from "@shared/CustomButton";
import { CheckboxField } from "@shared/form/CheckboxField";
import { SelectField, SimpleValue } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { useStyles } from "./ConfirmRegistrationForm.style";
import { profileValidationSchema } from "./ConfirmRegistrationPage.validation";

type FormValues = AccountProfile & {
    privacyChecked: boolean;
    eulaChecked: boolean;
    newsletterChecked: boolean;
};

type FormSelectionData = {
    phoneCodes: SimpleValue[];
    countryList: SimpleValue[];
};

interface SingleRowProps extends React.PropsWithChildren<object> {
    label: string;
    required?: boolean;
}

interface Props {
    email: string;
}

const SingleRow = (props: SingleRowProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.row}>
            <label
                className={clsx(`col-form-label ${props.required ? classes.requiredLabel : classes.label}`)}
                htmlFor="email"
            >
                <Typography style={{ display: "inline" }}>{props.label}</Typography>
            </label>
            {props.children}
        </Box>
    );
};

export const ConfirmRegistrationForm = ({ email }: Props): JSX.Element => {
    const classes = useStyles();
    const navigate = useNavigate();
    const validate = useValidationSchema(profileValidationSchema);
    const [data, setData] = useState<FormSelectionData>({
        phoneCodes: [],
        countryList: [],
    });

    const loadProfileData = async () => {
        const phoneCodes = await CountryService.getCountryPhonesList();
        const countryList = await CountryService.getSelectableCountryList();
        setData({
            phoneCodes: phoneCodes,
            countryList: countryList,
        });
    };
    const onSubmit = async (values: FormValues) => {
        try {
            const { success, message } = (await updateProfile(values)) ?? {};

            if (success) {
                showSuccessToast("Welcome to Emteria!");
                navigate("/dashboard/overview");
            } else if (Array.isArray(message)) {
                const result = message.flatMap((msgObj: { message: string }) => msgObj.message);
                if (result.length > 0) {
                    showErrorToast(result[0]);
                }
            } else {
                showErrorToast(message);
            }
        } catch (err) {
            showErrorToast(err as string);
        }
    };

    useEffect(() => {
        void loadProfileData();
    }, []);

    return (
        <PageContainer>
            <Form<FormValues>
                onSubmit={onSubmit}
                validate={validate}
                initialValues={{ email }}
                render={({ handleSubmit }) => (
                    <form onSubmit={(e) => void handleSubmit(e)}>
                        <Box className={classes.titleContainer}>
                            <h2>Finish Registration</h2>
                            <h5>Enter your account details</h5>
                        </Box>
                        <Divider />
                        <Box className={classes.subTitleContainer}>
                            <h3>Account Settings</h3>
                        </Box>
                        <Divider />

                        <SingleRow label="Email address">
                            <TextField name="email" disabled fullWidth />
                        </SingleRow>

                        <SingleRow label="Choose password" required>
                            <TextField name="password" type="password" fullWidth />
                        </SingleRow>

                        <SingleRow label="Repeat password" required>
                            <TextField name="password_repeat" type="password" fullWidth />
                        </SingleRow>

                        <Box className={classes.subTitleContainer}>
                            <h3>Personal Information</h3>
                        </Box>

                        <SingleRow label="Full name" required>
                            <TextField name="name" fullWidth />
                        </SingleRow>

                        <SingleRow label="Phone number">
                            <Box className={classes.phoneBox}>
                                <SelectField name="phoneCode" label="Phone code" fullWidth options={data?.phoneCodes} />
                                <TextField
                                    name="phoneNumber"
                                    type="number"
                                    fullWidth
                                    className={classes.phoneNumberContainer}
                                />
                            </Box>
                        </SingleRow>

                        <SingleRow label="Address">
                            <TextField name="address" fullWidth />
                        </SingleRow>

                        <SingleRow label="Zip code">
                            <TextField name="zipCode" fullWidth />
                        </SingleRow>

                        <SingleRow label="City">
                            <TextField name="city" fullWidth />
                        </SingleRow>

                        <SingleRow label="Country" required>
                            <SelectField name="country" label="Country" fullWidth options={data.countryList} />
                        </SingleRow>

                        <SingleRow label="Type of usage" required>
                            <SelectField
                                name="typeOfUsage"
                                label="How do you plan to use it?"
                                fullWidth
                                options={[
                                    { value: "nb", label: "Non-business use" },
                                    { value: "b", label: "Business use" },
                                ]}
                            />
                        </SingleRow>

                        <SingleRow label="Company">
                            <TextField name="companyName" fullWidth />
                        </SingleRow>

                        <SingleRow label="VAT number">
                            <TextField name="companyVAT" fullWidth />
                        </SingleRow>

                        <SingleRow label="Industry">
                            <TextField name="fieldOfApplication" fullWidth />
                        </SingleRow>

                        <Box className={classes.subTitleContainer}>
                            <h3>Terms of Service</h3>
                        </Box>

                        <Divider />
                        <Box className={classes.footer}>
                            <Box className={classes.agreeBox}>
                                <CheckboxField
                                    name="eulaChecked"
                                    label={
                                        <Typography>
                                            I agree to use emteria products in accordance with emteria&apos;s{" "}
                                            <a
                                                href="https://emteria.com/legal/terms-of-use"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Terms of Service and End User Licensing
                                            </a>
                                            .
                                            <span className={classes.requiredLabel} />
                                        </Typography>
                                    }
                                />
                            </Box>
                            <Box className={classes.agreeBox}>
                                <CheckboxField
                                    name="privacyChecked"
                                    label={
                                        <Typography>
                                            I agree with processing of my personal data in accordance with
                                            emteria&apos;s{" "}
                                            <a
                                                href="https://emteria.com/legal/privacy-policy"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Privacy Policy
                                            </a>
                                            .
                                            <span className={classes.requiredLabel} />
                                        </Typography>
                                    }
                                />
                            </Box>
                            <Box className={classes.agreeBox}>
                                <CheckboxField
                                    name="newsletterChecked"
                                    label="I agree to receive product news and promotional information from emteria."
                                />
                            </Box>
                        </Box>
                        <Box className={classes.finishRegistrationContainer}>
                            <PrimaryButton type="submit">Finish registration</PrimaryButton>
                        </Box>
                    </form>
                )}
            />
        </PageContainer>
    );
};
