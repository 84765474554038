import { isArray, mergeWith } from "lodash";
import {
    AccountProfile,
    getProfile as apiGetProfile,
    updateProfile as apiUpdateProfile,
} from "src/services/accountService";
import { RootState } from "src/store";
import { PartialDeep } from "type-fest";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { createRule, isNotificationDisabled, NotificationId } from "./helpers/notifications";
import { persistentStore } from "./persistentStore";

const lastStore = persistentStore.get();
const lastAccountStore = lastStore?.account as AccountState;

export type AccountState = {
    profile: AccountProfile | null;
    disabledNotifications: string[];
};

export const fetchProfile = createAsyncThunk("profile/fetch", apiGetProfile);
export const updateProfile = createAsyncThunk("profile/update", apiUpdateProfile);

const initialState: AccountState = {
    profile: null,
    disabledNotifications: [],
};

const initialWithLastPersistedState: AccountState = mergeWith(
    initialState,
    lastAccountStore,
    {
        // override anything from "initial-state" & "last-persisted-state" here
    },
    (obj: PartialDeep<AccountState>, src: PartialDeep<AccountState>) => {
        if (isArray(obj)) {
            return src;
        }
    },
);

const slice = createSlice({
    name: "account",
    initialState: initialWithLastPersistedState,
    reducers: {
        disableNotification(state, { payload }: { payload: NotificationId }) {
            const notificationId = payload;

            if (!state.profile) {
                return;
            }

            const rule = createRule(state.profile.email, notificationId);
            state.disabledNotifications.push(rule);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfile.fulfilled, (state: AccountState, { payload }) => {
            if (payload) {
                state.profile = payload;
            }
        });
        builder.addCase(updateProfile.fulfilled, (state: AccountState, { payload, meta }) => {
            if (payload) {
                state.profile = meta.arg;
            }
        });
    },
});

const localState = (state: RootState): AccountState => state?.account;

export const selectAccount = (state: RootState): AccountState => localState(state);

export const selectProfile = (state: RootState): AccountProfile | null => selectAccount(state)?.profile;

export const selectAccountEmail = (state: RootState): string | null => selectProfile(state)?.email ?? null;

export const selectNotificationDisabled = (state: RootState, notificationId: NotificationId): boolean => {
    const rules = state.account.disabledNotifications ?? [];
    const email = localState(state).profile?.email ?? "";
    return isNotificationDisabled({ rules, email, notificationId });
};

export const { disableNotification } = slice.actions;

export default slice.reducer;
