import clsx from "clsx";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { getEnumKeyByEnumValue } from "src/ui/utils/enums";

import { alternatingBg, deleteBorder } from "@dashboard/shared/styles";
import { useCommands } from "@devices/components/Commands/hooks";
import { DeviceMdmCommandStatusIcon, MdmCommand, MdmCommandStatus } from "@devices/types/commands";
import { convertToLocalDate } from "@devices/utils/dates";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import { grey } from "@mui/material/colors";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { Tooltip } from "@shared/Tooltip";

import { getCommandName } from "./CommandTab";

const useStyles = createUseStyles({
    commandPropertyValue: {
        fontSize: "0.9rem",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    icons: {
        marginTop: "2px",
        marginRight: "8px",
        fontSize: "0.9rem",
        color: grey[500],
    },
    collapsingRowContent: {
        padding: 10,
        width: "100%",
        maxWidth: "100%",
        minHeight: 36,
        textAlign: "left",
        fontSize: "0.9rem",
        wordWrap: "break-word",
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    borderBottomVisible: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    customCollapse: {
        "& .MuiCollapse-wrapper": {
            maxWidth: "650px",
        },
    },
    btnBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface GroupCommandItemProps {
    command: MdmCommand;
    groupId: number;
    index: number;
}

const GroupCommandItem = ({ command, groupId, index }: GroupCommandItemProps) => {
    const classes = useStyles();
    const { id, payload, created, creator, statuses, updated } = command;
    const [open, setOpen] = useState(-1);

    const {
        createMdmCommand,
        deleteMdmCommand,
        loadingState: { createLoading, deleteLoading },
    } = useCommands();

    const handleSubmitCommand = async () => {
        await createMdmCommand({ groupId, json: JSON.parse(payload) });
    };

    const handleDeleteCommand = async () => {
        await deleteMdmCommand({ groupId, command });
    };

    const createdDate = convertToLocalDate(created, "ISO", "dateTime");
    const lastUpdatedDate = convertToLocalDate(updated, "ISO", "dateTime");

    const commandJson = JSON.parse(payload);
    const commandPayload = JSON.stringify(commandJson, null, 2);

    const renderStatuses = () => {
        const statusCounts: Record<MdmCommandStatus, number> = {
            [MdmCommandStatus.Unknown]: 0,
            [MdmCommandStatus.Pending]: 0,
            [MdmCommandStatus.Running]: 0,
            [MdmCommandStatus.Successful]: 0,
            [MdmCommandStatus.Failed]: 0,
            [MdmCommandStatus.Received]: 0,
            [MdmCommandStatus.Ignored]: 0,
            [MdmCommandStatus.Queued]: 0,
        };

        statuses?.forEach((_status) => {
            statusCounts[_status]++;
        });

        const statusElements = Object.entries(statusCounts).map(([_status, count]) => {
            if (count > 0) {
                const commandStatus = parseInt(_status) as MdmCommandStatus;

                const iconClassName = DeviceMdmCommandStatusIcon[commandStatus] ?? "fas fa-solid fa-question";

                return (
                    <Tooltip
                        placement="top"
                        key={_status}
                        title={`${getEnumKeyByEnumValue(MdmCommandStatus, commandStatus)} (${count} devices)`}
                    >
                        <i key={_status} className={clsx(classes.icons, classes.commandPropertyValue, iconClassName)} />
                    </Tooltip>
                );
            } else {
                return null;
            }
        });

        return <>{statusElements}</>;
    };

    return (
        <>
            <TableRow key={id} style={alternatingBg(index)}>
                <TableCell align="center" style={deleteBorder(index, open)}>
                    <IconButton
                        className={classes.collapseBtn}
                        onClick={() => setOpen(open === index ? -1 : index)}
                        size="large"
                    >
                        {open === index ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell style={deleteBorder(index, open)}>
                    <Tooltip title={convertToLocalDate(created, "ISO", "dateTime")}>
                        <span>{convertToLocalDate(created, "ISO", "date")}</span>
                    </Tooltip>
                </TableCell>
                <TableCell align="center" style={deleteBorder(index, open)}>
                    {renderStatuses()}
                </TableCell>
                <TableCell align="left" style={deleteBorder(index, open)} className={clsx(classes.overflow)}>
                    {getCommandName(payload)}
                </TableCell>
                <TableCell align="right" style={deleteBorder(index, open)}>
                    <Box className={classes.btnBox}>
                        <SecondaryButton
                            tooltipProps={{ title: "Resend command" }}
                            onClick={handleSubmitCommand}
                            disabled={createLoading}
                            loading={createLoading}
                        >
                            <i className="fa-solid fa-arrows-rotate" />
                        </SecondaryButton>
                        <DangerOutlinedButton
                            style={{ marginLeft: 6 }}
                            tooltipProps={{ title: "Delete command" }}
                            onClick={handleDeleteCommand}
                            disabled={command.status !== MdmCommandStatus.Pending || deleteLoading}
                            loading={deleteLoading}
                        >
                            <i className="fas fa-trash-alt" />
                        </DangerOutlinedButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow className={open === index ? classes.borderBottomVisible : ""} style={alternatingBg(index)}>
                <TableCell
                    colSpan={6}
                    className={clsx(classes.overflow, classes.customCollapse)}
                    style={{ paddingTop: 0, paddingBottom: 0, border: "0px" }}
                >
                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                        <div className={classes.collapsingRowContent}>
                            <span className={classes.collapsePropertyTitle}>Creator:</span> {creator}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Created:</span> {createdDate}
                            <br />
                            <span className={classes.collapsePropertyTitle}>Updated:</span> {lastUpdatedDate}
                            <br />
                            <br />
                            <div>
                                <pre>{commandPayload}</pre>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default GroupCommandItem;
