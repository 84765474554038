import { styled, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    "@global": {
        body: {
            margin: "0 !important",
        },
    },
    container: {
        height: "100%",
        width: "100%",
        color: theme.palette.white[50],
        backgroundColor: theme.palette.blue[300],
    },
    spinner: {
        margin: "0 auto",
        color: theme.palette.white[50] + " !important",
    },
    centeredGrid: {
        maxWidth: "50%",
        margin: "0 auto !important",
        "@media (max-width: 768px)": {
            maxWidth: "100%",
        },
    },
    gridItem: {
        "@media (max-width: 768px)": {
            minWidth: "100%",
        },
    },
    card: {
        minHeight: "unset",
        padding: "20px 5px",
        backgroundColor: "transparent",
    },
    boldTitle: {
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: 600,
    },
    availableAndroidVersionsTitle: {
        marginTop: "1rem !important",
        marginBottom: "1rem !important",
        color: theme.palette.blue[150],
        fontWeight: 600,
        fontFamily: "Segoe UI, sans-serif",
    },
    downloadCard: {
        backgroundColor: theme.palette.white[50],
        color: theme.palette.blue[300],
    },
    downloadIcon: {
        color: theme.palette.blue[300] + " !important",
    },
}));

export const LightTooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        padding: 10,
        backgroundColor: theme.palette.white[50] + " !important",
        color: theme.palette.blue[300] + " !important",
    },
}));
