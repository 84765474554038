import { Tooltip } from "src/ui/shared/Tooltip";

import { List, ListSubheader, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    tooltip: { width: "100%" },
    subHeader: {
        fontSize: 12,
        textTransform: "uppercase",
        color: theme.palette.shadow[300],
        lineHeight: "100%",
        marginTop: 12,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

interface Props {
    open: boolean;
    title: string;
}

export const ListWithSubHeader = (props: React.PropsWithChildren<Props>) => {
    const classes = useStyles();
    return (
        <List
            subheader={
                props.open ? (
                    <Tooltip className={classes.tooltip} title={props.title} placement="right">
                        <ListSubheader className={classes.subHeader} disableSticky>
                            {props.title}
                        </ListSubheader>
                    </Tooltip>
                ) : undefined
            }
        >
            {props.children}
        </List>
    );
};
