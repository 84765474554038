import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { RootState, useDispatch, useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomTileContainer, CustomTileTitle } from "src/ui/shared/CustomTile";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";

import { setPaneTab, showLicenseById } from "@dashboard/devices/store";
import { selectDevicesAppsByGroupIds, selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { Device, DeviceState } from "@dashboard/devices/types";
import { selectFilesByGroupIdsWithApkFilter } from "@dashboard/files/store/selectors";
import { Paper, TableBody, TableContainer, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface TransformedApps {
    label: string;
    package: string;
    versionCode: number | string;
    versionName: string;
    count?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: { height: "100%" },
    table: { marginTop: 12, boxShadow: "none" },
    link: { color: theme.palette.blue[150], cursor: "pointer", padding: 0 },
    tooltip: { height: 53, display: "flex", alignItems: "center", justifyContent: "flex-end" },
}));

const hasCorrectPackage = (app: TransformedApps, packages: DeviceState["packages"]) => {
    return packages.some((item) => item.label === app.label && item.versionCode === app.versionCode);
};
const getDeviceWithCorrectApp = (app: TransformedApps, devices: (Device | null)[]) => {
    return devices.filter((item) => (item?.lastState ? hasCorrectPackage(app, item.lastState.packages) : false))[0];
};

const getUniqueKey = (app: TransformedApps) => `${app.label}-${app.versionCode}`;

type Props = {
    filter: number[];
};

export const ManagedAppsCard = ({ filter }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const groups = useSelector(selectGroups);

    const filteredGroups = filter.length > 0 ? groups.filter((group) => filter.includes(group.id)) : groups;
    const filteredGroupIds = filteredGroups.map((g) => g.id);
    const sharedApks = useSelector((state: RootState) => selectFilesByGroupIdsWithApkFilter(state, filteredGroupIds));

    const installedApps = useSelector((state) => selectDevicesAppsByGroupIds(state, filteredGroupIds));
    const filteredDevices = useSelector((state) => selectDevicesByGroupIds(state, filteredGroupIds));

    const mergeApps = () => {
        const appsMap = new Map<string, TransformedApps>();

        installedApps.forEach((app) => {
            const key = getUniqueKey(app);
            const existingApp = appsMap.get(key);
            if (existingApp) {
                existingApp.count = (existingApp.count || 0) + 1;
            } else {
                appsMap.set(key, { ...app, count: 1 });
            }
        });

        sharedApks.forEach((file) => {
            const apk: TransformedApps = {
                label: file?.filename || "",
                package: file?.metadata?.file?.apk?.manifest?.package || "",
                versionCode: Number(file.metadata?.file?.apk?.manifest?.versioncode || ""),
                versionName: file.metadata?.file?.apk?.manifest?.versionname || "",
                count: 0,
            };
            const key = getUniqueKey(apk);
            if (!appsMap.has(key)) {
                appsMap.set(key, apk);
            }
        });

        return Array.from(appsMap.values());
    };

    const mergedApps = mergeApps();

    const managedApps = _.orderBy(mergedApps, ["count", "label", "versionCode"], ["desc", "asc", "desc"]);

    const onClick = (app: TransformedApps) => {
        const device = getDeviceWithCorrectApp(app, filteredDevices);
        if (!device) {
            return;
        }

        const group = filteredGroups.find((g) => g.licenseIds.includes(device.licenseId));
        if (group) {
            dispatch(showLicenseById({ device, groupId: group.id }));
            dispatch(setPaneTab("APPS"));
            navigate("/dashboard/devices");
        }
    };

    return (
        <CustomTileContainer className={classes.container}>
            <CustomTileTitle>Managed applications</CustomTileTitle>
            <TableContainer component={Paper} className={classes.table}>
                <MinimalTable stickyHeader>
                    <MinimalTableHeader>
                        <TableRow>
                            <MinimalTableHeaderCell>Application</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell>Version</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell align="right">Devices</MinimalTableHeaderCell>
                        </TableRow>
                    </MinimalTableHeader>

                    <TableBody>
                        {!managedApps.length && (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={3}>
                                    No managed applications
                                </MinimalTableCell>
                            </TableRow>
                        )}
                        {managedApps.map((item, index) => (
                            <TableRow key={index}>
                                <MinimalTableCell border>{item.label}</MinimalTableCell>
                                <MinimalTableCell border>{item.versionCode}</MinimalTableCell>
                                {item.count === 1 ? (
                                    <MinimalTableCell
                                        border
                                        className={classes.link}
                                        onClick={() => onClick(item)}
                                        align="right"
                                    >
                                        <Tooltip title="Show this device" className={classes.tooltip}>
                                            <span>{item.count}</span>
                                        </Tooltip>
                                    </MinimalTableCell>
                                ) : (
                                    <MinimalTableCell border align="right">
                                        {item.count}
                                    </MinimalTableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
        </CustomTileContainer>
    );
};
