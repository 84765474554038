import { useState } from "react";
import { useSelector } from "react-redux";
import { getEnv } from "src/services/configService";
import { useDispatch } from "src/store";
import { LoadingIcon } from "src/ui/shared/Loading";

import { IconSize } from "@dashboard/devices/components/shared";
import useFetchInterval from "@dashboard/devices/hooks/useFetchInterval";
import TableItem from "@dashboard/files/components/TableItem/TableItem";
import { TagFilterDialog, useTagFilters } from "@dashboard/files/components/TagFilterDialog/TagFilterDialog";
import { fetchFiles } from "@dashboard/files/store";
import { selectFiles, selectFilesListStatus } from "@dashboard/files/store/selectors";
import { hasUnsuccessfulAnalyzer } from "@dashboard/files/utils";
import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import makeStyles from "@mui/styles/makeStyles";
import { TableHeader } from "@shared/table/TableHeader";
import { TableHeaderCell } from "@shared/table/TableHeaderCell";

import { getFilteredFiles, Sort, useGetSortedFiles } from "./UploadTable.utils";

export type Header = (typeof HEADERS)[number];

export const HEADERS = [
    { id: "contentType", label: "Type" },
    { id: "filename", label: "Name" },
    { id: "size", label: "Size" },
    { id: "createdDate", label: "Created" },
    { id: "visibility", label: "Visibility" },
    { id: "tags", label: "Tags" },
    { id: "actions", label: "Actions" },
] as const;

const rowsPerPage = 50;
const filterableColumnIds: Partial<Header["id"]>[] = ["tags"];
const sortableColumnIds: Partial<Header["id"]>[] = ["contentType", "filename", "size", "visibility", "createdDate"];
const defaultSort: Sort<string> = {
    orderDirection: "desc",
    orderBy: "filename",
};

const useStyles = makeStyles({
    container: {
        marginBottom: 5,
    },
    loadingContainer: {
        height: "50px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    noFilesBox: {
        fontSize: 22,
        marginTop: 40,
        textAlign: "center",
    },
});

const UploadTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [sort, setSort] = useState(defaultSort);

    const files = useSelector(selectFiles);
    const status = useSelector(selectFilesListStatus);

    const { filters, activeFiltersCount, setFilters } = useTagFilters(files);
    const sortedFiles = useGetSortedFiles(files, sort);
    const filteredFiles = getFilteredFiles(sortedFiles, filters);
    const paginatedFiles = filteredFiles.slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage);

    const reFetchFiles = async () => {
        try {
            await dispatch(fetchFiles());
        } catch (err) {
            if (getEnv() === "Development") {
                console.error("[Files] reFetchFiles failed: ", err);
            }
        }
    };

    useFetchInterval(reFetchFiles, { condition: hasUnsuccessfulAnalyzer(files) });

    return (
        <>
            <TableContainer className={classes.container} component={Paper}>
                <Table>
                    <TableHeader>
                        <TableRow>
                            {HEADERS.map((item) => (
                                <TableHeaderCell
                                    key={item.id}
                                    item={item}
                                    sort={sort}
                                    filterableColumnIds={filterableColumnIds}
                                    sortableColumnIds={sortableColumnIds}
                                    activeFiltersCount={activeFiltersCount}
                                    onFilter={() => setOpenFilterDialog((prev) => !prev)}
                                    cellProps={{ align: item.label === "Actions" ? "right" : "left" }}
                                    onSort={(orderBy) => {
                                        setSort((prev) => ({
                                            orderDirection: prev.orderDirection === "desc" ? "asc" : "desc",
                                            orderBy,
                                        }));
                                    }}
                                />
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {status === "pending" && !paginatedFiles.length ? (
                            <TableRow>
                                <TableCell align="center" colSpan={HEADERS.length}>
                                    <div className={classes.loadingContainer}>
                                        <LoadingIcon size={IconSize.large} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {status !== "pending" && !paginatedFiles.length ? (
                            <TableRow>
                                <TableCell align="center" colSpan={HEADERS.length}>
                                    There are no files yet.
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {paginatedFiles.length
                            ? paginatedFiles.map((file, index) => (
                                  <TableItem key={index} file={file} setSelectedFileId={setSelectedFileId} />
                              ))
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={files.length}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={(_e, page) => {
                    setPageIndex(page);
                }}
            />
            {selectedFileId ? (
                <FileSettingsDialog
                    open
                    onClose={() => setSelectedFileId(null)}
                    item={paginatedFiles.filter((item) => item.id === selectedFileId)[0]}
                />
            ) : null}

            <TagFilterDialog
                filters={filters}
                setFilters={setFilters}
                open={openFilterDialog}
                setOpenFilterDialog={setOpenFilterDialog}
            />
        </>
    );
};

export default UploadTable;
