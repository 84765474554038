import { Alert, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type ActionType = "createProduct" | "createProvisioningSettings" | "uploadFile" | "createFdroidRepository";

const useStyles = makeStyles((theme: Theme) => ({
    mailLink: {
        color: theme.palette.blue[200],
        cursor: "pointer",
    },
}));

type LimitsAlertProps = {
    action: ActionType;
};

export const LimitsAlert = (props: LimitsAlertProps) => {
    const classes = useStyles();

    const getAlertMessage = () => {
        switch (props.action) {
            case "createProduct":
                return "You have reached the product limit for your plan.";
            case "createProvisioningSettings":
                return "You have reached the provisioning limit for your plan.";
            case "uploadFile":
                return "You have reached the file limit for your plan.";
            case "createFdroidRepository":
                return "You have reached the repository limit for your plan.";
            default:
                return "";
        }
    };

    return (
        <Alert severity="warning">
            {getAlertMessage()} Please visit
            <a href="https://emteria.com/p/subscription" className={classes.mailLink}>
                &nbsp;https://emteria.com/p/subscription&nbsp;
            </a>
            to extend your subscription and allow for additional items.
        </Alert>
    );
};
