import React from "react";
import { useField } from "react-final-form";
import { shallowEqual, useSelector } from "react-redux";
import { FileInfo } from "src/ui/containers/dashboard/files/types";

import { selectFiles } from "@dashboard/files/store/selectors";
import { BuildExecutableBinaryFile } from "@dashboard/products/types/index";
import { SelectProps } from "@mui/material";
import ItemManagementTable from "@shared/table/ItemManagmentTable";

import TableItem from "./tableItems/TableItem";

const getFileName = (fileId: string, files: FileInfo[]) => {
    const app = files.find((item) => item.id === fileId);
    return app?.filename || "";
};

const headers = ["Name", "Action"];

export const ExecutableFiles = () => {
    const [value, setValue] = React.useState<string>("");

    const files = useSelector(selectFiles, shallowEqual).filter(
        (file) => file.contentType === "application/octet-stream",
    );

    const { input } = useField<BuildExecutableBinaryFile[]>("customBuildConfig.executables");
    const executables = Array.isArray(input.value) ? input.value : [];

    const selectedFilesIds = executables.map((item) => item.source.fileId);
    const isAllSelected = selectedFilesIds.length === files.length;
    const options = files.map((item) => ({
        label: item.filename,
        value: item.id,
        disabled: selectedFilesIds.includes(item.id),
    }));

    const onDropdownChange: SelectProps["onChange"] = (e) => {
        setValue(String(e.target.value));
    };

    const onAdd = () => {
        if (value) {
            const newFile = { source: { type: "storage", fileId: value } };
            input.onChange([...input.value, newFile]);
            setValue("");
        }
    };

    const onRemove = (fileId: string) => {
        const newApplications = [...input.value].filter((item) => item.source.fileId !== fileId);
        input.onChange(newApplications);
    };

    return (
        <ItemManagementTable
            title="Executable binaries"
            onAddItem={onAdd}
            columnHeaders={headers}
            inputType="select"
            dropdownOptions={options}
            onDropdownChange={onDropdownChange}
            isAllSelected={isAllSelected}
            placeholder="Select an executable binary file (e.g., installer.bin)"
        >
            {executables.map((app) => {
                return (
                    <TableItem
                        value={getFileName(app.source.fileId, files)}
                        onRemove={() => onRemove(app.source.fileId)}
                        key={app.source.fileId}
                    />
                );
            })}
        </ItemManagementTable>
    );
};
