import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";

import TableItem from "@dashboard/products/components/ProductDetails/fragments/tableItems/TableItem";
import ItemManagementTable from "@shared/table/ItemManagmentTable";

const regexApplicationInput = /^[a-zA-Z0-9_-]+$/;
const headers = ["Name", "Action"];

export const RemovePackages = () => {
    const [removedApplications, setRemovedApplications] = useState<string[]>([]);

    const { input } = useField<string[]>("customBuildConfig.removePackages");

    const validateInput = (value: string) => {
        if (!value) {
            return "Module name cannot be empty";
        }
        if (removedApplications.includes(value)) {
            return "Module is already in the list";
        }
        if (!regexApplicationInput.test(value)) {
            return "Module name cannot contain special characters or spaces";
        }
        return null;
    };

    const onAdd = (value: string) => {
        const updatedApplications = [...removedApplications, value];
        setRemovedApplications(updatedApplications);
        input.onChange(updatedApplications);
    };

    const onRemove = (appName: string) => {
        const newApplications = removedApplications.filter((item) => item !== appName);
        setRemovedApplications(newApplications);
        input.onChange(newApplications);
    };

    useEffect(() => {
        if (input.value && input.value.length) {
            setRemovedApplications(input.value);
        }
    }, [input.value]);

    return (
        <ItemManagementTable
            title="Excluded modules"
            onAddItem={onAdd}
            columnHeaders={headers}
            inputType="text"
            inputValidator={validateInput}
            placeholder="Enter module name to exclude (e.g., analytics_module)"
        >
            {removedApplications.map((app) => {
                return <TableItem value={app} onRemove={() => onRemove(app)} key={app} />;
            })}
        </ItemManagementTable>
    );
};
