import { useDispatch, useSelector } from "src/store";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import { resetFiltersToDefault } from "@dashboard/devices/store";
import { defaultFilter, selectFilterProperties } from "@dashboard/devices/store/selectors/filters";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FilterItem } from "./FilterItems";
import {
    deviceStatusFilterOptions,
    getFiltersCount,
    licenseStatusFilterOptions,
    policyStatusFilterOptions,
} from "./Filters.utils";

const useStyles = makeStyles((theme: Theme) => ({
    filtersContainer: {
        border: "2px solid " + theme.palette.blue[350],
        backgroundColor: theme.palette.white[50],
        minWidth: "170px",
        borderRadius: "4px",
        padding: "10px 8px",
        display: "flex",
        justifyContent: "space-between",
    },
    filtersList: {
        display: "flex",
        flex: 1,
    },
}));

export const Filters = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const filterItems = useSelector(selectFilterProperties);
    const allFiltersCount = getFiltersCount(defaultFilter.properties);
    const currentFiltersCount = getFiltersCount(filterItems);

    return (
        <Box className={classes.filtersContainer}>
            <Box className={classes.filtersList}>
                <FilterItem
                    title="Device status"
                    options={deviceStatusFilterOptions}
                    property="device_status"
                    values={filterItems["device_status"]}
                />
                <FilterItem
                    title="Policy status"
                    options={policyStatusFilterOptions}
                    property="policy_status"
                    values={filterItems["policy_status"]}
                />
                <FilterItem
                    title="License status"
                    options={licenseStatusFilterOptions}
                    property="license_activationStatus"
                    values={filterItems["license_activationStatus"]}
                />
            </Box>

            <SecondaryButton
                tooltipProps={{ style: { alignSelf: "flex-end" } }}
                onClick={() => dispatch(resetFiltersToDefault())}
                disabled={allFiltersCount === currentFiltersCount}
                size="small"
            >
                Reset filters
            </SecondaryButton>
        </Box>
    );
};
