import * as yup from "yup";

const zipCodeRegex = /^[a-zA-Z0-9\s-]+$/;

export const validationSchema = yup.object({
    name: yup.string().required().max(30),
    address: yup.string().required().max(30),
    zipCode: yup.string().required().matches(zipCodeRegex, "Invalid zip code format"),
    city: yup.string().required().max(30),
    country: yup.string().required(),
});
