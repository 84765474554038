import { useState } from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useSelector } from "src/store";
import { UploadProvider } from "src/ui/containers/dashboard/files/context/UploadContext";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectFilesByGroupId, selectFilesListStatus, selectFilesShared } from "@dashboard/files/store/selectors";
import { normalizeFiles } from "@dashboard/files/utils";
import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { selectIsWorkspaceFilesOverlimit, selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Group } from "@devices/types";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";
import Table from "@shared/table/Table";

import { FileUploadDialog } from "./components/FileUploadDialog";
import TableItem from "./components/TableItem";

const useStyles = makeStyles({
    headerBox: { display: "flex", justifyContent: "space-between", paddingBottom: 10 },
    btnBox: { display: "flex", gap: 5 },
    content: { maxHeight: "100%", overflowY: "scroll", marginBottom: 60 },
});

const FileTab = (props: Group) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

    const classes = useStyles();

    const status = useSelector(selectFilesListStatus);
    const workspace = useSelector(selectWorkspace);

    const { groupAbility } = permissionService();
    const mayManageFile = groupAbility(props, workspace).can("manage", "File");

    const groupFiles = useSelector((state: RootState) => selectFilesByGroupId(state, props.id));
    const normalizedGroupFiles = normalizeFiles(groupFiles);
    const isOverlimit = useSelector(selectIsWorkspaceFilesOverlimit);
    const sharedFiles = useSelector(selectFilesShared);
    const normalizedSharedFiles = normalizeFiles(sharedFiles);

    const file = [...groupFiles, ...sharedFiles].filter((item) => item.id === selectedFileId)[0];

    const isExpired = useSelector(selectSubscriptionExpired);

    const getAddBtnTitle = () => {
        const result = { title: "Upload file" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageFile) {
            result.title = "You are not allowed to upload files in this group";
        }
        if (isOverlimit) {
            result.title = "You have reached maximum file limit";
        }
        return result;
    };

    if (status === "pending" && !normalizedGroupFiles.length && !normalizedSharedFiles.length) {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Group files:</PaneElement.Header>
                <SecondaryButton
                    onClick={() => setIsDialogOpen(true)}
                    tooltipProps={getAddBtnTitle()}
                    disabled={!mayManageFile || isExpired || isOverlimit}
                >
                    <i className="fas fa-solid fa-square-plus" />
                </SecondaryButton>
            </Box>
            <Box className={classes.content}>
                {normalizedGroupFiles.length ? (
                    <Table<number>
                        selectedItem={props.id}
                        normalizedFiles={normalizedGroupFiles}
                        allFiles={[...sharedFiles, ...groupFiles]}
                        setSelectedFileId={setSelectedFileId}
                        component={TableItem}
                        cellProps={{ size: "small" }}
                    />
                ) : (
                    <PaneElement.NoData message="No files were uploaded yet" />
                )}

                <PaneElement.Header>Workspace files:</PaneElement.Header>
                {normalizedSharedFiles.length ? (
                    <Table<number>
                        selectedItem={props.id}
                        normalizedFiles={normalizedSharedFiles}
                        allFiles={[...sharedFiles, ...groupFiles]}
                        setSelectedFileId={setSelectedFileId}
                        component={TableItem}
                        cellProps={{ size: "small" }}
                    />
                ) : (
                    <PaneElement.NoData message="No files were uploaded yet" />
                )}
            </Box>
            <UploadProvider>
                <FileUploadDialog open={isDialogOpen} handleClose={setIsDialogOpen} groupId={props.id} />
            </UploadProvider>
            {selectedFileId ? <FileSettingsDialog open onClose={() => setSelectedFileId(null)} item={file} /> : null}
        </PaneElement.Container>
    );
};
export default FileTab;
