import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { RootState, useDispatch } from "src/store";
import { limitType } from "src/ui/utils/limitType";

import {
    deleteUniversalLicense,
    generateLicenses,
    revokeLicense,
    updateUniversalLicense,
} from "@dashboard/devices/store/index";
import { selectSubscriptionLimitByLimitType } from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@dashboard/devices/types/index";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { selectGroups } from "@groups/store/selectors";
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { permissionService } from "@services/permissionService/permissionService";
import { DangerButton, DangerOutlinedButton, PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { ControlledSelectField } from "@shared/form/SelectField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";
import { PaneElement } from "@sidePanel/shared/PaneElement";

const useStyles = makeStyles({
    paragraph: { marginBottom: 12 },
    label: { marginBottom: 8, marginTop: 8 },
    form: { display: "flex", flexDirection: "column", gap: 4 },
    btnBox: { display: "flex", gap: 4, alignSelf: "flex-end" },
});

interface FormValues {
    groupId: number | null;
}
const LicenseTab = (license: License) => {
    const [openRevokeDialog, setOpenRevokeDialog] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isRevoking, setIsRevoking] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);

    const permission = permissionService();
    const canManageLicense = permission.licenseAbility(license).can("manage", "License");

    const licensesLimit = useSelector((state: RootState) =>
        workspace?.subscription
            ? selectSubscriptionLimitByLimitType(state, workspace.subscription, limitType.LicenseLimit)
            : 0,
    );
    const revocation = licensesLimit > 200 ? "self-governing" : "on request";
    const isUniversal = Boolean(license.universalGroupName);

    const groups = useSelector(selectGroups);
    const groupOptions = groups.map((item) => ({
        label: item.name,
        value: item.id,
        disabled: permission.groupAbility(item, workspace).cannot("manage", "UniversalLicense"),
    }));
    const avaibleGroups = groups.filter((item) =>
        permission.groupAbility(item, workspace).can("manage", "UniversalLicense"),
    );

    const onRevoke = async () => {
        try {
            setIsRevoking(true);
            await dispatch(revokeLicense({ licenseId: license.id })).unwrap();
            await dispatch(generateLicenses({ groupId: license.groupId, grantCount: 1 })).unwrap();
            showSuccessToast("License has been revoked and a replacement license has been issued");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
            return;
        } finally {
            setIsRevoking(false);
            setOpenRevokeDialog(false);
        }
    };
    const onSubmit = async (values: FormValues) => {
        setIsUpdating(true);
        await dispatch(updateUniversalLicense({ id: license.id, groupId: values.groupId }))
            .unwrap()
            .then(() => showSuccessToast("Target group changed successful"))
            .catch(({ message = "Target group change failed" }) => showErrorToast(message))
            .finally(() => setIsUpdating(false));
    };
    const onRemove = async (form: FormApi<FormValues>) => {
        if (!isUniversal) {
            form.reset({ groupId: license.universalGroupId });
        } else {
            setIsRemoving(true);
            await dispatch(deleteUniversalLicense(license.id))
                .unwrap()
                .then(() => showSuccessToast("License is not universal anymore"))
                .catch(({ message = "Failed to remove the universal license. Please try again" }) =>
                    showErrorToast(message),
                )
                .finally(() => setIsRemoving(false));
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !isRevoking) {
            await onRevoke();
        }
        if (e.key === "Escape") {
            setOpenRevokeDialog(false);
        }
    };

    return (
        <PaneElement.Container>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={{ groupId: license.universalGroupId }}
                render={({ handleSubmit, values, form }) => (
                    <React.Fragment>
                        <PaneElement.Section>
                            <PaneElement.Header>Revoke license</PaneElement.Header>
                            {revocation === "on request" ? (
                                <Alert severity="info">
                                    Please reach out to <a href="mailto:support@emteria.com">support@emteria.com</a>{" "}
                                    with the license code and a short explanation for license revocation.
                                </Alert>
                            ) : null}
                            <Typography className={classes.paragraph}>
                                If your device is broken beyond repair, its associated license should be revoked. This
                                will permanently hide the broken device from the list of all devices and allow a license
                                replacement.
                            </Typography>
                            {revocation === "self-governing" ? (
                                <div className={classes.btnBox}>
                                    <DangerOutlinedButton
                                        onClick={() => setOpenRevokeDialog(true)}
                                        disabled={!canManageLicense}
                                        tooltipProps={{
                                            hide: canManageLicense,
                                            title: !canManageLicense
                                                ? "Your role does not allow you to revoke licenses"
                                                : "This license has already been revoked",
                                        }}
                                    >
                                        Revoke
                                    </DangerOutlinedButton>
                                </div>
                            ) : null}
                        </PaneElement.Section>
                        <PaneElement.Section withoutBorder>
                            <PaneElement.Header>Universal status</PaneElement.Header>
                            <Typography className={classes.paragraph}>
                                A{" "}
                                <a
                                    href="https://emteria.com/kb/create-universal-license#ul"
                                    rel="noreferrer nofollow"
                                    target="_blank"
                                >
                                    Universal License
                                </a>{" "}
                                enables automatic device enrollment into the selected group. Select a target group to
                                make this license universal:
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Box className={classes.form}>
                                    <Tooltip
                                        title="You have no permission to change target group"
                                        hide={!!avaibleGroups.length}
                                    >
                                        <ControlledSelectField
                                            name="groupId"
                                            options={groupOptions}
                                            disabled={!avaibleGroups.length}
                                            formControlProps={{ fullWidth: true }}
                                            placeholder="This license is not universal"
                                        />
                                    </Tooltip>
                                    <Box className={classes.btnBox}>
                                        <DangerOutlinedButton
                                            onClick={() => onRemove(form)}
                                            loading={isRemoving}
                                            disabled={isRemoving || isUpdating || !canManageLicense || !isUniversal}
                                            tooltipProps={{
                                                hide: isUniversal && canManageLicense,
                                                title: !canManageLicense
                                                    ? "You have no permission to remove the universal license"
                                                    : "This license is not universal",
                                            }}
                                        >
                                            Remove
                                        </DangerOutlinedButton>

                                        <PrimaryButton
                                            type="submit"
                                            loading={isUpdating}
                                            disabled={
                                                isRemoving ||
                                                isUpdating ||
                                                license.universalGroupId === values.groupId ||
                                                !canManageLicense
                                            }
                                            tooltipProps={{
                                                hide: license.universalGroupId !== values.groupId && canManageLicense,
                                                title: !canManageLicense
                                                    ? "You have no permission to change the universal license"
                                                    : "Target group didn't changed",
                                            }}
                                        >
                                            Change
                                        </PrimaryButton>
                                    </Box>
                                </Box>
                            </form>
                        </PaneElement.Section>
                        <Dialog
                            open={openRevokeDialog}
                            onClose={() => setOpenRevokeDialog(false)}
                            onKeyDown={onKeyDown}
                        >
                            <DialogTitle>Revoke License</DialogTitle>
                            <DialogContent>
                                <Typography>
                                    Revoking the license will remove this device from the list of your devices. Revoking
                                    a license cannot be undone and should only be used for broken devices. Are you sure
                                    you want to revoke this license?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <SecondaryButton onClick={() => setOpenRevokeDialog(false)} loading={isRevoking}>
                                    Abort
                                </SecondaryButton>

                                <DangerButton onClick={onRevoke} loading={isRevoking} disabled={isRevoking}>
                                    Revoke
                                </DangerButton>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )}
            />
        </PaneElement.Container>
    );
};
export default LicenseTab;
