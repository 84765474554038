import { Component } from "react";
import { SolutionBuilderContent } from "./SolutionBuilderContent";

import "./solution.css";

class SolutionBuilderPage extends Component {
    render() {
        return (
            <main className="product-builder-main">
                <SolutionBuilderContent />
            </main>
        );
    }
}

export default SolutionBuilderPage;
