import { getHomepageUrl } from "src/services/configService";
import ApmLink from "src/ui/shared/ApmLink";
import useMetaTags from "src/ui/utils/useMetaTags";

import { PageContainer } from "@dashboard/shared/styles";
import { Box } from "@mui/material";

import RegisterForm from "./RegisterForm";
import { useStyles } from "./RegisterPage.style";

const RegisterPage = () => {
    const classes = useStyles();
    useMetaTags({
        title: "Register | Get started for free",
        description:
            "Register for free to get emteria's Android OS and device management platform. Enjoy seamless integration, enhanced security, and powerful management tools.",
    });

    return (
        <PageContainer className={classes.container}>
            <Box className={classes.section}>
                <ApmLink href={getHomepageUrl()}>
                    <img src="./img/logos/emteria-colored.webp" alt="emteria logo" />
                </ApmLink>

                <Box className={classes.formBox}>
                    <RegisterForm />
                    <span className={classes.infoText}>
                        After creating your account, you will receive an email with further instructions.
                    </span>
                    <span className={classes.infoText}>No credit card required.</span>
                </Box>
                <span className={classes.footerText}>
                    Already have an account?
                    <ApmLink role="link" parent="Registration page" title="Sign in" to="/login">
                        {" "}
                        Sign in
                    </ApmLink>
                </span>
            </Box>
            <Box className={classes.separator} />
            <Box className={classes.section}>
                <Box className={classes.listBox}>
                    <Box className={classes.listDescriptionBox}>
                        <h1 className={classes.rightSideTitle}>Get started for free</h1>
                        <span className={classes.listDescription}>
                            Just three steps to deploy and manage your Android devices:
                        </span>
                    </Box>
                    <Box className={classes.listRow}>
                        <Box className={classes.listCircle}>
                            <span className={classes.circleText}>1</span>
                        </Box>
                        <span className={classes.listText}>
                            Create an account and receive a free starter plan for your first 3 devices.
                        </span>
                    </Box>
                    <Box className={classes.listRow}>
                        <Box className={classes.listCircle}>
                            <span className={classes.circleText}>2</span>
                        </Box>
                        <span className={classes.listText}>
                            Install emteria.OS on your devices using the emteria installer application.
                        </span>
                    </Box>
                    <Box className={classes.listRow}>
                        <Box className={classes.listCircle}>
                            <span className={classes.circleText}>3</span>
                        </Box>
                        <span className={classes.listText}>
                            Onboard your devices to the emteria Device Hub and manage them remotely.
                        </span>
                    </Box>
                </Box>
            </Box>
        </PageContainer>
    );
};

export default RegisterPage;
