import { Dictionary } from "@reduxjs/toolkit";

export type FileInfo = API.File;
export type ProductFile = API.ProductFile;

export type Files = Dictionary<FileInfo>;
export type BuildFile = API.BuildFile & { link: string | null };
export enum FileStatus {
    Pending = 0,
    Uploaded = 1,
}
export interface FileTableItem extends FileInfo {
    uploader: string;
    groupName: string;
    version?: string;
    sha256?: string;
    codename?: string;
    visibility?: string;
}
