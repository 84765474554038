import euporiaService from "src/services/euporiaService";
import { LocalStorage } from "src/services/localStorageService";
import { RootState } from "src/store";

import { createSlice } from "@reduxjs/toolkit";

const availableFeatures = [
    { key: "LegacyManagementLink", probability: 0 },
    { key: "LegacyProvisioningLink", probability: 0 },
    { key: "LegacyDeviceOverviewLink", probability: 0 },
] as const;

const initialState = {
    LegacyManagementLink: undefined,
    LegacyProvisioningLink: undefined,
    LegacyDeviceOverviewLink: undefined,
};

type Key = (typeof availableFeatures)[number]["key"];
type LocalState = Record<Key, boolean>;

const initializeState = () => {
    const state = LocalStorage.getItem<LocalState>("betaFeatures") || initialState;

    availableFeatures.forEach((item) => {
        if (state[item.key] === undefined) {
            state[item.key] = Math.random() * 100 < item.probability;
        }
    });
    LocalStorage.setItem("betaFeatures", state);

    return state;
};

const betaSlice = createSlice({
    name: "beta",
    initialState: initializeState(),
    reducers: {
        setBetaFeatureActive(state, { payload }: { payload: { key: Key; value: boolean; userEmail: string } }) {
            state[payload.key] = payload.value;
            LocalStorage.setItem("betaFeatures", state);

            euporiaService.createTransaction("beta", {
                feature: payload.key,
                action: payload.value,
                user_email: payload.userEmail,
            });
        },
    },
});

export default betaSlice.reducer;

export const { setBetaFeatureActive } = betaSlice.actions;

export const getBetaFeature = (state: RootState, key: Key) => state.beta[key];
export const selectLegacyDeviceOverviewEnabled = (state: RootState) =>
    getBetaFeature(state, "LegacyDeviceOverviewLink");
export const selectLegacyManagementEnabled = (state: RootState) => getBetaFeature(state, "LegacyManagementLink");
export const selectLegacyProvisioningEnabled = (state: RootState) => getBetaFeature(state, "LegacyProvisioningLink");
