import clsx from "clsx";
import { FormApi } from "final-form";
import { Dispatch, SetStateAction } from "react";
import { Form } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { selectMergedReleasesFiltersByProperty, updateFilterProperty } from "@dashboard/devices/store";
import { Box, Checkbox, Dialog, DialogProps, FormControlLabel, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CustomBadge } from "@shared/CustomBadge";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { CheckboxField } from "@shared/form/CheckboxField";

import { ReleasesFilterTypes } from "./Filter.utils";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        width: 410,
        minHeight: 300,
        margin: "12px 0px",
        background: theme.palette.shadow[50],
        borderRadius: "6px",
    },
    container: {
        padding: 20,
        minHeight: 400,
        maxHeight: 550,
    },
    dialogTitleBox: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        fontSize: 20,
    },
    informationText: {
        fontWeight: 600,
    },
    closeBtn: {
        cursor: "pointer",
        color: "black",
        fontSize: 20,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "scroll",
        overflowX: "hidden",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
    },
    title: {
        marginLeft: 10,
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
    tagIcon: {
        display: "inline",
        color: theme.palette.black[100],
    },
}));

interface FormValues {
    [x: string]: boolean;
}

interface Props extends DialogProps {
    title: string;
    options: string[];
    property: ReleasesFilterTypes;
    setOpenFilterDialog: Dispatch<SetStateAction<boolean>>;
}
export const FilterDialog = ({ title, options, property, setOpenFilterDialog, ...props }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const filters = useSelector(
        (state) => selectMergedReleasesFiltersByProperty(state)(property, options),
        shallowEqual,
    );
    const filtersArr = Object.keys(filters);
    const truthyFilters = Object.keys(filters).reduce((acc, key) => ({ ...acc, [key]: true }), {});
    const falsyFilters = Object.keys(filters).reduce((acc, key) => ({ ...acc, [key]: false }), {});

    const onSubmit = (values: FormValues) => {
        const newValues = Object.keys(values).filter((key) => values[key]);

        dispatch(
            updateFilterProperty({
                property: property,
                values: newValues,
            }),
        );
        setOpenFilterDialog(false);
    };

    const handleCloseButton = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset(filters);
        setOpenFilterDialog(false);
    };

    return (
        <>
            <CustomBadge
                className={classes.filterBadge}
                badgeContent={Object.values(filters).filter((item) => item).length}
                onClick={() => setOpenFilterDialog(true)}
                overlap="rectangular"
            >
                <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
            </CustomBadge>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={filters}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <Dialog
                            {...props}
                            onClose={(_event, reason) => reason === "backdropClick" && handleCloseButton(form)}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className={classes.container}>
                                    <div className={classes.dialogTitleBox}>
                                        <Typography className={classes.title}>{title}</Typography>
                                        <div
                                            className={clsx("fa-solid fa-times", classes.closeBtn)}
                                            onClick={() => handleCloseButton(form)}
                                        />
                                    </div>
                                    <div className={classes.content}>
                                        <div className={classes.checkboxContainer}>
                                            {filtersArr.map((item) => (
                                                <CheckboxField key={item} name={`[${item}]`} label={item} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    <Box className={classes.buttonBox}>
                                        <SecondaryButton onClick={() => handleCloseButton(form)}>
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit">Save</PrimaryButton>
                                    </Box>
                                </div>
                            </form>
                        </Dialog>
                    );
                }}
            />
        </>
    );
};
