import { init } from "@elastic/apm-rum";
import { v4 as uuidv4 } from "uuid";
import IdleTracker from "idle-tracker";

import { getEnv } from "./configService";
import authService from "./authService";
import { LocalStorage } from "./localStorageService";

let navigationIndex = 1;
const navigationHistory = {};

let activeSeconds = 0;
let totalActiveSeconds = 0;
let isRunning = false;
let timer = null;

function startActivityTimer() {
    if (!isRunning) {
        timer = setInterval(() => {
            activeSeconds = activeSeconds + 1;
        }, 1000);
        isRunning = true;
    }
}

function stopActivityTimer() {
    if (isRunning) {
        clearInterval(timer);
        isRunning = false;
    }
}

const initIdleTracker = () => {
    const idleTracker = new IdleTracker({
        timeout: 20000,
        onIdleCallback: ({ idle: isIdle }) => {
            if (isIdle) {
                stopActivityTimer();
            } else {
                startActivityTimer();
            }
        },
    });

    idleTracker.start();
};

const setEndpointId = (id) => {
    LocalStorage.setItem("endpoint", id);
};

const getEndpointId = () => {
    let id = LocalStorage.getItem("endpoint");
    if (!id) {
        id = uuidv4();
        setEndpointId(id);
    }

    return id;
};

const setCampaignId = (id) => {
    LocalStorage.setItem("campaign", id);
};

const getCampaignId = () => {
    return LocalStorage.getItem("campaign");
};

const recordCampaignId = (campaign) => {
    const existingId = getCampaignId();
    if (!existingId) {
        setCampaignId(campaign);
    }
};

const isInTest = () => {
    return process.env.JEST_WORKER_ID !== undefined;
};

const apm = isInTest()
    ? null
    : init({
          serverUrl: "https://euporia.emteria.com:8200",
          serviceName: getEnv().toLowerCase().trimRight() + "-portal",
          serviceVersion: "0.1",
      });

const createTransaction = (name, labels) => {
    const transaction = apm.startTransaction(name, "custom");
    if (transaction) {
        transaction.addLabels(labels);
        transaction.end(new Date().getTime() + 1);
    }
};

const initApmContext = () => {
    apm.observe("transaction:start", (transaction) => {
        // route-change transactions are usually discarded as they have no spans included
        // however, we can force sending them by pretending that no sampling was required
        if (transaction.type === "route-change") {
            transaction.sampled = false;
        }
    });
    apm.observe("transaction:end", (transaction) => {
        // before sending navigation transactions to the server, we add some additional context
        // this way we can build a history of all pages visited by this user
        if (transaction.type === "page-load" || transaction.type === "route-change") {
            const index = navigationIndex - 1;
            totalActiveSeconds += activeSeconds;

            navigationHistory["page"] = navigationHistory["navigation_history_" + index];
            navigationHistory["page_time"] = activeSeconds;
            navigationHistory["total_time"] = totalActiveSeconds;
            navigationHistory["navigation_history_" + navigationIndex] = transaction.name;

            // we only transmit data for the first 20 pages to euporia
            if (navigationIndex <= 20) {
                transaction.addLabels(navigationHistory);
            }

            navigationIndex++;
            activeSeconds = 0;
        }
    });
};

const updateUserContext = () => {
    const user = authService.getCurrentUser();
    if (user && user.sub != getEndpointId()) {
        setEndpointId(user.sub);
    }

    const endpoint = getEndpointId();
    const context = { id: endpoint };

    const email = user ? user.email : null;
    if (email) {
        context.email = email;
    }

    apm.setUserContext(context);
};

const updateCustomContext = () => {
    const context = {};

    const campaign = getCampaignId();
    if (campaign) {
        context.campaign = campaign;
    }

    const filled = Object.keys(context).length > 0;
    if (filled) {
        apm.setCustomContext(context);
    }
};

const euporiaService = {
    recordCampaignId,
    initApmContext,
    updateUserContext,
    updateCustomContext,
    createTransaction,
    initIdleTracker,
};

export default euporiaService;
