import "./jsonEditor.css";

import { rawSchema } from "src/data/commands";

import { JSONEditor } from "@json-editor/json-editor/dist/jsoneditor.js";

export enum JsonEditorCommandType {
    Examples,
    ComplexCommands,
    Raw,
}

export const updateEditor = (
    simpleEditor: JSONEditor | null,
    editorRef: React.RefObject<HTMLDivElement>,
    editorType: JsonEditorCommandType,
    commandsArr?: { name: string; schema: Record<string, unknown> }[],
    commandType?: string,
) => {
    if (simpleEditor) {
        simpleEditor.destroy();
    }

    if (!editorRef.current) {
        return null;
    }

    switch (editorType) {
        case JsonEditorCommandType.Examples:
            return new JSONEditor(editorRef.current, {
                schema: commandsArr?.find((obj) => obj.name == commandType)?.schema,
                compact: true,
                disable_collapse: true,
                disable_edit_json: true,
                disable_properties: true,
                show_errors: "change",
                theme: "barebones",
            });
        case JsonEditorCommandType.ComplexCommands:
            return new JSONEditor(editorRef.current, {
                schema: commandsArr?.find((obj) => obj.name == commandType)?.schema,
                disable_collapse: true,
                disable_edit_json: true,
                show_errors: "change",
                theme: "barebones",
            });
        case JsonEditorCommandType.Raw:
            return new JSONEditor(editorRef.current, {
                schema: rawSchema,
                disable_edit_json: false,
                disable_collapse: true,
                show_errors: "always",
                theme: "barebones",
                iconlib: "fontawesome5",
            });
    }
};

// validate if editor is empty && parsable
export const validateEditor = (editor: { validate: () => Array<Record<string, unknown>> } | null) => {
    const errors = editor && editor.validate();
    if (errors?.length) {
        return errors;
    }
};
