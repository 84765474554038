import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    sortLabel: {
        "& svg": {
            color: theme.palette.white[50] + " !important",
        },
        fontWeight: 500,
    },
    badge: {
        marginLeft: 10,
        paddingBottom: 3,
        display: "inline",
    },
    tagIcon: {
        display: "inline",
        color: theme.palette.black[100],
    },
    typeCell: {
        width: "50px !important",
    },
    withPointer: {
        cursor: "pointer",
    },
}));
