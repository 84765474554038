import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse } from "src/types";

import { unpackError } from "@dashboard/devices/api/helpers";

import { Workspace } from "../types";

export type FetchWorkspacesParams = { includeTerminated: boolean };
export const fetchWorkspaces = async (params?: FetchWorkspacesParams) => {
    try {
        const endpoint = await RouteService.getWorkspacesRoute();
        const { data } = await httpService.get<ApiResponse<API.Workspace[]>>(endpoint, {
            params,
        });
        return data.model || [];
    } catch (error) {
        throw unpackError(error);
    }
};
type UpdateResponse = AxiosResponse<{ workspace: API.Workspace }>;
export const updateWorkspace = async (payload: Workspace) => {
    const { id, ...rest } = payload;
    try {
        const endpoint = await RouteService.getWorkspacesRoute();
        await httpService.put<Omit<Workspace, "id">, UpdateResponse>(endpoint + String(id), rest);
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};

export interface MemberUpdatePayload {
    workspaceId: number;
    addedMembers?: Record<string, API.GroupMember["role"]>;
    changedMembers?: Record<string, API.GroupMember["role"]>;
    removedMembers?: string[];
}
export const updateWorkspaceMembers = async (payload: MemberUpdatePayload) => {
    const { workspaceId, ...updateData } = payload;
    try {
        const endpoint = await RouteService.getWorkspacesRoute();
        const response = await httpService.patch<typeof updateData, AxiosResponse<ApiResponse<API.Workspace>>>(
            endpoint + `${workspaceId}/members`,
            updateData,
        );
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const checkWorkspaceGuid = async (payload: string) => {
    try {
        const endpoint = await RouteService.getWorkspacesRoute();
        const response = await httpService.get<ApiResponse<boolean>>(endpoint + `guids/${payload}/availability`);
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
