import React, { useState } from "react";
import { useDispatch } from "src/store";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { claimStarterSubscription } from "@dashboard/devices/store";
import { ConfirmClaimDialog } from "@dashboard/overview/components/ConfirmClaimDialog";
import { fetchWorkspaces } from "@dashboard/workspaces/store";
import { Button, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        width: "100%",
        maxWidth: "600px",
        minHeight: "150px",
        margin: "0 auto",
        marginTop: 20,
    },
    message: {
        marginBottom: 14,
        textAlign: "center",
        color: theme.palette.text.primary,
    },
    button: {
        marginTop: 16,
    },
}));
const FreshAccountMessage: React.FC = () => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const confirmFreePlanClaim = async () => {
        try {
            await dispatch(claimStarterSubscription()).unwrap();
            await dispatch(fetchWorkspaces()).unwrap();
            showSuccessToast("Starter subscription successfully claimed");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Couldn't claim the starter subscription");
        } finally {
            setShowModal(false);
        }
    };

    return (
        <>
            <Paper className={classes.container} elevation={3}>
                <Typography className={classes.message}>
                    Get started by asking a collegue to invite you to their workspace, or use the button below to claim
                    your free plan.
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => setShowModal(true)}
                >
                    Start free plan now
                </Button>
            </Paper>
            <ConfirmClaimDialog onConfirm={confirmFreePlanClaim} open={showModal} onClose={() => setShowModal(false)} />
        </>
    );
};

export default FreshAccountMessage;
