import clsx from "clsx";
import React, { useEffect } from "react";
import { Form } from "react-final-form";
import { simpleCommands } from "src/data/commands";
import { SelectField } from "src/ui/shared/form/SelectField";

import { Device, Group } from "@dashboard/devices/types";
import { JSONEditor } from "@json-editor/json-editor/dist/jsoneditor.js";
import { Box, Typography } from "@mui/material";
import { showErrorToast } from "@shared/toasts/Toasts";

import { JsonEditorCommandType, updateEditor, validateEditor } from "../helpers/jsonEditor";
import { useCommands } from "../hooks";
import { FormValues } from "../NewCommandDialog";
import { InputItemContainer, useStyles } from "../styles";

let simpleEditor: JSONEditor | null = null;

type Props = {
    submitRef: React.RefObject<HTMLButtonElement>;
    editorRef: React.RefObject<HTMLDivElement>;
    device?: Device;
    group?: Group;
    isPolicy?: boolean;
    toggleDialog: () => void;
};

export const Examples = ({ device, group, submitRef, editorRef, isPolicy, toggleDialog }: Props) => {
    const styles = useStyles();
    const policyCommands = group?.policy || [];

    const { createMdmCommand, updatePolicyCommands } = useCommands();

    useEffect(() => {
        simpleEditor = updateEditor(
            simpleEditor,
            editorRef,
            JsonEditorCommandType.Examples,
            simpleCommands,
            simpleCommands[0].name,
        );

        // destroy the editor to prevent duplicates when component is unmounting
        return () => {
            if (simpleEditor) {
                simpleEditor.destroy();
            }
        };
    }, [device, group, editorRef]);

    const onSubmit = async (values: FormValues) => {
        const errors = validateEditor(simpleEditor);
        if (errors) {
            showErrorToast(`Error: validation failed: ${errors[0]?.message as string} `);
            return;
        }

        const input = simpleEditor && simpleEditor.getValue();
        if (!input) {
            showErrorToast("Command content is empty");
            return;
        }

        const commandTemplate = simpleCommands.find((obj) => obj.name == values.commandType)?.template;
        if (!commandTemplate) {
            showErrorToast("Command template not found");
            return;
        }

        // Merge with the params from the template
        const payload = { ...commandTemplate, ...input };

        if (isPolicy && group) {
            const policyPayload = Array.isArray(policyCommands) && [...policyCommands, payload];
            await updatePolicyCommands({ groupId: group.id, policy: JSON.stringify(policyPayload) });
        } else {
            await createMdmCommand({ groupId: group?.id, deviceId: device?.id, json: payload });
        }
        toggleDialog();
    };

    return (
        <React.Fragment>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={{ commandType: simpleCommands[0].name }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} id="editCommand">
                        <div className={styles.sectionContainer}>
                            <InputItemContainer>
                                <Box className={clsx([styles.input, styles.select])}>
                                    <div className="form-group">
                                        <Typography style={{ marginBottom: 5 }} variant="subtitle1">
                                            <b>Command type</b>
                                        </Typography>
                                        <SelectField
                                            name="commandType"
                                            className="form-control"
                                            options={simpleCommands.map((obj) => ({
                                                value: obj.name,
                                                label: obj.schema.title,
                                            }))}
                                            onChange={() => {
                                                simpleEditor = updateEditor(
                                                    simpleEditor,
                                                    editorRef,
                                                    JsonEditorCommandType.Examples,
                                                    simpleCommands,
                                                    form.getFieldState("commandType")?.value ?? simpleCommands[0].name,
                                                );
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                </Box>
                            </InputItemContainer>
                            <InputItemContainer style={{ paddingBottom: 15 }}>
                                <Typography className={styles.commandParametersLabel} variant="subtitle1">
                                    <b>Command parameters</b>
                                </Typography>
                                <div id="json-editor" ref={editorRef}>
                                    {/* filled out by jsoneditor */}
                                </div>
                            </InputItemContainer>
                            <button ref={submitRef} type="submit" hidden />
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};
