import _ from "lodash";
import { RootState } from "src/store";

import { selectGroupById } from "@dashboard/groups/store/selectors";
import { productsAdapter } from "@dashboard/products/store";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

const defaultSelectors = productsAdapter.getSelectors<RootState>((state) => state.products.list.products.items);

const selectProducts = createSelector(
    [(state: RootState) => state, (state: RootState) => state.products.list.products.items.entities],
    (state, products) => {
        const workspace = selectWorkspace(state);
        const arr = Object.values(products).filter((item) => item?.workspaceId === workspace?.id);
        return _.compact(arr);
    },
);
const selectProductsByGroupId = createSelector(
    [defaultSelectors.selectAll, (state: RootState, id: number) => selectGroupById(state, id)],
    (products, group) => {
        return products.filter((product) => product.groupId === group?.id);
    },
);
const selectProductChangelogsById = (state: RootState, productId: number) => {
    const products = selectProducts(state);
    return products.find((product) => product.id === productId)?.changes || null;
};

export { selectProductChangelogsById, selectProducts, selectProductsByGroupId };
