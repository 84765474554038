import { styled, Theme } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import makeStyles from "@mui/styles/makeStyles";

export const Accordion = styled(MuiAccordion)({
    boxShadow: "none",
    backgroundColor: "transparent",
    margin: "0 !important",

    "&:before": {
        display: "none",
    },
});
export const AccordionSummary = styled(MuiAccordionSummary)({
    padding: "0",
    minHeight: "auto !important",
});
export const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0",
});

export const useStyles = makeStyles((theme: Theme) => ({
    toggleIcon: {
        color: theme.palette.blue[150],
        fontSize: "22px",
        transform: "rotate(0)",
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        position: "relative",
        top: "2px",

        "&.toggleOpen": {
            transform: "rotate(90deg)",
        },
    },
    itemCheckbox: {
        width: "42px",
        height: "42px",
    },
    accordion: {
        width: "100%",
    },
    container: { margin: "0.25rem 0", display: "flex", width: "100%" },
    summaryBox: { display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" },
    expandBox: { display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", maxHeight: "40px" },
    detailsBox: { marginBottom: 20 },
}));
