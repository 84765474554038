import { PropsWithChildren } from "react";
import { Tooltip } from "src/ui/shared/Tooltip";

import { TableCell, TooltipProps } from "@mui/material";

interface Props {
    title: string;
    maxLength?: number;
    maxWidth?: number;
    testId?: string;
    style?: React.CSSProperties;
    tooltipProps?: Omit<TooltipProps, "children" | "title">;
}

export const CellWithTooltip = ({ maxLength = 1000, ...rest }: PropsWithChildren<Props>) => {
    return (
        <TableCell style={rest.style}>
            <Tooltip
                placement="top"
                title={rest.title}
                hide={rest.title.length <= maxLength}
                {...rest.tooltipProps}
                style={{
                    maxWidth: rest.maxWidth,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    ...rest.tooltipProps?.style,
                }}
            >
                <span data-testid={rest.testId}>{rest.children}</span>
            </Tooltip>
        </TableCell>
    );
};
