import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        margin: "0 auto",
    },
    card: { width: 400 },
    description: { margin: 0, marginBottom: 20 },
    linksContainer: {
        margin: 0,
        marginTop: 30,
        padding: "0 20px",
    },
    header: {
        backgroundColor: theme.palette.shadow[100],
        color: theme.palette.blue[200],
        padding: "10px 16px 6px 16px",
    },
    logo: {
        paddingBottom: 40,
    },
}));
