import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { DangerOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { deleteBorder } from "@dashboard/shared/styles";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { invokeFileDownload } from "@files/api";
import { useDeleteFile } from "@files/hooks/entities";
import { FileInfo } from "@files/types";
import { hasApkMetadata, hasUnsuccessfulAnalyzer, isApk } from "@files/utils";
import { selectGroupById } from "@groups/store/selectors";
import { Box, TableCell } from "@mui/material";
import { permissionService } from "@services/permissionService/permissionService";
import { DeviceOrNumber } from "@shared/table/Table";
import { selectWorkspace } from "@workspaces/store/selectors";

import { useStyles } from "../FileTab.style";
import { PickInstallationTypeDialog } from "./PickInstallationTypeDialog";

type Props<T extends DeviceOrNumber> = {
    index: number;
    collapseOpen: number;
    setDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    file: FileInfo;
    selectedItem: T;
    setSelectedFileId: React.Dispatch<React.SetStateAction<string | null>>;
};
export const ActionsCell = <T extends number>(props: Props<T>) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInstallationTypeModalOpen, setIsInstallationTypeModalOpen] = useState(false);
    const { removeFile } = useDeleteFile();
    const classes = useStyles();
    const group = useSelector((state: RootState) => selectGroupById(state, props.selectedItem));
    const isApkFile = isApk(props.file.filename);
    const unsuccessfulAnalyzerExist = hasUnsuccessfulAnalyzer(props.file);
    const apkMetadataExist = hasApkMetadata(props.file);
    const [loading, setLoading] = useState(false);

    const isExpired = useSelector(selectSubscriptionExpired);

    const workspace = useSelector(selectWorkspace);
    const { groupAbility, fileAbility } = permissionService();
    const mayManageFile = groupAbility(group || null, workspace).can("manage", "File");
    const mayManageTags = fileAbility(props.file).can("manage", "Tag");

    const toggleModal = () => setIsModalOpen((prev) => !prev);
    const toggleInstallationTypeModal = () => setIsInstallationTypeModalOpen((prev) => !prev);
    const handleDelete = async () => {
        await removeFile(props.file.id);
    };
    const handleFileDownload = async () => {
        setLoading(true);
        await invokeFileDownload(props.file.id, props.file.filename);
        setLoading(false);
    };

    const getInstallButtonProps = () => {
        if (groupAbility(group || null, workspace).cannot("install", "App")) {
            return {
                tooltip: "You are not allowed to install applications in this group",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        }
        if (isExpired) {
            return {
                tooltip: "Subscription expired",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        }
        if (unsuccessfulAnalyzerExist) {
            return {
                tooltip: "Processing, please wait...",
                iconClass: "fas fa-spinner fa-spin",
                disabled: true,
            };
        }
        if (!apkMetadataExist) {
            return {
                tooltip: "Invalid package",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        } else {
            return {
                tooltip: "Install to all devices in this group",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: false,
            };
        }
    };
    const getTagBtnTitle = () => {
        const result = { title: "Manage tags" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageTags) {
            result.title = "You are not allowed to manage tags in this group";
        }
        return result;
    };
    const getDownloadBtnTitle = () => {
        const result = { title: "Download file" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        return result;
    };

    return (
        <>
            <TableCell align="right" style={deleteBorder(props.index, props.collapseOpen)}>
                <Box className={classes.buttonBox}>
                    {isApkFile && (
                        <SecondaryButton
                            onClick={toggleInstallationTypeModal}
                            tooltipProps={{ title: getInstallButtonProps().tooltip }}
                            disabled={getInstallButtonProps().disabled}
                        >
                            <i className={getInstallButtonProps().iconClass} />
                        </SecondaryButton>
                    )}
                    <SecondaryButton
                        onClick={() => props.setSelectedFileId(props.file.id)}
                        tooltipProps={getTagBtnTitle()}
                        disabled={!mayManageTags || isExpired}
                    >
                        <i className="fa-solid fa-gear" />
                    </SecondaryButton>
                    <SecondaryButton
                        onClick={() => handleFileDownload()}
                        tooltipProps={getDownloadBtnTitle()}
                        disabled={loading || isExpired}
                        loading={loading}
                    >
                        <i className="fa-solid fa-download" />
                    </SecondaryButton>
                    <SecondaryButton
                        tooltipProps={{ title: "Show details" }}
                        onClick={() => props.setDetailsModalOpen(true)}
                    >
                        <i className="fas fa-info-circle" />
                    </SecondaryButton>
                    <DangerOutlinedButton
                        tooltipProps={{
                            title: mayManageFile ? "Delete file" : "You are not allowed to delete files in this group",
                        }}
                        onClick={toggleModal}
                        disabled={!mayManageFile}
                    >
                        <i className="fa-solid fa-trash-alt" />
                    </DangerOutlinedButton>
                </Box>
            </TableCell>
            <ConfirmDialog
                title="Delete file"
                content={
                    <p>
                        This will delete <b>{props.file.filename}</b>. Are you sure?
                    </p>
                }
                dangerButton
                primaryActionText="Delete"
                open={isModalOpen}
                onConfirm={handleDelete}
                onClose={toggleModal}
            />
            {group ? (
                <PickInstallationTypeDialog
                    file={props.file}
                    group={group}
                    open={isInstallationTypeModalOpen}
                    onClose={toggleInstallationTypeModal}
                />
            ) : null}
        </>
    );
};
