import { DateTime } from "luxon";

import { Icon, IconSize } from "@devices/components/shared";
import { CellWithTooltip } from "@devices/components/shared/CellWithTooltip";
import { convertToLocalDate, timeElapsedFromNow } from "@devices/utils/dates";
import { Box, BoxProps, Paper, TableContainer as MuiTableContainer, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingIcon } from "@shared/Loading";

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        flexDirection: "column",
        position: "relative",
        height: "100%",
        display: "flex",
    },
    loadingContainer: {
        minHeight: 120,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    noDataContainer: {
        minHeight: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        borderBottomStyle: "solid",
        borderBottomColor: "lightGray",
        borderBottomWidth: 1,
        marginBottom: 10,
        paddingBottom: 10,
    },
    sectionWithoutBorder: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
    },
    timeElapsed: {
        fontSize: 14,
    },
    tableContainer: { maxHeight: "100%", marginBottom: 60 },
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.blue[50],
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
    },
}));

const Container = (props: React.PropsWithChildren) => {
    const classes = useStyles();
    return <Box className={classes.container}>{props.children}</Box>;
};
const TableContainer = (props: React.PropsWithChildren<object> & { style?: React.CSSProperties }) => {
    const classes = useStyles();
    return (
        <MuiTableContainer component={Paper} className={classes.tableContainer} style={props.style}>
            {props.children}
        </MuiTableContainer>
    );
};
const Section = ({ withoutBorder, ...props }: React.PropsWithChildren<BoxProps & { withoutBorder?: boolean }>) => {
    const classes = useStyles();
    return (
        <Box className={withoutBorder ? classes.sectionWithoutBorder : classes.section} {...props}>
            {props.children}
        </Box>
    );
};
const Loader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.loadingContainer}>
            <LoadingIcon size={IconSize.medium} />
        </Box>
    );
};
const Header = (props: React.PropsWithChildren<object>) => {
    return (
        <span
            style={{
                fontWeight: 600,
                display: "block",
                marginBottom: 10,
                fontSize: 16,
            }}
        >
            {props.children}
        </span>
    );
};
const Text = (props: React.PropsWithChildren<object>) => {
    return <span>{props.children}</span>;
};
const NoData = (props: { message: string }) => {
    const classes = useStyles();
    return (
        <Box className={classes.noDataContainer}>
            <Text>{props.message}</Text>
        </Box>
    );
};
const TabTitle = (props: { title: string; icon: string }) => {
    return (
        <Box style={{ display: "flex", alignItems: "center" }}>
            <Icon name={props.icon} color="primary" size={IconSize.xsmall} />
            <Typography style={{ marginLeft: 5, fontSize: 14, fontWeight: "bold" }}>{props.title}</Typography>
        </Box>
    );
};
const CellWithTimeElapsed = (props: { date: string; style?: React.CSSProperties }) => {
    const date = DateTime.fromISO(props.date);

    return (
        <CellWithTooltip style={props.style} title={convertToLocalDate(props.date, "ISO", "date")}>
            <Typography style={{ fontSize: 16 }}>{timeElapsedFromNow(date)}</Typography>
        </CellWithTooltip>
    );
};
const HeaderBox = (props: React.PropsWithChildren<object>) => {
    const classes = useStyles();
    return <Box className={classes.headerBox}>{props.children}</Box>;
};

export const PaneElement = {
    CellWithTimeElapsed,
    TableContainer,
    Container,
    TabTitle,
    Section,
    Loader,
    NoData,
    Header,
    HeaderBox,
    Text,
};
