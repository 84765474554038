import React, { createContext, useContext, useState } from "react";
import { showWarningToast } from "src/ui/shared/toasts/Toasts";

interface UploadContextProps {
    abortController: AbortController;
    uploadStarted: boolean;
    uploadProgress: number;
    uploadedFileIds: string[];
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>;
    setUploadProgress: React.Dispatch<React.SetStateAction<number>>;
    setUploadedFileIds: React.Dispatch<React.SetStateAction<string[]>>;
    cancelUpload: () => void;
}

const UploadContext = createContext<UploadContextProps | undefined>(undefined);

export const UploadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [abortController, setAbortController] = useState(new AbortController());
    const [uploadStarted, setUploadStarted] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileIds, setUploadedFileIds] = useState<string[]>([]);

    const cancelUpload = () => {
        abortController.abort();
        setUploadProgress(0);
        setUploadStarted(false);
        setAbortController(new AbortController());
        showWarningToast("Upload canceled");
    };

    return (
        <UploadContext.Provider
            value={{
                abortController,
                uploadStarted,
                uploadProgress,
                uploadedFileIds,
                setUploadStarted,
                setUploadProgress,
                setUploadedFileIds,
                cancelUpload,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export const useUploadContext = () => {
    const context = useContext(UploadContext);
    if (!context) {
        throw new Error("useUpload must be used within an UploadProvider");
    }
    return context;
};
