import { useSelector } from "react-redux";
import { RootState } from "src/store";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { Device } from "@devices/types";
import { selectFilesByGroupIdWithShared, selectFilesListStatus } from "@files/store/selectors";
import { isApk } from "@files/utils";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withExpiredWrapper } from "@shared/ExpiredWrapper";
import { TableHeader } from "@shared/table/TableHeader";

import AppTabTableItem from "./AppTabTableItem/AppTabTableItem";

const useStyles = makeStyles({
    content: { maxHeight: "100%", overflowY: "scroll", marginBottom: 60 },
    systemAppsContainer: {
        marginTop: 10,
    },
});

interface Props {
    device: Device;
    groupId: number;
}
const AppTab = (props: Props) => {
    const status = useSelector(selectFilesListStatus);

    const classes = useStyles();

    const files = useSelector((state: RootState) => selectFilesByGroupIdWithShared(state, props.groupId));
    const apkFiles = files.filter((file) => isApk(file.filename));

    const packages = props.device.lastState?.packages;
    const systemApps = packages?.filter((app) => app.system);
    const sortedSystemApps = systemApps?.slice().sort((a, b) => a.label.localeCompare(b.label));
    const installedApps = packages?.filter((app) => !app.system);
    const sortedInstalledApps = installedApps?.slice().sort((a, b) => a.label.localeCompare(b.label));

    if (status === "pending") {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.content}>
                <PaneElement.Header>Installed applications</PaneElement.Header>
                {sortedInstalledApps?.length ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHeader>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedInstalledApps.map((deviceApp, index) => (
                                    <AppTabTableItem
                                        deviceApp={deviceApp}
                                        index={index}
                                        key={index}
                                        deviceId={props.device.id}
                                        apkFiles={apkFiles}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <PaneElement.NoData message="No apps installed" />
                )}
                <Box className={classes.systemAppsContainer}>
                    <PaneElement.Header>System applications</PaneElement.Header>
                </Box>
                {sortedSystemApps?.length ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHeader>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedSystemApps.map((deviceApp, index) => (
                                    <AppTabTableItem
                                        deviceApp={deviceApp}
                                        index={index}
                                        key={index}
                                        deviceId={props.device.id}
                                        apkFiles={apkFiles}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <PaneElement.NoData message="No apps installed" />
                )}
            </Box>
        </PaneElement.Container>
    );
};

export default withExpiredWrapper(AppTab);
